export const relations = [
  {id: 0, descriptionFr: 'Aucune', descriptionEn: 'None'},
  {id: 1, descriptionFr: 'Ami', descriptionEn: 'Friend'},
  {id: 2, descriptionFr: 'Cousin', descriptionEn: 'Cousin'},
  {id: 3, descriptionFr: 'Enfant', descriptionEn: 'Child'},
  {id: 4, descriptionFr: 'Frère', descriptionEn: 'Brother'},
  {id: 5, descriptionFr: 'Parent', descriptionEn: 'Parent'},
  {id: 6, descriptionFr: 'Soeur', descriptionEn: 'Sister'},
  {id: 7, descriptionFr: 'Conjoint', descriptionEn: 'Spouse'},
  {id: 8, descriptionFr: 'Petit-enfant', descriptionEn: 'Grandchild'},
];

export const relationsByIds = relations.reduce((relationsById, relation) => (relationsById[relation.id] = relation, relationsById), {});