import React, { useEffect, useState } from "react";
import withNavigation from '../higher-order/navigation';
import Popup from 'reactjs-popup';
import { getUniteProduits } from "../../server/services/unite-service";
import OptionalProductRow from './produit-optionnel-row.js';
import PopupHeader from "./popup-header.js";
import PopupFooter from "./popup-footer.js";
import "./produit-optionnel.css";

// temporary values test
//const produits = [
//  { idProduit: 1, description: "Chauffage du gazebo (pris à l'heure')", prix: 5.00, check: true },
//  { idProduit: 6, description: "BBQ (prix fixe)", prix: 20, check: true },
//  { idProduit: 2, description: "Sac de bois", prix: 10.00 },
//  { idProduit: 3, description: "invité", prix: 10.00 },
//]

function mapProductQuantityPairArrToDict(productQuantityPairArr) {
  if (productQuantityPairArr.length === 0)
    return {};
  let dict = {};
  for (let i = 0; i < productQuantityPairArr.length; i++) 
    dict[productQuantityPairArr[i].idProduit] = productQuantityPairArr[i].quantite;
  
  return dict;
}

function OptionalProduct(props) {
  const [open, setOpen] = useState(props.open);
  const [fetched, setFetched] = useState(false);
  const [optionalProduct, setOptionalProduct] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(props.selectedProducts);
  const [previousQuantities, setPreviousQuantities] = useState(props.selectedProducts); 

  useEffect(
    () => {
      setOpen(props.open);
      setSelectedProducts(props.selectedProducts);
      setPreviousQuantities(mapProductQuantityPairArrToDict(props.selectedProducts));
    },
    [props.selectedProducts, props.open]);

  useEffect(
    () => {
      if (props.idUnite == null) {
        setFetched(true);
        handleContinue();
      }
      else {
        getUniteProduits(props.idUnite, props.startDate, props.endDate)
          .then(products => {
            const optionalProduct = products && Object.keys(products).length === 0 ? [] : products;
        
            setOptionalProduct(optionalProduct);
            
            if (optionalProduct.lenght === 0)
              handleContinue();
        
            setFetched(true);
          })
          .catch(console.log);
      }
    },
    [props.idZec, props.idUnite, props.startDate, props.endDate]);

  function handleChange(idProduit, quantite) {
    for (let i = 0; i < selectedProducts.length; i++) {
      if (selectedProducts[i].idProduit === idProduit) {
        let newArray = selectedProducts;
        if (quantite === 0) 
          newArray.splice(i, 1);
        else if (quantite > 0) 
          newArray[i].quantite = quantite;
        
        setSelectedProducts(newArray);
        return;
      }
    }

    if (quantite > 0) {
      for (let i = 0; i < optionalProduct.length; i++) {
        if (optionalProduct[i].idProduit === idProduit)
          setSelectedProducts([
            ...selectedProducts,
            { ...optionalProduct[i], quantite }
          ]);
      }
    }
  }

  function handleClose() {
    props.setSelectedProducts(selectedProducts);
    props.handleClose();
  }

  function handleContinue() {
    props.setSelectedProducts(selectedProducts);
    props.openMemberGroup();
    props.handleClose();
  }

  // Éviter de render le popup lorsque non nécessaire
  if (!open || !fetched || optionalProduct.length === 0)
    return <div />;

  // Génère le warning suivant dans la console: "Warning: Cannot update during an existing state transition (such as within `render`). Render methods should be a pure function of props and state."
  //else if (optionalProduct.length === 0)
  //  handleContinue();
  
  return (
    <Popup
      content="test"
      modal
      nested
      on='click'
      onClose={handleClose}
      open={open}>
      <div className="fixed-panel" >
        <div className="dark-container" onClick={handleClose}>
        </div>
        <div className="item-popup-div">
          <div className="item-popup-background">
            <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 0%' }}>
              <PopupHeader
                title="Produits optionnels"
                onClick={handleClose}
              />
              <div className="item-popup-details">
                <p className="item-popup-desc">
                  Ajouter des produits optionnels à la réservation
                    </p>
                <table className="table">
                  <thead>
                    <tr className="table-header">
                      <th className="table-header-produit">Produit</th>
                      <th className="table-header-prix">Prix unitaire</th>
                      <th className="table-header-quantite">Quantité</th>
                    </tr>
                  </thead>
                  <tbody>
                    {optionalProduct.map((product, key) => (
                      <OptionalProductRow
                        key={key}
                        product={product}
                        selectedHours={props.selectedHours}
                        previousQuantity={previousQuantities[product.idProduit]}
                        handleChange={handleChange}/>
                    ))}
                  </tbody>
                </table>
              </div>
              <PopupFooter title="CONTINUER" onClick={handleContinue} />
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default withNavigation(OptionalProduct);