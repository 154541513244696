import React, { Component } from "react";
import OptionTitle from "./option-title.js";
import OptionCard from './option-card.js';
import "./option-container.css";

class OptionContainer extends Component {
  
  render() {
    let gridTemplateColumns = [];
    for (let i = 0; i < this.props.cards.length; i++) {
      let gridTemp = '130px';
      for (let y = 1; y < this.props.cards[i].items.length; y++) {
        gridTemp += ' 130px';
      }
      gridTemplateColumns.push(gridTemp);
    }

    return (
      this.props.cards.map((card, index) => (
        <div className="jss2612" key={index}>
          <OptionTitle number={index + 1} name={card.name}
            isChosen={this.props.optionsIndex[index]}
          />
          <div className="jss2619">
            <div className="jss2620" style={{ gridTemplateColumns: gridTemplateColumns[index] }}>
              {card.items.map((item, cardIndex) =>
                <OptionCard
                  key={cardIndex}
                  name={card.name}
                  handleClick={this.props.handleClick}
                  isChosen={this.props.optionsIndex[index] === item.name}
                  item={item}
                  optionIndex={index}
                  index={cardIndex} />
              )}
            </div>
          </div>
          {/* <div className="aSeparator"></div> */}
          <div className="intersections">     </div>
        </div>
      ))
    );
  }
}

export default OptionContainer;