import {get} from '../../server/api.js';
import {SET_FETCHED_PROVINCES_CONFIG_WEB, SET_PROVINCES_CONFIG_WEB} from "./types";

function setFetchedProvinces() {
  return {
    type: SET_FETCHED_PROVINCES_CONFIG_WEB
  };
}

function fetchProvinces({idZec}) { // Utiliser un objet comme argument afin d'être facilement extensible
  return dispatch => get(`/${idZec}/ProvinceConfigWeb`).then(provinces => dispatch(setProvinces({provinces})));
}

function setProvinces({provinces}) { // Utiliser un objet comme argument afin d'être facilement extensible
  return {
    type: SET_PROVINCES_CONFIG_WEB,
    provinces: provinces,
  }
}

export function getProvincesConfigWeb({idZec}) { // Utiliser un objet comme argument afin d'être facilement extensible
  return (dispatch, getState) => {
    if (getState().provincesConfigWeb.fetched)
      return Promise.resolve({provinces: getState().provincesConfigWeb.provinces});

    dispatch(setFetchedProvinces());

    return dispatch(
      fetchProvinces({idZec})
    ).then(() =>
      ({provinces: getState().provincesConfigWeb.provinces})
    );
  };
}