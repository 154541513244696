import React from "react";
import { connect } from "react-redux";
import Card from './card.js';
import "../../css/card-container.css";

function mapStateToProps(state, ownProps) {
  return {
  };
}

function CardContainer(props) {
  return (
    <div className="card-container">
      <div className="card-sub-container">
        {props.cards.map(card => <Card {...card} />)}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(CardContainer);