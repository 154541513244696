import {
  QTE,
  QTEMAX,
  HEURE_FIN,
  SET_RELOAD,
  HEURE_DEBUT,
  SET_ID_UNITE,
  SET_IDUNITECATEGORIE,
  DISPONIBILITES_UNITES,
} from "../../components/actions/types";

const initialState = {
  qte:1,
  qteMax:0,
  idUnite:[],
  heureFin: '',
  reload:false,
  heureDebut: '',
  idUniteCategorie:'',
  disponibiliteUnites: [],
};

export default function (state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_RELOAD:
      newState = {
        ...state,
        reload: action.value,
      }
      return newState || state;
    case HEURE_DEBUT:
      newState = {
        ...state,
        heureDebut: action.value,
      };
      return newState || state;
    case HEURE_FIN:
      newState = {
        ...state,
        heureFin: action.value,
      };
      return newState || state;
      case SET_IDUNITECATEGORIE :
        newState = {
            ...state,
            idUniteCategorie:action.value
        }
        return newState || state;
        case QTE :
          newState = {
              ...state,
              qte:action.value
          }
          return newState || state;
          case QTEMAX :
            newState = {
                ...state,
                qteMax:action.value
            }
            return newState || state;
          case SET_ID_UNITE: 
          newState = {
            ...state,
            idUnite: action.value
          }
          return newState || state
          case DISPONIBILITES_UNITES: 
          newState = {
            ...state,
            disponibiliteUnites: action.value
          }
          return newState || state
    default:
      return state;
  }
}
