import React from "react";
import {DIV_NAME, INPUT_NAME, LABEL_NAME, MANDATORY_MISSING} from "./info-client-constants";
import FieldError, {MandatoryFieldAsterisk} from './info-client-field-check';

function InfoClientName(props) {
    return (
        <div className={DIV_NAME}>
            <label className={LABEL_NAME}>
                {(props.label || props.name).toUpperCase() + " : "}{<MandatoryFieldAsterisk/>}
                {props.error && <FieldError message={props.error}/>}
            </label>
            <input
                className={props.error ? `${INPUT_NAME} ${MANDATORY_MISSING}` : INPUT_NAME}
                maxLength="40"
                name={props.name}
                onChange={props.onChange}
                placeholder={props.placeholder}
                required
                type="text"
                value={props.value}
            />
        </div>
    );
}

export default InfoClientName;