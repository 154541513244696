import React, {useContext} from 'react';
import Calendar from "./calendar.js";
import UniteIcons from "./unite-icons.js";
import loadingImage from "../../images/loading.svg";

// Ce composant permet de rendre les autres punites sauf les activites

const RenderAnotherUnitChoice = (props) => {
    const  {
        images,
        language,
        thumbnail,
        classModifier,
        idUnite,
        indisponibilites,
        selectedArrivee,
        selectedDepart,
        onDayClick,
        heureArrivee,
        heureDepart,
        maxJoursEnAvanceReservation,
        calendrierInitialMonth,
        minDate,
        maxSearchDate,
        isActivite,
        showCalendrier,
        minSearchDate,
        nom,
    } = props
    return (
        <>
                {/* images */}
                {(images || []).map((image, index) => (
                    <div key={index} className={"unite-card-thumbnail" + classModifier}>
                        <img className={thumbnail ? "" : "loading"} src={thumbnail || loadingImage} alt="thumbnail" />
                    </div>
                ))}

                {/* description */}
                <div className={"unite-card-info" + classModifier}>
                    <div className="unite-card-info-nom">
                        <span>{nom}</span>
                    </div>
                    <div className={"unite-card-info-description" + classModifier}>
                        {props.description.split("\n").map((item, key) => (
                            <span key={key}>
                                {item}
                                <br />
                            </span>
                        ))}
                    </div>
                    {props.showIcons && (
                        <div className="unite-card-info-icons">
                            <UniteIcons afficherFumeur={props.typeHebergement !== 'CP'} afficherNbPersonne={true} nombrePersonnes={props.nombrePersonnes} animaux={props.animaux} fumeur={props.fumeur} />
                        </div>
                    )}
                </div>

            {/* Calendrier droit */}
            <div className="unite-card-disponibilites">
                <Calendar
                    minDate={minDate}
                    idUnite={idUnite}
                    language={language}
                    onDayClick={onDayClick}
                    heureDepart={heureDepart}
                    heureArrivee={heureArrivee}
                    maxSearchDate={maxSearchDate}
                    minSearchDate={minSearchDate}
                    selectedDepart={selectedDepart}
                    selectedArrivee={selectedArrivee}
                    indisponibilites={indisponibilites}
                    initialMonth={calendrierInitialMonth}
                    key={[selectedDepart, selectedArrivee, calendrierInitialMonth]}
                    maxJoursEnAvanceReservation={parseInt(maxJoursEnAvanceReservation || 0)}
                />
            </div>
        </>
    )
}

export default RenderAnotherUnitChoice