import React, { Component } from "react";
import { connect } from "react-redux";
import withNavigation from "./higher-order/navigation";
import SVG from "react-inlinesvg";
import Zoomer from "./zoomer.js";
import $ from "jquery";
import "../../css/choix-unite-carte.css";
import { setSeoTitle } from "../utils/document";
import { descriptionHebergement } from "../constants/reservation-constants";
import loadingImage from "../../images/loading.svg";

function mapStateToProps(state) {
  return {
    entrepriseName: state.entreprise.nom
  };
}

function cap(value, min, max) {
  if (value < min) return min;

  if (value > max) return max;

  return value;
}

class ChoixUniteCarte extends Component {
  constructor(props) {
    super(props);

    this.onMapImageLoad = this.onMapImageLoad.bind(this);
    this.onMapDrag = this.onMapDrag.bind(this);
    this.onMapDragStart = this.onMapDragStart.bind(this);
    this.onMapDragEnd = this.onMapDragEnd.bind(this);
    this.onPlusClick = this.onPlusClick.bind(this);
    this.onMinusClick = this.onMinusClick.bind(this);

    this.currentUnitesPaths = [];
    this.currentUnitesTextPaths = [];

    this.state = {
      zoom: 1,
      offsetX: 0,
      offsetY: 0,
      dragMouseX: 0,
      dragMouseY: 0,
      isDragging: false,
      mapLoaded: false
      //touches: {}, // {key: value} = {[event.touches[n].identifier]: {x: float, y: float, startX: float, startY: float}}
    };
  }

  componentDidMount() {
    setSeoTitle(this.props.entrepriseName, "Réservation", `Carte des ${descriptionHebergement[this.props.getTypeHebergement()]}`);
    this.transparentImg = new Image(0, 0);
    this.transparentImg.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
    //this.mapRef.addEventListener("touchstart", this.onMapTouchMove, {passive: true});
  }

  componentWillUnmount() {
    //this.mapRef.removeEventListener("touchstart", this.onMapTouchMove);
  }

  componentDidUpdate(prevProps) {
    if (this.props.unites !== prevProps.unites) {
      this.initUnitesPathsOnMap();
    }
  }

  //resetTouches(e) {
  //    const touches = {};
  //
  //    for (var i = 0; i < e.touches.length; i++) {
  //        touches[e.touches[i].identifier] = {
  //            x: e.touches[i].clientX,
  //            y: e.touches[i].clientY,
  //            startX: e.touches[i].clientX,
  //            startY: e.touches[i].clientY,
  //        };
  //    }
  //
  //    this.setState({
  //        touches: touches,
  //    });
  //}

  updateMap(newClientX, newClientY, newZoom) {
    if (this.mapWrapper === undefined)
      // Image not fully loaded yet
      return;

    const zoom = cap(newZoom, 1, 5);

    const minLeft = (this.mapWrapper.offsetWidth / zoom - this.mapWrapper.offsetWidth) / 2;
    const maxLeft = (this.mapWrapper.offsetWidth - this.mapWrapper.offsetWidth / zoom) / 2;
    const minTop = (this.mapWrapper.offsetHeight / zoom - this.mapWrapper.offsetHeight) / 2;
    const maxTop = (this.mapWrapper.offsetHeight - this.mapWrapper.offsetHeight / zoom) / 2;

    const left = cap(this.state.offsetX - this.state.dragMouseX + newClientX, minLeft, maxLeft);
    const top = cap(this.state.offsetY - this.state.dragMouseY + newClientY, minTop, maxTop);

    const isDragEnd = this.state.isDragging && newClientX === 0 && newClientY === 0;

    if (!isDragEnd) {
      this.setState({
        offsetX: left,
        offsetY: top,
        dragMouseX: newClientX,
        dragMouseY: newClientY,
        zoom: zoom
      });
    }
  }

  getUniteSelector(codeUnite) {
    codeUnite = codeUnite.replace(" ", "_");
    //return (`g[id="U_${code}"] > :not(g[id="T_${code}"]), g[id="U_${code}"] g > :not([id="T_${code}"]) `);

    return (`g[id="U_x5F_${codeUnite}"],
                g[id="U_x5F_${codeUnite}"] > g:not([id^="T_x5F_"]) *,
                path[id="U_x5F_${codeUnite}"],
                polygon[id="U_x5F_${codeUnite}"],
                polyline[id="U_x5F_${codeUnite}"],
                g[id="U_x5F_${codeUnite}"] > path,
                g[id="U_x5F_${codeUnite}"] > rect,
                g[id="U_x5F_${codeUnite}"] > polygon,
                g[id="U_x5F_${codeUnite}"] > polyline,
                g[id="U_x5F_${codeUnite}"] > ellipse,
                g[id="U_x5F_${codeUnite}"] > circle`);
  }

  getUniteTextSelector(codeUnite) {
    codeUnite = codeUnite.replace(" ", "_");
    return `text[id="T_x5F_${codeUnite}"], path[id="T_x5F_${codeUnite}"], g[id="T_x5F_${codeUnite}"] *`;
  }

  initUnitesPathsOnMap() {
    this.removeCurrentUnites();
    this.saveNewUnitesOriginalStyles();
    this.drawNewUnites();
  }

  removeCurrentUnites() {
    const component = this;

    this.currentUnitesPaths.forEach(({
      codeUnite,
      originalStyle
    }) => {
      $(component.getUniteSelector(codeUnite)).removeClass("disponible indisponible partiellement-disponible selected").off("mouseenter mouseleave click touchend");
    });

    this.currentUnitesTextPaths.forEach(({
      codeUnite,
      originalStyle
    }) => {
      $(component.getUniteTextSelector(codeUnite)).off("mouseenter mouseleave click touchend");
    });
  }

  saveNewUnitesOriginalStyles() {
    const component = this;

    this.currentUnitesPaths = this.props.unites
      .filter((unite) => $(component.getUniteSelector(unite.code_unite)) !== undefined)
      .map((unite) => ({
        codeUnite: unite.code_unite,
        originalStyle: $(component.getUniteSelector(unite.code_unite)).css(["stroke", "fill", "stroke-width", "zIndex", "fill", "cursor", "fontWeight"])
      }));

    this.currentUnitesTextPaths = this.props.unites
      .filter((unite) => $(component.getUniteTextSelector(unite.code_unite)) !== undefined)
      .map((unite) => ({
        codeUnite: unite.code_unite,
        originalStyle: $(component.getUniteTextSelector(unite.code_unite)).css(["fill", "stroke", "strokeWidth", "cursor", "strokeWidth"])
      }));
  }

  drawNewUnites() {
    const component = this;
    const params = new URLSearchParams(this.props.location.search);
    const idUnite = params.get("idUnite");

    this.props.unites.forEach((unite) => {
      const isSelected = idUnite === unite.id_unite;

      //couleur du background
      const minDisponible = unite.periodeDisponible !== false && unite.equipementDisponible !== false && unite.longueurDisponible !== false;
      const disponible = minDisponible && unite.serviceDisponible !== false && unite.amperageDisponible !== false && unite.adultesDisponible !== false && unite.enfantsDisponible !== false;
      $(component.getUniteSelector(unite.code_unite))
        .attr("class", (unite.availabilityLevel || "disponible") + " " + (isSelected ? "selected" : ""))
        .css({ fill: "" })
        .off("click touchend");

      //couleur du texte g[id="U_x5F_' + unite.code_unite + '"] text,
      $(component.getUniteTextSelector(unite.code_unite))
        .attr("class", (isSelected ? "selected" : ""))
        .css({
          fill: "",
          "stroke-width": ""
        })
        .attr("fill", "")
        .off("click touchend");

      if ((unite.availabilityLevel || "disponible") === "disponible" || (unite.availabilityLevel || "disponible") === "partiellement-disponible") {
        $(component.getUniteSelector(unite.code_unite)).on("click touchend", (e) => {
          component.props.gotoChoixUnite(unite.type_hebergement, unite.id_type_unite, unite.id_unite);
        });
        $(component.getUniteTextSelector(unite.code_unite)).on("click touchend", (e) => {
          component.props.gotoChoixUnite(unite.type_hebergement, unite.id_type_unite, unite.id_unite);
        });
      }
    });
  }

  onMapImageLoad() {
    this.initUnitesPathsOnMap();
    this.setState({
      mapLoaded: true
    });
  }

  onMapDrag(e) {
    this.updateMap(e.clientX, e.clientY, this.state.zoom);
  }

  onMapDragStart(e) {
    e.dataTransfer.setDragImage(this.transparentImg, 0, 0);

    this.setState({
      dragMouseX: e.clientX,
      dragMouseY: e.clientY,
      isDragging: true
    });
  }

  onMapDragEnd(e) {
    this.setState({
      isDragging: false
    });
  }

  onPlusClick() {
    this.updateMap(this.state.dragMouseX, this.state.dragMouseY, this.state.zoom * 1.2);
  }

  onMinusClick() {
    this.updateMap(this.state.dragMouseX, this.state.dragMouseY, this.state.zoom / 1.2);
  }

  render() {
    return (<div style={{ display: "flex", flexDirection: "column" }}>
      <div className={"legende"} style={{ paddingTop: "1rem", flexWrap: "wrap" }}>
        <div className={"legende"}>
          <div className={"legende disponible"} />
          <div>Disponible et corresponds à vos critères de recherche</div>
        </div>
        <div className={"legende"}>
          <div className={"legende partiellement-disponible"} />
          <div>Disponible et corresponds partiellement à vos critères de recherche</div>
        </div>
        <div className={"legende"}>
          <div className={"legende indisponible"} />
          <div>Disponible mais ne corresponds pas à vos critères de recherche</div>
        </div>
        <div className={"legende"}>
          <div className={"legende reserve"} />
          <div>Déjà réservé</div>
        </div>
      </div>
      <div id="choix-unite-carte">
        {this.state.mapLoaded &&
          <Zoomer onPlusClick={this.onPlusClick} onMinusClick={this.onMinusClick} />}

        <div
          ref={(e) => (this.mapWrapper = e)}
          style={{
            transform: "scale(" + this.state.zoom + ") translate(" + this.state.offsetX + "px, " + this.state.offsetY + "px)",
            transition: this.state.isDragging ? "initial" : "transform 0.3s ease-out 0s"
          }}
          onDragStart={this.onMapDragStart}
          onDrag={this.onMapDrag}
          onDragEnd={this.onMapDragEnd}
          onTouchStart={this.onMapTouchStart}
          onTouchMove={this.onMapTouchMove}
          onTouchEnd={this.onMapTouchEnd}
          onTouchCancel={this.onMapTouchCancel}
          draggable={true}
        >
          {!this.props.unites || this.props.unites.length <= 0 &&
            <div style={{ position: "absolute", margin: 0, zIndex: 999, height: "100%", width: "100%", backgroundColor: "#00000055" }} id="aucune-unite-wrapper">
              <img style={{ height: "100%", filter: "brightness(0) saturate(100%) invert(90%) sepia(1%) saturate(20%) hue-rotate(290deg) brightness(104%) contrast(100%)" }} src={loadingImage} alt="chargement" />)
            </div>
          }
          <SVG src={this.props.src} onLoad={this.onMapImageLoad} />
        </div>
      </div>
    </div>
    );
  }
}

export default withNavigation(connect(mapStateToProps)(ChoixUniteCarte));
