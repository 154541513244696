import React from "react";

function PlusSvg(props) {
  let { dispatch, fill, ...otherProps } = props

  fill = fill || "rgb(0, 0, 0)";

  return (
    <svg className={props.className} fill={fill} viewBox="0 0 24 24" {...otherProps}>
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z">
      </path><path fill="none" d="M0 0h24v24H0z">
      </path>
    </svg>
  );
}

export default PlusSvg;