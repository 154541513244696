import CryptoJS from 'crypto-js';
import { ENCRYPTION_KEY } from '../constants/storage.js'

function _objectEncrypte (object) {
    let ciphertext  =  CryptoJS.AES.encrypt(JSON.stringify(object), ENCRYPTION_KEY).toString();
    return ciphertext;
}

function _objectDecrypt (ciphertext) {
    let bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTION_KEY);
    let decryptData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // const decryptData = CryptoJS.AES.decrypt(ciphertext, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    return decryptData
}

const crypto = {
    encryptObject : _objectEncrypte,
    decryptObject : _objectDecrypt
}

export default crypto;