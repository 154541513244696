import {
  //GET_PARAMETRES_WEB,
  SET_PARAMETRES_WEB,
} from '../components/actions/types';

const initialState = {
};

export default function (state = initialState, action) {
  const { type, parametresWeb } = action;
  switch (type) {
    //case GET_PARAMETRES_WEB:
    //  return {
    //    ...state,
    //  };

    case SET_PARAMETRES_WEB:
      return parametresWeb;

    default:
      return state;
  }
}