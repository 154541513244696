import React from "react";
import "../../../css/info-client.css";
import InfoAdresseInput from './info-client-adresse-input';
import InfoPays from './info-client-pays';
import InfoProvince from './info-client-province';
import InfoEmail from './info-client-email';
import InfoName from './info-client-name';
import InfoPhone from './info-client-phone';

function InfoClient(props) {
    const {infos, showPermisOrignal, showPermisCerf} = props;
    const isCanada = infos.pays.description === 'CANADA';
    const isUsaOrCanada = !infos.pays.description || isCanada || infos.pays.description === 'ETATS-UNIS';
    const isEspaceTonik = props.idZec === 'espacetonik';
    const hasError = Object.keys(props.errors).length > 0;

    function getError(info) {
        return hasError ? props.errors[info] : '';
    }

    return (
        <div id="info-client">
            <InfoName
                error={getError('prenom')}
                name="prenom"
                value={infos.prenom}
                onChange={props.updateInfo}/>
            <InfoName
                error={getError('nom')}
                name="nom"
                value={infos.nom}
                onChange={props.updateInfo}/>
            <InfoAdresseInput
                error={getError('adresse')}
                name="adresse"
                length="100"
                value={infos.adresse}
                onChange={props.updateInfo}/>
            <InfoAdresseInput
                error={getError('ville')}
                length="100"
                name="ville"
                required
                value={infos.ville}
                onChange={props.updateInfo}/>
            <InfoPays
                name="idPays"
                onChange={props.updateInfoPays}
                value={infos.pays.idPays}/>
            {isUsaOrCanada &&
                <InfoProvince
                    error={getError('idProvince')}
                    isCanada={isCanada}
                    idPays={infos.pays.idPays}
                    isUsaOrCanada={isUsaOrCanada}
                    name="idProvince"
                    value={infos.idProvince}
                    onChange={props.updateInfo}
                    updateIdProvince={props.updateIdProvince}/>
            }
            <InfoAdresseInput
                error={getError('codePostal')}
                name="codePostal"
                pays={infos.pays.description}
                value={infos.codePostal}
                onChange={props.updateInfo}/>
            <InfoEmail
                error={getError('email')}
                name="email"
                value={infos.email}
                onChange={props.updateInfo}/>
            <InfoEmail
                error={getError('emailConfirmation')}
                name="emailConfirmation"
                value={infos.emailConfirmation}
                onChange={props.updateInfo}/>
            <InfoPhone
                error={getError('telephone1')}
                isEspaceTonik={isEspaceTonik}
                name="telephone1"
                value={infos.telephone1}
                onChange={props.updateInfo}/>
            {!isEspaceTonik &&
                <InfoPhone
                    error={getError('telephone2')}
                    name="telephone2"
                    required
                    value={infos.telephone2}
                    onChange={props.updateInfo}/>
            }
            <InfoPhone
                error={getError('portable')}
                isEspaceTonik={isEspaceTonik}
                label="TELEPHONE CELLULAIRE :"
                name="portable"
                value={infos.portable}
                onChange={props.updateInfo}/>
            {showPermisOrignal && <InfoName
                error={getError('noPermisOrignal')}
                name="noPermisOrignal"
                label={"# certificat de chasse orignal"}
                value={infos.noPermis}
                onChange={props.updateInfo}/>}
            {showPermisCerf && <InfoName
                error={getError('noPermisCerf')}
                name="noPermisCerf"
                label={"# certificat de chasse Cerf de Virginie"}
                value={infos.noPermis}
                onChange={props.updateInfo}/>}
        </div>
    );
}

// champs requis
export default InfoClient;

