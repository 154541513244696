import React, { useEffect, useState } from 'react';

import jwtDecode from "jwt-decode";

//redux
import { useSelector, useDispatch } from "react-redux";
import { _setIdClient } from '../actions/fermer-enregistrement';

//composant
import FermerEnregistrement from './connecte/fermer';
import RechercheEnregistrement from './non-connecte/chercher-enregistrement';
import { getIdClient } from '../../server/services/configuration-service';

const VerifieAuthentification = () => {

    // State
    const authToken = useSelector(state => state.authToken);
    const [ernregistrementIsDne, setCheckEnregistrementIsDone] = useState(false);

    const dispatch = useDispatch();

    //useEffect
    useEffect(() => {

        // Verifie si l'utilisateur est connecte
        verifieExistenceToken()
    }, [])

    //functions
    const verifieExistenceToken = () => {

        if (authToken) {
            //recupere l'idProfile de l'utilisateur connecte
            let decodedToken = jwtDecode(authToken);
           try {
            getIdClient(decodedToken.idProfile)
            .then(ïdClient => {
                dispatch(_setIdClient(ïdClient))
                // met a jour le state pour naviguer vers l'onglet de fermeture
                setCheckEnregistrementIsDone(true)
            })
           } catch (error) {
               console.log('error', decodedToken.idProfile)
           }
        } else {
            // met a jour le state pour rechercher un enregistrement
            setCheckEnregistrementIsDone(false)
        }
    }

    return (
        <>
            {ernregistrementIsDne ? (
                <FermerEnregistrement />
            ) : (
                <RechercheEnregistrement _setCheckEnregistrementIsDne={(value) => setCheckEnregistrementIsDone(value)} />
            )
            }
        </>
    )

}

export default VerifieAuthentification
