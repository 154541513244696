import React, { useState, useEffect, Fragment } from 'react';
import Popup from 'reactjs-popup';
import ChoixRecherchePanel from '../choix-recherche-panel';
import ChampsDeRecherche from '../reservation/champ-de-recherche';
import "./unite-recherche-popup.css";
import CloseSvg from '../svg/close';

function UniteRecherchePopup(props) {
  const applySearch = () => {
    props.applySearchFilters();
    props.handleClose();
  }

  return (
    <Popup
      content="test"
      on='click'
      open={props.isOpen}
      onClose={props.handleClose}
      modal
      nested
    >
      {close => (
        <div className="fixed-panel">
          <div className="dark-container" onClick={close}>
          </div>
          <div className="item-popup-div">
            <div className="item-popup-background" style={{
              borderRadius: '0px',
              maxWidth: '600px',
              minHeight: '256px',
              maxHeight: '100%'
            }}>
              {/* <p className="item-popup-font">
                  {(props.title).toUpperCase()}
                </p> */}
              <span className="unite-recherche-popup-span">
                <CloseSvg fill='white' className='unite-recherche-popup-close' onClick={() => props.handleClose()} />
              </span>
              <ChoixRecherchePanel
                affichageClass={props.affichageClass}
                hasError={props.hasError}
                fieldError={props.fieldError}
                getInputClassName={props.getInputClassName}
                toggleCalendrierArrivee={props.toggleCalendrierArrivee}
                toggleCalendrierDepart={props.toggleCalendrierDepart}
                formatDateYMD={props.formatDateYMD}
                previewArrivee={props.previewArrivee}
                previewDepart={props.previewDepart}
                searchOptions={props.searchOptions}
                showCalendrierArrivee={props.showCalendrierArrivee}
                showCalendrierDepart={props.showCalendrierDepart}
                blurCalendrierArrivee={props.blurCalendrierArrivee}
                blurCalendrierDepart={props.blurCalendrierDepart}
                maxJoursEnAvanceReservation={props.maxJoursEnAvanceReservation}
                onDayClick={props.onDayClick}
                onHoveredDayChange={props.onHoveredDayChange}
                selectedRangeBegin={props.selectedRangeBegin}
                selectedRangeEnd={props.selectedRangeEnd}
                initialMonth={props.initialMonth}
                isSelectingRangeBegin={props.isSelectingRangeBegin}
                minDate={props.minDate}

                onDayClickDepart={props.onDayClickDepart}
                onHoveredDayChangeDepart={props.onHoveredDayChangeDepart}
                initialMonthDepart={props.initialMonthDepart}
                isSelectingRangeEnd={props.isSelectingRangeEnd}

                idUnite={props.idUnite}
                isTypeAffichageUnite={props.isTypeAffichageUnite}
                idTypeUnite={props.idTypeUnite}
                typeHebergement={props.typeHebergement}
                applySearchFilters={applySearch}
                addUniteToShoppingCart={props.addUniteToShoppingCart}
                showAddToShoppingCart={props.disponibiliteSeulement}
                resetSearchOptions={props.resetSearchOptions}
                updateSearchField={props.updateSearchField}
                updateSearchFieldExtra={props.updateSearchFieldExtra}
                consultationSeulement={props.consultationSeulement}
                peutAjouterPanier={props.peutAjouterPanier}
                language={props.language}
              />
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default UniteRecherchePopup;