import {SET_ENTREPRISE_NAME, SET_ENTREPRISE_CONTACT, SET_ENTREPRISE_LOGO, SET_ENTREPRISE_NO_ETABLISSEMENT} from "./types";

export const setEntrepriseName = entrepriseName => dispatch => {
  try {
    dispatch({
      type: SET_ENTREPRISE_NAME,
      payload: entrepriseName
    });
  }
  catch (err) {
    console.log(err);
  }
}


export const setEntrepriseContact = entrepriseContact => dispatch => {
  try {
    dispatch({
      type: SET_ENTREPRISE_CONTACT,
      payload: entrepriseContact
    });
  }
  catch (err) {
    console.log(err);
  }
}


export const setEntrepriseLogo = entrepriseLogo => dispatch => {
  try {
    dispatch({
      type: SET_ENTREPRISE_LOGO,
      payload: entrepriseLogo
    });
  }
  catch (err) {
    console.log(err);
  }
}

export const setEntrepriseNoEtablissement = entrepriseNoEtablissement => dispatch => {
  try {
    dispatch({
      type: SET_ENTREPRISE_NO_ETABLISSEMENT,
      payload: entrepriseNoEtablissement
    });
  }
  catch (err) {
    console.log(err);
  }
}
