import React, { useRef, useEffect, useState } from 'react';
import jwtDecode from "jwt-decode";

//components
import TimeInput from './time-input.js';
import LayoutFermerEnregistrement from '../layout.js';
import withNavigation from "../../higher-order/navigation.js";
import CustomInputWithCalendar from './custum-input-calendrier.js';

//redux
import { setAlert } from '../../actions/alert';
import { useDispatch, useSelector } from "react-redux";

import loadingImage from "../../../../images/loading.svg";

//constante
import { PATH_FERMER_ENREGISTREMENT, PATH_RESERVATION } from '../../../constants/navigation-constants.js';
import { postFemerEnregistrement } from '../../../server/services/fermer-enregistrement.js';



const FermerEnregistrementEtCapture = ({ goTo }) => {

    //reference
    const calendarRef = useRef(null);

    // States
    const [heure, setHeure] = useState('');
    const [date, setDate] = useState(null);
    const [load, setLoad] = useState(false);
    const [minutes, setMinutes] = useState('');
    const [heureIsSlected, setHeureIsSlected] = useState(false);
    const [dateIsSelected, setdateIsSelected] = useState(false);
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    const [enregistrementEntete, setenregistrementEntete] = useState('')

    //redux
    const dispatch = useDispatch();
    const { authToken, fermerEnregistrement: { idEnregistrementEntete } } = useSelector(state => state);

    //useEffect ---- initialisation
    useEffect(() => {
        if (idEnregistrementEntete && idEnregistrementEntete !== '' && idEnregistrementEntete !== undefined) {
            setenregistrementEntete(idEnregistrementEntete)
        }
    }, [])

    useEffect(() => {
        const handleClickOutsideCalendar = (event) => {
            if (calendarRef.current && isOpenCalendar && !calendarRef.current.contains(event.target)) {
                setIsOpenCalendar(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideCalendar);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideCalendar);
        };
    }, []);

    const formatDateToISOWithoutZ = (date) => {
        const pad = (num) => num < 10 ? '0' + num : num;

        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate());
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    };



    //function -- ferme l'enregistrement
    const _fermerEnregistrement = async () => {
        setLoad(true);
        try {
            if (authToken != null) {
                const decodedToken = jwtDecode(authToken);

                // Construire la date en format ISO 8601
                let dateFermeture = new Date(date);
                dateFermeture.setHours(heure, minutes);
                const dateFermetureISO = formatDateToISOWithoutZ(dateFermeture);

                const ParamFermerEnregistrement = {
                    IdProfile: decodedToken.idProfile,
                    DateFermeture: dateFermetureISO,
                    IdEnregistrementEntete: enregistrementEntete
                };

                const response = await postFemerEnregistrement(ParamFermerEnregistrement);

                if (response.ok) {
                    dispatch(setAlert("L'enregistrement a été fermé avec succès", 'success'));
                    return true;
                } else {
                    // Lire le corps de la réponse
                    const errorData = await response.json();
                    // Extraire le détail de l'erreur
                    const errorMessage = errorData.detail || 'Veuillez réessayer. Quelque chose a mal tourné.';
                    dispatch(setAlert(errorMessage, 'danger'));
                    return false;
                }
            }
        } catch (error) {
            dispatch(setAlert("Une erreur est survenue. Veuillez réessayer.", 'danger'));
            return false;
        } finally {
            setLoad(false);
        }

        return false;
    };


    //fonction ---- met a jour les heures et les minutes
    const handleTimeChange = (heure, minutes) => {
        if (!isNaN(heure) && !isNaN(minutes) && heure >= 0 && heure < 24 && minutes >= 0 && minutes < 60) {
            setHeure(heure);
            setMinutes(minutes);
            setHeureIsSlected(true)
        } else {
            console.log('dfkgslkjg', heure, minutes)
            dispatch(setAlert(`Veuillez entrer une heure valide`, 'danger'));
        }
    };


    //fonction ---- navigation apres la fermeture
    const navigation = async () => {
        //verifie si l'utilisateur est toujours connecte
        if (authToken) {
            const result = await _fermerEnregistrement();
            if (result) {
                //la fermeture a réussi
                goTo(PATH_FERMER_ENREGISTREMENT)
            }
        }
    }


    return (
        <LayoutFermerEnregistrement custmeClass={'fermer-enregistrement-capture-container'}>
            {
                load ?
                    <img style={{ height: "100%", filter: "brightness(0) saturate(100%) invert(90%) sepia(1%) saturate(20%) hue-rotate(290deg) brightness(104%) contrast(100%)" }} src={loadingImage} alt="chargement" />
                    :
                    <div className='fermer-enregistrement-content'>
                        {/* titre */}
                        <div class="title-section"><span class="droit-acces-span">Fermeture enregistrement et captures</span></div>

                        <div className="content-body" >
                            {/* Date */}
                            <CustomInputWithCalendar
                                dateSelected={date}
                                calendarRef={calendarRef}
                                isOpenCalendar={isOpenCalendar}
                                onChangeValue={(date) => setDate(date)}
                                setIsOpenCalendar={(value) => setIsOpenCalendar(value)}
                                setdateIsSelected={(value) => setdateIsSelected(value)}
                            />

                            {/* heure */}
                            {dateIsSelected &&
                                <TimeInput
                                    // date={dateSelected}
                                    onChangeValue={(hours, minutes) => handleTimeChange(hours, minutes)}
                                />
                            }

                            {/* button */}
                            {heureIsSlected &&
                                <button
                                    onClick={() => navigation()}
                                    className="fermer-enregistrement-button"><span class="droit-acces-span">Fermer enregistrement</span>
                                </button>
                            }

                        </div>

                    </div>
            }
        </LayoutFermerEnregistrement>
    )

}

export default withNavigation(FermerEnregistrementEtCapture);