/*
 * Ce module gère les fonctions de réservation: lecture, ajout, suppression de réservation. 
 */

import {
    api
} from "../api";

export function addReservationTemp(infoReservation) {
    return api.post("reservationTemp/addreservation", JSON.stringify(infoReservation, (key, value) => key === "noConfirmation" ? `${value}` : value));
}

export function addBilleterieTemp(infoReservation) {
    return api.post("reservationTemp/addProduitBilletterie", JSON.stringify(infoReservation));
}

export function getQuantiteTempByIdProduit(idProduit = "") {
    return api.get("reservationTemp/getQuantiteTempByIdProduit/" + idProduit);
}

export function createReservation(infoReservation, convertToJson) {
    return api.post("Reservation/Creer", JSON.stringify(infoReservation), convertToJson);
}

export function calculerReservation(infoReservation, convertToJson) {
    return api.post("Reservation/Creer", JSON.stringify(infoReservation), convertToJson);
}

/**
 * Calculer les montants du sommaire de l'unité selectionné
 * @param {any} selectedUnite Unité sélectionnée pour réservation.
 */
export function calculateSumarryAmounts(selectedUnite) {
    return api.post("ReservationEntete/CalculateAmounts", JSON.stringify(selectedUnite));
}

export function deleteReservationTemp(idsReservationTemp) {
    return api.post("ReservationTemp/deleteReservation", JSON.stringify(idsReservationTemp));
}

export function purchaseForfaits(infoPaiement) {
    console.log(new Error("La route paiement/forfaits ne concerne pas les réservations. Les forfaits proviennent du catalogue et permettent de ne pas payer lors d'un enregistrement (par exemple, un forfait de pêche donne un accès gratuite quand le client s'enregistrement pour une activité de pêche). Appeler plutôt purchaseForfaits depuis paiement-service."));
    //return api.post("paiement/forfaits", JSON.stringify(infoPaiement))
}

export function updateReservationTempTimeout(idsReservationTemp) {
    return api.post("ReservationTemp/updateExpiration", JSON.stringify(idsReservationTemp));
}

export function getReservationListe(all, limite) {
    return api.get(`ReservationEntete/getReservationListe/${all}/${limite}`);
}

export function getReservationClientInfo(idReservationEntete) {
    return api.get(`reservation/${idReservationEntete}/gestion`);
}