import React from 'react';

//style
import "./style.css"

//navigation
import withNavigation from "../higher-order/navigation";

const LayoutFermerEnregistrement = ({ children, custmeClass }) => {
    return (
        <div className='container-enregistrement'>
           {/* cadre */}
           <div className={`content-enregistrement ${custmeClass}`}>
            {children}
           </div>
        </div>
    );
};

export default LayoutFermerEnregistrement;
