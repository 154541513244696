import React, { useEffect, useState } from "react";
import { DIV_NAME, INPUT_NAME, LABEL_NAME, MANDATORY_MISSING} from "./info-client-constants";
import FieldError, { MandatoryFieldAsterisk } from './info-client-field-check';

const LabelName = {
  portable: 'TÉLÉPHONE PORTABLE :',
  telephone1: 'TÉLÉPHONE MAISON : ',
  telephone2: 'TÉLÉPHONE BUREAU : '
}

const LabelNameEspaceTonik = {
  telephone1: 'TÉLÉPHONE PRINCIPAL : ',
  portable: 'TÉLÉPHONE SECONDAIRE : '
}

function InfoClientPhone(props) {
  const [value, setValue] = useState(props.value);
  const [isTelephone1, setIsTelephone1] = useState(props.name === 'telephone1');
  function handleChange(e) {
    if (e.target.value.length <= 13) {
      props.onChange(e);
    }
  }

  useEffect(() => setValue(props.value), [props.value]);

  return (
    <div className={DIV_NAME}>
      <label className={LABEL_NAME}>
        {props.isEspaceTonik ? LabelNameEspaceTonik[props.name] : LabelName[props.name]}
        {isTelephone1 && <MandatoryFieldAsterisk />}
        {props.error && <FieldError message={props.error} />}
      </label>
      <input
       className={`hide-increment-input-number ${isTelephone1 && props.error ? `${INPUT_NAME} ${MANDATORY_MISSING}` : INPUT_NAME}`}
        maxLength="15"
        name={props.name}
        onChange={handleChange}
        required={props.required}
        type="number"
        value={value}
      />
    </div>
  );
}

export default InfoClientPhone;