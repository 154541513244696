import React, { useEffect, useState } from 'react';

//Api
import { getEnregistrement } from '../../../server/services/fermer-enregistrement'

//redux
import { setAlert } from '../../actions/alert';
import { useDispatch } from "react-redux";

//navigation
import withNavigation from "../../higher-order/navigation";

//composants
import LayoutFermerEnregistrement from '../layout'

//image
import loadingImage from "../../../../images/loading.svg";
import WarningLoginComponent from '../../auth/login';

const RechercheEnregistrement = ({ _setCheckEnregistrementIsDne}) => {
    
    // State
    const [load, setLoad] = useState (false);
    const [courriel, setCourriel] = useState('');
    const [noEnregistrement, setNoEnregistrement] = useState('');
    
    const dispatch = useDispatch();

    //functions
    const validationChamps = () => {
        if (courriel == "" || noEnregistrement == "") {
            dispatch(setAlert(`Assurez-vous que le courriel et le numéro d'enrégistrement sont valides puis réessayez à nouveau.`, 'danger'));
            return false;
        }
        setLoad(true)
        return true;
    }
    const rechercheEnregistrement = async () => {
        const result = await getEnregistrement(courriel, noEnregistrement)
        if (result) {
            // next
            setLoad(false)
            _setCheckEnregistrementIsDne(true)
            return true
        }
        setLoad(false)
        _setCheckEnregistrementIsDne(false)
        return false
    }

    const getRegistration = () => {
        if (validationChamps()) rechercheEnregistrement()

    }

    return (
        <LayoutFermerEnregistrement custmeClass={'content-recherche-enregistrement'}>
            {!load ?
                // <div style={{ maxWidth: '360px', margin: 'auto' }}>
                //     <div >
                //         <div className="paiement-comptes-page-montant-row text">
                //             <span className="text">Votre courriel:</span>
                //         </div>

                //         <div className="paiement-comptes-page-montant-row">
                //             <input type="text" className="paiement-comptes-page-input"
                //                 value={courriel}
                //                 onChange={e => setCourriel(e.target.value)} />
                //         </div>
                //     </div>

                //     <div>
                //         <div className="paiement-comptes-page-montant-row text">
                //             <span className="text">Le numéro d'enrégistrement à payer:</span>
                //         </div>

                //         <div className="paiement-comptes-page-montant-row">
                //             <input type="text" className="paiement-comptes-page-input"
                //                 value={noEnregistrement}
                //                 onChange={e => setNoEnregistrement(e.target.value)} />
                //         </div>
                //     </div>

                //     <div className="paiement-comptes-page-montant-row">
                //         <div className="btn-span-wrapper" style={{ marginTop: '0px', textAlign: 'center' }} onClick={e => getRegistration()}>
                //             <span>Rechercher</span>
                //         </div>
                //     </div>
                // </div> 
                <WarningLoginComponent/>
                :
                <img src={loadingImage} alt="chargement" />
            }
        </LayoutFermerEnregistrement>
    )

}


export default withNavigation(RechercheEnregistrement);