import {
  SET_CONFIGURATION,
} from '../components/actions/types';

const initialState = {
};

export default function (state = initialState, action) {
  const { type, configuration } = action;

  switch (type) {
    case SET_CONFIGURATION:
      return configuration;

    default:
      return state;
  }
}