import React, { useState } from "react";
import { connect } from "react-redux";
import QrReader from 'react-qr-reader';
import "../../css/lecteur-cartes-membres-page.css";

const EXEMPLE_MEMBRE = {
  nom: 'Rabbit',
  prenom: 'Roger',
  adresse: '134 Fictif',
  ville: 'Sim City',
  code_postal: 'G9R2D2',
  telephone1: '4188153039',
  telephone2: '4188152958',
  portable: '14188152176',
  pays: 'CANADA',
  province: 'QUÉBEC',
  email: 'rogerrabbit@gmail.com',
  date_naissance: '1989-07-22 00:00:00.000',
  is_membre: true,
  forfait: {
    produits: "Produit 1, Produit 2",
    gibiers: "Gibier 1, Gibier 2",
    activites: "Activite 1, Activite 2, Activite 3",
    peche_annuel: true,
    peche_annuel_hiver: true,
    circulation_annuel: false,
    camping_annuel: true,
    gerer_cache: true,
  },
};
const EXEMPLE_NON_MEMBRE = Object.assign({}, EXEMPLE_MEMBRE, {is_membre: false});
const EXEMPLE_CLIENT_NON_TROUVE = null;

const EXEMPLES = {
  '00000000-0000-0000-0000-800000000000': EXEMPLE_MEMBRE,
  '00000000-0000-0000-0000-900000000000': EXEMPLE_NON_MEMBRE,
  '00000000-0000-0000-0000-A00000000000': EXEMPLE_CLIENT_NON_TROUVE,
};

function mapStateToProps(state, ownProps) {
  return {
  };
}

function LecteurCartesMembresPage(props) {
  const [client, setClient] = useState(null);

  const onScan = data => {
    if (EXEMPLES[data])
      setClient(EXEMPLES[data]);
  }

  const onScanError = err => {
    console.error(err);
  }

  const formatPostalCode = (postalCode) => {
    var alphaNumsOnly = ('' + postalCode).replace(/[^a-zA-Z0-9]/g, '');

    var isCanadianPostalCode = alphaNumsOnly.match(/^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/);
    if (isCanadianPostalCode)
      return alphaNumsOnly.substring(0, 3) + " " + alphaNumsOnly.substring(3, 6);

    //var numsOnly = ('' + postalCode).replace(/[^0-9]/g, '');
    //
    //var isUsPostalCode = numsOnly.match(/\d{5}$/);
    //if (isUsPostalCode)
    //    return numsOnly;
    //    
    //var numsHyphensOnly = ('' + postalCode).replace(/[^0-9\-]/g, '');
    //
    //var isUsExtendedPostalCode = numsHyphensOnly.match(/\d{5}\-\d{4}$/);
    //if (isUsExtendedPostalCode)
    //    return numsHyphensOnly;

    return postalCode;
  }

  const formatPhoneNumber = (phoneNumber) => { // https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    var matches = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (matches) {
      var intlCode = (matches[1] ? '+1 ' : '');

      return [intlCode, '(', matches[2], ') ', matches[3], '-', matches[4]].join('');
    }

    return "";
  }

  const {forfait} = (client || {});

  return (
    <div id="lecteur-cartes-membres-page">
      <div id="lecteur-cartes-membres-scanner">
        <QrReader delay={300} onError={onScanError} onScan={onScan} style={{width: '100%'}} />
        {/*<p>{client === 'NON TROUVE' ? "Client non trouvé"}</p>*/}
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>NOM : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{(client || {}).nom}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>PRENOM : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{(client || {}).prenom}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>ADRESSE : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{(client || {}).adresse}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>VILLE : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{(client || {}).ville}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>CODE POSTAL : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{formatPostalCode((client || {}).code_postal)}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>TÉLÉPHONE 1 : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{formatPhoneNumber((client || {}).telephone1)}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>TÉLÉPHONE 2 : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{formatPhoneNumber((client || {}).telephone2)}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>PORTABLE : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{formatPhoneNumber((client || {}).portable)}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>PAYS : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{(client || {}).pays}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>PROVINCE : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{(client || {}).province}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>COURRIEL : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{(client || {}).email}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>DATE DE NAISSANCE : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{((client || {}).date_naissance || '').substring(0, 10)}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>MEMBRE : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{client == null ? "" : client.is_membre ? "OUI" : "NON"}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>FORFAIT PRODUITS : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{(forfait || {}).produits}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>FORFAIT GIBIERS : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{(forfait || {}).gibiers}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>FORFAIT ACTIVITÉS : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{(forfait || {}).activites}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>FORFAIT PÊCHE ANNUEL : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{forfait == null ? "" : forfait.peche_annuel ? "OUI" : "NON"}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>FORFAIT PÊCHE ANNUEL HIVER : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{forfait == null ? "" : forfait.peche_annuel_hiver ? "OUI" : "NON"}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>FORFAIT CIRCULATION ANNUEL : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{forfait == null ? "" : forfait.circulation_annuel ? "OUI" : "NON"}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>FORFAIT CAMPING ANNUEL : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{forfait == null ? "" : forfait.camping_annuel ? "OUI" : "NON"}</span>
          </div>
        </div>
      </div>
      <div id="lecteur-cartes-membres-info">
        <div className="lecteur-cartes-membres-info-row">
          <div className="lecteur-cartes-membres-info-label-wrapper">
            <span>FORFAIT GÉRER CACHE : </span>
          </div>
          <div className="lecteur-cartes-membres-info-text-wrapper">
            <span>{forfait == null ? "" : forfait.gerer_cache ? "OUI" : "NON"}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(LecteurCartesMembresPage);
