import { SAVE_PROFIL_CLIENT, CLEAR_PROFIL_CLIENT } from '../components/actions/types';

const initialState = {
    nom: "",
    prenom: "",
    telephone1: "",
    telephone2: "",
    portable: "",
    email: "",
    emailConfirmation: "",
    adresse: "",
    pays: { idPays: "", description: "" },
    ville: "",
    idProvince: "",
    codePostal: "",
  }
  
export default function(state = initialState, action) {
 let newState ;
  switch (action.type) {
    case SAVE_PROFIL_CLIENT:
        newState = {
            ...state,
            nom: action.value?.nom || "",
            prenom: action.value?.prenom || "",
            telephone1: action.value?.telephone1 || "",
            telephone2: action.value?.telephone2 || "",
            portable: action.value?.portable || "",
            email: action.value?.email || "",
            emailConfirmation: action.value?.email || "", 
            adresse: action.value?.adresse || "",
            pays: { idPays: action.value?.pays || "", description: action.value?.description || "" },
            ville: action.value?.ville || "",
            idProvince: action.value?.idProvince || "",
            codePostal: action.value?.codePostal || "",
        }
      return newState || state;
    case CLEAR_PROFIL_CLIENT:
        newState = {
            ...state,
            nom: "",
            prenom: "",
            telephone1: "",
            telephone2: "",
            portable: "",
            email: "",
            emailConfirmation: "",
            adresse: "",
            pays: { idPays: "", description: "" },
            ville: "",
            idProvince: "",
            codePostal: "",
        }
      return newState || state;
    default:
      return state;
  }
}