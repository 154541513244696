import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import "./filter-left-panel.css";
import FilterPanelMobilePopup from "../popups/filter-panel-mobile-popup";
import datas from "./items.json";
import ListSvg from '../svg/list';

function FilterLeftPanel(props) {

  const [isLoading, setIsLoading] = useState(true);
  const [isSortPopupOpen, setIsSortPopupOpen] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const search = decodeURI(history.location.search);
    let checkboxes = datas;
    let searchString = "";

    checkboxes.forEach(data => {
      data.values.forEach(value => {
        if (search.includes(value.name)) { value['checked'] = true; searchString += value.value + ','; }
        else value['checked'] = false;
      })
    })
    setCheckboxes(checkboxes);
    setIsLoading(false);

    //if (searchString === "") props.getForfaits();
    props.getForfaits(false); // Toujours recharger les forfaits, sinon quoi, sur un reload de la page, la liste des forfaits est vide, ce qui fait crasher plus loin quand on lit un forfait apr�s ajout dans le panier.
    props.applyFilter(searchString);
  }, []);

  const removeAllFilter = () => {
    checkboxes.forEach(checkbox => {
      checkbox.values.forEach(cur => {
        cur.checked = false;
      });
    });
    setCheckboxes(checkboxes);
    history.push({ pathname: history.location.pathname });
  }

  const applyFilter = () => {
    let filteredArray = []
    let searchString = "";
    checkboxes.forEach(checkbox => {
      checkbox.values.forEach(current => {
        if (current.checked) {
          filteredArray.push(current.name);
          searchString += current.value + ','
        }
      })
    })
    if (filteredArray.length === 0) {
      history.push({
        pathname: history.location.pathname
      })
      props.getForfaits();
    } else {
      history.push({
        pathname: history.location.pathname,
        search: '?filtrer=' + filteredArray
      })
      props.applyFilter(searchString);
    }
  }

  const handleOnChange = (event) => {
    const { value, lang } = event.target;
    let copy = [...checkboxes];
    copy[value].values[lang].checked = !copy[value].values[lang].checked;
    setCheckboxes(copy)
  };

  const handleClose = () => {
    setIsSortPopupOpen(false);
  };

  return (
    <Fragment>
      {!isLoading &&
        <FilterPanelMobilePopup
          handleClose={handleClose}
          checkboxes={checkboxes}
          isOpen={isSortPopupOpen}
          onChange={handleOnChange}
          applyFilter={applyFilter}
          removeAllFilter={removeAllFilter}
        />
      }
      <div className='div-filtrer' onClick={() => setIsSortPopupOpen(true)}>
        <span className="icon">
          <ListSvg width="39" height="39"/>
        </span>
      </div>
      <div className={`recherche-component`}>
        {/* <PopupHeader fill='white' title='FILTRES' /> */}
        <div className="recherche-checkboxes">
          {!isLoading && checkboxes && checkboxes.map((data, categoryIndex) => {
            return (
              <div key={categoryIndex}>
                {/* <div className=''>
                    {data.category}
                  </div> */}
                {data.values.map((value, valueIndex) => {
                  return (
                    <div className="ais-facet--item" key={valueIndex}>
                      <div>
                        <div className="checkbox-filter ais-facet--checkbox">
                          <input
                            className="checkbox__element"
                            checked={checkboxes[categoryIndex].values[valueIndex].checked}
                            type="checkbox"
                            id={value.name}
                            value={categoryIndex}
                            lang={valueIndex}
                            onChange={handleOnChange}
                          />
                          <label className="checkbox__label" htmlFor={value.name} >
                          </label>
                        </div>
                        <label className="ais-facet--label" htmlFor={value.name}>
                          <span> {value.name} </span>
                          {/* <span className="ais-facet--count"> ({value.quantity}) </span> */}
                        </label>
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>

        <div id="recherche-appliquer">
          <span onClick={applyFilter}>APPLIQUEZ LES FILTRES</span>
        </div>
      </div>
    </Fragment>
  );
}

export default FilterLeftPanel;