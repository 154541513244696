import React from 'react';
import { getHeure } from '../../../utils/activite/';

/**
 * Composant SelectHeure : affiche un select pour les heure de debut et de fin avec filtrage optionnel.
 * @param {Array} data - Les données à afficher dans le select.
 * @param {function} onChange - Fonction appelée lorsqu'une sélection est modifiée.
 * @param {boolean} disabled - Indique si le select est désactivé ou non.
 * @param {function} filterFn - Fonction de filtrage optionnelle pour les éléments du select de fin.
 *                               Elle filtre les heure > a l'heure de debut sélectionnée
 * @returns {JSX.Element} - Le select JSX avec les options générées à partir des données.
 */


// Composant SelectHeure
const SelectHeure = ({
    type,
    data,
    onChange,
    disabled, 
    heureFin,
    heureDebut
}) => 
{
    return (
        <div className="droit-acces-div input-container" style={{ width: '90px' }}>
        <select
            onChange={onChange} 
            disabled={disabled}
            style={{ width: '100%' }}
        >
            {data.flatMap((categorie) => categorie.items
            ).map((item, index) => {
                const heure = getHeure(item.periode); // Obtient l'heure à partir de l'élément
                return (
                    <option
                        key={index} 
                        value={heure} 
                        selected={type === 'fin' ? heure === heureFin : heure === heureDebut}
                        className={item.couleur} // Classe CSS basée sur la couleur de l'élément
                    >
                        {heure} 
                    </option>
                );
            })}
        </select>
    </div>
    )
}

export default SelectHeure; 


