import React from "react";

function SearchSvg(props) {
  let { dispatch, fill, width, height, ...otherProps } = props

  fill = fill || "rgb(0, 0, 0)";
  width = width || "48px";
  height = height || "48px";

  return (
    <svg className='main-color-hover' xmlns="http://www.w3.org/2000/svg" fill={fill} viewBox="0 0 24 18" width={width} height={height} {...otherProps}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
    </svg>
  );
}

export default SearchSvg;