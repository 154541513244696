import { SET_IDZEC, SET_LANGUAGE } from "../components/actions/types";

const initialState = {
  idZec: "",
  language: "fr"
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_IDZEC:
      return {
        ...state,
        idZec: action.payload
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload
      };

    default:
      return state;
  }
}