import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import gtag from "./gtag";

const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        // track pageview with gtag / react-ga / react-ga4, for example:
        gtag("event", "page_view", {
            page_path: location.pathname + location.search,
        });
    }, [location]);
};

export default usePageTracking;