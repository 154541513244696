import React, { Component } from 'react';
import InputDay from "../inputDay.js";
import InputTime from "../inputTime.js";
import HeaderTitle from "./header-title.js";

class ContainerTakeout extends Component {
  render() {
    let canConfirm = false;
    if (this.props.type === 'emporter' && this.props.optionNow || !this.props.optionNow && this.props.day == null && this.props.time == null) {
      canConfirm = true;
    }

    return (
      <React.Fragment>
        <HeaderTitle number={2} text="Récupérer la commande au" />
        <div style={{ margin: "auto" }}>
          <div className="row" style={{ margin: "auto" }}>
            <div style={{ display: "table", width: "100%", marginLeft: "10px", marginTop: "15px", cursor: "pointer" }}>
              <span style={{ display: "table-cell", width: "2em", verticalAlign: "middle" }}>
                <input type="radio" name="pickupChoice" className="option-input radio" value="217" />
              </span>
              <span style={{ float: "right", display: "table-cell", verticalAlign: "middle", width: "5em", margin: "10px" }}>
                <div>0,5 km
                </div>
              </span>
              <b>
                <div>2451 rue des Giroflées</div>
                <div>Lévis QC G7A 1E1</div>
              </b>
              <div>
                Fermeture à 22:00 aujourd'hui
              </div>
            </div>
            {/* <hr style={{ margin: "10px 0px 0px", maxWidth: "100%" }} /> */}
            <HeaderTitle number={3} text="Préparer pour" />
            <div data-toggle="buttons" className="btn-group" style={{ display: "flex", margin: "auto auto 20px" }}>
              <label className={`btn-popup ${this.props.optionNow && "active"}`} style={{ padding: "10px" }}>
                <input type="radio" value={true} onClick={() => this.props.handleNowOrLater(true)} />
                Maintenant
              </label>
              <label className={`btn-popup ${!this.props.optionNow && "active"}`} style={{ padding: "10px" }}>
                <input type="radio" value={false} onClick={() => this.props.handleNowOrLater(false)} />
                Plus tard
              </label>
            </div>

            {!this.props.optionNow &&
              <div style={{ marginTop: "40px" }}>
                <div className="form-group " style={{ display: "table", width: "100%" }}>
                  <div className="has-float-label">
                    <label>Jour*</label>
                    <InputDay
                      className="details-form-control"
                      day={this.props.day}
                      handleChange={this.props.handleChange}
                      value={this.props.takeoutDay}
                    />
                  </div>
                  <div className="has-float-label" style={{ display: "table-cell", width: "40%", minWidth: "60px" }}>
                    <label>Heure*
                    </label>
                    <InputTime
                      className="details-form-control"
                      time={this.props.time}
                      handleChange={this.props.handleChange}
                      value={this.props.takeoutTime}
                    />
                  </div>
                </div>
              </div>
            }

            <div className="form-group has-float-label">
              <input
                maxLength={40}
                value={this.props.instructionsTakeout}
                onChange={(e) => this.props.handleChange('instructionsTakeout', e.target.value)}
                type="text" id="inInstructions"
                placeholder=" "
                className="details-form-control"
              />
              <label>Notes pour le comptoir
              </label>
            </div>
            <span className="optionnal">Falcutatif*</span>
            <br />
            <button
              className={`btn-popup btn-confirm ${!canConfirm && 'not-allowed'}`}
              onClick={() => {
                canConfirm && this.props.handleClose()
              }
              }> Valider la commande
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContainerTakeout;