import { api } from "../api";

export function purchaseForfaits(infoPaiement) {
  return api.post("paiement/achatProduit", JSON.stringify(infoPaiement), false)
}

export function getFraisAdminReservationDue(sousTotal, courriel, noReservation) {
  return api.get(`paiement/fraisAdminReservationDue?sousTotal=${sousTotal}&courriel=${courriel}&noReservation=${noReservation}`, false)
}

export function getFraisAdminComptesDues(sousTotal, idProfile) {
  return api.get(`paiement/fraisAdminComptesDues?sousTotal=${sousTotal}&idProfile=${idProfile}`, false)
}

export function payComptesDues({creditCard, idProfile, cardType, sousTotal}) {
  return api.post(`paiement/comptesDues`, JSON.stringify({creditCard, idProfile, cardType, sousTotal}), false)
}

export function payReservationDue({creditCard, courriel, noConfirmation, cardType, sousTotal}) {
  return api.post(`paiement/reservationDue`, JSON.stringify({creditCard, courriel, noConfirmation, cardType, sousTotal}), false)
}