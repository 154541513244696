import React from "react";

const ButtonEvenement = ({title, custumClassContainer, onClick, custumClassOnButton, disabled=false}) => {
    return (
        <div className={`${custumClassContainer}`}>
        <button onClick={onClick} className={`${custumClassOnButton}`} disabled={disabled}>
           {title}
        </button>
    </div>
    
    )
}

export default ButtonEvenement