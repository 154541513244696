import { SET_IDZEC, SET_LANGUAGE } from "./types";

export const setIdZec = idZec => dispatch => {
  try {
    dispatch({
      type: SET_IDZEC,
      payload: idZec
    });
  }
  catch (err) {
    console.log(err);
  }
}

export const setLanguage = language => dispatch => {
  try {
    dispatch({
      type: SET_LANGUAGE,
      payload: language
    });
  }
  catch (err) {
    console.log(err);
  }
}