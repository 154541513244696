import React from "react";
import withNavigation from "../higher-order/navigation";

const WarningLoginComponent= (props) => {

return(
    <div id="droit-acces-page" className="droit-acces-page">
    <div className="droit-acces-div" style={{ textAlign: "center" }}>
      <span className="droit-acces-span message">Vous n'êtes pas connecté à votre compte.</span>
    </div>
    <div className="droit-acces-div" style={{ textAlign: "center" }}>
      <span className="droit-acces-span message">Cliquez ici pour vous </span>
      <span className="clickable message primary-color droit-acces-span droit-acces-span-primary" onClick={e => props.gotoProfile('fermer-enregistrement')}>connecter à votre compte</span>
      {/* <span className="droit-acces-span message"> ou pour </span>
      <span className="clickable message primary-color droit-acces-span droit-acces-span-primary" onClick={e => {}}>créer un nouveau compte</span>
      <span className="droit-acces-span message">.</span> */}
    </div>
    {/* <div className="droit-acces-div" style={{ textAlign: "center" }}>
      <span className="droit-acces-span message">Vous pouvez également </span>
      <span className="clickable message primary-color droit-acces-span droit-acces-span-primary" onClick={e => {}}>continuer sans compte</span>
      <span className="droit-acces-span message">.</span>
    </div> */}
  </div>
)
}
export default withNavigation(WarningLoginComponent)