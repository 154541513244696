import React, { useEffect, useState } from "react";
import { DIV_NAME, INPUT_NAME, LABEL_NAME, MANDATORY_MISSING } from "./info-client-constants";
import FieldError, { MandatoryFieldAsterisk }from './info-client-field-check';

const LabelName = {
  email: 'COURRIEL : ',
  emailConfirmation: 'CONFIRMATION COURRIEL : '
}

function InfoClientEmail(props) {
  const [value, setValue] = useState(props.value);
  
  function handleChange(e) {
    if (e.target.value.length <= 255) {
      setValue(e.target.value); //validation à venir
      props.onChange(e);
    }
  }

  useEffect(() => setValue(props.value), [props.value]);

  return (
    <div className={DIV_NAME}>
      <label className={LABEL_NAME}>
        {LabelName[props.name]}
        {<MandatoryFieldAsterisk />}
        {props.error && <FieldError message={props.error} />}
      </label>
      <input
        className={props.error ? `${INPUT_NAME} ${MANDATORY_MISSING}` : INPUT_NAME}
        maxLength="60"
        name={props.name}
        onChange={handleChange}
        placeholder="exemple@domaine"
        required
        type="email"
        value={props.value}
      />
    </div>
  );
}

export default InfoClientEmail;