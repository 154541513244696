import React, { Component } from "react";
import Popup from 'reactjs-popup';
import "./details-popup.css";
import ContainerDelivery from "./container-delivery.js";
import ContainerTakeout from "./container-takeout.js";
import PopupHeader from "../../popups/popup-header.js";
import HeaderTitle from "./header-title.js";

class DetailsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmed: false
    }
  }

  // validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
  validateForm = (err) => {
    // let valid = true;
    // let errors = this.state.errors;

    // if (this.props.type === "livrer") {
    //   if(this.state.fullName > 5){

    //     this.setState({
    //       errors.fullName: 'Full Name must be 5 characters long!'
    //     })
    //     return false
    //   }
    // } else if (this.props.type === "emporter" && this.state.password > 7 && this.state.password != "" && this.state.email != "") {
    //   return true;
    // }

    // if (this.state.fullName < 5) valid = false;
    // if (this.state.fullName == "") valid = false;
    // if (this.state.email == "") valid = false;
    // if (this.state.password == "") valid = false;
    // if (this.state.password < 8) valid = false;
    // Object.values(errors).forEach(
    //   (val) => val.length > 0 && (valid = false)
    // );
    return true;
  }

  handleClose = () => {
    if (!this.state.isConfirmed) {
      this.props.handleType("");
    }
    this.props.closePopup();
  };

  handleDetail = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case 'fullName':
        errors.fullName =
          value.length < 5
            ? 'Full Name must be 5 characters long!'
            : '';
        break;
      case 'email':
        errors.email =
          value.length < 7
            ? 'Email must be 7 characters long!'
            : '';
        // errors.email =
        //   this.validEmailRegex.test(value)
        //     ? ''
        //     : 'Email is not valid!';
        break;
      case 'password':
        errors.password =
          value.length < 8
            ? 'Password must be 8 characters long!'
            : '';
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value }, () => {
      console.log(errors)
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm(this.state.errors)) {
      console.info('Valid Form')
    } else {
      console.error('Invalid Form')
    }
  }

  render() {
    // const { errors } = this.state;
    return (
      <Popup
        content="test"
        on='click'
        open={this.props.isOpen}
        onClose={this.handleClose}
        modal
        nested
      >
        {close => (
          <div className="fixed-panel">
            <div className="dark-container" onClick={close}>
            </div>
            <div className="item-popup-div">
              <div className="item-popup-background" style={{ borderRadius: '0px', maxWidth: '600px', minHeight: '256px', maxHeight: '100%' }}>
                {/* <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 0%' }}> */}
                <div className="popup-main-panel">


                  {/* <form onSubmit={this.handleSubmit} noValidate>
                      <div>
                        <label htmlFor="fullName">Full Name</label>
                        <input type='text' name='fullName' onChange={this.handleDetail} noValidate />
                        {errors.fullName.length > 0 &&
                          <span className='error'>{errors.fullName}</span>}
                      </div>
                      <div>
                        <label htmlFor="email">Email</label>
                        <input type='email' name='email' onChange={this.handleDetail} noValidate />
                        {errors.email.length > 0 &&
                          <span className='error'>{errors.email}</span>}
                      </div>
                      <div>
                        <label htmlFor="password">Password</label>
                        <input type='password' name='password' onChange={this.handleDetail} noValidate />
                        {errors.password.length > 0 &&
                          <span className='error'>{errors.password}</span>}
                      </div>
                      <div className='info'>
                        <small>Password must be eight characters in length.</small>
                      </div>
                      <div className='submit'>
                        <button>Create</button>
                      </div>
                    </form> */}

                  <PopupHeader title="DÉTAILS DE LA COMMANDE" onClick={close} />
                  <div className="panel-body config">
                    <HeaderTitle number={1} text="Soumettre une commande pour" />
                    <div data-toggle="buttons" className="btn-group" style={{ display: "flex", margin: "auto auto" }}>
                      <label className={`btn-popup ${this.props.type === 'emporter' && "active"}`} style={{ padding: "10px" }}>
                        <input type="radio" value="emporter" onClick={this.props.selectType} />
                            À Emporter
                        </label>
                      <label className={`btn-popup ${this.props.type === 'livrer' && "active"}`} style={{ padding: "10px" }}>
                        <input type="radio" value="livrer" onClick={this.props.selectType} />
                          Livraison Zéro Contact
                      </label>
                    </div>
                    {
                      this.props.type === 'livrer' &&
                      <ContainerDelivery
                        handleChange={this.props.handleChange}
                        type={this.props.type}
                        addressType={this.props.addressType}
                        addressNumber={this.props.addressNumber}
                        addressStreet={this.props.addressStreet}
                        addressPostal={this.props.addressPostal}
                        city={this.props.city}
                        province={this.props.province}
                        instructionsDelivery={this.props.instructionsDelivery}
                        errors={this.props.errors}
                        close={close}
                      />
                    }
                    {
                      this.props.type === 'emporter' &&
                      <ContainerTakeout
                        optionNow={this.props.optionNow}
                        handleNowOrLater={this.props.handleNowOrLater}
                        type={this.props.type}
                        day={this.props.day}
                        time={this.props.time}
                        handleChange={this.props.handleChange}
                        handleClose={this.handleClose}
                        takeoutDay={this.props.takeoutDay}
                        takeoutTime={this.props.takeoutTime}
                        instructionsTakeout={this.props.instructionsTakeout}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}

export default DetailsPopup;