import React, {useEffect, useState} from "react";
import Popup from "reactjs-popup";
import {connect} from "react-redux";
import "./catalogue-produits-popup.css";
import PopupHeader from "./popup-header";
import PopupFooter from "./popup-footer";
import {addToShoppingCart} from "../actions/shop";
import {getProductById} from "../../server/services/catalogue-service";
import {parse as parseDate, differenceInDays, parseJSON} from "date-fns";
import {OptionReservationModeCalcul} from "../../enums/OptionReservationModeCalcul.js";

function CatalogueProduitsPopup(props) {

    const addToShoppingCart = async () => {
        const products = [];
        props.produits.forEach((cur) => {
            var doc = document.getElementById(cur.idAuxiliaire);

            let facteurModeCalcul = 1;
            switch (cur.modeCalcul) {
                case OptionReservationModeCalcul.Duree:
                    facteurModeCalcul = differenceInDays(parseJSON(props.depart), parseJSON(props.arrivee));
                    break;
                case OptionReservationModeCalcul.DureePersonne:
                    facteurModeCalcul = differenceInDays(parseJSON(props.depart), parseJSON(props.arrivee)) * props.nombrePersonne;
                    break;
                case OptionReservationModeCalcul.Global:
                    facteurModeCalcul = 1;
                    break;
                case OptionReservationModeCalcul.Gratuite:
                    facteurModeCalcul = 0;
                    break;
                default:
                    break;
            }

            if (doc && doc.checked) {
                products.push({
                    idProduit: cur.idAuxiliaire,
                    quantite: facteurModeCalcul,
                    description: cur.description,
                    prixVente: cur.prixVente
                });
            } else if (doc.value > 0) {
                products.push({
                    idProduit: cur.idAuxiliaire,
                    quantite: doc.value * facteurModeCalcul,
                    description: cur.description,
                    prixVente: cur.prixVente
                });
            }
        });

        props.addToShoppingCartSuite(props.idUnite, products);
        props.handleClose();
    };

    /*function onChange(field, e) {
     setFields({
       ...fields,
       [field]: e.target.checked
     });
   }*/

    function handleClose() {
        window.document.body.style.overflow = "";
        props.addToShoppingCartSuite(props.idUnite);
        props.handleClose();
    }

    if (!props.open || props.produits.length === 0) return <div/>;

    return (
        <Popup on="click" open={props.open} onClose={handleClose} modal nested lockScroll>
            {(close) => (
                <div className="fixed-panel">
                    <div className="dark-container" onClick={handleClose}></div>
                    <div className="item-popup-div">
                        <div className="item-popup-background">
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: "1 1 0%",
                                maxHeight: "70vh"
                            }}>
                                <PopupHeader title="Produits optionnels" onClick={handleClose}/>
                                <div className="item-popup-details">
                                    <p className="item-popup-desc">Ajouter des produits optionnels à la réservation</p>
                                    <table className="table">
                                        <thead>
                                        <tr className="table-header">
                                            <th className="table-header-produit">Produit</th>
                                            {/* <th className="table-header-prix">Prix unitaire</th> */}
                                            <th className="table-header-quantite">Quantité</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {props.produits &&
                                            props.produits.map((cur) => {
                                                let input;
                                                switch (cur.typeChamp) {
                                                    case 0:
                                                        input =
                                                            <input
                                                                className="produits-popup-checkbox"
                                                                type="checkbox"
                                                                name={cur.idAuxiliaire}
                                                                id={cur.idAuxiliaire}
                                                                //onChange={(e) => onChange(cur.idAuxiliaire, e)}
                                                            />
                                                        break
                                                    case 1:
                                                        input =
                                                            <input name={cur.idOptionReservation} type="number" className="produits-popup-checkbox" id={cur.idAuxiliaire} min={0} max={cur.quantiteMaximum}/>
                                                        break
                                                }

                                                return (
                                                    <tr key={cur.idOptionReservation} className="table-row">
                                                        <td className="table-row-desc">{cur.description}</td>
                                                        <td>
                                                            {input}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <PopupFooter title="CONTINUER" onClick={addToShoppingCart}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Popup>
    );
}

const dispatchProps = {addToShoppingCart};

export default connect(null, dispatchProps)(CatalogueProduitsPopup);
