import React, { Component } from 'react';
import "./popup-sub-header.css";

class PopupSubHeader extends Component {
  render() {

    return (
      <div className="popup-header-div">
        {/* <div
              style={{ width: '100px', background: 'url(https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350) center center / 100% no-repeat', transition: 'height 0.2s ease 0s', height: '56.25px' }}
            >
            </div> */}
        <div className="popup-header-text">
          <p className="options-popup-font option-sub-header-title">ITEM SÉLECTIONNÉ
          </p>
          <p className="options-popup-font option-sub-header-name"><b>{(this.props.name).toUpperCase()}</b>
          </p>
        </div>
        <div className="popup-header-img" />
      </div>
    );
  }
}

export default PopupSubHeader;