import { combineReducers } from 'redux';
import alert from './alert';
import authToken from './auth-token';
import client from './client';
import configuration from './configuration';
import entreprise from "./entreprise";
import genericPopup from './generic-popup';
import routeLocation from "./route-location";
import member from './member';
import overlay from './overlay';
import parametresWeb from './parametres-web';
import shop from './shop';
import paysConfigWeb from './pays-config-web';
import provincesConfigWeb from './provinces-config-web';
import infoClient from './infoClient';
import formPopActive from './form-pop-activite/form-pop-active';
import categoriesActivite from './unite-activites';
import fermerEnregistrement from './fermer-enregistrement';

export default combineReducers({
  alert,
  authToken,
  client,
  configuration,
  entreprise,
  genericPopup,
  member,
  overlay,
  parametresWeb,
  routeLocation,
  shop,
  paysConfigWeb,
  provincesConfigWeb,
  infoClient,
  formPopActive,
  categoriesActivite,
  fermerEnregistrement
});