import React, {
  Fragment,
  useState
} from "react";
import PropTypes
  from "prop-types";
import {
  connect,
  useDispatch,
  useSelector
} from "react-redux";
import "./sommaire-right-panel.css";
import SommaireItem
  from "../restaurant/sommaire-item";
import {
  getShop,
  removeFromShoppingCart,
  CartItemType,
  updatePromotions,
  removePromotions
} from "../actions/shop";
import {
  he
} from "date-fns/locale";
import {
  api
} from "../../server/api";

function SommaireRightPanel(props) {
  const roundTwoDecimals = (n) => Math.round(Number(n) * 100) / 100;

  const remove = (item) => {
    props.removeFromShoppingCart(item);
    if (props.plusUpdate) props.plusUpdate(item);
  };

  const renderPanierItems = () => {
    const {
      fraisAdminFixe,
      fraisAdminPourcent,
      tauxTps,
      tauxTvq
    } = props.shop;
    let rows = [];
    let total = 0,
        subtotal = 0,
        hebergement = 0,
        tps = 0,
        tvq = 0,
        fraisAdmin = 0,
        montantDepot = 0;
    let isMontantDepotFix = false;
    props.shop.cart.forEach((item, index) => {
      if (props.showByType === "all" || item.type === props.showByType) {
        rows.push(
            <SommaireItem remove={remove} key={index} index={index} item={item}
                          showAllMontant={props.showByType !== "all"}
                          isDescriptionShowed={props.showByType === "all"}/>);
        subtotal += item.montant;
        fraisAdmin += item.fraisAdmin || 0;
        if (item.hebergement) hebergement = hebergement + item.hebergement;
        tps += item.tps;
        tvq += item.tvq;
        total += item.total || 0;
        montantDepot += item.type === CartItemType.ReservationActivite || item.type === CartItemType.ReservationCamping || item.type === CartItemType.ReservationHebergement ? item.montantDepots : item.total;
        isMontantDepotFix = item.type === CartItemType.ReservationActivite || item.type === CartItemType.ReservationCamping || item.type === CartItemType.ReservationHebergement ? item.isMontantDepotFix : false;
      }
    });

    props.shop.promotions.forEach(promo => {
      subtotal -= promo.montantPromotion.montantAvantTaxes;
      tps -= promo.montantPromotion.tps;
      tvq -= promo.montantPromotion.tvq;
      total -= promo.montantPromotion.total;
      if (montantDepot > total)
        montantDepot = total;
    });

    const roundedSubTotal = roundTwoDecimals(subtotal);
    const roundedFraisAdmin = roundTwoDecimals(fraisAdmin);
    const roundedHebergement = roundTwoDecimals(hebergement);
    const roundedTps = roundTwoDecimals(tps);
    const roundedTvq = roundTwoDecimals(tvq); // Ce [+ tps - tps arrondit] corrige les erreurs d'arrondissement de cents (e.g. round(3.333 + 3.333) = 6.67 mais round(3.33) + round(3.33) = 6.66. Le Manisoft met la cet supplémentaire dans la TVQ).
    const roundedTotal = roundTwoDecimals(roundedSubTotal + roundedHebergement + roundedTps + roundedTvq + roundedFraisAdmin);

    //if (roundedSubTotal !== 0) {
    //  fraisAdmin = roundTwoDecimals((fraisAdminFixe || 0) + (fraisAdminPourcent || 0) * subtotal * 0.01);
    //  tps += fraisAdmin * (tauxTps || 0) * 0.01;
    //  tvq += fraisAdmin * (tauxTvq || 0) * 0.01;
    //  total = subtotal + tps + tvq + fraisAdmin;
    //}

    // console.log({subtotal, fraisAdmin, tps, tvq, total, roundedSubTotal, roundedFraisAdmin, roundedTps, roundedTvq, roundedTotal, props});

    if (!rows.length) {
      rows = [
        <div key="empty" className="empty-commande">
          <p className="normal-font empty-commande-font">Votre
            commande
            est
            vide</p>
        </div>
      ];
    }
    return {
      rows,
      total: roundedTotal,
      hebergement: roundedHebergement,
      subtotal: roundedSubTotal,
      tps: roundedTps,
      tvq: roundedTvq,
      fraisAdmin: roundedFraisAdmin,
      montantDepot
    };
  };

  const {
    rows,
    total,
    subtotal,
    hebergement,
    tps,
    tvq,
    fraisAdmin,
    montantDepot
  } = renderPanierItems();
  const [codePromotion, setCodePromotion] = useState("");

  function valideEtCalculPromotion() {
    api.post("promotion/Valider/" + codePromotion, JSON.stringify({shop: props.shop}), true)
        .then((promotionValide) => {
          props.updatePromotions(promotionValide);
          setCodePromotion("");
        })
  }


  return (
      <Fragment>
        <div className="position-relative">
          <div className="facture-top-first"/>
          <div className="header-sommaire">
            <p className="normal-font">{props.message}</p>
            <p className="normal-font">{props.time}</p>
            <p className="normal-font">{props.day}</p>
            {/* <p className="normal-font">dans 15 minutes</p> */}
          </div>
          <div className="facture-top-second"/>
        </div>

        <div className="containerItems">
          <div className="display-flex">
            <div className="flex-1" style={{width: "100%"}}>
              {rows}
              <div className="sommaire-taxes-div">
                {props.shop.promotions && props.shop.promotions.map((promotion) => {
                  return <div className="sommaire-flex-row" key={promotion.code}>
                    <div style={{
                      display: "flex",
                      flex: "1 1 0%",
                      alignItems: "center"
                    }}>
                      <span style={{fontSize: "18px"}} className="remove" onClick={() => (props.removePromotions(promotion))}>☒</span>
                      <p style={{
                        margin: 0,
                        paddingLeft: "5px"
                      }} className="sommaire-items-font sommaire-items-name">{promotion.description}</p>
                    </div>
                    <p style={{margin: 0}} className="sommaire-items-font sommaire-items-price">- {promotion.montantPromotion.montantAvantTaxes.toFixed(2).toString()} &nbsp;$&nbsp;CA</p>
                  </div>
                })}
                <div className="sommaire-flex-row">
                  <div style={{
                    display: "flex",
                    flex: "1 1 0%"
                  }}>
                    <p className="display-text font-category">SOUS-TOTAL
                      ({props.shop.cart.length} articles)</p>
                  </div>
                  <p className="display-text font-price">{(subtotal).toFixed(2).toString()} &nbsp;$&nbsp;CA</p>
                </div>
                {(hebergement || 0) !== 0 && (
                    <div className="sommaire-flex-row">
                      <div style={{
                        display: "flex",
                        flex: "1 1 0%"
                      }}>
                        <p className="display-text font-category">TAXE
                          HÉBERGEMENT</p>
                      </div>
                      <p className="display-text font-price">
                        <span>{(hebergement).toFixed(2).toString()} &nbsp;$&nbsp;CA</span>
                      </p>
                    </div>
                )}
                {(props.shop.fraisAdminFixe || 0) + (props.shop.fraisAdminPourcent || 0) !== 0 && (
                    <div className="sommaire-flex-row">
                      <div style={{
                        display: "flex",
                        flex: "1 1 0%"
                      }}>
                        <p className="display-text font-category">FRAIS
                          D'ADMINISTRATION</p>
                      </div>
                      <p className="display-text font-price">{fraisAdmin.toFixed(2).toString()} &nbsp;$&nbsp;CA</p>
                    </div>
                )}
                <div className="sommaire-flex-row">
                  <div style={{
                    display: "flex",
                    flex: "1 1 0%"
                  }}>
                    <p className="display-text font-category">TPS</p>
                  </div>
                  <p className="display-text font-price">{(tps).toFixed(2).toString()} &nbsp;$&nbsp;CA</p>
                </div>
                <div className="sommaire-flex-row">
                  <div style={{
                    display: "flex",
                    flex: "1 1 0%"
                  }}>
                    <p className="display-text font-category">TVQ</p>
                  </div>
                  <p className="display-text font-price">{(tvq).toFixed(2).toString()} &nbsp;$&nbsp;CA</p>
                </div>
                <div className="sommaire-flex-row" style={{borderTop: "2px solid #ccc"}}>
                  <div style={{
                    display: "flex",
                    flex: "1 1 0%"
                  }}>
                    <p className="display-text font-category">TOTAL</p>
                  </div>
                  <p className="display-text font-price">{(total).toFixed(2).toString()} &nbsp;$&nbsp;CA</p>
                </div>
              </div>
            </div>
          </div>
          {props.showPromo && (
              <div className="flesx-1">
                <div className="sommaire-flex-row">
                  <div style={{
                    display: "flex",
                    flex: "1 1 0%"
                  }}>
                    <p className="display-text">Code</p>
                  </div>
                  <div style={{
                    display: "flex",
                    flex: "1 1 0%"
                  }}>
                    <input type="text" value={codePromotion} onChange={(e) => setCodePromotion(e.target.value)}
                           style={{maxWidth: "200px"}}/>
                    <button onClick={valideEtCalculPromotion}>Appliquer</button>
                  </div>
                </div>
              </div>)}
        </div>

        <div className="position-relative">
          <div className="facture-bottom-first"/>
          <div className="jss567">
            <div className="jss569">
              <p className="normal-font jss572">MONTANT
                DU
                DÉPÔT</p>
              <p className="normal-font jss573">{montantDepot.toFixed(2).toString()}$
                CA</p>
            </div>
          </div>
          <div className="facture-bottom-second"/>
        </div>
        {props.showConfirm && (
            <div className="divConfirm">
              <button className={`mainButton payButton ${props.canConfirm ? "allowed" : "not-allowed"}`}
                      onClick={props.canConfirm ? props.confirm : () => {
                      }}>
                <p className={`payButton-trigger normal-font`}>{props.confirmTitle}</p>
                {!props.canConfirm &&
                    <div className="sommaire-error-msg hide">{props.confirmError}</div>}
              </button>
            </div>
        )}
      </Fragment>
  );
}

SommaireRightPanel.propTypes = {
  getShop: PropTypes.func.isRequired,
  removeFromShoppingCart: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  shop: state.shop
});

SommaireRightPanel.defaultProps = {
  allowDelete: true,
  confirmTitle: "CONFIRM",
  confirmError: "Veuillez sélectionner le type de commande",
  showByType: "all",
  showConfirm: true,
  canConfirm: true,
  showPromo: false,
  tauxTps: 0.05, // Ne pas utiliser ces default props. Les remplacer par les taux de taxes provenant de la BD. Ces props sont utilisées temporairement pour que le site continue de fonctionner car je ne sais pas où sommaire-right-pannel.js est utilisé.
  tauxTvq: 0.09975 // Ne pas utiliser ces default props. Les remplacer par les taux de taxes provenant de la BD. Ces props sont utilisées temporairement pour que le site continue de fonctionner car je ne sais pas où sommaire-right-pannel.js est utilisé.
};

export default connect(mapStateToProps, {
  getShop,
  removeFromShoppingCart,
  updatePromotions,
  removePromotions
})(SommaireRightPanel);
