import React from 'react';

// Cette composante est temporaire et sera déplacée dans champs-de-recherche en meme temps que les dates debut et fin de la recherche.
export default function ChampDeRechercheFieldError(props) {
  if (!props.errorMessage)
    return <div/>;

  return (
    // un charactère est environ 2x font-size
    <span className='choix-unite-recherche-title-wrapper-error'>
      {props.errorMessage}
    </span>
  )
}

