import SessionKeys from "../../session";
import { CLEAR_NO_DOSSIER, SET_NO_DOSSIER } from './types';

export const clearNoDossier = () => dispatch => {
  dispatch({ type: CLEAR_NO_DOSSIER });
  sessionStorage.removeItem(SessionKeys.NO_DOSSIER);
}

export const setNoDossier = (noDossier, isFromStorage = false) => dispatch => {
  dispatch({ type: SET_NO_DOSSIER, payload: noDossier });

  if (!isFromStorage)
    sessionStorage.setItem(SessionKeys.NO_DOSSIER, noDossier);
}
