import {
  //GET_PARAMETRES_WEB,
  SET_PARAMETRES_WEB,
} from "./types";

//export const getParametresWeb = () => async (dispatch) => {
//  try {
//    dispatch({
//      type: GET_PARAMETRES_WEB
//    });
//  } catch (err) {
//      console.log(err);
//  }
//};

export const setParametresWeb = parametresWeb => async (dispatch) => {
  try {
    dispatch({
      type: SET_PARAMETRES_WEB,
      parametresWeb: parametresWeb,
    });
  } catch (err) {
      console.log(err);
  }
};