import React, { Component } from "react";
import "./restaurant-card.css";

class InputDay extends Component {
  render() {
    return (
      <select className={this.props.className} value={this.props.day} onChange={(e) => this.props.handleChange('day', e.target.value)}>
        <option value="vendredi 1 février">
          Aujourd'hui
        </option>
        <option value="samedi 2 février">
          Demain
        </option>
        <option value="dimanche 3 février">
          3 février
        </option>
        <option value="lundi 4 février">
          4 février
        </option>
        <option value="mardi 5 février">
          5 février
        </option>
      </select>
    );
  }
}

export default InputDay;