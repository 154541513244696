import React, { Component } from "react";
import Popup from 'reactjs-popup';
import PopupFooter from "./popup-footer";
import CloseSvg from "../svg/close";
import FilterPanelMobileSectionPopup from "./filter-panel-mobile-section-popup";

function FilterPanelMobilePopup(props) {

  const confirm = () => {
    props.applyFilter();
    props.handleClose();
  }

  return (
    <Popup
      content="test"
      on='click'
      open={props.isOpen}
      onClose={props.handleClose}
      modal
      nested
    >
      {close => (
        <div className="fixed-panel">
          <div className="dark-container" onClick={close}>
          </div>
          <div className="item-popup-div">
            <div className="item-popup-background" style={{ borderRadius: '0px', maxWidth: '600px', minHeight: '256px', maxHeight: '100%' }}>
              <div className="popup-main-panel" style={{ paddingBottom: '10px' }}>
                <div role="presentation" className="modal-filter-sort">
                  <div className="modal-container container jss19">
                    <div className="ais-page">
                      <div className="modal-close" onClick={close}>
                        <span className="icon icon--x">
                          <button className="item-popup-btn btn-close" style={{ float: 'right' }}>
                            <span className="btn-svg">
                              <CloseSvg />
                            </span>
                          </button>
                        </span>
                      </div>
                      <div className="ais-sort">
                        <span className="ais-label">TRIER</span>
                        <div className="ais-sort-orders-container">
                          <div>
                            <span className="ais-sort-cta">Best Match</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span className="ais-label ais-label--filter">FILTRER
                            <span className="ais-current-refined-values--clear-all"
                              onClick={() => props.removeAllFilter()}>Effacer tous
                              {/* Clear all filters */}
                            </span>
                          </span>
                        </div>

                        <div className="ais-facets">
                          {
                            Object.entries(props.checkboxes).map(([key, val], i) => (
                              <FilterPanelMobileSectionPopup key={key}
                                item={props.checkboxes[key]}
                                categoryIndex={i}
                                onChange={props.handleOnChange}
                              />
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <PopupFooter title="APPLIQUER" onClick={() => confirm()} />
            </div>
          </div>
        </div>
      )}
    </Popup>
  )
}

export default FilterPanelMobilePopup;