import {SET_ENTREPRISE_NAME, SET_ENTREPRISE_CONTACT, SET_ENTREPRISE_LOGO, SET_ENTREPRISE_NO_ETABLISSEMENT} from "../components/actions/types";

const initialState = {
    nom: "",
    contact: undefined,
    logo: undefined,
    noEtablissement: undefined
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ENTREPRISE_NAME:
            return {
                ...state,
                nom: action.payload
            };
        case SET_ENTREPRISE_CONTACT:
            return {
                ...state,
                contact: action.payload
            };
        case SET_ENTREPRISE_LOGO:
            return {
                ...state,
                logo: action.payload
            };
        case SET_ENTREPRISE_NO_ETABLISSEMENT:
            return {
                ...state,
                noEtablissement: action.payload
            };
        default:
            return state;
    }
}
