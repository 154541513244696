import {
  SHOW_POPUP,
  HIDE_POPUP,
} from '../components/actions/types';

const initialState = {
  show: false,
  title: "",
  titleStyle: {},
  body: null,
  footer: null,
  footerStyle: {},
  onCloseClick: null,
  contentStyle: {},
  contentClass: "",
  bodyClass:""
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_POPUP:
      return {
        ...state,
        show: true,
        title: action.title || "",
        titleStyle: action.titleStyle || {},
        body: action.body,
        footer: action.footer,
        footerStyle: action.footerStyle,
        onCloseClick: action.onCloseClick,
        contentStyle: action.contentStyle,
        contentClass: action.contentClass || "",
        bodyClass: action.bodyClass || ""
      };

    case HIDE_POPUP:
      return {
        ...state,
        show: false,
      };

    default:
      return state;
  }
}