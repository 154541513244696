import React from "react";
import CloseSvg from "../svg/close.js";
import "./popup-header.css";

function PopupHeader(props) {
  return (
    <div className="item-popup-header">
      <p className="item-popup-font">
        {(props.title).toUpperCase()}
      </p>
      <button className="item-popup-btn btn-close">
        <CloseSvg fill={props.fill} onClick={() => props.onClick()} />
      </button>
    </div>
  );
}

export default PopupHeader;