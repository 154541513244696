import {
  SHOW_OVERLAY,
  HIDE_OVERLAY,
} from "./types";

export const showOverlay = (options = {}) => dispatch => {
  if (typeof options === "string")
    options = {key: options};

  try {
    dispatch({
      type: SHOW_OVERLAY,
      text: options.text || "",
      key: options.key || 'DEFAULT',
    });
  } catch (err) {
    console.log(err);
  }
};

export const hideOverlay = (options = {}) => dispatch => {
  if (typeof options === "string")
    options = {key: [options]};

  if (!Array.isArray(options.key))
    options = {key: [options.key || 'DEFAULT']};

  try {
    dispatch({
      type: HIDE_OVERLAY,
      keys: options.key,
    });
  } catch (err) {
    console.log(err);
  }
};