// Permet de centraliser les keys pour la session et aide à garantir qu'aucune key n'est utilisé pour plus d'une valeur

const SessionKeys = { // Naming convention provenant de https://google.github.io/styleguide/jsguide.html#features-objects-enums
  AUTH_TOKEN: "authToken",
  IdS_RESERVATION_TEMP: "idsReservationTemp",
  IS_SHOP_LOADED: "isShopLoaded",
  NO_DOSSIER: "client::noDossier",
  DROIT_ACCES_DATE_ARRIVEE: "droitAccesDateArrivee",
  DROIT_ACCES_DATE_DEPART: "droitAccesDateDepart",
  MESSAGE_ACCUEIL_DEJA_AFFICHE: 'messageAccueilDejaAffiche'
};

export default SessionKeys;