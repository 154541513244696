import React, {Component, setState} from "react";
import ReactDOM from "react-dom";
import {connect} from "react-redux";
import {Route} from "react-router-dom";
import "../../css/choix-unite-page.css";
import Fetch, {post} from "../server/api.js";
import {
    getNombreJoursMaximumEnAvance,
    getSvgData,
    getConfigurationByKeyName
} from "../server/services/configuration-service";
import {addReservationTemp, calculateSumarryAmounts} from "../server/services/reservation-service";
import {getForfaitList, getUnites, getUniteTypeService, searchUnites, validateSearchOptions} from "../server/services/unite-service";
import {toSelectedISODateTimeString, toDateTimeString, toDateString} from "../utils/formatting";
import {addToShoppingCart, setErrorAlert, setTimeoutShop} from "./actions/shop";
import Calendar from "./calendar.js";
import ChoixUniteCarte from "./choix-unite-carte.js";
import ChoixUniteListe from "./choix-unite-liste.js";
import withNavigation from "./higher-order/navigation";
import ChampsDeRecherche from "./reservation/champ-de-recherche";
import ChampDeRechercheFieldError from "./reservation/champ-de-recherche-field-error";
// import ChoixRecherchePanel from "./choix-recherche-panel";
import ChoixRecherchePanel from "./choix-recherche-panel-old";
import ListSvg from "./svg/list.js";
import LocationSvg from "./svg/location.js";
import SearchSvg from "./svg/search.js";
import Unite from "./unite.js";
import {
    parse as parseDate,
    addDays,
    parse,
    getHours,
    format as formatDate,
    differenceInDays,
    parseJSON
} from "date-fns";
import UniteRecherchePopup from "./popups/unite-recherche-popup";
import DemanderTrancheAge from "./popups/demander-tranche-age";
import unite from "./unite.js";
import {
    getUniteSearchFields,
    getPrixById,
    getUnitePrixBase,
    getOptionsReservation,
    getUniteProduits
} from "../server/services/unite-service.js";
import {getSearchFieldsExtra} from "../server/services/parametre-reservation-en-ligne";
import ConfirmationOptionReservation from "./popups/confirmation-option-reservation-auto";
import {TypeAuxiliaire} from "../enums/";
import CatalogueProduitsPopup from "./popups/catalogue-produits-popup";
import {getProductById} from "../server/services/catalogue-service";
import OptionReservationModeCalcul from "../enums/OptionReservationModeCalcul";
import Translator from "./translator";
import gtag from "../utils/gtag";
import {setAlert} from "./actions/alert";
import loadingImage from "../../images/loading.svg";
import ConfirmationOptionForfait from "./popups/option-forfait.js";


const today = new Date();
today.setHours(0);
today.setMinutes(0);
today.setSeconds(0);
today.setMilliseconds(0);

const TypeHebergement = {
    A: "Reservation Activité", // Voir page-reservation
    AC: "Reservation Activité",
    CP: "Reservation Camping",
    PC: "Reservation Hebergement",
    CH: "Reservation Hebergement"
};

const NULLDATE = new Date(1900, 1, 1, 0, 0, 0);
const NULL_VALUES = ["", "null", null];
const mandatoryInputError = " mandatory-input-error";

function appendSearchParam(search, param, value) {
    return search === "" ? search + "?" + param + "=" + value : search + "&" + param + "=" + value;
}

function formatDateYMD(date) {
    if (!(date instanceof Date)) return date;

    return date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0");
}


class ChoixUnitePage extends Component {
    constructor(props) {
        super(props);

        this.PrepareForAddToShoppingCart = this.PrepareForAddToShoppingCart.bind(this);
        this.getAllUnites = this.getAllUnites.bind(this);
        this.onDateArriveeHoverChange = this.onDateArriveeHoverChange.bind(this);
        this.onDateDepartHoverChange = this.onDateDepartHoverChange.bind(this);
        this.onSelectDateArrivee = this.onSelectDateArrivee.bind(this);
        this.onSelectDateDepart = this.onSelectDateDepart.bind(this);
        this.applyUnitesSearch = this.applyUnitesSearch.bind(this);
        this.setSearchOptions = this.setSearchOptions.bind(this);
        this.updateSearchField = this.updateSearchField.bind(this);
        this.onSelectDateCalendar = this.onSelectDateCalendar.bind(this);
        this.toggleCalendrierArrivee = this.toggleCalendrierArrivee.bind(this);
        this.toggleCalendrierDepart = this.toggleCalendrierDepart.bind(this);
        this.formatDateYMD = formatDateYMD.bind(this);
        this.getInputClassName = this.getInputClassName.bind(this);
        this.blurCalendrierArrivee = this.blurCalendrierArrivee.bind(this);
        this.applySearchFilters = this.applySearchFilters.bind(this);
        this.resetSearchOptions = this.resetSearchOptions.bind(this);
        this.getUniteById = this.getUniteById.bind(this);
        this.addToShoppingCart = this.addToShoppingCart.bind(this);
        this.onTrancheAgeValueChange = this.onTrancheAgeValueChange.bind(this);
        this.updateSearchFieldExtra = this.updateSearchFieldExtra.bind(this);
        this.onConfirmationOptionReservationPopup = this.onConfirmationOptionReservationPopup.bind(this);
        this.setPeuxAjouterPanier = this.setPeuxAjouterPanier.bind(this);
        this.getUniteAvecTrancheAges = this.getUniteAvecTrancheAges.bind(this);
        this.ValiderEtOuvrirPopupTrancheAge = this.ValiderEtOuvrirPopupTrancheAge.bind(this);

        this.state = Object.assign({
            previewArrivee: null,
            previewDepart: null,
            unites: [],
            maxJoursEnAvanceReservation: null,
            dataLoadedChampsRecherches: true, //false
            dataLoadedUnites: false,
            isCalenderDateChange: false,
            isSearchOption: false,
            isCalendarBlurred: false,
            initialLocation: this.props.location.pathname,
            dataLoadedConfigs: false,
            dataLoadedParametres: false,
            showCalendrierArrivee: false,
            showCalendrierDepart: false,
            typeHebergement: this.props.getTypeHebergement(),
            cartePathname: "",
            listePathname: "",
            recherchePathname: "",
            unitePathname: "",
            activitePathname: "",
            carte: null,
            AjouterPanierEtape: 0,
            searchOptions: {
                selectedArrivee: null,
                selectedDepart: null,
                selectedIdEquipement: "",
                selectedLongueur: "",
                selectedService: -1, // -1: tous les services
                selectedIdAmperage: "",
                selectedAdultes: 0,
                selectedEnfants: 0,
                selectedTrancheAges: [],
                extra: [],
                selectedEntreeSortie: false
            },
            includedOptions: [],
            selectedProducts: [],
            fieldError: {},
            calendarDateInSelection: false,
            isSearchPopupOpen: false,
            demandeDeReservationParCourriel: false,
            consultationSeulement: false,
            isTrancheAgePopupOpen: false,
            isOptionsReservaitonPopupOpen: false,
            OptionsReservaitonPopupResult: false,
            produitsOptionnelsPopup: false,
            peutAjouterPanier: false,
            isOptionForfait: false,
            isOkverification: false,
            optionsForfaitList: [],
            optionsForfaitSelected: {
               description: null,
               idPlanEntete: null
            },
            idUniteSelected:null,
            TEXTS: Translator(this.props.match.params.language, "TXT_CHOIX_UNITE_PAGE")
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.language) this.setState({TEXTS: Translator(nextProps.match.params.language, "TXT_CHOIX_UNITE_PAGE")});
    }

    async componentDidMount() {
        this.setPathnameConfig();
        this.getConfigs();
        this.setSearchOptions();
        this.getParametres();
        getUniteSearchFields(null, this.state.typeHebergement).then((champsRecherche) => {
            this.setState({searchFields: champsRecherche});
        });

        var searchString = this.props.location.search.toLowerCase();
        if ((searchString.includes("idtypeunite") && this.state.typeHebergement === "CP") || searchString.includes("arrivee") || searchString.includes("depart") || searchString.includes("iduniteequipmenttype") || searchString.includes("equipmenttype") || searchString.includes("longueurequipement") || searchString.includes("typeservice") || searchString.includes("idamperage") || searchString.includes("adultes") || searchString.includes("enfants")) {

            this.applyUnitesSearch(true);
        } else {
            this.getAllUnites();
        }
        gtag("event", "page_view", {
            page_path: this.props.location.pathname + this.props.location.search,
        });
    }

    openTrancheAgePopup(selectedUnite) {
        this.setState({
            isTrancheAgePopupOpen: true,
            selectedUnite: selectedUnite,
            selectedAdultesBackup: this.state.searchOptions.selectedAdultes,
            selectedEnfantsBackup: this.state.searchOptions.selectedEnfants,
            searchOptions: {
                ...this.state.searchOptions,
                selectedTrancheAges: selectedUnite.plan_base.TrancheAge
            }
        });
    }

    onTrancheAgeValueChange(trancheAges) {
        if (trancheAges.length > 0) {
            let nbAdultes = 0;
            let nbEnfants = 0;
            trancheAges.forEach((trancheage) => {
                if (trancheage.trancheAge.isAdulte) nbAdultes += parseInt(trancheage.quantite); else nbEnfants += parseInt(trancheage.quantite);
            });

            this.setState({
                searchOptions: {
                    ...this.state.searchOptions,
                    selectedAdultes: nbAdultes,
                    selectedEnfants: nbEnfants,
                    selectedTrancheAges: trancheAges
                }
            });
        }
    }

    openConfirmationOptionReservationPopup(selectedUnite, message) {
        this.setState({

            isOptionsReservaitonPopupOpen: true,
            selectedUnite: selectedUnite,
            OptionReservationMessage: message
        });
    }

    onConfirmationOptionReservationPopup(selectedUnite) {
        const {uniteAvecNbAdulteEtEnfant} = this.getUniteAvecTrancheAges(selectedUnite);
        this.setState({
            OptionsReservaitonPopupResult: true,
            isOptionsReservaitonPopupOpen: false,
            AjouterPanierEtape: 3,
        }, () => {

            this.setState({
                OptionsReservaitonPopupResult: false,
                searchOptions: {
                    ...this.state.searchOptions,
                    selectedTrancheAges: uniteAvecNbAdulteEtEnfant.plan_base.TrancheAge
                }
            }, async () => {
                const idReservationTempParent = await this.addToShoppingCart(uniteAvecNbAdulteEtEnfant, false);
                let idReservationTemp;
                const OptionsReservation = await getOptionsReservation(uniteAvecNbAdulteEtEnfant.id_unite);
                if (OptionsReservation && OptionsReservation.some((options) => options.typeAuxiliaire === TypeAuxiliaire.Unite)) {
                    for (const optionIndex in OptionsReservation) {
                        const option = OptionsReservation[optionIndex];
                        const uniteAuxiliaire = this.state.unites.find((unite) => unite["id_unite"] === option.idAuxiliaire);
                        if (!uniteAuxiliaire) continue;

                        const uniteOptionReservation = {
                            ...uniteAuxiliaire,
                            idReservationTempParent: idReservationTempParent,
                            plan_base: {
                                ...uniteAuxiliaire.plan_base,
                                TrancheAge: uniteAvecNbAdulteEtEnfant.plan_base.TrancheAge
                            }
                        };

                        idReservationTemp = await this.addToShoppingCart(uniteOptionReservation, false);
                    }
                }
                if (idReservationTemp || idReservationTempParent) this.resetSearchOptions(true);
            });
        });
    }

    setPeuxAjouterPanier(value) {
        this.setState({peutAjouterPanier: value});
    }

    async valideEtPrepareData(unite, type_hebergement = null) {
        // this.setState((prevState) => ({
        //     ...prevState,
        //     isOkverification: false
        // }))
        const idUnite = unite.id_unite;
        const newItem = {};
        newItem["image"] = await Fetch("/" + this.props.idZec + "/Unite/" + idUnite + "/Image").then((image) => (image === null ? require("../../images/no-image.png") : "data:image/jpeg;base64," + image));
        const options = this.state.searchOptions; // ceci n'existe pas pour une activite

        // dans ce bloc il faut passer les bonnes heures 
        const dateArrivee = (options.selectedArrivee ? options.selectedArrivee : NULLDATE).setHours(new Date(unite.heure_arrivee).getHours(), new Date(unite.heure_arrivee).getMinutes());
        const dateDepart = (options.selectedDepart ? options.selectedDepart : NULLDATE).setHours(new Date(unite.heure_depart).getHours(), new Date(unite.heure_depart).getMinutes());
        const arrivee = toSelectedISODateTimeString(dateArrivee);
        const depart = toSelectedISODateTimeString(dateDepart);
        if (dateDepart < dateArrivee) {
            this.props.setErrorAlert("La date de départ pour cet unite doit être après de la date d'arrivée!");
            this.setState({fieldError: {selectedDate: "la date de départ invalide !"}});
            return false;
        }
        if (!options.selectedArrivee || dateArrivee < new Date()) { // pour l'activite le searchxOption n'existe pas
            this.props.setErrorAlert("La date d'arrivée est invalide!");
            this.setState({fieldError: {selectedDate: "La date d'e 'arrivée est invalide !"}});
            return false;
        }
        if (!options.selectedArrivee) { //pour l'activite le searchOption n'existe pas
            this.props.setErrorAlert("La date de départ est invalide");
            this.setState({fieldError: {selectedDate: "La date de départ est invalide !"}});
            return false;
        }
        const equipemenType = (this.state.searchFields.equipements || []).find((equipement) => {
            return equipement.id_unite_equipment_type === options.selectedIdEquipement;
        });

        var includedOptions = [{
            name: "Arrivée",
            textVal: formatDate(dateArrivee, unite.cacher_heures ? "yyyy-MM-dd " : "yyyy-MM-dd HH:mm:ss"),
            value: arrivee
        }, {
            name: "Départ",
            textVal: formatDate(dateDepart, unite.cacher_heures ? "yyyy-MM-dd " : "yyyy-MM-dd HH:mm:ss"),
            value: depart
        }, {
            name: "Adultes",
            textVal: `${ options.selectedAdultes }`,
            value: options.selectedAdultes
        }, {
            name: "Enfants",
            textVal: `${ options.selectedEnfants }`,
            value: options.selectedEnfants
        }]; //this.state;
        if (equipemenType && equipemenType.description) includedOptions = [...includedOptions, {
            name: "Équipement",
            textVal: equipemenType.description,
            value: options.selectedIdEquipement
        }];

        if (options.selectedLongueur !== "") includedOptions = [...includedOptions, {
            name: "Longueur",
            textVal: `${ options.selectedLongueur }`,
            value: options.selectedLongueur
        }];

        if (unite.services && unite.services.length > 0) includedOptions = [...includedOptions, {
            name: "Service",
            textVal: unite.services.map((service) => service.description).join(", "),
            value: unite.services
        }];

        newItem["selectedUnite"] = {...unite, ...options};
        newItem["selectedUnite"].selectedArrivee = arrivee;
        newItem["selectedUnite"].selectedDepart = depart;
        newItem["name"] = "Unité-" + unite.description;
        newItem["type"] = TypeHebergement[unite.type_hebergement] || TypeHebergement.CH;
        newItem["isReservation"] = true;
        newItem["inclus"] = includedOptions;
        newItem["selectedUnite"]["selectedTrancheAges"] = options.selectedTrancheAges.map((trancheAge) => {
            return {
                IdTrancheAge: trancheAge.trancheAge.idTrancheAge,
                quantite: trancheAge.quantite
            };
        });
        const selectedProducts = this.state.selectedProducts || [];
        for (let i = 0; i < selectedProducts.length; i++) {
            const montantProduit = selectedProducts[i].prixVente * selectedProducts[i].quantite;
            newItem["inclus"].push({
                name: `${ selectedProducts[i].description }:   x ${ selectedProducts[i].quantite }${ selectedProducts[i].quantiteTypeHoraire ? "h" : "" }`,
                montant: montantProduit
            });
        }
        newItem["selectedUnite"]["SelectedProducts"] = selectedProducts;
        const errors = await validateSearchOptions(JSON.stringify(newItem["selectedUnite"]));

        if (Object.keys(errors).length > 0) {
            // Ajouter l'unité aux reservations temporaires
            this.setState({
                fieldError: errors,
                // isOkverification: false
            });
            this.props.setErrorAlert({
                description: "Il y a des erreurs dans les options de recherche. Veuillez corriger et reessayer!",
                details: Object.values(errors)
            });
            return false;
        }
        // this.setState((prevState) => ({
        //     ...prevState,
        //     // isOkverification: true
        // }))
        return newItem;
    }

    getUniteAvecTrancheAges(unite, initialize = false) {
        let nbAdultes = parseInt(this.state.searchOptions.selectedAdultes);
        let nbEnfants = parseInt(this.state.searchOptions.selectedEnfants);
        const uniteAvecNbAdulteEtEnfant = {
            ...unite,
            plan_base: {
                ...unite.plan_base,
                TrancheAge: this.state.searchOptions.selectedTrancheAges.length === 0 || initialize ? unite.plan_base.TrancheAge.map((tancheAge) => {
                    let tranche = {
                        ...tancheAge,
                        quantite: 0
                    };
                    if (tancheAge.trancheAge.isAdulte && nbAdultes !== 0) {
                        tranche = {
                            ...tancheAge,
                            quantite: nbAdultes
                        };
                        nbAdultes = 0;
                    } else if (!tancheAge.trancheAge.isAdulte && nbEnfants !== 0) {
                        tranche = {
                            ...tancheAge,
                            quantite: nbEnfants
                        };
                        nbEnfants = 0;
                    }
                    return tranche;
                }) : this.state.searchOptions.selectedTrancheAges
            }
        };
        return {
            nbAdultes,
            nbEnfants,
            uniteAvecNbAdulteEtEnfant
        };
    }

    async ValiderEtOuvrirPopupTrancheAge(idUnite) {
        const unite = this.getUniteById(idUnite);
        const NbTrancheAgeAdulte = (unite.plan_base.TrancheAge || []).filter((trancheAge) => trancheAge.trancheAge.isAdulte).length;
        const NbTrancheAgeEnfants = (unite.plan_base.TrancheAge || []).filter((trancheAge) => !trancheAge.trancheAge.isAdulte).length;

        const {uniteAvecNbAdulteEtEnfant} = this.getUniteAvecTrancheAges(unite, true);
        this.setState({
            AjouterPanierEtape: 1,
            searchOptions: {
                ...this.state.searchOptions,
                selectedTrancheAges: uniteAvecNbAdulteEtEnfant.plan_base.TrancheAge
            }
        });
        if ((unite.plan_base.TrancheAge || []).length > 0 && ((NbTrancheAgeAdulte > 1 && parseInt(this.state.searchOptions.selectedAdultes) > 0) || (NbTrancheAgeEnfants > 1 && parseInt(this.state.searchOptions.selectedEnfants) > 0))) {
            this.openTrancheAgePopup(uniteAvecNbAdulteEtEnfant);
            return true
        }
        return false;
    }

    async confirmationPopupMultipleUnite(idUnite) {
        const OptionsReservation = await getOptionsReservation(idUnite);
        const unite = this.getUniteById(idUnite);
        const {uniteAvecNbAdulteEtEnfant} = this.getUniteAvecTrancheAges(unite);
        if (OptionsReservation && OptionsReservation.some((options) => options.typeAuxiliaire === TypeAuxiliaire.Unite)) {
            var unites = [];
            for (const optionIndex in OptionsReservation) {
                const option = OptionsReservation[optionIndex];
                const uniteAuxiliaire = this.state.unites.find((unite) => unite["id_unite"] === option.idAuxiliaire);
                if (!uniteAuxiliaire) continue;

                unites.push(uniteAuxiliaire["code_unite"]);
            }

            this.openConfirmationOptionReservationPopup(uniteAvecNbAdulteEtEnfant, `${ unites.length > 1 ? "Les unités" : "L'unité" } <b>${ unites.join(", ") }</b> ${ unites.length > 1 ? "seront" : " sera" } également ajouté au panier. Voulez-vous continuer?`);
            return true;
        }
        return false;
    }

    async confirmationOptionForfait(idUnite) {
        try {
            const unite = await this.getUniteById(idUnite);
            if (unite) {
                const result = await this.valideEtPrepareData(unite, this.state.typeHebergement);
                if (result) {
                    const forfaitList = await getForfaitList(JSON.stringify(result.selectedUnite));
                    if (forfaitList && (forfaitList.length > 0 )) {
                        this.setState((prevState) => ({
                            ...prevState,
                            isOptionForfait: true,
                            isOkverification: true,
                            AjouterPanierEtape: 4,
                            optionsForfaitList: forfaitList,
                        }));
                        
                        return true;
                    }
                }
            }
        } catch (error) {
            console.log('error', error);
        }
        return false;
        
    }

    async ouvrirPopupProduitOption(idUnite) {
        const options = this.state.searchOptions;

        const dateArrivee = (options.selectedArrivee ? options.selectedArrivee : NULLDATE).setHours(23, 59, 59);
        const dateDepart = (options.selectedDepart ? options.selectedDepart : NULLDATE).setHours(23, 59, 59);

        const arrivee = toSelectedISODateTimeString(dateArrivee);
        const depart = toSelectedISODateTimeString(dateDepart);
        const OptionsReservation = await Promise.all(((await getOptionsReservation(idUnite, arrivee, depart)) || []).filter((options) => options.typeAuxiliaire === TypeAuxiliaire.Produit).map(async (produitCourrant) => {
            const produit = await getProductById(produitCourrant.idAuxiliaire);
            return {
                ...produitCourrant,
                prixVente: produit.prixVente || 0
            }
        }));

        if (OptionsReservation && OptionsReservation.length > 0) {
            var products = [];
            const nbPersonne = parseInt(this.state.searchOptions.selectedEnfants) + parseInt(this.state.searchOptions.selectedAdultes);
            for (const cur of OptionsReservation.filter(p => p.ajoutAutomatique)) {
                const produit = await getProductById(cur.idAuxiliaire);
                let facteurModeCalcul = 1;
                switch (cur.modeCalcul) {
                    case OptionReservationModeCalcul.Duree:
                        facteurModeCalcul = differenceInDays(parseJSON(this.state.searchOptions.selectedDepart), parseJSON(this.state.searchOptions.selectedArrivee));
                        break;
                    case OptionReservationModeCalcul.DureePersonne:
                        facteurModeCalcul = differenceInDays(parseJSON(this.state.searchOptions.selectedDepart), parseJSON(this.state.searchOptions.selectedArrivee)) * nbPersonne;
                        break;
                    case OptionReservationModeCalcul.Global:
                        facteurModeCalcul = 1;
                        break;
                    case OptionReservationModeCalcul.Gratuite:
                        facteurModeCalcul = 0;
                        break;
                    default:
                        break;
                }
                products.push({
                    idProduit: cur.idAuxiliaire,
                    quantite: facteurModeCalcul,
                    description: produit.description,
                    prixVente: produit.prixVente
                });
            }
            const OptionsReservationFiltered = OptionsReservation.filter((p) => p.ajoutAutomatique === false);
            this.setState({
                AjouterPanierEtape: 2,
                selectedProducts: products,
                produitsOptionnelsPopup: true,
                produits: OptionsReservationFiltered,
                selectedIdUnite: idUnite,
            });
            return OptionsReservationFiltered.length > 0
        }
        this.setState({
            AjouterPanierEtape: 2,
            selectedIdUnite: idUnite,
        });
        return false;
    };

    async PrepareForAddToShoppingCart(idUnite, type_hebergement = null) {
        if (this.state.AjouterPanierEtape < 1 && await this.ValiderEtOuvrirPopupTrancheAge(idUnite)) return;
        if (this.state.AjouterPanierEtape < 2 && await this.ouvrirPopupProduitOption(idUnite)) return;
        if (this.state.AjouterPanierEtape < 3 && await this.confirmationPopupMultipleUnite(idUnite)) return;
        if (this.state.AjouterPanierEtape < 4 && await this.confirmationOptionForfait(idUnite, type_hebergement)) return;
        const unite = this.getUniteById(idUnite)
        this.addToShoppingCart(unite, true, type_hebergement);

    }

    async addToShoppingCart(unite, resertSearch = true, type_hebergement) {
        const newItem = await this.valideEtPrepareData(unite, type_hebergement);
        if (newItem === false) {
            this.setState({AjouterPanierEtape: 0});
            return;
        }

        var montants = await calculateSumarryAmounts(newItem["selectedUnite"]);
        if (montants.montantDetailles.some(mntDetail => mntDetail.description === "Invalide")) {
            // this.setState((prevState) => ({
            //     ...prevState,
            //     isOkverification: false
            // }));
            this.props.setErrorAlert("Une erreur c'est produite lors de l'ajout au panier");
            return;
        }

        const result = await addReservationTemp({
            dateDebut: newItem["selectedUnite"].selectedArrivee,
            dateFin: newItem["selectedUnite"].selectedDepart,
            idUnite: unite.id_unite
        });

        if (result.uniteIsUnavailable) {
            this.props.setErrorAlert("L'unité n'est actuellement pas disponible pour la période sélectionnée. Veuillez SVP modifier et reessayer!");
            return;
        }

        newItem["idsReservationTemp"] = result.idsReservationTemp;

        this.props.setTimeoutShop(result.idsReservationTemp);
        this.setState({fieldError: {}});
        if (resertSearch) this.resetSearchOptions(true);
        this.updateOption("Arrivée", toDateString(newItem["selectedUnite"].selectedArrivee), toSelectedISODateTimeString(newItem["selectedUnite"].selectedArrivee));
        this.updateOption("Départ", toDateString(newItem["selectedUnite"].selectedArrivee), toSelectedISODateTimeString(newItem["selectedUnite"].selectedArrivee));
        if (this.state.optionsForfaitSelected && this.state.optionsForfaitSelected.idPlanEntete !== null && this.state.optionsForfaitSelected.description !== null) { 
            newItem.inclus.push({ Forfait: this.state.optionsForfaitSelected.description })
            newItem.selectedUnite.id_plan_entete = this.state.optionsForfaitSelected.idPlanEntete ;
        }
        this.props.addToShoppingCart(newItem);
        return result.idsReservationTemp;
    }

    applySearchFilters() {
        let hasAnySearchCriteria = false;
        const searchCriterias = Object.entries(this.state.searchOptions);

        for (const [searchCriteria, searchCriteriaValue] of searchCriterias) {
            if (searchCriteriaValue && searchCriteriaValue !== -1 && searchCriteriaValue !== "-1") hasAnySearchCriteria = true;
        }
        this.setState({fieldError: {}});
        if (!hasAnySearchCriteria) {
            if (this.state.initialLocation !== this.props.location.pathname) {
                this.getAllUnites();
                this.props.history.push(this.state.initialLocation);
            }
            return;
        }

        this.applyUnitesSearch().then(() => this.props.history.push(this.props.location.pathname + this.getSearchQueryString()));
    }

    async applyUnitesSearch(isUUnitesInitialization) {
        this.setState({
            dataLoadedUnites: false,
            unites: []
        });
        const params = new URLSearchParams(this.props.location.search);
        const idUnite = params.get("idUnite");


        let queryString = isUUnitesInitialization ? this.getInitialSearchQueryString() : this.getSearchQueryString();

        if (isUUnitesInitialization) {
            const idTypeUnite = params.get("idTypeUnite");
            if (idTypeUnite) {
                const typeService = params.get("typeService");
                const newTypeService = await getUniteTypeService(idTypeUnite);
                queryString = queryString.replace(`typeService=${ typeService }`, `typeService=${ newTypeService }`);
                this.setState({
                    searchOptions: {
                        ...this.state.searchOptions,
                        selectedService: newTypeService
                    }
                });
            }
        }

        var unites = await searchUnites(queryString.replace("idUnite=" + idUnite, ""));
        //console.log(`${unites.length} unité(s) chargés. Premier possible unité à la prochaine ligne.`);
        //console.log(unites.length ? unites[0] : {});

        this.setState({
            dataLoadedUnites: true,
            unites: unites
        });
    }

    blurCalendrierArrivee() {
        if (!this.state.isCalendarBlurred) {
            this.toggleCalendrierArrivee();
            return;
        }
        this.setState({isCalendarBlurred: false});
    }

    blurCalendrierDepart() {
        if (!this.state.isCalendarBlurred) {
            this.toggleCalendrierDepart();
            return;
        }
        this.setState({isCalendarBlurred: false});
    }

    getSearchSyncedWithGetParams() {
        const params = new URLSearchParams(this.props.location.search);

        return {
            selectedArrivee: params.get("arrivee") === null ? null : parseDate(params.get("arrivee"), "yyyy-MM-dd", new Date()),
            selectedDepart: params.get("depart") === null ? null : parseDate(params.get("depart"), "yyyy-MM-dd", new Date()),
            selectedIdEquipement: params.get("idUniteEquipmentType") || "",
            selectedEquipementType: params.get("equipmentType") || "",
            selectedLongueur: params.get("longueurEquipement") || "",
            selectedService: params.get("typeService") || -1, // -1: tous les services
            selectedIdAmperage: params.get("idAmperage") || "",
            selectedAdultes: params.get("adultes") || 0,
            selectedEnfants: params.get("enfants") || 0
        };
    }

    getParametres() {
        getNombreJoursMaximumEnAvance().then(({keyValue: maxJoursEnAvanceReservation}) => {
            this.setState({
                maxJoursEnAvanceReservation: parseInt(maxJoursEnAvanceReservation || 0),
                dataLoadedParametres: true
            });
        });
    }

    getConfigs() {
        getSvgData()
            .then(({keyValue: carte}) => {
                this.setState({
                    //carte: (carte || "").replace("xmlnsXml", "xmlnsxml"),
                    carte: carte,
                    dataLoadedConfigs: true
                });
            })
            .catch(() => this.setState({
                carte: null,
                dataLoadedConfigs: true
            }));

        getConfigurationByKeyName("MODE_RESERVATION_WEB")
            .then(({keyValue: modeReservationWeb}) => this.setState({
                demandeDeReservationParCourriel: modeReservationWeb === "2",
                consultationSeulement: modeReservationWeb === "1"
            }))
            .catch(() => this.setState({
                demandeDeReservationParCourriel: false,
                consultationSeulement: false
            }));
    }

    getInputClassName = () => {
        return this.state.fieldError !== null && this.state.fieldError["selectedDate"] ? `choix-unite-recherche-input-wrapper ${ mandatoryInputError }` : "choix-unite-recherche-input-wrapper";
    };

    async getAllUnites() {
        this.setState({
            dataLoadedUnites: false,
            unites: []
        });

        const idTypeUnite = new URLSearchParams(this.props.location.search).get("idTypeUnite");
        // const unites = await getUnites(idTypeUnite, this.state.typeHebergement, this.generateQueryStringInclude());
        const unites = await getUnites(idTypeUnite, this.state.typeHebergement, this.generateQueryStringInclude());
        this.setState({
            dataLoadedUnites: true,
            unites: unites
        });
    }

    generateQueryStringInclude() {
        return "arriveeDepartTypeUnite,planBase,indisponibilites";
    }

    getUniteById(id) {
        //console.log([this.state.unites, id]);
        return this.state.unites.find((unite) => unite.id_unite === id);
    }

    getSearchQueryString() {
        const params = new URLSearchParams(this.props.location.search.toLowerCase());

        const idTypeUnite = params.get("idtypeUnite");
        const idUnite = params.get("idunite");
        const arrivee = this.state.searchOptions.selectedArrivee;
        const depart = this.state.searchOptions.selectedDepart;
        const adultes = this.state.searchOptions.selectedAdultes;
        const enfants = this.state.searchOptions.selectedEnfants;
        const idUniteEquipmentType = this.state.searchOptions.selectedIdEquipement;
        const equipmentType = this.state.searchOptions.selectedEquipementType;
        const longueurEquipement = this.state.searchOptions.selectedLongueur;
        const typeService = this.state.searchOptions.selectedService;
        const idAmperage = this.state.searchOptions.selectedIdAmperage;
        const extra = this.state.searchOptions.selectedExtra;
        const entreeSortie = this.state.searchOptions.selectedEntreeSortie;

        let search = "";
        search = appendSearchParam(search, "typehebergement", this.state.typeHebergement);
        if (!NULL_VALUES.includes(idTypeUnite)) search = appendSearchParam(search, "idtypeunite", idTypeUnite);
        if (!NULL_VALUES.includes(idUnite)) search = appendSearchParam(search, "idunite", idUnite);
        if (arrivee) search = appendSearchParam(search, "arrivee", formatDateYMD(arrivee));
        if (depart) search = appendSearchParam(search, "depart", formatDateYMD(depart));
        if (adultes !== "") search = appendSearchParam(search, "adultes", adultes);
        if (enfants !== "") search = appendSearchParam(search, "enfants", enfants);
        if (idUniteEquipmentType !== "") search = appendSearchParam(search, "iduniteequipmenttype", idUniteEquipmentType);
        if (equipmentType !== "") search = appendSearchParam(search, "equipmenttype", equipmentType);
        if (longueurEquipement !== "") search = appendSearchParam(search, "longueurequipement", longueurEquipement);
        if (typeService !== "") search = appendSearchParam(search, "typeservice", typeService);
        if (idAmperage !== "") search = appendSearchParam(search, "idamperage", idAmperage);
        if (entreeSortie) search = appendSearchParam(search, "entreesortie", entreeSortie);
        if (extra !== "") {
            const extraKeys = Object.keys(extra);
            search = appendSearchParam(search, "extraid", Object.keys(extra).map((e, i) => `${ e }${ i > 0 && i < extraKeys.length - 1 ? "," : "" }`));
            search = appendSearchParam(search, "extravalue", Object.keys(extra).map((e, i) => `${ extra[e] }${ i > 0 && i < extraKeys.length - 1 ? "," : "" }`));
        }
        return appendSearchParam(search, "include", this.generateQueryStringInclude());
    }

    getInitialSearchQueryString() {
        const params = new URLSearchParams(this.props.location.search.toLowerCase());

        const idTypeUnite = params.get("idtypeunite");
        const idUnite = params.get("idunite");
        const arrivee = params.get("arrivee");
        const depart = params.get("depart");
        const adultes = params.get("adultes");
        const enfants = params.get("enfants");
        const idUniteEquipmentType = params.get("iduniteequipmenttype");
        const equipmentType = params.get("equipmenttype");
        const longueurEquipement = params.get("longueurequipement");
        const typeService = params.get("typeservice");
        const idAmperage = params.get("idamperage");
        const entreeSortie = params.get("entreesortie") === "true";
        const extraId = params.get("extraid");
        const extraValue = params.get("extravalue");

        let search = "";
        search = appendSearchParam(search, "typehebergement", this.state.typeHebergement);
        if (!NULL_VALUES.includes(idTypeUnite)) search = appendSearchParam(search, "idtypeunite", idTypeUnite);
        if (!NULL_VALUES.includes(idUnite)) search = appendSearchParam(search, "idunite", idUnite);
        if (arrivee) search = appendSearchParam(search, "arrivee", formatDateYMD(arrivee));
        if (depart) search = appendSearchParam(search, "depart", formatDateYMD(depart));
        if (adultes !== "") search = appendSearchParam(search, "adultes", adultes);
        if (enfants !== "") search = appendSearchParam(search, "enfants", enfants);
        if (idUniteEquipmentType !== "") search = appendSearchParam(search, "iduniteequipmenttype", idUniteEquipmentType);
        if (equipmentType !== "") search = appendSearchParam(search, "equipmenttype", equipmentType);
        if (longueurEquipement !== "") search = appendSearchParam(search, "longueurequipement", longueurEquipement);
        if (typeService !== "") search = appendSearchParam(search, "typeservice", typeService);
        if (idAmperage !== "") search = appendSearchParam(search, "idamperage", idAmperage);
        if (extraId !== "") search = appendSearchParam(search, "extraid", extraId);
        if (extraValue !== "") search = appendSearchParam(search, "extravalue", extraValue);
        if (entreeSortie) search = appendSearchParam(search, "entreesortie", entreeSortie);

        return appendSearchParam(search, "include", this.generateQueryStringInclude());
    }

    onDateArriveeHoverChange(date) {
        if (this.isSelectableDateArriveeDepart(date)) this.setState({previewArrivee: date}); else this.setState({previewArrivee: null});
    }

    onDateDepartHoverChange(date) {
        if (this.isSelectableDateArriveeDepart(date)) this.setState({previewDepart: date}); else this.setState({previewDepart: null});
    }

    onSelectDateCalendar(date) {
        if (this.state.searchOptions.selectedArrivee === null || !this.state.calendarDateInSelection) {
            this.setState({
                ...this.state,
                calendarDateInSelection: true
            });
            this.onSelectDateArrivee(date);
        } else {
            this.setState({
                ...this.state,
                calendarDateInSelection: false
            });
            this.onSelectDateDepart(date);
        }
    }

    onSelectDateArrivee(date) {
        if (this.isSelectableDateArriveeDepart(date)) {
            const selectedDepart = this.state.searchOptions.selectedDepart !== null && this.state.searchOptions.selectedDepart < date ? null : this.state.searchOptions.selectedDepart;

            this.updateOption("Arrivée", toDateString(date), toSelectedISODateTimeString(date));

            this.setState(Object.assign({
                showCalendrierArrivee: false,
                previewArrivee: null,
                showCalendrierDepart: selectedDepart === null,
                isCalenderDateChange: true,
                searchOptions: {
                    ...this.state.searchOptions,
                    selectedArrivee: date,
                    selectedDepart: selectedDepart
                }
            }));
        }
    }

    onSelectDateDepart(date) {
        if (this.isSelectableDateArriveeDepart(date)) {
            const selectedArrivee = this.state.searchOptions.selectedArrivee !== null && this.state.searchOptions.selectedArrivee > date ? null : this.state.searchOptions.selectedArrivee;

            this.updateOption("Départ", toDateString(date), toSelectedISODateTimeString(date));

            this.setState(Object.assign({
                showCalendrierDepart: false,
                previewDepart: null,
                showCalendrierArrivee: selectedArrivee === null,
                isCalendarDateChange: true,
                searchOptions: {
                    ...this.state.searchOptions,
                    selectedArrivee: selectedArrivee,
                    selectedDepart: date
                }
            }));
        }
    }

    closeSearchPopup() {
        this.setState({isSearchPopupOpen: false});
    }

    isSelectableDateArriveeDepart(date) {
        if (date === null) return false;

        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        let maxDate = null;
        if ((this.state.maxJoursEnAvanceReservation || 0) !== 0) {
            maxDate = new Date(today.getTime());
            maxDate.setDate(maxDate.getDate() + parseInt(this.state.maxJoursEnAvanceReservation));
        }

        return date >= today && (maxDate === null || date <= maxDate);
    }

    updateSearchField(event) {
        const option = event.target || event;
        var {
            name,
            value,
            checked,
            type
        } = option;

        this.updateOption(name.replace("selected", ""), option.selectedIndex ? option[option.selectedIndex].innerText : type === "checkbox" ? checked : value, value);

        this.setState({
            dataLoadedUnites: false,
            isCalenderDateChange: false,
            isSearchOption: true,
            searchOptions: {
                ...this.state.searchOptions,
                [name]: type === "checkbox" ? checked : value
            }
        });
    }

    updateSearchFieldExtra(event) {
        var {
            name,
            value
        } = event.target || event;

        this.setState((prevState) => ({
            searchOptions: {
                ...prevState.searchOptions,
                selectedExtra: {
                    ...prevState.searchOptions.selectedExtra,
                    [name]: value,
                },
            },
        }), () => {

        });
    }

    updateOption(name, textVal, value) {
        const {includedOptions} = this.state;
        const newOptionValue = {
            name: name,
            textVal: textVal,
            value: value
        };
        let options = null;
        const index = includedOptions.findIndex((o) => name === o.name);

        if (index >= 0) {
            includedOptions[index].value = value;
            includedOptions[index].textVal = textVal;
        } else {
            options = [...includedOptions, newOptionValue];
        }

        this.setState({
            includedOptions: [...(options || includedOptions)]
        });
    }

    resetSearchOptions(keepDate) {
        let searchOptions = {
            selectedIdEquipement: "",
            selectedLongueur: "",
            selectedService: -1, // -1: tous les services
            selectedIdAmperage: "",
            selectedAdultes: 0,
            selectedEnfants: 0,
            selectedEntreeSortie: false
        };

        let dateSearchOptions = keepDate ? {} : {
            selectedArrivee: null,
            selectedDepart: null
        };

        this.setState({
            isCalenderDateChange: false,
            isSearchOption: true,
            searchOptions: {...this.state.searchOptions, ...searchOptions, ...dateSearchOptions},
            includedOptions: [],
            previewArrivee: null,
            previewDepart: null,
            fieldError: {},
            selectedIdUnite: null,
            selectedProducts: null,
            AjouterPanierEtape: 0
        }, this.getAllUnites);


    }

    // Définition de la fonction setSearchOptions
    setSearchOptions() {
        // Récupére les paramètres de l'URL et les convertir en minuscules
        const params = new URLSearchParams(this.props.location.search.toLowerCase());

        // Vérifie si les paramètres 'datedebut', 'datefin', 'equipmenttype', 'service' ou 'amperage' existent dans params
        if (params.has("datedebut") || params.has("datefin") || params.has("equipmenttype") || params.has("service") || params.has("amperage")) {
            try {
                getUniteSearchFields(null, this.state.typeHebergement).then((champsRecherche) => {
                    // Crée un objet pour représenter la base des équipements
                    const equipementsBase = [
                        {type: 'Tente', code: 200},
                        {type: 'Tente-roulotte', code: 210},
                        {type: 'Roulotte', code: 220},
                        {type: 'Roulotte hybride', code: 230},
                        {type: 'Caravane à sellette', code: 240},
                        {type: 'Caravane portée', code: 250},
                        {type: 'Motorisé', code: 260},
                        {type: 'Chalet et prêt à camper', code: 999}
                    ];
                    // Crée un objet pour représenter la base des amperages
                    const amperageBase = [
                        {type: '15 ampères', code: 1},
                        {type: '20 ampères', code: 2},
                        {type: '30 ampères', code: 3},
                        {type: '50 ampères', code: 4}
                    ];

                    // Récupére les valeurs des paramètre 'equipmenttype', 'service', 'amperage'
                    const equipmentSearch = params.get("equipmenttype");
                    const serviceValue = params.get("service");
                    const amperageValue = params.get("amperage");

                    // Cherche à quel type 'equipmenttype' appartient dans 'equipements'
                    const matchedEquipement = equipementsBase.find(equipement => equipement.code === parseInt(equipmentSearch));
                    // Cherche dans le tableau "services" pour trouver le "service_type" correspondant
                    const serviceType = champsRecherche.services.find((service) => service.service_type === parseInt(serviceValue));
                    // Trouve le type d'amperage correspondant dans amperageBase
                    const matchedAmperage = amperageBase.find((item) => item.code === parseInt(amperageValue));

                    // Si on trouve un type correspondant dans 'equipements'
                    if (matchedEquipement) {
                        // Cherche l'id_unite_equipment_type dont la description est équivalente dans champsRecherche.equipements
                        const matchedChampsRechercheEquipementsItem = champsRecherche.equipements.find(item => item.description === matchedEquipement.type);
                        // Si on trouve l'id_unite_equipment_type correspondant dans 'champsRecherche.equipements'
                        if (matchedChampsRechercheEquipementsItem) {
                            // Remplace 'equipmenttype' dans 'params' par l'id_unite_equipment_type correspondant
                            params.set("iduniteequipmenttype", matchedChampsRechercheEquipementsItem.id_unite_equipment_type);
                        }
                    }

                    // Vérifie si on a trouvé une correspondance dans "services"
                    if (serviceType) {
                        // Remplace le paramètre "service" par "typeservice" avec la valeur trouvée
                        params.set("typeservice", serviceType.service_type.toString());
                    }

                    // Vérifie si on a trouvé un amperage correspondant
                    if (matchedAmperage) {
                        // Trouve l'id_unite_service_type correspondant dans amperages
                        const matchedAmperageService = champsRecherche.amperages.find(
                            (item) => item.description.includes(amperageValue)
                        );

                        if (matchedAmperageService) {
                            // Remplace "amperage" par "idamperage" avec l'id_unite_service_type correspondant
                            params.set("idamperage", matchedAmperageService.id_unite_service_type);
                        }
                    }

                    // Mettre à jour les options de recherche dans l'état
                    this.setState({
                        searchOptions: {
                            ...this.state.searchOptions,
                            selectedArrivee: params.get("datedebut") === null ? this.state.searchOptions.selectedArrivee || null : parseDate(params.get("datedebut"), "yyyy-MM-dd", new Date()),
                            selectedDepart: params.get("datefin") === null ? this.state.searchOptions.selectedDepart || null : parseDate(params.get("datefin"), "yyyy-MM-dd", new Date()),
                            selectedIdEquipement: params.get("iduniteequipmenttype") || "",
                            selectedEquipementType: params.get("equipmenttype") || "",
                            selectedLongueur: params.get("longueurequipement") || "",
                            selectedService: params.get("typeservice") || -1, // -1: tous les services
                            selectedIdAmperage: params.get("idamperage") || "",
                            selectedAdultes: params.get("adultes") || 0,
                            selectedEnfants: params.get("enfants") || 0,
                            selectedTrancheAges: [],
                            extra: {},
                            selectedEntreeSortie: params.get("entreesortie") === "true",
                            selectedExtra: {}
                        }
                    }, () => {
                        // Mettre à jour les options supplémentaires de recherche
                        getSearchFieldsExtra().then((searchFieldExtra) => {
                            if (searchFieldExtra) {
                                searchFieldExtra.map((fieldExtra) => this.setState({
                                    searchOptions: {
                                        ...this.state.searchOptions,
                                        extra: {
                                            ...this.state.searchOptions.extra,
                                            [fieldExtra.idParametresReservationEnLigne]: {
                                                ...fieldExtra,
                                                value: params.get("extravalue") || 0
                                            }
                                        },
                                        selectedExtra: {
                                            ...this.state.searchOptions.selectedExtra,
                                            [fieldExtra.idParametresReservationEnLigne]: params.get("extravalue") || 0
                                        }
                                    }
                                }));
                            }
                        });
                    });

                });
            } catch (error) {
                console.log("Quelque a mal tourne dans la recherche. Aller dans le composant choix-unite-page, fonction setSearchOptions() try and catch")
            }
        } else {
            // Si les paramètres ne sont pas présents, mettre à jour les options de recherche dans l'état
            this.setState({
                searchOptions: {
                    ...this.state.searchOptions,
                    selectedArrivee: params.get("arrivee") === null ? this.state.searchOptions.selectedArrivee || null : parseDate(params.get("arrivee"), "yyyy-MM-dd", new Date()),
                    selectedDepart: params.get("depart") === null ? this.state.searchOptions.selectedDepart || null : parseDate(params.get("depart"), "yyyy-MM-dd", new Date()),
                    selectedIdEquipement: params.get("iduniteequipmenttype") || "",
                    selectedEquipementType: params.get("equipmenttype") || "",
                    selectedLongueur: params.get("longueurequipement") || "",
                    selectedService: params.get("typeservice") || -1, // -1: tous les services
                    selectedIdAmperage: params.get("idamperage") || "",
                    selectedAdultes: params.get("adultes") || 0,
                    selectedEnfants: params.get("enfants") || 0,
                    selectedTrancheAges: [],
                    extra: {},
                    selectedEntreeSortie: params.get("entreesortie") === "true",
                    selectedExtra: {}
                }
            }, () => {
                // Mettre à jour les options supplémentaires de recherche
                getSearchFieldsExtra().then((searchFieldExtra) => {
                    if (searchFieldExtra) {
                        searchFieldExtra.map((fieldExtra) => this.setState({
                            searchOptions: {
                                ...this.state.searchOptions,
                                extra: {
                                    ...this.state.searchOptions.extra,
                                    [fieldExtra.idParametresReservationEnLigne]: {
                                        ...fieldExtra,
                                        value: params.get("extravalue") || 0
                                    }
                                },
                                selectedExtra: {
                                    ...this.state.searchOptions.selectedExtra,
                                    [fieldExtra.idParametresReservationEnLigne]: params.get("extravalue") || 0
                                }
                            }
                        }));
                    }
                });
            });

        }

    }

    setPathnameConfig() {
        const typeHeb = this.state.typeHebergement;
        this.setState({
            activityPathname: `/choix-unite/${ typeHeb }/activite`,
            cartePathname: `/choix-unite/${ typeHeb }/carte`,
            listePathname: `/choix-unite/${ typeHeb }/liste`,
            unitePathname: `/choix-unite/${ typeHeb }/unite`
        });
    }

    toggleCalendrierArrivee() {
        this.setState({
            isCalendarBlurred: true,
            showCalendrierArrivee: !this.state.showCalendrierArrivee,
            showCalendrierDepart: false,
            previewArrivee: null,
            previewDepart: null
        });
    }

    toggleCalendrierDepart() {
        // La date d'arrivée doit exister pour ce choix.
        if (!this.state.searchOptions.selectedArrivee) {
            this.toggleCalendrierArrivee();
            return;
        }
        this.setState({
            isCalendarBlurred: true,
            showCalendrierArrivee: false,
            showCalendrierDepart: !this.state.showCalendrierDepart,
            previewArrivee: null,
            previewDepart: null
        });
    }

    render() {
        if (!this.state.listePathname) return <div/>;

        const isNotActivity = this.state.typeHebergement !== "AC";
        const pathName = this.props.getUrlExcludingInitialParameters(); //this.props.location.pathname.replace(/\/([A-Za-z0-9-_]+)/, ""); // Enlève le id zec du path
        const params = new URLSearchParams(this.props.location.search);
        const idUnite = params.get("idUnite");
        const idTypeUnite = params.get("idTypeUnite");

        let affichageClass = pathName === this.state.cartePathname ? "type-affichage-carte" : pathName === this.state.listePathname ? "type-affichage-liste" : "type-affichage-unite";

        affichageClass += this.props.showRecherche ? " has-recherche" : " no-recherche";

        const showChoixTypeAffichage = pathName === this.state.cartePathname || pathName === this.state.listePathname;
        const isTypeAffichageUnite = pathName === this.state.unitePathname;
        const hasError = this.state.fieldError !== null;
        return (
            <div id="choix-unite-page">
                { this.state.produitsOptionnelsPopup && (
                    <CatalogueProduitsPopup
                        //isOpen={this.state.produitsOptionnelsPopup}
                        open
                        handleClose={ () => this.setState({produitsOptionnelsPopup: false}) }
                        produits={ this.state.produits }
                        addToShoppingCartSuite={ (idUnite, produits) => {
                            this.setState({
                                AjouterPanierEtape: 2,
                                selectedProducts: [...this.state.selectedProducts, ...(produits || [])]
                            }, () => this.PrepareForAddToShoppingCart(idUnite));

                        } }
                        arrivee={ this.state.searchOptions.selectedArrivee }
                        depart={ this.state.searchOptions.selectedDepart }
                        idUnite={ this.state.selectedIdUnite }
                        nombrePersonne={ parseInt(this.state.searchOptions.selectedAdultes) + parseInt(this.state.searchOptions.selectedEnfants) }
                    />) }
                { this.props.showRecherche && (
                    <ChoixRecherchePanel
                        affichageClass={ affichageClass }
                        hasError={ hasError }
                        fieldError={ this.state.fieldError }
                        getInputClassName={ this.getInputClassName }
                        toggleCalendrierArrivee={ this.toggleCalendrierArrivee }
                        toggleCalendrierDepart={ this.toggleCalendrierDepart }
                        formatDateYMD={ this.formatDateYMD }
                        previewArrivee={ this.state.previewArrivee }
                        previewDepart={ this.state.previewDepart }
                        searchOptions={ this.state.searchOptions }
                        showCalendrierArrivee={ this.state.showCalendrierArrivee }
                        showCalendrierDepart={ this.state.showCalendrierDepart }
                        blurCalendrierArrivee={ this.blurCalendrierArrivee }
                        blurCalendrierDepart={ this.blurCalendrierDepart }
                        maxJoursEnAvanceReservation={ parseInt(this.state.maxJoursEnAvanceReservation || 0) }
                        onDayClick={ this.onSelectDateArrivee }
                        onHoveredDayChange={ this.onDateArriveeHoverChange }
                        selectedRangeBegin={ this.state.searchOptions.selectedArrivee }
                        selectedRangeEnd={ this.state.searchOptions.selectedDepart }
                        initialMonth={ this.state.searchOptions.selectedArrivee || this.state.searchOptions.selectedDepart }
                        isSelectingRangeBegin={ true }
                        minDate={ today }
                        onDayClickDepart={ this.onSelectDateDepart }
                        onHoveredDayChangeDepart={ this.onDateDepartHoverChange }
                        initialMonthDepart={ this.state.searchOptions.selectedDepart || this.state.searchOptions.selectedArrivee }
                        isSelectingRangeEnd={ true }
                        idUnite={ idUnite }
                        isTypeAffichageUnite={ isTypeAffichageUnite }
                        idTypeUnite={ idTypeUnite }
                        typeHebergement={ this.state.typeHebergement }
                        applySearchFilters={ this.applySearchFilters }
                        addUniteToShoppingCart={ (idUnite) =>  this.PrepareForAddToShoppingCart(idUnite, this.state.typeHebergement) }
                        showAddToShoppingCart={ !this.state.demandeDeReservationParCourriel }
                        resetSearchOptions={ this.resetSearchOptions }
                        updateSearchField={ this.updateSearchField }
                        updateSearchFieldExtra={ this.updateSearchFieldExtra }
                        consultationSeulement={ this.state.consultationSeulement }
                        peutAjouterPanier={ this.state.peutAjouterPanier }
                        language={ this.props.match.params.language }
                    />) }

                <UniteRecherchePopup
                    handleClose={ () => this.setState({isSearchPopupOpen: false}) }
                    isOpen={ this.state.isSearchPopupOpen }
                    affichageClass={ affichageClass }
                    hasError={ hasError }
                    fieldError={ this.state.fieldError }
                    getInputClassName={ this.getInputClassName }
                    toggleCalendrierArrivee={ this.toggleCalendrierArrivee }
                    toggleCalendrierDepart={ this.toggleCalendrierDepart }
                    formatDateYMD={ this.formatDateYMD }
                    previewArrivee={ this.state.previewArrivee }
                    previewDepart={ this.state.previewDepart }
                    searchOptions={ this.state.searchOptions }
                    showCalendrierArrivee={ this.state.showCalendrierArrivee }
                    showCalendrierDepart={ this.state.showCalendrierDepart }
                    blurCalendrierArrivee={ this.blurCalendrierArrivee }
                    blurCalendrierDepart={ this.blurCalendrierDepart }
                    maxJoursEnAvanceReservation={ parseInt(this.state.maxJoursEnAvanceReservation || 0) }
                    onDayClick={ this.onSelectDateArrivee }
                    onHoveredDayChange={ this.onDateArriveeHoverChange }
                    selectedRangeBegin={ this.state.searchOptions.selectedArrivee }
                    selectedRangeEnd={ this.state.searchOptions.selectedDepart }
                    initialMonth={ this.state.searchOptions.selectedArrivee || this.state.searchOptions.selectedDepart }
                    isSelectingRangeBegin={ true }
                    minDate={ today }
                    onDayClickDepart={ this.onSelectDateDepart }
                    onHoveredDayChangeDepart={ this.onDateDepartHoverChange }
                    initialMonthDepart={ this.state.searchOptions.selectedDepart || this.state.searchOptions.selectedArrivee }
                    isSelectingRangeEnd={ true }
                    idUnite={ idUnite }
                    isTypeAffichageUnite={ isTypeAffichageUnite }
                    idTypeUnite={ idTypeUnite }
                    typeHebergement={ this.state.typeHebergement }
                    applySearchFilters={ this.applySearchFilters }
                    addUniteToShoppingCart={ this.PrepareForAddToShoppingCart }
                    showAddToShoppingCart={ !this.state.demandeDeReservationParCourriel }
                    resetSearchOptions={ this.resetSearchOptions }
                    updateSearchField={ this.updateSearchField }
                    updateSearchFieldExtra={ this.updateSearchFieldExtra }
                    consultationSeulement={ this.state.consultationSeulement }
                    peutAjouterPanier={ this.state.peutAjouterPanier }
                    language={ this.props.match.params.language }
                />
                { this.state.isTrancheAgePopupOpen && (
                    <DemanderTrancheAge
                        unite={ this.state.selectedUnite }
                        trancheAges={ this.state.searchOptions.selectedTrancheAges || this.state.selectedUnite.plan_base.TrancheAge }
                        isOpen={ this.state.isTrancheAgePopupOpen }
                        onValueChange={ this.onTrancheAgeValueChange }
                        onConfirmation={ this.PrepareForAddToShoppingCart }
                        handleClose={ (isCancel) => {
                            this.setState({
                                isTrancheAgePopupOpen: false,
                                selectedUnite: null,
                                searchOptions: isCancel ? {
                                    ...this.state.searchOptions,
                                    selectedAdultes: this.state.selectedAdultesBackup,
                                    selectedEnfants: this.state.selectedEnfantsBackup
                                } : {...this.state.searchOptions},
                                AjouterPanierEtape: (isCancel ? 0 : this.state.AjouterPanierEtape)
                            });
                        } }
                    />) }
                { this.state.isOptionsReservaitonPopupOpen && (
                    <ConfirmationOptionReservation
                        message={ this.state.OptionReservationMessage }
                        selectedUnite={ this.state.selectedUnite }
                        isOpen={ this.state.isOptionsReservaitonPopupOpen }
                        handleClose={ () => this.setState({
                            isOptionsReservaitonPopupOpen: false,
                            selectedUnite: null,
                            AjouterPanierEtape: 0
                        }) }
                        onConfirmation={ this.onConfirmationOptionReservationPopup }
                    />) }

                <div id="choix-unite-main-content" className={ affichageClass }>
                    { showChoixTypeAffichage && (
                        <div id="choix-unite-choix-type-affichage">
                            { isNotActivity && this.state.carte && (
                                <div className="choix-type-affichage-wrapper">
                                    <LocationSvg
                                        fill={ pathName === this.state.cartePathname ? "rgb(255, 103, 76)" : "black" }
                                        className="type-affichage-icon"
                                        onClick={ (e) => this.props.gotoChoixUniteCarte(this.state.typeHebergement, this.getSearchQueryString()) }/>
                                    <span
                                        style={ {color: pathName === this.state.cartePathname ? "rgb(255, 103, 76)" : "black"} }
                                        className="type-affichage-label"
                                        onClick={ (e) => this.props.gotoChoixUniteCarte(this.state.typeHebergement, this.getSearchQueryString()) }>
                                        { this.state.TEXTS.title0 }
                                    </span>
                                </div>) }
                            { isNotActivity && (
                                <div className="choix-type-affichage-wrapper">
                                    <ListSvg
                                        fill={ pathName === this.state.listePathname ? "rgb(255, 103, 76)" : "black" }
                                        className="type-affichage-icon"
                                        onClick={ (e) => this.props.gotoChoixUniteListe(this.state.typeHebergement, this.getSearchQueryString()) }/>
                                    <span
                                        style={ {color: pathName === this.state.listePathname ? "rgb(255, 103, 76)" : "black"} }
                                        className="type-affichage-label"
                                        onClick={ (e) => this.props.gotoChoixUniteListe(this.state.typeHebergement, this.getSearchQueryString()) }>
                                        { this.state.TEXTS.title1 }
                                    </span>
                                </div>) }
                        </div>) }
                    <div className="choix-type-affichage-search-icon"
                         onClick={ () => this.setState({isSearchPopupOpen: true}) }>
                        <span className="icon">
                            <SearchSvg width="39" height="39"/>
                        </span>
                    </div>
                    <div className={ "choix-unite-affichage " + affichageClass }>
                        <Route path={ `/:idZec/:language${ this.state.cartePathname }` }>{ this.state.carte !== null &&
                            <ChoixUniteCarte src={ this.state.carte } unites={ this.state.unites }/> }</Route>
                        <Route path={ `/:idZec/:language${ this.state.listePathname }` }>
                            { (this.state.unites.length > 0 && (
                                    <ChoixUniteListe
                                        isActivite={ !isNotActivity }
                                        calendrierInitialMonth={ this.state.selectedArrivee || this.state.selectedDepart }
                                        minDate={ today }
                                        maxSearchDate={ this.state.searchOptions.selectedDepart }
                                        minSearchDate={ this.state.searchOptions.selectedArrivee }
                                        selectedArrivee={ this.state.searchOptions.selectedArrivee }
                                        selectedDepart={ this.state.searchOptions.selectedDepart }
                                        onDayClick={ this.onSelectDateCalendar }
                                        maxJoursEnAvanceReservation={ this.state.maxJoursEnAvanceReservation }
                                        // showCalendrier={isNotActivity}  /**Old logique */
                                        showCalendrier={ true }
                                        showIcons={ isNotActivity }
                                        showRecherche={ isNotActivity }
                                        typeHebergement={ this.state.typeHebergement }
                                        unites={ this.state.unites }
                                        onClickAjouterPanier={(idUnite) => {
                                            this.setState((prevState) => ({
                                                ...prevState,
                                                idUniteSelected: idUnite
                                            }),
                                                () => {
                                                    this.PrepareForAddToShoppingCart(this.state.idUniteSelected, this.state.typeHebergement);
                                                }
                                            )
                                        }}
                                        showAddToShoppingCart={ !this.state.demandeDeReservationParCourriel && !this.state.consultationSeulement }
                                    />)) ||
                                <div id="aucune-unite-wrapper">{ (!this.state.dataLoadedUnites &&
                                        <img src={ loadingImage } alt="chargement"/>) ||
                                    <span>AUCUNE UNITÉ NE CORRESPOND AUX CRITÈRES DE RECHERCHE.</span> }</div> }
                        </Route>
                        <Route path={ `/:idZec/:language${ this.state.unitePathname }` }>
                            <Unite idUnite={ idUnite }
                                   setPeutAjouterPanier={ this.setPeuxAjouterPanier }
                                   selectedArrivee={ this.state.searchOptions.selectedArrivee }
                                   selectedDepart={ this.state.searchOptions.selectedDepart }
                                   carteIsEmpty={ !this.state.carte || this.state.carte === "" }
                            />
                            { this.state.carte !== null &&
                                <ChoixUniteCarte src={ this.state.carte } unites={ this.state.unites }/> }
                            <div style={ {
                                display: "flex",
                                gap: "1rem",
                                justifyContent: "end"
                            } }>
                                <div className={ "choix-unite-ajouter-au-panier-wrapper" }
                                     onClick={ this.props.history.goBack }>
                                    <span>RETOUR</span>
                                </div>
                                { idUnite && !this.state.consultationSeulement && this.state.peutAjouterPanier && (
                                    <div id="choix-unite-ajouter-au-panier-wrapper"
                                         className={ "choix-unite-ajouter-au-panier-wrapper" }
                                         onClick={ (e) => this.PrepareForAddToShoppingCart(idUnite) }>
                                        <span>{ this.state.demandeDeReservationParCourriel ? "Envoyer un demande de réservation" : "AJOUTER AU PANIER" }</span>
                                    </div>
                                ) }
                            </div>
                        </Route>
                    </div>
                </div>
                { this.state.isOptionForfait && (
                    <ConfirmationOptionForfait
                        isOpen={ this.state.isOptionForfait }
                        optionsForfaitList={ this.state.optionsForfaitList }
                        onConfirmation={ (forfaitSelected) => {
                            this.PrepareForAddToShoppingCart(this.state.idUniteSelected, this.state.typeHebergement);
                            this.setState((prevState) => ({
                                ...prevState,
                                optionsForfaitSelected : {
                                    ...prevState.optionsForfaitSelected,
                                    description: forfaitSelected.description,
                                    idPlanEntete: forfaitSelected.idPlanEntete
                                }
                            }))
                        }
                        }
                        handleClose={ (value) =>
                            this.setState((prevState) => ({
                                ...prevState,
                                isOptionForfait: false,
                                AjouterPanierEtape: 0
                            }), () => {

                            })
                        }
                        language={ this.props.match.params.language }
                    />
                ) }
            </div>);
    }
}

ChoixUnitePage
    .defaultProps = {
    showRecherche: true
};

const mapStateToProps = state => ({});

const dispatchProps = {
    addToShoppingCart,
    setErrorAlert,
    setTimeoutShop,
};

export default withNavigation(connect(mapStateToProps, dispatchProps)(ChoixUnitePage));
