import {
  SET_AUTH_TOKEN,
  CLEAR_AUTH_TOKEN
} from '../components/actions/types';

const initialState = null;

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      return action.authToken;
    case CLEAR_AUTH_TOKEN:
      return action.authToken;
    default:
      return state;
  }
}