import React, { useEffect, useState } from "react";
import "../../../css/info-client.css";
import { DIV_NAME, INPUT_NAME, LABEL_NAME, MANDATORY_MISSING } from "./info-client-constants";
import FieldError, { MandatoryFieldAsterisk } from './info-client-field-check';

const InputMaxLength = {
  name: 40,
  number: 16,
  expiryMonth: 2,
  expiryYear: 2,
  cvd: 4,
  codePromotionnel: 32
}

function InfoClientCarte(props) {
  const [{ codePromotionnel, cvd, expiryMonth, expiryYear, name, number }, setInfo] = useState(props.infos);
  const hasError = Object.keys(props.errors).length > 0;

  useEffect(() => setInfo(props.infos), [props.infos]);

  function handleChange (event) {
    const { name, value } = event.target;
    if (value.length <= InputMaxLength[name])
      props.updateInfo(name, value);
  }
  
  return (
    <div id="info-client">
      {hasError && <FieldError message={props.errors.paiement} />}
      <div className={DIV_NAME}>
        <label className={LABEL_NAME}>
          NOM DU DÉTENTEUR DE LA CARTE : {<MandatoryFieldAsterisk />}
          {hasError && <FieldError message={props.errors.name} />}
        </label>
        <input
          className={getInputName(props.errors.paiement || props.errors.name)}
          name="name" type="text" value={name} onChange={handleChange} required />
      </div>
      <div className={DIV_NAME}>
        <label className={LABEL_NAME}>
          NUMÉRO DE LA CARTE DE CRÉDIT : {<MandatoryFieldAsterisk />}
          {hasError && <FieldError message={props.errors.number} />}
        </label>
        <input className={`hide-increment-input-number ${getInputName(props.errors.number)}`}
          name="number" type="number" value={number} onChange={handleChange} required />
      </div>
      <div className={DIV_NAME}>
        <label className={LABEL_NAME}>
          EXPIRATION : {<MandatoryFieldAsterisk />}
          {hasError && <FieldError message={props.errors.month} /> && <FieldError message={props.errors.year} />}
        </label>
        <input
          className={`hide-increment-input-number ${getFullInputName(props.errors.month, 'info-client-left-demi-field-input')}`}
          name="expiryMonth" placeholder="MM" type="number" value={expiryMonth} onChange={handleChange} required />
        <input
          className={`hide-increment-input-number ${getFullInputName(props.errors.year, 'info-client-right-demi-field-input')}`}
          name="expiryYear" placeholder="AA" type="number" value={expiryYear} onChange={handleChange} required />
      </div>
      <div className={DIV_NAME}>
        <label className={LABEL_NAME}>
          CVC : {<MandatoryFieldAsterisk />}
          {hasError && <FieldError message={props.errors.cvd} />}
        </label>
        <input className={`hide-increment-input-number ${getInputName(props.errors.cvd)}`}
          name="cvd" type="number" placeholder="7777" value={cvd} onChange={handleChange} required />
      </div>
      <div className={DIV_NAME}>
        {hasError && <FieldError message={props.errors.codePromotionnel} />}
        <label className={LABEL_NAME}>CODE PROMOTIONNEL : </label>
        <input
          className={getInputName(props.errors.codePromotionnel)}
          name="codePromotionnel" type="text" value={codePromotionnel} onChange={handleChange} />
      </div>
    </div>
    );
}
function getInputName(error) {
  return error ? `${INPUT_NAME} ${MANDATORY_MISSING}` : INPUT_NAME
}
function getFullInputName(error, inputName) {
  return error ? `${inputName} ${MANDATORY_MISSING}` : inputName
}
export default InfoClientCarte;
