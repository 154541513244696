import React from 'react';

// Rendre les accompagnateurs 
const AddCompanion = ({
    title,
    authToken,
    EditComponent,
    relationsByIds,
    DeleteComponent,
    onChangeCheckbox,
    entrepriseContact,
    accompagnateurList,
    canManagerDependants,
    canAddDeleteModifyDependants
  }) => {
    return (
      <div className="droit-acces-section">
        {/* ligne de separation */}
        <div className="droit-acces-page-separator" />
  
        {/* title */}
        <div className="droit-acces-label-wrapper-title">
          <span className="droit-acces-span">{title.toUpperCase()} </span>
        </div>
  
        {/* content */}
        <div className="droit-acces-div">
  
          {/* Label */}
          <div className="droit-acces-div droit-acces-label-wrapper droit-acces-label-wrapper-customize">
            <span className="droit-acces-span"> CHOIX DES {title.toUpperCase()} :</span>
          </div>
  
          {/* Companion list */}
          <div className="droit-acces-div input-container input-container-customize">
            <div style={{ padding: '3px' }}>
              {accompagnateurList.map((accompaganteur, index) => (
                <div key={index} className="div-accompagneur">
                  {authToken != null &&
                    <input
                      type="checkbox"
                      id={accompaganteur.idClient}
                      checked={accompaganteur.isSelected}
                      onChange={() => onChangeCheckbox(accompaganteur)} />
                  }
                  <label htmlFor={accompaganteur.idClient} className="unselectable">
                    <span className="clickable" style={{ float: 'left', fontFamily: 'Barlow-Bold', fontSize: '26px', paddingLeft: authToken == null ? '4px' : '24px', marginTop: '5px', wordBreak: 'break-word' }}>{accompaganteur.nom} {accompaganteur.prenom} {(accompaganteur.relation || 0) === 0 ? "" : "(" + relationsByIds[accompaganteur.relation].descriptionFr + ")"}</span>
                  </label>
  
                  {authToken == null && EditComponent}
                  {authToken == null && DeleteComponent}
                </div>
              )
              )}
            </div>
            <div>
              <div style={{ display: 'grid', padding: '8px' }}>
                {canAddDeleteModifyDependants
                  ? <span
                    className="label primary clickable"
                    style={{ textTransform: 'initial' }}
                    onClick={e => canManagerDependants()}>Cliquez ici pour ajouter des {title.toLowerCase()}</span>
                  : <span className="label primary" style={{ textTransform: 'initial' }}>{`Pour ajouter des dépendants, contacter la zec au ${entrepriseContact}.`}</span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
export default AddCompanion