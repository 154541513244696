import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "../../../css/info-client.css";
import Fetch from '../../server/api';
import { getProvinces } from "../../server/services/client-service";
import { DIV_NAME, LABEL_NAME, SELECT_NAME } from "./info-client-constants";
import FieldError from './info-client-field-check';

function InfoClientProvince(props) {
  const [defaultIdProvince, setDefaultIdprovince] = useState("")
  const [isFetched, setIsFetched] = useState(false);
  const [lastIdPays, setLastIdPays] = useState("");
  const [provinces, setProvinces] = useState([]);
  const needUpdate = !(isFetched && lastIdPays === props.idPays);
  
  useEffect(() => {
    if (!needUpdate)
      return;

    if (props.isUsaOrCanada || !props.idProvince)
      fetchProvinces();
    else if (props.idPays)
      fetchCountryAsProvince();

    setIsFetched(true);
    setLastIdPays(props.idPays)
  }, [needUpdate, props.idPays]);

  function fetchProvinces() {
    getProvinces(props.idPays)
      .then(provinces => {
        setProvinces(provinces);
        if (!props.idPays || props.isCanada) {
          const idProvinceQc = provinces.find(province => province.code === "QC").idProvince;
          setDefaultIdprovince(idProvinceQc);
          props.updateIdProvince(idProvinceQc);
        }
      });
  }

  function fetchCountryAsProvince() {
    Fetch(`/${props.match.params.idZec}/client/province/${props.idPays}`)
      .then(province => props.updateIdProvince(province.idProvince));
  }

  return (
    <div className={DIV_NAME}>
      <label className={LABEL_NAME}>PROVINCE</label>
      {props.error && <FieldError message={props.error} />}
      <select className={SELECT_NAME} style={{fontSize: "2em"}} name={props.name} value={props.value || defaultIdProvince} onChange={props.onChange}>
        {
          provinces.length > 0 && provinces.map(province => (
            <option key={province.idProvince} value={province.idProvince}>
              {province.description}
            </option>
          ))
        }
      </select>
    </div >
  );
}

// champs requis
export default withRouter(InfoClientProvince);

