import { api, get } from "../api";

// #region configuration 
export async function getConfiguration() {
  return await api.get("Configuration/Web");
}

export async function getReservationModuleConfig() {
  return await api.get("configuration/modules/reservation");
}

export async function getSvgData() {
  return await api.get("Configuration/SVG_DATA");
}
export async function getConfigurationByKeyName(keyName) {
  return await api.get(`Configuration/${keyName}`);
}
// #endregion

// #region Parametres web 
export async function getNombreJoursMaximumEnAvance() {
  return await api.get("ParametresSitesWeb/NOMBRE_JOURS_MAXIMUM_EN_AVANCE");
}

export async function getParametresClient() {
  return await api.get("ParametresWeb/Client");
}
// #endregion 

// #region Taxe
export async function getNowTaxe() {
  const now = new Date(Date.now()).toJSON();
  return await api.get(`Taxe/${now}`);
}
// #endregion 

//feuillets non remis 
export async function getFeuilletNonRemis(idClient) {
  return await api.get(`EnregistrementEntete/ficheNonRemis/${idClient}`)
}

export async function getIdClient(idProfile) {
  return await api.get(`profile/${idProfile}/IdClient`)
}

export async function deleteVehicule(vehicledeleted) {
  return await api.post("vehicule/DeleteVehicules", JSON.stringify(vehicledeleted), false)
}

// export async function getDependant(idClient) {
//   return await api.get(`client/${idClient}/accompagnateursHistorique`);
// }

//profil client
export async function getProfilClient(idZec, idProfile, authToken) {
  return await get("/" + idZec + '/Profile/' + idProfile, false, authToken)
}