import { api } from "../api";

export async function getPays() {
  return await api.get("client/pays");
}

export async function getProvinces(idPays) {
  return await api.get(`client/provinces/${idPays || "CANADA"}`);
}

export async function getClientName(idClient) {
  return await api.get(`client/getClientName/${idClient}`);
}

/**
 * Retourne le code client lorsque le client est identifié à une carte membre valide
 * @param {any} client
 * @param {any} startDate
 */
export function isClientMembre(client, startDate) {
  return api.get(`Client/isMembre?dossier=${client.idDossier}&prenom=${client.firstName}&nom=${client.lastName}&telephone=${client.number}&startDate=${startDate}`);
}
