import React from "react";
import "./popup-footer.css";

function PopupFooter(props) {
  var default_color = 'rgb(255, 103, 76, 1)';
  return (
    <div style={{ background: props.color || default_color }} className="item-popup-footer">
      <button className="item-popup-btn btn-exit" type="button" onClick={props.onClick}>
        <p className="item-popup-font">{props.title}</p>
      </button>
    </div>
  );
}

export default PopupFooter;