import { api } from "../api";
/*
 * Service utilisé pour la gestion des politiques d'annulation de réservations
 */

export function getPolitique(idReservation, email) {
    return api.get(`PolitiqueAnnulation/Politique?idReservation=${idReservation}&email=${email}`);
}

export function annulerPolitique(idReservationDetail, idReservationEntete) {
    return api.get(`PolitiqueAnnulation/Politique/annuler?idReservationDetail=${idReservationDetail}&idReservationEntete=${idReservationEntete}`);
}