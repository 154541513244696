import React, { useEffect, useState } from "react";
import "../../../css/choix-unite-page.css";
import { getReservationModuleConfig } from "../../server/services/configuration-service";
import { getUniteSearchFields } from "../../server/services/unite-service";
import { getSearchFieldsExtra } from "../../server/services/parametre-reservation-en-ligne";
import ChampDeRechercheFieldError from "./champ-de-recherche-field-error";
import SpinInput from "../inputs/SpinInput";
import Translator from "../translator";

const ASK_AMPERAGE = "DEMANDER_TYPE_AMPERAGE";
const ASK_EQUIPEMENT = "DEMANDER_TYPE_EQUIPEMENT";
const ASK_LENGTH_EQUIPEMENT = "DEMANDER_LONGUEUR_EQUIPEMENT";
const ASK_SERVICE = "DEMANDER_TYPE_SERVICE";
const mandatoryInputError = " mandatory-input-error";
const fieldClassName = "choix-unite-recherche-input-wrapper";
const maxAdultes = 10;
const maxEnfants = 10;
const ADULTES = Array.from({ length: maxAdultes }, (_, i) => i + 1); // fetcher les maximums
const ENFANTS = Array.from({ length: maxEnfants }, (_, i) => i + 1); // fetcher les maximums

function ChampsDeRecherche(props) {
  const TEXTS = Translator(props.language, "TXT_CHAMP_DE_RECHERCHE");
  const {
    idTypeUnite,
    typeHebergement
  } = props;
  const [configFetched, setConfigFetched] = useState(false);
  const [searchField, setSearchField] = useState({
    equipements: [],
    longueursEquipements: [],
    services: [],
    amperages: []
  });
  const [askOption, setAskOption] = useState({
    askEquipement: false,
    askLengthEquip: false,
    askAmperage: false,
    askService: false
  });

  /*const [searchFieldExtra, setSearchFieldExtra] = useState([]);
  const [searchFieldExtraFetched, setSearchFieldExtraFetched] = useState(false);
  useEffect(() => {
    getSearchFieldsExtra()
      .then((searchFieldExtra) => setSearchFieldExtra(searchFieldExtra))
      .then(() => setSearchFieldExtraFetched(true));
  }, [searchFieldExtraFetched]);*/

  useEffect(() => {
    if (!idTypeUnite && !typeHebergement) return;

    getUniteSearchFields(idTypeUnite, typeHebergement).then((champsRecherche) => {
      setSearchField({
        equipements: champsRecherche.equipements,
        longueursEquipements: champsRecherche.longueursEquipement,
        services: champsRecherche.services,
        amperages: champsRecherche.amperages
      });
    });
  }, [idTypeUnite, typeHebergement]);

  useEffect(() => {
    function fetchSearchConfig() {
      getReservationModuleConfig().then((config) => {
        const askOption = getAskOption(config);
        setAskOption({
          askEquipement: askOption.equip,
          askLengthEquip: askOption.lengthEquip,
          askAmperage: askOption.amp,
          askService: askOption.serv
        });
      });
    }

    if (configFetched) return;

    fetchSearchConfig();
    setConfigFetched(true);
  }, [configFetched, askOption]);

  const getSelectClassName = (key) => (props.fieldError !== null && props.fieldError[key] ? `${fieldClassName} ${mandatoryInputError}` : fieldClassName);
  const hasError = props.fieldError !== null;
  const isCamping = props.typeHebergement === "CP";
  return (
    <div> 
      {isCamping && askOption.askEquipement && searchField.equipements.length > 0 && (
        <div className="choix-unite-recherche-section">
          <div className="choix-unite-recherche-title-wrapper">
            {hasError &&
              <ChampDeRechercheFieldError errorMessage={props.fieldError.selectedIdEquipement} />}
            <span>{TEXTS.title5}</span>
          </div>
          <div className="choix-unite-recherche-input-wrapper">
            <select className={getSelectClassName("selectedIdEquipement")} name="selectedIdEquipement" value={props.searchOptions.selectedIdEquipement} onChange={props.updateSearchField}>
              {searchField.equipements.length > 1 && (
                <option key="nothing-selected" value="">
                  {TEXTS.title6}
                </option>
              )}
              {searchField.equipements.map((equipement) => (
                <option key={equipement.id_unite_equipment_type} value={equipement.id_unite_equipment_type}>
                  {equipement.description.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {isCamping && askOption.askLengthEquip && searchField.longueursEquipements.length > 0 && (
        <div className="choix-unite-recherche-section">
          <div className="choix-unite-recherche-title-wrapper">
            {hasError &&
              <ChampDeRechercheFieldError errorMessage={props.fieldError.selectedLongueur} />}
            <span>{TEXTS.title7}</span>
          </div>
          <div className="choix-unite-recherche-input-wrapper">
            <select className={getSelectClassName("selectedLongueur")} name="selectedLongueur" value={props.searchOptions.selectedLongueur} onChange={props.updateSearchField}>
              <option key="nothing-selected" value="">
                {TEXTS.title8}
              </option>
              {searchField.longueursEquipements.map((longueur) => (
                <option key={longueur.longueur} value={longueur.longueur}>
                  {longueur.description.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {isCamping && askOption.askService && searchField.services.length > 0 && (
        <div className="choix-unite-recherche-section">
          <div className="choix-unite-recherche-title-wrapper">
            {hasError &&
              <ChampDeRechercheFieldError errorMessage={props.fieldError.selectedService} />}
            <span>{TEXTS.title9}</span>
          </div>
          <div className="choix-unite-recherche-input-wrapper">
            <select className={getSelectClassName("selectedService")} name="selectedService" value={props.searchOptions.selectedService} onChange={props.updateSearchField}>
              {searchField.services.length > 1 && (
                <option key="-1" value="-1">
                  {TEXTS.title10}
                </option>
              )}
              {searchField.services.map((service) => (
                <option key={service.service_type} value={service.service_type}>
                  {service.description.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {isCamping && askOption.askAmperage && searchField.amperages.length > 0 && (
        <div className="choix-unite-recherche-section">
          <div className="choix-unite-recherche-title-wrapper">
            {hasError &&
              <ChampDeRechercheFieldError errorMessage={props.fieldError.selectedIdAmperage} />}
            <span>{TEXTS.title11}</span>
          </div>
          <div className="choix-unite-recherche-input-wrapper">
            <select className={getSelectClassName("selectedIdAmperage")} name="selectedIdAmperage" value={props.searchOptions.selectedIdAmperage} onChange={props.updateSearchField}>
              {searchField.amperages.length > 1 && (
                <option key="nothing-selected" value="">
                  {TEXTS.title12}
                </option>
              )}
              {searchField.amperages.map((amperage) => (
                <option key={amperage.id_unite_service_type} value={amperage.id_unite_service_type}>
                  {amperage.description.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      <div className="choix-unite-recherche-section">
        <div className="choix-unite-recherche-title-wrapper">{hasError &&
          <ChampDeRechercheFieldError errorMessage={props.fieldError.selectedAdultes || props.fieldError.selectedEnfants} />}</div>
        <div className="choix-unite-recherche-input-wrapper">
          <div className="choix-unite-recherche-demi-input-wrapper">
            <div className="choix-unite-recherche-title-wrapper">
              <span>{TEXTS.title0}</span>
            </div>
            <SpinInput name="selectedAdultes" value={props.searchOptions.selectedAdultes || 0} max={999} min={0} onChange={props.updateSearchField} />
          </div>
          <div className="choix-unite-recherche-demi-input-wrapper">
            <div className="choix-unite-recherche-title-wrapper">
              <span>{TEXTS.title1}</span>
            </div>
            <SpinInput name="selectedEnfants" value={props.searchOptions.selectedEnfants || 0} max={999} min={0} onChange={props.updateSearchField} />
          </div>
        </div>
      </div>
      {props.searchOptions.extra &&
        Object.keys(props.searchOptions.extra).map((field) => (
          <div key={field} className="choix-unite-recherche-section">
            <div className="choix-unite-recherche-title-wrapper">{hasError &&
              <ChampDeRechercheFieldError errorMessage={props.fieldError[field]} />}</div>
            <div className="choix-unite-recherche-title-wrapper">{
              <span>{props.searchOptions.extra[field].description.toUpperCase()}</span>}</div>
            <SpinInput name={field} value={props.searchOptions.selectedExtra[field] || 0} max={999} min={0} onChange={props.updateSearchFieldExtra} />
          </div>
        ))}
      {isCamping && (
        <div className="choix-unite-recherche-section">
          <div className="choix-unite-recherche-title-wrapper">{hasError &&
            <ChampDeRechercheFieldError errorMessage={props.fieldError.selectedEntreeSortie} />}</div>
          <div className="choix-unite-recherche-input-wrapper">
            <div className="choix-unite-recherche-title-wrapper">
              <span>{TEXTS.title13}</span>
            </div>
            <input type="checkbox" className={"entreeSortieCheckbox"}  name="selectedEntreeSortie" checked={props.searchOptions.selectedEntreeSortie || false} value={props.searchOptions.selectedEntreeSortie || false} onChange={props.updateSearchField} />
          </div>
        </div>
      )}
      {!props.isTypeAffichageUnite && (
        <div className="choix-unite-recherche-section" style={{ marginTop: "32px" }}>
          <div className="choix-unite-recherche-button-wrapper" onClick={props.applySearchFilters}>
            <span> {TEXTS.title3} </span>
          </div>
          <div className="choix-unite-recherche-button-wrapper" onClick={props.resetSearchOptions}>
            <span> {TEXTS.title4} </span>
          </div>
        </div>
      )}
      {props.isTypeAffichageUnite && props.idUnite && props.addUniteToShoppingCart && !props.consultationSeulement && props.peutAjouterPanier && (
        <div id="choix-unite-recherche-section" style={{ marginTop: "32px" }}>
          <div className="choix-unite-recherche-button-wrapper" onClick={props.addUniteToShoppingCart}>
            <span>{!props.showAddToShoppingCart ? "Envoyer un demande de réservation" : "AJOUTER AU PANIER"}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChampsDeRecherche;

function getAskOption(config) {
  // défint hors de la fonction fetchConfig pour Éviter le re-rendering à chaque setState
  const len = config.length;
  let amp = false;
  let equip = false;
  let lengthEquip = false;
  let serv = false;
  for (let i = 0; i < len; i++) {
    const configRow = config[i];

    switch (configRow.keyName) {
      case ASK_AMPERAGE:
        amp = configRow.keyValue === "True";
        break;
      case ASK_EQUIPEMENT:
        equip = configRow.keyValue === "True";
        break;
      case ASK_LENGTH_EQUIPEMENT:
        lengthEquip = configRow.keyValue === "True";
        break;
      case ASK_SERVICE:
        serv = configRow.keyValue === "True";
        break;
      default:
        break;
    }
  }
  return {
    amp,
    equip,
    lengthEquip,
    serv
  };
}
