import React, { useContext } from 'react';

//context
import { EvenementContext } from '../../contexte/evenement-page';

// Components
import ButtonEvenement from './boutton-evenement';
import DetailsEvenement from './details-evenement';
import CalendarCustom from './calendrier.js/calendrier';

//image loader
import loadingImage from "../../../images/loading.svg";
import { setAlert } from '../actions/alert';
import { useDispatch } from 'react-redux';

const StepEvenement2 = () => {
    const { detailsEvenement, handleNext, clickDates } = useContext(EvenementContext);
    const dispacth = useDispatch()

    return (
        detailsEvenement !== undefined ? (
            <div className='container-details-evenement'>
                <div className='content-details-evenement'>
                    <DetailsEvenement details={detailsEvenement} />

                    <CalendarCustom
                        disabled={false}
                        dataSource={[{dateDebut: detailsEvenement.dateDebut, dateFin:detailsEvenement.dateFin}]}
                    />
                </div>
                <ButtonEvenement
                    onClick={() => clickDates.length > 0 ? handleNext(null) : dispacth(setAlert("Aucune date selectionnee", "danger"))}
                    title={"PARTICIPER A CET EVENEMENT"}
                    custumClassContainer={"container-button-details-evenement"} 
                    custumClassOnButton={"achat-forfait-card-buyable-list-item-montant btn-achat-evenement"}/>

            </div>
        ) : (
            <img src={loadingImage} alt="chargement" />
        )
    );
};

export default StepEvenement2;