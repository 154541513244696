import { CLEAR_NO_DOSSIER, SET_NO_DOSSIER } from '../components/actions/types'

const initialState = { noDossier: "" };

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_NO_DOSSIER:
      return { ...state, noDossier:action.payload };

    case CLEAR_NO_DOSSIER:
      return { ...initialState };

    default:
      return state;
  }
}