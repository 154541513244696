import React, { useEffect, useState } from "react";
import "./produit-optionnel.css";

const BBQ_NUMBER = 1; //le nombre de bbq est hardcod� et sera remplac� par un champs du catalogue plus tard
export default function OptionalProductRow(props) {
  const { product } = props;
  const [inputType] = useState(() => product.quantiteTypeHoraire ? "checkbox" : "number");
  const [isBbq, setIsBbq] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(props.previousQuantity);
  const [error, setError] = useState("");

  useEffect(
    () => setIsBbq(product.description && product.description.toLowerCase().includes("bbq"))
    , [product]);

  useEffect(
    () => {
      setSelectedQuantity(props.previousQuantity)
      if (product.quantiteDisponible < props.previousQuantity) 
        setErrorMsg();
    },
    [props.previousQuantity, product.quantiteDisponible]);
  
  const handleChange = event => {
    const { checked, name, value } = event.target;
    let qty = product.quantiteTypeHoraire ? checked ? props.selectedHours : 0 : value === "" ? 0 : parseInt(value);
    if (qty > 99)
      return;

    if (product.quantiteDisponible < qty) {
      setErrorMsg();
      return;
    }
    // Hardcoder le nombre de BBQ � 1 pour espacetonk
    if (qty > 0 && isBbq)
      qty = BBQ_NUMBER;

    setErrorMsg(false);
    setSelectedQuantity(qty);
    props.handleChange(name, qty);
  }

  function setErrorMsg(hasError = true) {
    if (hasError)
      setError(`*Seulement ${product.quantiteDisponible} ${product.quantiteTypeHoraire ? 'H' : ''} de disponible!`);
    else
      setError("");
  }
  
  return (
    <tr className="table-row">
      <td className="table-row-desc">{product.description}</td>
      <td className="table-row-prix">
        ${product.prix.toFixed(2)}{product.quantiteTypeHoraire ? 'x' + (isBbq ? BBQ_NUMBER : props.selectedHours) + 'h' : ''}
        {error && <div className="table-row-prix-error">{error}</div>}
      </td>
      <td>
        <input
          checked={product.quantiteTypeHoraire ? selectedQuantity > 0 : false}
          name={product.idProduit}
          type={inputType}
          value={product.quantiteTypeHoraire ? (props.selectedHours || 0) : (selectedQuantity || 0)} //undefined value return error on
          min={0}
          onChange={handleChange}/>
      </td>
    </tr>
  );
}
