import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../../css/calendar.css";
import dates from "../constants/dates.js";
import { addDays, max, min } from "date-fns";

const MIN_DATE = new Date(-8640000000000000);
const MAX_DATE = new Date(8640000000000000);
const today = new Date();
today.setHours(0);
today.setMinutes(0);
today.setSeconds(0);
today.setMilliseconds(0);

function mapStateToProps(state, ownProps) {
  return {};
}

function formatDateYMD(date) {
  return date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0");
}

function isSameDay(dateA, dateB) {
  if (dateA == null || dateB == null) return false;

  return dateA.getFullYear() === dateB.getFullYear() && dateA.getMonth() === dateB.getMonth() && dateA.getDate() === dateB.getDate();
}

function periodsIntersect(beginA, endA, beginB, endB, inclusif) {
  try {
    return inclusif ? max([beginA, beginB]) <= min([endA, endB]) : max([beginA, beginB]) < min([endA, endB]);
  } catch (e) {
    return false;
  }
}

class Calendar extends Component {
  constructor(props) {
    super(props);

    const firstDayOfthisMonth = new Date(today);
    this.renderDayHeader = this.renderDayHeader.bind(this);

    const minDate = this.props.minDate || today; //new Date("1900-01-01 00:00:00.000");
    const maxDate = this.props.maxDate || new Date(today); // new Date("2100-01-01 00:00:00.000");
    if (!this.props.maxDate) maxDate.setFullYear(maxDate.getFullYear() + 2); //2 years from now

    this.state = {
      isMounted: true,
      selectedArrivee: null,
      selectedDepart: null,
      date: typeof props.initialMonth === "string" || props.initialMonth instanceof String ? new Date(props.initialMonth) : props.initialMonth || new Date(minDate), //,now
      disponibilites: {}, // Un dictionnaire des classes CSS indiquant la disponibilité pour chaque journée. Structure: disponibilites[year-month-day] = '[green|red]-am [green|red]-pm'.
      isInitialSearchView: true,
      iteratorDay: firstDayOfthisMonth,
      iteratorIndisponibilites: 0,
      forceUpdating: false,
      hoveredDay: null,
      minDate: minDate,
      maxDate: maxDate,
      years: (() => {
        let years = [];

        for (let year = minDate.getFullYear(); year <= maxDate.getFullYear(); year++) years.push(year);

        return years;
      })(),
      monthsArr: this.props.language === 'fr' ? dates.MONTHS : dates.MONTHS_EN,
      daysArr: this.props.language === 'fr' ? dates.DAYS_ABB : dates.DAYS_EN_ABB
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { forceUpdating } = this.state;
    if (forceUpdating) this.setState({ forceUpdating: false });
    return nextState.forceUpdating || this.state.date !== nextState.date;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.language && nextProps.language != this.props.language) {
      const newMonths = nextProps.language == 'fr' ? dates.MONTHS : dates.MONTHS_EN;
      const newDays = nextProps.language == 'fr' ? dates.DAYS_ABB : dates.DAYS_EN_ABB;
      this.setState({
        monthsArr: newMonths,
        daysArr: newDays
      });
    }
  }

  componentDidMount() {
    const lastDayOfSelectedMonth = new Date(this.getReferenceDate().getTime());
    lastDayOfSelectedMonth.setMonth(lastDayOfSelectedMonth.getMonth() + 1);
    lastDayOfSelectedMonth.setDate(0);

    if (this.props.indisponibilites !== null) this.getDisponibilitesUntil(lastDayOfSelectedMonth);
  }


  getDisponibilitesUntil(lastDay, setStateCallback) {
    const { indisponibilites, heureArrivee, heureDepart } = this.props;
    // console.warn('2222ssss', heureDepart ,heureArrivee)
    let { iteratorDay, iteratorIndisponibilites, disponibilites } = this.state;
    if ((heureArrivee === heureDepart && heureArrivee !== "00:00:00") || heureArrivee > heureDepart) {
      let todayCheckIn = new Date(Date.parse(formatDateYMD(iteratorDay) + "T" + heureArrivee));
      let todayCheckOut = new Date(Date.parse(formatDateYMD(iteratorDay) + "T" + heureDepart));
      let previousCheckIn = new Date(todayCheckIn.getTime() - 1000 * 60 * 60 * 24);
      let nextCheckOut = new Date(todayCheckOut.getTime() + 1000 * 60 * 60 * 24);
      while (iteratorDay <= lastDay) {
        const date = formatDateYMD(iteratorDay);

        while (iteratorIndisponibilites < indisponibilites.length && indisponibilites[iteratorIndisponibilites].fin < todayCheckIn) iteratorIndisponibilites++;

        const statusMorning =
          (iteratorIndisponibilites > 0 && periodsIntersect(previousCheckIn, todayCheckOut, indisponibilites[iteratorIndisponibilites - 1].debut, indisponibilites[iteratorIndisponibilites - 1].fin)) ||
            (iteratorIndisponibilites < indisponibilites.length && periodsIntersect(previousCheckIn, todayCheckOut, indisponibilites[iteratorIndisponibilites].debut, indisponibilites[iteratorIndisponibilites].fin))
            ? "cell-am-indisponible"
            : "cell-am-disponible";

        const statusAfternoon =
          (iteratorIndisponibilites < indisponibilites.length && periodsIntersect(todayCheckIn, nextCheckOut, indisponibilites[iteratorIndisponibilites].debut, indisponibilites[iteratorIndisponibilites].fin)) ||
            (iteratorIndisponibilites < indisponibilites.length - 1 && periodsIntersect(todayCheckIn, nextCheckOut, indisponibilites[iteratorIndisponibilites + 1].debut, indisponibilites[iteratorIndisponibilites + 1].fin))
            ? "cell-pm-indisponible"
            : "cell-pm-disponible";

        disponibilites[date] = statusMorning + " " + statusAfternoon;

        iteratorDay.setDate(iteratorDay.getDate() + 1);
        todayCheckIn.setDate(todayCheckIn.getDate() + 1);
        todayCheckOut.setDate(todayCheckOut.getDate() + 1);
        previousCheckIn.setDate(previousCheckIn.getDate() + 1);
        nextCheckOut.setDate(nextCheckOut.getDate() + 1);
      }
    } else if (heureArrivee === "00:00:00" && heureDepart === "00:00:00") {
      let nextDay = new Date(iteratorDay.getTime() + 1000 * 60 * 60 * 24);

      while (iteratorDay <= lastDay) {
        const date = formatDateYMD(iteratorDay);

        while (iteratorIndisponibilites < indisponibilites.length && indisponibilites[iteratorIndisponibilites].fin < iteratorDay) iteratorIndisponibilites++;

        disponibilites[date] =
          iteratorIndisponibilites < indisponibilites.length && periodsIntersect(iteratorDay, nextDay, indisponibilites[iteratorIndisponibilites].debut, indisponibilites[iteratorIndisponibilites].fin)
            ? " cell-am-indisponible cell-pm-indisponible"
            : " cell-am-disponible cell-pm-disponible";

        iteratorDay.setDate(iteratorDay.getDate() + 1);
        nextDay.setDate(nextDay.getDate() + 1);
      }
    } else {
      // On ne gère pas le cas où heureDepart > heureArrivee car aucun séjour ne fonctionne ainsi. On implémentera si un client de Manisoft a ce cas particulier dans le future.
      while (iteratorDay <= lastDay) {
        // console.warn('jhbsvjkshdkjhsf', iteratorDay ,lastDay)
        let nextDay = new Date(iteratorDay.getTime() + 1000 * 60 * 60 * 24);
        const date = formatDateYMD(iteratorDay);

        while (iteratorIndisponibilites < indisponibilites.length && indisponibilites[iteratorIndisponibilites].fin < iteratorDay) iteratorIndisponibilites++;

        disponibilites[date] = "cell-non-applicable";

        iteratorDay.setDate(iteratorDay.getDate() + 1);
        nextDay.setDate(nextDay.getDate() + 1);
      }
    }

    this.setState({ ...this.state, iteratorDay, iteratorIndisponibilites, disponibilites, forceUpdating: true }, setStateCallback);
  }

  getDateColorClassName(date, minDate, maxDate) {
    const isDateWithinSelectableRange = (minDate === null || date >= minDate) && (maxDate === null || date <= maxDate);

    if (!isDateWithinSelectableRange) return "";

    const isHoveredDateWithinSelectableRange = this.state.hoveredDay !== null && (minDate === null || this.state.hoveredDay >= minDate) && (maxDate === null || this.state.hoveredDay <= maxDate);
    const validHoveredDay = isHoveredDateWithinSelectableRange ? this.state.hoveredDay : null;

    const rangeBegin = this.props.isSelectingRangeBegin ? validHoveredDay || this.props.selectedRangeBegin : this.props.isSelectingRangeEnd && this.props.selectedRangeBegin <= (validHoveredDay || this.props.selectedRangeEnd || MAX_DATE) ? this.props.selectedRangeBegin : null;
    const rangeEnd = this.props.isSelectingRangeEnd ? validHoveredDay || this.props.selectedRangeEnd : this.props.isSelectingRangeBegin && this.props.selectedRangeEnd >= (validHoveredDay || this.props.selectedRangeBegin || MIN_DATE) ? this.props.selectedRangeEnd : null;

    if ((rangeBegin != null || rangeEnd != null) && (isSameDay(rangeBegin, date) || isSameDay(rangeEnd, date))) return "cell-am-disponible  cell-pm-disponible ";

    if (rangeBegin != null && rangeEnd != null && date > rangeBegin && date < rangeEnd) return "light cell-am-disponible cell-pm-disponible";

    if (date.getMonth() !== this.getReferenceDate().getMonth()) return "";

    return this.state.disponibilites[formatDateYMD(date)] || "";
  }

  getReferenceDate() {
    return this.props.minSearchDate && this.state.isInitialSearchView ? this.props.minSearchDate : this.state.date;
  }

  updateMonth(change) {
    const now = new Date();
    const referenceDate = this.getReferenceDate();
    const newMonth = new Date(referenceDate.getTime());
    newMonth.setMonth(newMonth.getMonth() + change);

    const currentYearMonth = referenceDate.getFullYear() * 12 + referenceDate.getMonth();
    const newYearMonth = newMonth.getFullYear() * 12 + newMonth.getMonth();
    const minYearMonth = this.state.minDate.getFullYear() * 12 + this.state.minDate.getMonth();
    const maxYearMonth = this.state.maxDate.getFullYear() * 12 + this.state.maxDate.getMonth();

    if ((newYearMonth < minYearMonth && newYearMonth < currentYearMonth) || (newYearMonth > maxYearMonth && newYearMonth > currentYearMonth)) return;

    const lastDayOfNewMonth = new Date(newMonth.getTime());
    lastDayOfNewMonth.setMonth(lastDayOfNewMonth.getMonth() + 1);
    lastDayOfNewMonth.setDate(0);

    let lastMonthWithDisponibilites = null;
    if ((this.props.maxJoursEnAvanceReservation || 0) > 0) {
      lastMonthWithDisponibilites = new Date();
      lastMonthWithDisponibilites.setDate(lastMonthWithDisponibilites.getDate() + parseInt(this.props.maxJoursEnAvanceReservation));
    }

    const lastDayGetDisponibilites = lastMonthWithDisponibilites === null || lastMonthWithDisponibilites > lastDayOfNewMonth ? lastDayOfNewMonth : lastMonthWithDisponibilites;

    if (newMonth > now && this.props.indisponibilites !== null && this.state.disponibilites[formatDateYMD(newMonth)] === undefined) {
      this.setState(
        {
          date: newMonth,
          isInitialSearchView: false
        },
        () => this.getDisponibilitesUntil(lastDayGetDisponibilites)
      );
    } else {
      this.setState({ date: newMonth, isInitialSearchView: false });
    }
  }


  onDayClick = (date) => {
    if (this.props.dateActivite) {
      const dateAComparer = new Date(date);
      const dateActuelle = new Date();

      const millisecondesAComparer = Date.UTC(dateAComparer.getFullYear(), dateAComparer.getMonth(), dateAComparer.getDate());
      const millisecondesActuelles = Date.UTC(dateActuelle.getFullYear(), dateActuelle.getMonth(), dateActuelle.getDate());
      if (millisecondesActuelles <= millisecondesAComparer) {
        if (this.props.onDayClick) {
          this.props.onDayClick(date);
        }
        this.props.dateActivite && this.forceUpdate();
      }
    } else {
      this.props.onDayClick(date);
    }
  }


  onDayHoverStart(date) {
    if (date !== this.state.hoveredDay) {
      this.setState({ hoveredDay: date });

      (this.props.onHoveredDayChange || ((date) => { }))(date);
    }
  }

  onDayHoverEnd(date) {
    if (date === this.state.hoveredDay) {
      this.setState({ hoveredDay: null });

      (this.props.onHoveredDayChange || (() => { }))(null);
    }
  }

  renderDayHeader(day, index) {
    return (
      <div key={index} className="calendar-day-header calendar-header-cell">
        <span>{day.toUpperCase()}</span>
      </div>
    );
  }

  renderWeeks() {
    const sundayBeforeCurrentMonth = new Date(this.getReferenceDate());
    sundayBeforeCurrentMonth.setDate(1); // Le premier jour du mois
    sundayBeforeCurrentMonth.setDate(sundayBeforeCurrentMonth.getDate() - (sundayBeforeCurrentMonth.getDay() === 0 ? 7 : sundayBeforeCurrentMonth.getDay())); // La date devient le dimanche précédant le premier jour du mois.

    return [0, 1, 2, 3, 4, 5].map((week) => this.renderWeek(sundayBeforeCurrentMonth, week));
  }

  renderWeek(sundayBeforeCurrentMonth, week) {
    const days = [0, 1, 2, 3, 4, 5, 6];

    return (
      <div key={week} className="calendar-cell-row">
        {days.map((day) => this.renderDay(sundayBeforeCurrentMonth, week, day))}
      </div>
    );
  }

  renderDay(sundayBeforeCurrentMonth, week, day) {
    const date = new Date(sundayBeforeCurrentMonth.getTime());
    date.setDate(date.getDate() + week * 7 + day);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    const dateFin = new Date(date);
    dateFin.setHours(23);
    dateFin.setMinutes(59);
    dateFin.setSeconds(59);

    const dateDebutSelected = this.props.selectedArrivee || this.props.rangeBegin;
    const dateFinSelected = this.props.selectedDepart || this.props.rangeEnd;

    let { minDate, maxDate } = this.props;
    if (maxDate === null && (this.props.maxJoursEnAvanceReservation || 0) !== 0) {
      maxDate = new Date(today.getTime());
      maxDate.setDate(maxDate.getDate() + this.props.maxJoursEnAvanceReservation);
    }

    const isDayWithinSelectableRange = (minDate === null || date >= minDate) && (maxDate === null || date <= maxDate);
    const isGrayedDay = !isDayWithinSelectableRange || date.getMonth() !== this.getReferenceDate().getMonth();

    const spanClassName = isGrayedDay ? " grayed-day " : "";
    const colorClassName = this.props.type_hebergement == undefined ? this.getDateColorClassName(date, minDate, maxDate) : 'bg-class'
    const hoveredClassName = !isDayWithinSelectableRange || this.props.onDayClick === null ? "" : " clickable ";
    const dateSelectedClassName = this.props.dateActivite && (this.props.dateActivite.toString() === date.toString() || this.props.dateActivite.toString() === date.toString()) ? "cell-date-selected-debut-fin" : dateDebutSelected && dateFinSelected && (dateDebutSelected.toString() === date.toString() || dateFinSelected.toString() === date.toString()) ? "cell-date-selected-debut-fin" : "cell-date-selected";

    return (
      <div key={day} className={`calendar-cell ${hoveredClassName}`} onClick={(e) => this.onDayClick(date)}>
        {((dateDebutSelected && dateFinSelected && periodsIntersect(dateDebutSelected, dateFinSelected, date, dateFin, true)) || (this.props.dateActivite && periodsIntersect(this.props.dateActivite, this.props.dateActivite, date, dateFin, true))) && <div className={dateSelectedClassName} />}
        <div className={colorClassName + hoveredClassName}>
          <span className={spanClassName + hoveredClassName}>{date.getDate()}</span>
        </div>
      </div>
    );
  }

  render() {
    const referenceDate = this.getReferenceDate();
    const month = referenceDate.getMonth();
    const year = referenceDate.getFullYear();

    const monthName = this.state.monthsArr[month].toUpperCase();
    return (
      <div className="calendar">
        <div className="calendar-month-section-wrapper">
          <div className="calendar-previous-month-btn-wrapper" onClick={(e) => this.updateMonth(-1)}>
            <img className="calendar-previous-month-btn" src="/../../images/icons/drop-down.png" alt="precedent" />
          </div>
          <div className="calendar-month-name-wrapper">
            {this.props.allowChangeYear ? (
              <select onChange={(e) => this.setState({ date: new Date(this.getReferenceDate().setFullYear(e.target.value)) }, this.props.onYearChanged || (() => { }))} value={year}>
                {this.state.years.map((year) => (
                  <option key={year} value={year}>
                    {monthName} {year}
                  </option>
                ))}
              </select>
            ) : (
              <span>
                {monthName} {year}
              </span>
            )}
          </div>
          <div className="calendar-next-month-btn-wrapper" onClick={(e) => this.updateMonth(1)}>
            <img className="calendar-next-month-btn" src="/../../images/icons/drop-down.png" alt="suivant" />
          </div>
        </div>
        <div className="calendar-days-of-week-wrapper calendar-cell-row">{this.state.daysArr.map(this.renderDayHeader)}</div>
        <div className="calendar-dates-wrapper">{this.renderWeeks()}</div>
      </div>
    );
  }
}

Calendar.propTypes = {
  heureArrivee: PropTypes.string, // String au format hh:mm:ss. Correspond à l'heure de check in de l'unité.
  heureDepart: PropTypes.string, // String au format hh:mm:ss. Correspond à l'heure de check out de l'unité.
  indisponibilites: PropTypes.arrayOf(PropTypes.object), // Structure: Un array de {debut: [Date], fin: [Date]}. Chaque item correspond à une période de Début à Fin où l'unité est indisponible.
  maxJoursEnAvanceReservation: PropTypes.number,
  initialMonth: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  selectedRangeBegin: PropTypes.instanceOf(Date),
  selectedRangeEnd: PropTypes.instanceOf(Date),
  isSelectingRangeBegin: PropTypes.bool,
  isSelectingRangeEnd: PropTypes.bool,
  onDayClick: PropTypes.func,
  onHoveredDayChange: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  allowChangeYear: PropTypes.bool,
  onYearChanged: PropTypes.func
};

Calendar.defaultProps = {
  heureArrivee: null,
  heureDepart: null,
  dateActivite: null,
  indisponibilites: null,
  maxJoursEnAvanceReservation: null,
  initialMonth: null,
  selectedRangeBegin: null,
  selectedRangeEnd: null,
  isSelectingRangeBegin: false,
  isSelectingRangeEnd: false,
  onDayClick: null,
  onHoveredDayChange: null,
  minDate: null,
  maxDate: null,
  allowChangeYear: false
};

export default connect(mapStateToProps)(Calendar);