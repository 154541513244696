import React, {Fragment, useEffect, useState} from 'react';
import "./camps-page.css"
import {getChandailByIdProduct, getProductById} from "../../server/services/catalogue-service";
import {addToShoppingCart, CartItemType, getShop, removeFromShoppingCart, setErrorAlert, updateShop} from "../actions/shop";
import withNavigation from "../higher-order/navigation";
import {connect} from "react-redux";
import shop from "../../reducers/shop";

function Chandail(props) {
    const [produitPrixChandails, setProduitPrixChandails] = useState([])
    const [produitCasquette, setProduitCasquette] = useState()
    const [produitEvenement, setProduitEvenement] = useState()
    const [produitChandail, setProduitChandail] = useState()

    useEffect(() => {
            if (props.shop.cart.length > 0) {
                if (!props.shop.cart.some((item) => {
                    if (item.type === CartItemType.CampJour && item.CampInscription) {
                        getChandailByIdProduct("C4D673AB-BEDF-EE11-B49C-10F60A435E41")
                            .then(result => {
                                setProduitPrixChandails(result.chandailDetail);
                                setProduitCasquette(result.casquette);
                                setProduitEvenement(result.evenement);
                                setProduitChandail(result.chandail)
                            });
                        return true;
                    }
                    return false;
                })) {
                    props.history.push({pathname: `/${props.match.params.idZec}/${props.match.params.language || "fr"}/reservation-camps/formulaire`});

                }
            } else {
                props.history.push({pathname: `/${props.match.params.idZec}/${props.match.params.language || "fr"}/reservation-camps/formulaire`});
            }

        }
        ,
        []
    )
    ;
    const onChangeChandail = (e) => {
        const detail = produitPrixChandails.find(f => f.idProduitPrix === e.target.value);
        const newItem = {
            //id: 'forfait-' + forfait.idProduit, // id utilisé pour identifier l'item dans le cart
            id: e.target.value, // id utilisé pour identifier l'item dans le cart
            idProduit: produitChandail.idProduit,
            selectedProduct: {idProduit: produitChandail.idProduit, idProduitPrix: detail.idProduitPrix, quantite: 1},
            name: `${produitChandail.description} - ${detail.ageDebut < 18 ? "Enfant" : "Adulte"} ${detail.description}`,
            montant: Number(detail.prix),
            tps: produitChandail.tps ? Number(produitChandail.tps) : 0,
            tvq: produitChandail.tvq ? Number(produitChandail.tvq) : 0,
            total: Number(detail.prix) + Number(produitChandail.tps) + Number(produitChandail.tvq),
            type: CartItemType.CampJour
        }
        if (props.shop.cart.some(s => s.id === e.target.value))
            props.removeFromShoppingCart(newItem, false);
        else
            props.addToShoppingCart(newItem, false);
    };
    const onChange = (e) => {
        const produit = e.target.value === produitCasquette.idProduit ? produitCasquette : produitEvenement;
        const newItem = {
            //id: 'forfait-' + forfait.idProduit, // id utilisé pour identifier l'item dans le cart
            id: e.target.value, // id utilisé pour identifier l'item dans le cart
            idProduit: produit.idProduit,
            selectedProduct: {idProduit: produit.idProduit, quantite: 1},
            name: `${produit.description}`,
            montant: Number(produit.prixVente),
            tps: produit.tps ? Number(produit.tps) : 0,
            tvq: produit.tvq ? Number(produit.tvq) : 0,
            total: Number(produit.prixVente) + Number(produit.tps) + Number(produit.tvq),
            type: CartItemType.CampJour
        }
        if (props.shop.cart.some(s => s.id === e.target.value))
            props.removeFromShoppingCart(newItem, false);
        else
            props.addToShoppingCart(newItem, false);
    };

    return (<>
        <div id={"produits"}>
            <div className={"list"}>
                <div className={"enfant"}>
                    <label>ENFANT</label>
                    <div className={"grandeur"}>
                        {(produitPrixChandails || []).map(p => {
                            return p.ageDebut < 18 ? <Fragment key={p.idProduitPrix}>
                                <div key={p.description}>{p.description}</div>
                                <div key={p.idProduitPrix}><input type={"checkbox"} value={p.idProduitPrix} checked={props.shop.cart.some(s => s.id === p.idProduitPrix)} onChange={onChangeChandail}/></div>
                            </Fragment> : null;
                        })}
                    </div>
                </div>
                <div className={"adulte"}>
                    <label>ADULTE</label>
                    <div className={"grandeur"}>
                        {(produitPrixChandails || []).map(p => {
                            return p.ageDebut >= 18 ? <Fragment key={p.idProduitPrix}>
                                <div>{p.description}</div>
                                <div><input type={"checkbox"} value={p.idProduitPrix} checked={props.shop.cart.some(s => s.id === p.idProduitPrix)} onChange={onChangeChandail}/></div>
                            </Fragment> : null;
                        })}
                    </div>
                </div>
            </div>
            <div className={"prix"}>
                <div>
                    <label>Chandail</label>
                    <div>{(produitPrixChandails[0]?.prix || 0).toFixed(2)}$</div>
                </div>
            </div>
            <div style={{paddingTop: "2rem", display: "flex", gap: "1rem", justifyContent: "left", alignItems: "center"}}>
                <div><b>Désirez-vous une casquette ({(produitCasquette?.prixVente || 0).toFixed(2)}$)</b></div>
                <div><input type={"checkbox"} value={produitCasquette?.idProduit} checked={props.shop.cart.some(s => s.id === produitCasquette?.idProduit)} onChange={onChange}/></div>
            </div>
            <div style={{paddingTop: "2rem", display: "flex", gap: "1rem", justifyContent: "left", alignItems: "center"}}>
                <div><b>Désirez-vous que votre enfant participe à l'événement "{produitEvenement?.description}" ({(produitEvenement?.prixVente || 0).toFixed(2)}$)</b></div>
                <div><input type={"checkbox"} value={produitEvenement?.idProduit} checked={props.shop.cart.some(s => s.id === produitEvenement?.idProduit)} onChange={onChange}/></div>
            </div>
        </div>

        <div className="billet-unite-achat-btn unselectable billetterie-page-retour-btn ">
      <span className="clickable" onClick={() => {
          props.history.push({pathname: `/${props.match.params.idZec}/${props.match.params.language || "fr"}/reservation-camps/formulaire`});
      }}>
          PRÉCÉDENT
      </span>
            <span
                className={`clickable right`}
                onClick={() => {
                    props.gotoPaiement();
                }}>
        CONFIRMEZ
      </span>
        </div>
    </>)

}

const mapStateToProps = (state, ownProps) => ({
    shop: state.shop, entrepriseName: state.entreprise.nom
});

const dispatchProps = {
    getShop, addToShoppingCart, removeFromShoppingCart, updateShop, setErrorAlert
};

export default withNavigation(connect(mapStateToProps, dispatchProps)(Chandail));