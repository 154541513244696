import React, { Component } from "react";
import Popup from 'reactjs-popup';
import "./item-popup.css";
import ButtonAddToCart from "../../buttons/buttonAddToCart.js";
import OptionsPopup from "../options-popup/options-popup.js";
import PopupHeader from "../../popups/popup-header.js";
import PopupFooter from "../../popups/popup-footer.js"

class ItemPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen) this.handleOpen();
  }

  handleOpen = () => {
    this.setState({
      isOpen: true
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false
    });
  };

  render() {
    return (
      <Popup
        content="test"
        on='click'
        open={this.state.isOpen}
        onClose={this.handleClose}
        modal
        nested
      >
        {close => (
          <div className="fixed-panel" >
            <div className="dark-container" onClick={this.handleClose}>
            </div>
            <div className="item-popup-div">
              <div className="item-popup-background" style={{ borderRadius: '0px', maxWidth: '600px', minHeight: '256px', maxHeight: '100%' }}>
                {/* <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 0%' }}> */}
                <PopupHeader
                  title={this.props.item.name}
                  onClick={this.handleClose}
                />
                <div className="item-popup-details">
                  <img alt="item" src="data:image/svg+xml,<svg xmlns=&quot;http://www.w3.org/2000/svg&quot; width=&quot;512&quot; height=&quot;412&quot;>
                            <rect fill-opacity=&quot;0&quot;/></svg>"
                    style={{ width: "65%", background: "url(https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350) center center / 100% no-repeat", transition: "height 0.2s ease 0s", height: '80%' }}
                  // style="background-blend-mode:normal!important;background-clip:content-box!important;background-position:50% 50%!important;background-color:rgba(0,0,0,0)!important;background-image:var(--sf-img-4)!important;background-size:100% 100%!important;background-origin:content-box!important;background-repeat:no-repeat!important"
                  />
                  {this.props.item.options ?
                    <OptionsPopup
                      item={this.props.item}
                      handleClose={this.handleClose}
                    />
                    :
                    <ButtonAddToCart
                      classButton="item-popup-btn btn-add-to-cart"
                      message="Ajouter"
                      classSvg="item-popup-svg"
                      item={this.props.item}
                    />
                  }

                  <p className="item-popup-name">
                    {this.props.item.name}
                  </p>
                  <p className="item-popup-description">
                    {this.props.item.description &&
                      this.props.item.description.map((current, index) =>
                        <span key={index}>
                          {current} <br />
                        </span>
                      )}
                  </p>
                </div>
                <PopupFooter title="SORTIR" onClick={this.handleClose} />
              </div>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}

export default ItemPopup;