export const DIV_NAME = "info-client-field";
export const INPUT_NAME = "info-client-field-input";
export const LABEL_NAME = "info-client-field-label";
export const MANDATORY_MISSING = 'mandatory-input-missing'
export const SELECT_NAME = "info-client-field-select";

export const emptyCard = {
  codePromotionnel: "",
  cvd: "",
  expiryYear: "",
  expiryMonth: "",
  name: "",
  number: ""
};

export const emptyClient = {
  nom: "",
  prenom: "",
  telephone1: "",
  telephone2: "",
  portable: "",
  email: "",
  emailConfirmation: "",
  adresse: "",
  pays: { idPays: "", description: "" },
  ville: "",
  idProvince: "",
  codePostal: "",
};

export const clientTestCedres = {
  nom: "Ares",
  prenom: "Bernard",
  telephone1: "4506761280",
  portable: "5149106420"
}

export const clientCarteTest = {
  codePromotionnel: "",
  cvd: "123",
  expiryYear: "21",
  expiryMonth: "05",
  name: "infoNom infoPrenom",
  number: "4030000010001234"
}
