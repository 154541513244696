import React, { Component } from "react";
import withNavigation from "../higher-order/navigation";
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';
import "./member.css";
import PopupHeader from "./popup-header.js";
import PopupFooter from "./popup-footer.js";
import { connect } from "react-redux";
import { setNoDossier } from '../actions/client';
import { setAlert } from '../actions/alert';
import { isClientMembre } from "../../server/services/client-service";

class MemberPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: "2",
      error: "",
      idDossier: "",
      firstName: "",
      lastName: "",
      number: "",
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(stateName, event) {
    this.setState({
      error:"",
      [stateName]: event
    })
  }

  handleConfirm = (e) => {
    e.preventDefault();
    isClientMembre(this.state, this.props.startDate)
      .then(noDossier => {
        if (!noDossier) {
          this.setState({ error: "Informations non valides. Veuillez reéssayer SVP !" });
          return;
        }
        this.props.setNoDossier(noDossier);
        this.closeAndConfirm();
      })
  }

  closeAndConfirm = () => {
    this.props.handleClose();
    this.props.confirmFunction();
  }

  render() {

    let canConfirm = false;
    if (this.state.lastName && this.state.firstName && this.state.number || this.state.idDossier) canConfirm = true
    return (
      <Popup
        on='click'
        open={this.props.isOpen}
        onClose={this.props.handleClose}
        modal
        nested
      >
        {close => (
          <div className="fixed-panel" >
            <div className="dark-container" onClick={this.props.handleClose}>
            </div>
            <div className="item-popup-div">
              <div className="item-popup-background" style={{ borderRadius: '0px', maxWidth: '600px', minHeight: '256px', maxHeight: '100%' }}>
                {/* <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 0%' }}> */}
                <PopupHeader
                  title="ESPACE MEMBRE"
                  onClick={this.props.handleClose}
                />
                <div className="testbox">
                  <form>
                    <hr />
                    {/* <div className="accounttype">
                        <input onChange={(e) => this.switchOption(e.target.name, e.target.value)} type="radio" value="1" id="radioOne" name="option" checked={this.state.option === "1"} />
                        <label htmlFor="radioOne" className="radio">ID dossier</label>
                        <input onChange={(e) => this.switchOption(e.target.name, e.target.value)} type="radio" value="2" id="radioTwo" name="option" checked={this.state.option === "2"} />
                        <label htmlFor="radioTwo" className="radio">Informations</label>
                      </div> */}
                    <p>
                      Entrer vos renseignements si vous êtes membre <br />
                    </p>
                    <hr />
                    <div className="member-error">
                      {this.state.error && this.state.error}
                    </div>
                    <div className="member-infos">
                      <input onChange={(e) => this.handleChange(e.target.name, e.target.value)} value={this.state.idDossier} type="text" name="idDossier" placeholder="ID du dossier" />
                      <br /><br />
                      <p><b><u>OU</u></b></p>
                      {/* <label id="icon" for="name"><i className="icon-envelope "></i></label> */}
                      <input onChange={(e) => this.handleChange(e.target.name, e.target.value)} value={this.state.firstName} type="text" name="firstName" placeholder="Prénom" />
                      {/* <label id="icon" for="name"><i className="icon-user"></i></label> */}
                      <input onChange={(e) => this.handleChange(e.target.name, e.target.value)} value={this.state.lastName} type="text" name="lastName" placeholder="Nom" />
                      {/* <label id="icon" for="name"><i className="icon-shield"></i></label> */}
                      <input onChange={(e) => this.handleChange(e.target.name, e.target.value)} value={this.state.number} type="text" name="number" placeholder="Numéro de téléphone" />
                    </div>
                    
                    <div className="member-confirm-div">
                      <button
                        onClick={(e) => this.handleConfirm(e)}
                        disabled={!canConfirm}
                        type="submit"
                        className="member-confirm-btn">
                        CONFIRMER
                        </button>
                    </div>
                  </form>
                </div>
                <PopupFooter title="JE SUIS NON MEMBRE" onClick={this.props.handleClose} />
              </div>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}

MemberPopup.propTypes = {
  setAlert: PropTypes.func.isRequired,
};

export default withNavigation(connect(null, { setAlert, setNoDossier })(MemberPopup));