import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import "../../css/card.css";
import { addToShoppingCart } from './actions/shop';

function mapStateToProps(state, ownProps) {
  return {
  };
}

function Card(props) {
  const addToShoppingCart = (item) => {
    const newItem = {};
    newItem["name"] = item.name;
    newItem["montant"] = item.montant;
    newItem["type"] = "forfait";
    props.addToShoppingCart(newItem);
  }

  const renderContent = (content) => {
    if (content.type === "bulletList")
      return renderBulletListContent(content);

    if (content.type === "buyableList")
      return renderBuyableListContent(content);

    if (content.type === "section")
      return renderSectionContent(content);

    return <div />; // Default
  }

  const renderSectionContent = (content) => {
    return (
      <div key={content.key} className="card-section">
        <span>{content.name}</span>
      </div>
    )
  }

  const renderBuyableListContent = (content) => {
    return (
      <div key={content.key} className="buyable-list-container">
        {content.items.map(renderBuyableListItem)}
      </div>
    )
  }

  const renderBuyableListItem = (item, index) => {
    const textStyle = item.selected ? { color: 'rgb(255, 103, 76)' } : {};
    const img = item.selected ? require('../../images/icons/minus.png') : require('../../images/icons/plus.png');

    return (
      <div key={item.key} className="card-buyable-list-item">
        {index === 0 || <div className="card-buyable-list-item-separator" />}
        <div className="card-buyable-list-item-name-wrapper">
          <span className="card-buyable-list-item-name" style={textStyle}>{item.name}</span>
        </div>
        <div className="card-buyable-list-item-montant-wrapper">
          <span className="card-buyable-list-item-montant" style={textStyle}>{item.montant.toFixed(2).toString()}$</span> {/* NOTE: Dans le design de l'infographiste, les montants étaient toujours à 000.00$ lorsque non sélectionnés */}
          {/*<span className="card-buyable-list-item-montant" style={textStyle}>{item.selected ? item.montant.toFixed(2).toString() : "000.00"}$</span>*/}
        </div>
        <div className="card-buyable-list-item-image-wrapper"
          onClick={item.onClick || (() => addToShoppingCart(item))}
        >
          <img src={img} alt="add/remove" />
        </div>
      </div>
    )
  }

  const renderBulletListContent = (content) => {
    return (
      <ul key={content.key}>
        {content.items.map(renderBulletListItem)}
      </ul>
    )
  }

  const renderBulletListItem = (item) => {
    return (
      <li onClick={item.onClick || (() => { })} key={item.key} className="card-bullet-list-item">
        <span>{item.name}</span>
      </li>
    )
  }

  const cardClass = props.onTitleClick ? "card clickable" : "card";
  const titleSpanClass = props.onTitleClick ? "clickable" : "";

  return (
    <div key={props.key} className={cardClass}>
      <div className="card-thumbnail">
        <img
          src={props.thumbnailType === "base64" ? "data:image/png;base64," + props.thumbnail : require('../../images/' + props.thumbnail)}
          onClick={props.onTitleClick || (() => { })} alt="thumbnail"
        />
      </div>
      <div className="card-title">
        <span
          className={titleSpanClass} onClick={props.onTitleClick || (() => { })}>{props.title}
        </span>
      </div>
      <div className="card-contents">
        {props.contents.map(renderContent)}
      </div>
    </div>
  );
}

Card.propTypes = {
  addToShoppingCart: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { addToShoppingCart })(Card);