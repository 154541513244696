export function isValidEmail(email, maxLength = 0) {
  // Regex provenant de https://www.regular-expressions.info/email.html. Explication de ce Regex provenant de la même source: We get a more practical implementation of RFC 5322 if we omit IP addresses, domain-specific addresses, the syntax using double quotes and square brackets. It will still match 99.99% of all email addresses in actual use today.

  if (!(typeof email === 'string' || email instanceof String))
    return false;

  if (maxLength !== 0 && email.length > maxLength) // Note: Dans les BDs de Manisoft, la longueur maximale de client.email, profile.email, entreprise.courriel1, entreprise.courriel2, et fournisseur.email est 60, employe.courriel est 50, et usager.email est 100.
    return false;

  return email.match(/[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/) !== null;
}

export function isValidPhoneNumber(phone) {
  if (!(typeof phone === 'string' || phone instanceof String))
    return false;

  phone = phone.replace(/\D/g,''); // Enlève les non digit (\D)

  return phone.length >= 10 && phone.length <= 13;
}

export function isValidPostalCode(postalCode, countryCode) { // Valide seulement les code postaux canadiens et américains.  
  if (!postalCode || !(typeof postalCode === 'string' || postalCode instanceof String) || !countryCode)
    return false;

  switch (countryCode.toUpperCase()) { // countryCode correspond au code ISO 3166-1 alpha-2 du pays. Il semble que les code de pays de la BD correspondent aux codes ISO 3166-1 alpha-2 de toute façon. Liste des code: https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
    case 'CA': return postalCode.toUpperCase().match(/^[A-Z][0-9][A-Z]\s?[0-9][A-Z][0-9]$/) !== null; // Canada. On permet d'omettre l'espace.
    case 'US': return postalCode.match(/^([0-9]{5})(-?[0-9]{4})?$/) !== null; // United States. À noter qu'on permet d'omettre le -.
    default: return true;
  }
}

export function isEmptyObject(obj) {
  for (var prop in obj)
    if (obj.hasOwnProperty(prop))
      return false;

  return true;
}

// Retourne true si un item existe dans au moins deux arrays envoyés en argument.
// Un item est considéré comme étant existant dans deux arrays si l'opérateur === retourne true pour deux items dans deux sets différents.
// Par exemple, intersect([1,3,5], [2,4,6], [0, 1]) retourne true car 1 existe dans au moins deux sets.
export function intersect(...sets) {
  for (var i = 0; i < sets.length - 1; i++)
    for (var j = i + 1; j < sets.length; j++)
      if (_intersect(sets[i], sets[j]))
        return true;

  return false;
}

function _intersect(setA, setB) {
  for (var i = 0; i < setA.length; i++)
    for (var j = 0; j < setB.length; j++)
      if (setA[i] === setB[j])
        return true;

  return false;
}
