import {
  SET_FETCHED_PAYS_CONFIG_WEB,
  SET_PAYS_CONFIG_WEB,
} from '../components/actions/types';

const initialState = {
  fetched: false, // Permet de charger les pays une seule fois. Le premier getPays() set fetched à true. Les appels subsequant ne chargent pas les pays quand la méthode voit que fetched === true.
  pays: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_FETCHED_PAYS_CONFIG_WEB:
      return {...state, fetched: true};
    
    case SET_PAYS_CONFIG_WEB:
      return {...state, pays: action.pays};

    default:
      return state;
  }
}