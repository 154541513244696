import React, { useContext } from 'react';

//component
import ButtonEvenement from './boutton-evenement';

//context
import { EvenementContext } from '../../contexte/evenement-page';
import SeatMap from './Plans-de-salle';


const StepEvenement = () => {

    const {
        currentStep,
        handlePrevious,
        goToStep, steps, visitedSteps
    } = useContext(EvenementContext)

    return (
        <div className="stepper-evenement">
            <div className="stepper-evenement-header">
                {steps.map((step, index) => (
                    <div
                        key={index}
                        className={`step ${index === currentStep ? 'active' : ''} ${visitedSteps.includes(index) ? 'visited' : ''}`}
                        onClick={() => goToStep(index)}
                    >
                        <div className="circle">{index + 1}</div>
                        <div className="label">{step.label}</div>
                    </div>
                ))}
            </div>
            <div className="stepper-evenement-content">
                {steps[currentStep].content}
            </div>
            {currentStep > 0 &&
                <ButtonEvenement
                    title={"Précédent"}
                    onClick={handlePrevious}
                    disabled={currentStep === 0}
                    custumClassContainer={"stepper-evenement-buttons"}
                />
            }
        </div>
    );
}
export default StepEvenement