import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import "./achat-forfait-item.css";
import { addToShoppingCart, removeFromShoppingCart, CartItemType } from '../actions/shop';
import CartSvg from "../svg/cart";

function AchatForfaitItem(props) {
  const addToShoppingCart = (item) => {
    const produit = props.forfaits.find(f => f.idProduit === item.idProduit && f.idProduitPrix === item.idProduitPrix); // On récupère le produit original. item n'est qu'une copie ne contenant que certains champs.

    props.addToShoppingCart({
      id: item.key, // Permet de retirer du panier par id plutôt que par référence
      idProduit: item.idProduit, // Très important d'inclure le idProduit, sinon quoi l'achat de forfaits crash côté serveur.
      idProduitPrix: item.idProduitPrix,
      name: produit.description.trim(), // Dans au moins une des BD, il y a des descriptions avec des espaces au début.
      montant: produit.prixVente,
      tps: produit.tps ? Number(produit.tps) : 0,
      tvq: produit.tvq ? Number(produit.tvq) : 0,
      total: Number(produit.prixVente) + Number(produit.tps) + Number(produit.tvq),
      type: CartItemType.Forfait,
    });

    if (item.idProduitRequis !== '00000000-0000-0000-0000-000000000000') {
      const produitRequis = props.forfaits.find(f => f.idProduit === item.idProduitRequis);

      if (produitRequis !== undefined) {
        const isProduitRequisInCart = props.shop.cart.some(produit => produit.type === CartItemType.Forfait && produit.idProduit === item.idProduitRequis);

        if (!isProduitRequisInCart) {
          props.addToShoppingCart({
            id: produitRequis.idProduit + (produitRequis.idProduitPrix || ""), // Permet de retirer du panier par id plutôt que par référence
            idProduit: produitRequis.idProduit, // Très important d'inclure le idProduit, sinon quoi l'achat de forfaits crash côté serveur.
            idProduitPrix: produitRequis.idProduitPrix, // Très important d'inclure le idProduit, sinon quoi l'achat de forfaits crash côté serveur.
            name: produitRequis.description.trim(), // Dans au moins une des BD, il y a des descriptions avec des espaces au début.
            montant: produitRequis.prixVente,
            tps: produitRequis.tps ? Number(produitRequis.tps) : 0,
            tvq: produitRequis.tvq ? Number(produitRequis.tvq) : 0,
            total: Number(produitRequis.prixVente) + Number(produitRequis.tps) + Number(produitRequis.tvq),
            type: CartItemType.Forfait,
          });
        }
      }
    }
  }

  const removeFromShoppingCart = item => {
    const produit = props.forfaits.find(f => f.idProduit === item.idProduit && f.idProduitPrix === item.idProduitPrix); // On récupère le produit original. item n'est qu'une copie ne contenant que certains champs.

    console.log({item, produit})

    props.removeFromShoppingCart({
      id: item.key, // Permet de retirer du panier par id plutôt que par référence
      idProduit: item.idProduit, // Très important d'inclure le idProduit, sinon quoi l'achat de forfaits crash côté serveur.
      idProduitPrix: item.idProduitPrix,
      name: produit.description.trim(), // Dans au moins une des BD, il y a des descriptions avec des espaces au début.
      montant: produit.prixVente,
      tps: produit.tps ? Number(produit.tps) : 0,
      tvq: produit.tvq ? Number(produit.tvq) : 0,
      total: Number(produit.prixVente) + Number(produit.tps) + Number(produit.tvq),
      type: CartItemType.Forfait,
    });
  }

  const renderContent = (item) => {
    const isSelected = props.shop.cart.some(p => p.type === CartItemType.Forfait && p.idProduit === item.idProduit && p.idProduitPrix === item.idProduitPrix)

    return (
      <li key={item.key} className="achat-forfait-item achat-forfait-item-hover">
        <div className="achat-forfait-card-buyable-list-item-name-wrapper">
          <span className="achat-forfait-card-buyable-list-item-name">
            {item.name}
          </span>
        </div>
        <br />
        <br />
        <div className="achat-forfait-card-buyable-list-item-description-wrapper">
          {item.name}
        </div>
        <div className="achat-forfait-card-right-side">
          <span className="achat-forfait-card-buyable-list-item-montant">
            {item.montant.toFixed(2).toString()}$
          </span>
          <button className="achat-forfait-card-buyable-list-item-montant button-achat-forfait-card"
            onClick={() => addToShoppingCart(item)}>
            <img src={require('../../../images/icons/plus.png')} alt="add/remove" />
            <CartSvg width="26" height="26" />
          </button>
        </div>
      </li>
    )
  }

  return (
    <div className="achat-forfait-item-main-div">
      {props.items.map(renderContent)}
    </div>
  );
}

const mapStateToProps = (state) => ({
  shop: state.shop,
});


AchatForfaitItem.propTypes = {
  addToShoppingCart: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { addToShoppingCart, removeFromShoppingCart })(AchatForfaitItem);