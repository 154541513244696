import React from "react";
import {connect} from "react-redux";
import PersonSvg from "./svg/person.js";
import PetSvg from "./svg/pet.js";
import PoolSvg from "./svg/pool.js";
import SmokingSvg from "./svg/smoking.js";
import "../../css/unite-icons.css";

function mapStateToProps(state, ownProps) {
    return {};
}

function UniteIcons(props) {
    return (
        <div className="unite-icons-wrapper">
            {props.afficherNbPersonne || true &&
                <div className="unite-icon-wrapper">
                    <PersonSvg className="unite-icon" style={{
                        width: '36px',
                        float: 'left'
                    }}/>

                    <div className="unite-person-count-wrapper">
                        <span>{props.nombrePersonnes}</span>
                    </div>
                </div>}
            <div className="unite-icon-wrapper">
                <PetSvg className="unite-icon"/>
                <img className="unite-icon-circle" src={require(props.animaux ? '../../images/icons/circle-green.png' : '../../images/icons/circle-red.png')} alt="pets"/>
            </div>
            {props.afficherFumeur &&
                <div className="unite-icon-wrapper">
                    <SmokingSvg className="unite-icon"/>
                    <img className="unite-icon-circle" src={require(props.fumeur ? '../../images/icons/circle-green.png' : '../../images/icons/circle-red.png')} alt="smoking"/>
                </div>}
        </div>
    );
}

export default connect(mapStateToProps)(UniteIcons);
