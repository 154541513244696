import React from "react";
import {connect} from "react-redux";
import withNavigation from "./higher-order/navigation";
import {setAuthToken} from './actions/auth-token';
import "../../css/header.css";
import {PATH_ACCUEIL, PATH_PROFILE, PATH_RESERVATION} from "../constants/navigation-constants";
import {SELECTED_COLOR, UNSELECTED_COLOR} from "../constants/colors.json";
import Translator from "./translator";
import SessionKeys from "../session";
import {DeleteProfilClient} from './actions/infoClient'
import {STORAGE} from "../constants/storage";
import {removeFromLocalStorage} from "../utils/localStorageUtils";


function mapStateToProps(state, ownProps) {
    return {
        authToken: state.authToken,
        configuration: state.configuration,
        entreprise: state.entreprise,
    };
}

function Header(props) {
    if (!props.configuration.affichageIframe || props.configuration.affichageIframe === "True") return <div/>;

    const TEXTS = Translator(props.match.params.language, "TXT_MAIN_HEADER");

    const deleteBrowserData = () => {
        props.setAuthToken(null);
        sessionStorage.removeItem(SessionKeys.AUTH_TOKEN);
        props.DeleteProfilClient();
        removeFromLocalStorage(STORAGE.info_profile_client);
    }
    return (
        <header>
            <div id="header-content">
                <div id="header-left" style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                            <img id="logo" src={require("../../images/logo-fond-noir-fr.png")} alt="header-logo" />
                            {(props.entreprise.noEtablissement && props.entreprise.noEtablissement != 0) ?
                                <div style={{ color: "white", paddingLeft: "80px" }}>
                                    <span>Nos Etablissement: </span><span>{Object.entries(props.entreprise.noEtablissement).map((key, value) => `${value}`)}</span>
                                </div>
                                : null
                            }
                        </div>
                        <img id="logo" src={"data:image/png;base64," + (props.configuration.imageLogoEntrepriseRpa || props.entreprise.logo || "")} alt="header-logo"/>
                    </div>
                </div>
                <div id="header-right" className={props.authToken !== null ? 'add-margin-after-login' : ""}>
                    <div id="header-right-sub-container">
                        <div>
                            <span
                                onClick={() => props.goTo(PATH_ACCUEIL)}>
                            {TEXTS.title0}
                            </span>
                        </div>
                        <div>
                            <span
                                onClick={() => props.gotoProfile()}>
                            {TEXTS.title1}
                            </span>
                        </div>
                        <div>
                            <span>{TEXTS.title2}</span>
                        </div>
                        {props.authToken !== null &&
                            <div>
                                <span onClick={() => deleteBrowserData()}>
                                  DECONNEXION
                                </span>
                            </div>
                        }

                        <div>
                            {props.language === "fr" && <span onClick={props.setLanguageEn}>EN</span>}
                            {props.language === "en" && <span onClick={props.setLanguageFr}>FR</span>}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default withNavigation(connect(mapStateToProps, {setAuthToken, DeleteProfilClient})(Header));
