import React, { useState } from "react";
import Popup from "reactjs-popup";
import "./demander-tranche-age.css";
import {setAlert} from "../actions/alert"
import { useDispatch } from "react-redux";

const ConfirmationOptionForfait = (props) => {
    const dispatch = useDispatch();
    const {
        langue,
        isOpen,
        handleClose,
        optionsForfaitList,
        onConfirmation,
    } = props

    const [optionSelected, setOptionSelectd] = useState({
        idPlanEntete: null,
        description: null,
      });
      const [hoveredOption, setHoveredOption] = useState(null);

      const validationAjout = () => {
        if(optionSelected.idPlanEntete !== null) {
            handleClose();
            onConfirmation(optionSelected);
        } else {
            dispatch(setAlert("Aucun forfait n'a ete selectionne", 'danger'))
        }
      }

    return (
        <div>
            <Popup
                nested
                on="click"
                modal={true}
                open={isOpen}
                onClose={() => { handleClose(); setOptionSelectd(null) }}
                closeOnDocumentClick={false}
                className="popup-tranche-age option-fofait"
            >

                {/* header */}
                <div className="tranche-age-header">
                    <h3>Veuillez choisir une option de forfait</h3>
                </div>

                {/* Body */}
                <div className="tranche-age option-forfait-body">
                    {optionsForfaitList.map((option) => (
                        <div
                            key={option.idPlanEntete}
                            onMouseOver={() => setHoveredOption(option.idPlanEntete)}
                            onMouseLeave={() => setHoveredOption(null)}
                            onClick={() =>  
                                setOptionSelectd({
                                    ...optionSelected,
                                    idPlanEntete: option.idPlanEntete,
                                    description: langue
                                      ? langue === "fr"
                                        ? option.descriptionWebFrancaise
                                        : option.descriptionWebAnglaise
                                      : option.descriptionWebFrancaise,
                                  })}
                            onMouseEnter={() => setHoveredOption(option.idPlanEntete)}
                            className={` ${optionSelected.idPlanEntete === option.idPlanEntete ? 'selected' : hoveredOption === option.idPlanEntete ? 'hovered' : ''}`}
                        >
                            <div className="option-forfait">
                                <div className="description">{langue ? langue == "fr" ? option?.descriptionWebFrancaise : option?.descriptionWebAnglaise : option?.descriptionWebFrancaise}</div>
                                <div className="option-content-body-left">
                                    <div className="price">{option.montant} $</div>
                                    {/* <input
                                        type="radio"
                                        name="forfait"
                                        checked={selectedOption === option.idplan}
                                        onChange={() => setSelectedOption(option.idplan)}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* button */}
                <div className="tranche-age-button">
                    <button
                        onClick={(event) => validationAjout()}
                    >
                        Ok
                    </button>
                    <button
                        onClick={() => handleClose()}>
                        Annuler
                    </button>
                </div>
            </Popup>
        </div>
    );
};

export default ConfirmationOptionForfait;
