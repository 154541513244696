import React, { useContext } from 'react';

//component
import Evenement from './liste-evenement';

//context
import { EvenementContext } from '../../contexte/evenement-page';

const StepEvenement1 = () => {

    const {
        listEvenemt,
        handleNext
    } = useContext(EvenementContext)

    return (
        <div className="achat-forfait-card-container evenement-card-container">
            {/* liste evenement */}
            <div className="card-sub-container">
                {
                    listEvenemt.length > 0 ?
                        listEvenemt.map((evenement) => (
                            <Evenement
                                idEvenement={evenement.idEvenement}
                                handleNext={() => handleNext(evenement.idEvenement)}
                                nomImage={evenement.nomImage}
                                description={evenement.descriptionWeb}
                                prixEnfant={evenement.prixEnfant}
                                prixAdulte={evenement.prixAdulte}
                                titre={evenement.description}
                            />
                        ))
                        :
                        <div className='content-message-no-evenement'> 
                            <span> AUCUN ÉVENEMENT DSIPONIBLE POUR LE MOMENT.</span>
                        </div>
                }
            </div>
        </div>
    )
}
export default StepEvenement1