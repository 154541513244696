import React from "react";

function BarUp(props) {
    let { dispatch, fill, ...otherProps } = props

    fill = fill || "rgb(0, 0, 0)";

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={fill} width="48px" height="48px" {...otherProps}>
            <path d="M0 0h24v24H0z" fill="none" />
            <path fillRule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z" />
        </svg>
    );
}

export default BarUp;