import {get} from '../../server/api.js';
import {SET_FETCHED_PAYS_CONFIG_WEB, SET_PAYS_CONFIG_WEB} from "./types";

function setFetchedPays() {
  return {
    type: SET_FETCHED_PAYS_CONFIG_WEB
  };
}

function fetchPays({idZec}) { // Utiliser un objet comme argument afin d'être facilement extensible
  return dispatch => get(`/${idZec}/PaysConfigWeb?fields=description,code,id_pays`).then(pays => dispatch(setPays({pays}))); // On veut particulièrement exclure l'image du drapeau, qui contient beaucoup de bytes dont on n'a pas besoin.
}

function setPays({pays}) { // Utiliser un objet comme argument afin d'être facilement extensible
  return {
    type: SET_PAYS_CONFIG_WEB,
    pays: pays.sort((a, b) => a.code.toUpperCase() === "CA" ? -1
                            : b.code.toUpperCase() === "CA" ? 1
                            : a.code.toUpperCase() === "US" ? -1
                            : b.code.toUpperCase() === "US" ? 1
                            : a.description.toUpperCase() < b.description.toUpperCase() ? -1
                            : a.description.toUpperCase() > b.description.toUpperCase() ? 1
                            : 0), // Performance de ce filter().sort() avec ces toLowerCase() testé avec le profiler de Chrome, et est essentiellement instantané (prend < 1ms)
  }
}

export function getPaysConfigWeb({idZec}) { // Utiliser un objet comme argument afin d'être facilement extensible
  return (dispatch, getState) => {
    if (getState().paysConfigWeb.fetched)
      return Promise.resolve({pays: getState().paysConfigWeb.pays});

    dispatch(setFetchedPays());

    return dispatch(
      fetchPays({idZec})
    ).then(() =>
      ({pays: getState().paysConfigWeb.pays})
    );
  };
}