export const TypeAuxiliaire = {
  Aucun: "",
  Unite: "UN",
  TypeUnite: "TU",
  Produit: "PR"
};

export const TypeHebergement = {
  A: "Reservation Activité", // Voir page-reservation
  AC: "Reservation Activité",
  CP: "Reservation Camping",
  PC: "Reservation Hebergement",
  CH: "Reservation Hebergement"
};

