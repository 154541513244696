import React from 'react';
import TitleEvenement from './titre-evenement';
import ButtonEvenement from './boutton-evenement';

const Evenement = ({ description, prixEnfant, prixAdulte, titre, nomImage, handleNext }) => {
    return (
        <div className='content-evenement'>
            <div className='image-evenement'>
                <img className="image-evenement" src="/static/media/no-image.eb3751d07456d873a88b.png" alt={nomImage != null && nomImage != undefined ? nomImage : 'image evenement'} />
            </div>
            <div className='body-evenement'>
                <TitleEvenement title={titre} />
                {/* Description */}
                <div><p>{description} </p> </div>
                {/* Prix */}
                <div className='footer-evenement'>
                    <div className="details-prix">
                        <div>
                            <span>Enfant</span>
                            <span>{prixEnfant} $</span>
                        </div>
                        <div>
                            <span>Adulte</span>
                            <span> {prixAdulte} $</span>
                        </div>
                    </div>
                    {/* Boutton */}
                    <ButtonEvenement
                        onClick={handleNext}
                        title={"SELECTIONNER CET EVENEMENT"}
                        custumClassContainer={"content-button-footer-evenement"} 
                        custumClassOnButton={"achat-forfait-card-buyable-list-item-montant btn-achat-evenement"}/>
                </div>
            </div>
        </div>
    )
}
export default Evenement