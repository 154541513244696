import React, { Component } from "react";
import "./restaurant-card.css";
import ButtonAddToCart from "../buttons/buttonAddToCart.js";
import ItemPopup from "./item-popup/item-popup.js";
import OptionsPopup from "./options-popup/options-popup.js";

class RestaurantCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
    this.openPopup = this.openPopup.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  openPopup() {
    this.setState({
      isOpen: true
    })
  }

  handleClose() {
    this.setState({
      isOpen: false
    })
  }

  render() {
    let isOpen = this.state.isOpen;
    return (
      <span className="containerWidth">
        <ItemPopup
          item={this.props.item}
          isOpen={isOpen}
        />
        <li className="cardContainer" style={{ height: "auto", padding: "10px" }}>
          <div className="divContainer ">
            <div className="flex">
              <div className="div-image"
                onClick={this.openPopup}
                style={{ width: "100%", background: "url(https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350) center center / 100% no-repeat", transition: "height 0.2s ease 0s", height: "143.437px" }}>
              </div>
              {
                !this.props.item.outOfOrder &&
                (
                  this.props.item.options ?
                    <OptionsPopup
                      classButton="buttonAddToCart"
                      classSvg="small-svg"
                      item={this.props.item}
                      handleClose={this.handleClose}
                    />
                    :
                    <ButtonAddToCart
                      classButton="buttonAddToCart"
                      classSvg="small-svg"
                      item={this.props.item}
                    />
                )
              }
            </div>
            <div className="subDiv">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="div-name">
                  <p className="normal-font item-name">{this.props.item.name}</p>
                </div>
                <div className="divPrice">
                  <p className="normal-font price-font">{this.props.item.montant.toFixed(2).toString()} $ CA</p>
                </div>
              </div>
              {
                this.props.item.outOfOrder &&
                <div className="divNotInStock">
                  <p className="notInStockText">Temporairement indisponible
                </p>
                </div>
              }
            </div>
          </div>
        </li>
      </span>
    );
  }
}

export default RestaurantCard;