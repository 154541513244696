import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../css/page-not-found.css";

export default function PageNotFound(props) {
  const location = useLocation();
  const [url, setURL] = useState();

  useEffect(() => {
    setURL(location.pathname);
  }, [location])

  return (
    <div id="page-not-found">
      <h1>Page not found</h1>
      <p>désolée, la page <code>{url}</code> est introuvable</p>
      <p>Raison&nbsp;: {props.error || "La route est érronée"}</p>
    </div>
  );
}
