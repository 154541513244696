import { formatDateYMD } from "../formatting";
import { getHeure } from "./activite-utils";

export const filtrerUnites = (
  disponibiliteUnites,
  heureDebut,
  heureFin,
  date
) => {
  const debut = `${formatDateYMD(date)}T${heureDebut}:00`;
  const fin = `${formatDateYMD(date)}T${heureFin}:00`;

  let unitesFiltrees = [];

  disponibiliteUnites.forEach((categorie) => {
    categorie.unites.forEach((unite) => {
      let uniteFiltree = { ...unite };
      uniteFiltree.items = [];

      // Vérifier si l'unité a au moins une période indisponible dans l'intervalle [heureDebut, heureFin[
      const hasIndisponible = unite.items.some(
        (item) =>
          getHeure(item.periode) >= getHeure(debut) &&
          getHeure(item.periode) < getHeure(fin) &&
          !item.disponible
      );

      if (hasIndisponible) {
        // Si l'unité a une période indisponible, passer à l'unité suivante
        return;
      }

      // Recherche de l'item correspondant à l'heure de début
      const itemDebut = unite.items.find(
        (item) => getHeure(item.periode) === getHeure(debut)
      );

      // Si l'heure de début n'est pas disponible, on exclut cette unité
      if (!itemDebut || !itemDebut.disponible) {
        return; // Passe à l'unité suivante
      }

      // Parcours de chaque période dans la plage spécifiée à partir de l'heure de début
      for (
        let periode = new Date(debut);
        periode <= new Date(fin);
        periode.setHours(periode.getHours() + 1)
      ) {
        const heurePeriode = getHeure(periode); // Obtention de l'heure de la période au format "HH:mm"

        // Recherche de l'item correspondant à l'heure de la période actuelle
        const item = unite.items.find(
          (item) => getHeure(item.periode) === heurePeriode
        );

        // Si l'item existe et est disponible, on l'ajoute aux items filtrés
        if (item && item.disponible === true) {
          uniteFiltree.items.push(item);
        }
      }

      // Si l'unité a passé tous les tests, on l'ajoute aux unités filtrées
      unitesFiltrees.push(uniteFiltree);
    });
  });
  return unitesFiltrees;
};
