/**
 * Transforme les données d'objets en un format de tableau regroupé.
 * @param {Array} objets - Tableau d'objets à transformer.
 * @returns {Array} Tableau transformé.
 */
export function transformData(objets) {
    if (!Array.isArray(objets)) {
        return [];
    }

    // Crée un objet pour stocker les objets regroupés.
    const groupedObjects = {};

    // Parcourt chaque objet dans le tableau d'entrée.
    objets.forEach((objet) => {
        const { idUniteCategorie, idUnite } = objet;

        // Vérifie si l'idUniteCategorie existe déjà dans l'objet regroupé.
        if (!groupedObjects[idUniteCategorie]) {
            groupedObjects[idUniteCategorie] = {
                idUniteCategorie,
                unites: {},
            };
        }

        // Vérifie si l'idUnite existe déjà dans l'objet regroupé.
        if (!groupedObjects[idUniteCategorie].unites[idUnite]) {
            groupedObjects[idUniteCategorie].unites[idUnite] = {
                idUnite,
                items: [],
            };
        }

        // Ajoute l'objet actuel dans le tableau d'objets correspondant à cette idUnite.
        groupedObjects[idUniteCategorie].unites[idUnite].items.push({
            periode: objet.periode,
            disponible: objet.disponible,
            prix: objet.prix,
            isDeleted: objet.isDeleted,
        });
    });

    // Transforme l'objet regroupé en tableau et retourne le résultat.
    return Object.values(groupedObjects).map((item) => {
        const unitesArray = Object.values(item.unites);
        return {
            idUniteCategorie: item.idUniteCategorie,
            unites: unitesArray,
        };
    });
}