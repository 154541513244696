import React, { Component } from 'react';

class HeaderTitle extends Component {
  render() {

    return (
      <div className="heading">
        <span className="btn-popup btn-circle">
          {this.props.number}
        </span>
        <span className="cursor-default">
          {this.props.text}
        </span>
      </div>
    );
  }
}

export default HeaderTitle;