import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import './annuler-reservation.css'
import AnnulationPopup from "./annulation-popup";
import { setAlert } from "../actions/alert";
import { getPolitique } from "../../server/services/politique-service";

function AnnulationReservationPage(props) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [info, setInfo] = useState({});
  const [idError, setIdError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [facture, setFacture] = useState('');

  const validate = async () => {
    setIdError('');
    setEmailError('');
    if (!info.id) setIdError('Veuillez entrer le numéro de réservation')
    if (info.id > 100) setIdError('Id invalide')
    if (!info.email) setEmailError('Veuillez entrer le numéro de réservation')
    if (info.email > 100) setEmailError('Id invalide')
    if (!idError && !emailError) {
      var response = await getPolitique(info.id, info.email).then(response => response);
      console.log(response)
      if (!response.length) { dispatch(setAlert('La validation a échouée.', 'danger')); }
      else {
        dispatch(setAlert('Validation confirmée.', 'success')); // F904FAEE-BC90-EB11-AA57-D4AE526DD012 - mcbelanger54@videotron.ca
        setFacture(response);
        setIsOpen(true);
      }
    }
  }

  const handleClose = () => {
    this.setState({
      isOpen: false
    })
  }

  function handleChange(target) {
    setInfo({ ...info, [target.name]: target.value });
  }

  return (
    <div id="profile-page">
      <AnnulationPopup
        isOpen={isOpen}
        facture={facture}
        handleClose={() => setIsOpen(false)}
      />
      <div className="display-inline-block">
        <div>
          <div className="profile-label-wrapper">
            <span className="profile-span">NUMÉRO DE RÉSERVATION :</span>
          </div>
          <div className="input-container">
            <input
              //   ref={input => this.usernameInput = input} 
              type="text"
              name="id"
              value={info.id}
              onChange={(e) => handleChange(e.target)}
              //onChange={e => setId(e.target.value)}
              //   onChange={e => this.setState({ username: e.target.value })} 
              className="profile-input" />
            {idError && <span className="color-error profile-span">*Champ obligatoire</span>}
          </div>
        </div>
        <br />
        <br />
        <div>
          <div className="profile-label-wrapper">
            <span className="profile-span">COURRIEL :</span>
          </div>
          <div className="input-container">
            <input
              type="text"
              name="email"
              value={info.email}
              onChange={e => handleChange(e.target)}
              //   onChange={e => this.setState({ password: e.target.value })} 
              className="profile-input" />
            {emailError && <span className="color-error profile-span">*Champ obligatoire</span>}
          </div>

        </div>
        <div className="profile-div">
          <div className="profile-div btn-login clickable"
            onClick={() => validate()}
          >
            <span className="profile-span">VALIDER L'ANNULATION</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
  };
}

const dispatchProps = { setAlert };

export default connect(mapStateToProps, dispatchProps)(AnnulationReservationPage);