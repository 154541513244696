import React, { useState } from 'react';
import { MapContainer, CircleMarker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const SeatMap = () => {
  const [selectedSeats, setSelectedSeats] = useState([]);

  const center = [51.505, -0.09];  // Centre de la carte
  const numberOfBlocks = 3;  // Nombre de blocs
  const numberOfRows = 5;  // Nombre de rangées par bloc
  const seatsPerRow = 10;  // Nombre de sièges par rangée
  const rowSpacing = 0.0005;  // Espacement entre les rangées
  const totalAngle = Math.PI;  // 180 degrés en radians
  const blockSpacing = totalAngle / (numberOfBlocks * (numberOfRows + 1));  // Espacement angulaire entre les blocs
  const angleStart = -Math.PI / 2;  // Angle de début pour le premier bloc (haut du cercle)
  const angleEnd = Math.PI / 2;  // Angle de fin pour le dernier bloc (bas du cercle)

  const seats = [];
  for (let block = 0; block < numberOfBlocks; block++) {
    const blockAngleOffset = block * (totalAngle / numberOfBlocks);
    for (let row = 0; row < numberOfRows; row++) {
      const radius = 0.001 + row * rowSpacing;
      const angleStep = (totalAngle / numberOfBlocks - blockSpacing) / (seatsPerRow - 1);
      for (let i = 0; i < seatsPerRow; i++) {
        const angle = angleStart + blockAngleOffset + i * angleStep + blockSpacing;
        seats.push({
          id: `${block}-${row}-${i}`,
          position: [
            center[0] + radius * Math.cos(angle),
            center[1] + radius * Math.sin(angle),
          ],
        });
      }
    }
  }

  const handleSeatClick = (seatId) => {
    setSelectedSeats((prev) =>
      prev.includes(seatId)
        ? prev.filter((id) => id !== seatId)
        : [...prev, seatId]
    );
  };

  return (
    <MapContainer center={center} zoom={15} style={{ height: "500px", width: "100%" }}>
      <CircleMarker
        center={center}
        radius={10}
        color='red'
      />
      {seats.map((seat) => (
        <CircleMarker
          key={seat.id}
          center={seat.position}
          radius={5}
          color={selectedSeats.includes(seat.id) ? 'red' : 'blue'}
          eventHandlers={{
            click: () => handleSeatClick(seat.id),
          }}
        >
          <Popup>
            <div>
              <p>Seat {seat.id}</p>
              <button onClick={() => handleSeatClick(seat.id)}>
                {selectedSeats.includes(seat.id) ? 'Deselect' : 'Select'}
              </button>
            </div>
          </Popup>
        </CircleMarker>
      ))}
    </MapContainer>
  );
};

export default SeatMap;
