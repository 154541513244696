import React, { Component } from "react";

class Select extends Component {
  render() {
    return (
      <div className="select-unite">
        {this.props.title}
        <div className="select-div">
          <select
            className="activity-select"
            disabled={this.props.disabled}
            name={this.props.name}
            value={this.props.value}
            onChange={this.props.onChange}
          >
            <option key="nothing-selected" value="">
              {this.props.option}
            </option>
            {this.props.renderSelect}
            {this.props.plusOption && <option key={99} value={99}>99+ {this.props.option}</option>}
          </select>
          <div className="select_arrow">
          </div>
        </div>
      </div>
    );
  }
}

export default Select;