// Couleur pour la disponibilité
export const couleurDisponible = 'heure-disponible';
export const couleurPartiellementDisponible = 'heure-partiellement-disponible';
export const couleurNonDisponible = 'heure-non-disponible';
export const couleurParDefaut = 'heure-defaut'

//type Peride
export const typePeriode = {
    0:"minute",
    1:"heure",
    2:"jour",
    3:"moi",
    4:"annee"
}