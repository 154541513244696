import { SET_ID_CLIENT_ENREGISTREMET, SET_ID_ENREGISTREMENT_ENTETE } from "../components/actions/types";

const initialState = {
    idClient: '',
    idEnregistrementEntete:''
};

export default function (state = initialState, action) {
    let newState;
    switch (action.type) {
        case SET_ID_CLIENT_ENREGISTREMET:
            newState = {
                ...state,
                idClient: action.value,
            }
            return newState || state;
            case SET_ID_ENREGISTREMENT_ENTETE:
                newState = {
                    ...state,
                    idEnregistrementEntete: action.value,
                }
                return newState || state;
        default:
            return state;
    }
}