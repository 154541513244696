import { api } from "../api";

const controllerName = 'Evenement' 

export async function getEvenementList() {
    return api.get(`${controllerName}/evenements`);
}

export async function getDetailsEvenementById(id){
    return api.get(`${controllerName}/${id}/details`);
}

// export async function getSchemaSite(id){
//     return api.get(`${controllerName}/${id}/plans`)
// }

export async function getSchemaSite(id){
    return api.get(`Configuration/SVG_DATA`)
}