import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "./confirmation-termes-et-condition.css";

const ConfirmationTermesEtConditions = (props) => {

  const onConfirmation = () => {
    props.onConfirmation();
  };

  return (
    <div>
      <Popup open={props.isOpen} onClose={props.handleClose} modal closeOnDocumentClick={false} position={"center center"} lockScroll nested className="confirmation-termes-et-condition-auto">
        <div className="tranche-age-header">
          <h3>Termes et conditions</h3>
        </div>
        <div className="confirmation-termes-et-condition-auto message">
          <p dangerouslySetInnerHTML={{ __html: props.message }}></p>
        </div>
        <div className="buttons">
          <button onClick={onConfirmation}>J'accepte</button>
          <button onClick={props.handleClose}>Non</button>
        </div>
      </Popup>
    </div>);
};

export default ConfirmationTermesEtConditions;
