import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withNavigation from "./higher-order/navigation";
import { getBilletterie, getBilletterieImage } from "../server/services/catalogue-service";
import "../../css/choix-unite-calendrier.css";
import { getShop, CartItemType } from "./actions/shop";
import FacturePanierPopup from "./popups/facture-panier-popup";
import SommaireRightPanel from "./achat-forfait/sommaire-right-panel";
import CartSvg from "./svg/cart";
import data from './choix-unite-calendrier-data.json'
import { useSeoTitle } from "../utils/hooks";
import Translator from "./translator";

function ChoixUniteCalendrier(props) {
  //   useSeoTitle("Billetterie");
  const TEXTS = Translator(props.match.params.language, "TXT_BILLETTERIE_PAGE");
  const [isShoppingCartOpen, setIsShoppingCartOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingImage, setLoadingImage] = useState(true);
  const [billetterie, setBilletterie] = useState([]);

  useEffect(async () => {
    // const billets = await getBilletterie();
    // await setBilletterie(billets);
    await setLoading(false);
    // const promiseImage = billets.map(item => getBilletterieImage(item.idProduit).then(imageBillet => item.produitImage = imageBillet));
    // await Promise.all(promiseImage)

    // await setBilletterie(billets);
    // await setLoadingImage(false);
  }, []);


  const renderContents = (item) => {
    console.log(item)
    return <div className="cf-item-data col-xs-12" id={item.id}>
      <div className="row">
        <div className="col-xs-12 mobile-title">
          <h2>{item.title}</h2>
          <div className="cf-price ">
            <strong>{item.price}</strong> <em>par heure</em>
            <span className="sr-only">.</span>
          </div>
        </div>
        <div className="cf-item-info pull-left col-xs-4 col-sm-3 col-md-3">
          <ul className="cf-item-action nav nav-pills nav-stacked">
            <li><a href="/#87@book@" className={`cf-item-status ${item.availability === 'Non disponible' ? 'CLOSED' : 'AVAILABLE'}`}>
              {item.availability}</a>
            </li>
            <li>
              <a href="/#87@book@" className="cf-btn-book res-btn ct">
                <i className="fa fa-check-square"></i>&nbsp;Réserver</a>
            </li><li>
              <a href="/#87@summary@" className="cf-btn-summary res-btn ct">
                <i className="fa fa-file-o"></i>&nbsp;Détails</a></li><li>
              <a href="/#87@cal@" className="cf-btn-calendar res-btn ct">
                <i className="fa fa-calendar-o"></i>&nbsp;Autres dates
              </a>
            </li>
          </ul>
        </div>
        <div className="cf-mobile col-xs-8 col-sm-9 col-md-9 pull-left">
          <div className="clearfix">
            <div className="cf-item-thumb cf-item-action pull-right col-xs-5 col-sm-4 col-md-4">
              <a href="/#87@photo" className="cf-item-thumb">
                <img className="img-responsive img-rounded"
                  src={item.image} />
              </a>
            </div>
            <div className="cf-item-title-summary">
              <div className="cf-title">
                <h2>
                  {item.title}
                  <span className="sr-only">.</span>
                </h2>
                <div className="cf-price ">
                  <strong>{item.price}</strong> <em>par heure</em>
                  <span className="sr-only">.</span>
                </div>
              </div>
              <div className="cf-item-summary">
                <p>Nouveauté {item.year}</p>
                <p className="focus-visible">Grandeur recommandée pour ce vélo: {item.size} = pour la plupart des enfants de 6 à 8 ans.
                  {item.casque && <>
                    <br /><br />Le casque est inclus avec la location.
                  </>}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  };

  return (
    <div id="calendrier-page-container">
      <div className="recherche-component billeterie-div-filter" />
      <div className="row">
        <div className="col-md-12 clearfix">
          <div id="cf-items">
            <div className="cf-item-list">
              {isLoading ? (
                <span className="billetterie-header">{TEXTS.text1}</span>
              ) : data && data.length
                ? data.map(renderContents)
                : <>
                  "Vérification de disponibilité en cours..."
                  <span className="billetterie-header">{TEXTS.text0}</span>
                </>}
            </div>
          </div>
        </div>
      </div>
      <FacturePanierPopup showByType="billet" handleClose={() => setIsShoppingCartOpen(false)} isOpen={isShoppingCartOpen} />
      <div className="div-filtrer" onClick={() => setIsShoppingCartOpen(true)}>
        <span className="icon">
          <CartSvg width="30" height="30" />
        </span>
      </div>
      <div className="sommaire-right-panel max-width sommaire-large-screen">
        <SommaireRightPanel message={TEXTS.title1} confirm={() => props.goTo("/panier", "PANIER")} confirmTitle={TEXTS.confirmTitle} confirmError={TEXTS.confirmError} showByType={CartItemType.Billetterie} canConfirm={props.shop.cart.length > 0} />
      </div>
    </div >
  );
}
const mapStateToProps = (state, ownProps) => ({
  shop: state.shop
});
export default withNavigation(connect(mapStateToProps, { getShop })(ChoixUniteCalendrier));