import React from 'react';

const CompanionSelectOrAddVehicle = ({
    updateListVehicle,
    onchangeInputText,
    accompagnateurList,
    desactiverNumeroFqcq,
    setNewVehicleRegistrationDetails,
  }) => {
  return (
    <>
      {accompagnateurList.filter(a => a.isSelected).map((accompagnateur, index) => {  //filtre pour afficher uniquement les accompagnateurs dont iselected est true. 
      return (
        <tr key={index} className="droit-acces-choix-accompagnateur-tr">
          <td style={{ textAlign: 'left' }} className="droit-acces-choix-accompagnateur-td-nom">
            <span className="label" style={{ textTransform: 'initial' }}>{accompagnateur.nom} {accompagnateur.prenom} :</span>
          </td>
          <td className="droit-acces-choix-accompagnateur-td-vehicule">
            <select style={{ ...{ marginLeft: '16px', width: '100%' }, ...(accompagnateur.idVehicule === "00000000-0000-0000-0000-000000000000" ? { color: "var(--medium-gray)" } : null) }} value={accompagnateur.idVehicule} onChange={e => {
              if (e.target.value === "new") {
                setNewVehicleRegistrationDetails(accompagnateur)
              }
              else {  
                updateListVehicle(e, accompagnateur);
              }
            }}>
              <option value="00000000-0000-0000-0000-000000000000" style={{ color: 'var(--medium-gray)' }}>CHOIX DES VÉHICULES</option>
              {accompagnateur.vehicules?.map(v => 
              {
               return (<option key={v.idVehicule} value={v.idVehicule} style={{ color: 'black' }}>{v.description} {v.noPlaque}</option>)
              }
              )}
              <option value="new" style={{ fontStyle: 'italic', color: 'black' }} className="clickable">-AJOUTER UN VÉHICULE-</option>
            </select>
          </td>
          {desactiverNumeroFqcq.toUpperCase() === "FALSE" &&
            <td className="droit-acces-choix-accompagnateur-td-fqcq">
              <input 
              type="text" 
              maxLength="10" 
              placeholder="VIGNETTE FQCQ" 
              style={{ marginLeft: '32px', width: '100%' }} 
              disabled={accompagnateur.idVehicule === "00000000-0000-0000-0000-000000000000"} 
              value={accompagnateur.idVehicule === "00000000-0000-0000-0000-000000000000" ? "" : accompagnateur.fqcq} 
              onChange={e => onchangeInputText(e, accompagnateur)} />
            </td>
          }
        </tr>
      )
    })
  }</>
  )
  }
  export default CompanionSelectOrAddVehicle