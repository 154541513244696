import React from 'react';

import { parseISO, format } from 'date-fns';
import TitleEvenement from './titre-evenement';

const ETATS_EVENEMENT = {
    0: "Pas disponible en ligne",
    1: "Disponible en ligne",
    2: "Complet",
    3: "Possibilite complementaire",
    4: "En construction"
}

const Details = ({details}) => {
    return (
        <>
            {/* Bloc 1 */}
            <div className="content-details-left">
                <div style={{ position: 'sticky', top: 40 }}>
                    <div>
                        <img src="/static/media/no-image.eb3751d07456d873a88b.png" alt={`image-${details.description}`} />
                    </div>
                    {/* Information de date */}
                    <div className='content-info-date-evenement'>
                        {details.artiste != "" && details.artiste !== undefined && <div>
                            <span>Artiste: <b>{details.artiste}</b></span>
                        </div>}
                        <div>
                            <span>Debut: <b>{format(parseISO(details.dateDebut), 'yyy-MM-dd')}</b></span>
                        </div>
                        <div>
                            <span>Fin: <b>{format(parseISO(details.dateFin), 'yyy-MM-dd')}</b></span>
                        </div>
                        {details.adresseEvenement != "" && details.adresseEvenement !== undefined && <div>
                            <span>Adresse : </span>
                            <span><b>{details.adresseEvenement}</b></span>
                        </div>}
                        <div>
                            <span>Etat: </span>
                            <span><b>{ETATS_EVENEMENT[details.etat]}</b></span>
                        </div>
                        <div>
                            <span>Prix enfant : </span>
                            <span><b>{details.prixEnfant} $</b></span>
                        </div>
                        <div>
                            <span>Prix Adulte : </span>
                            <span><b>{details.prixAdulte} $</b></span>
                        </div>
                    </div>
                </div>
            </div>
            {/* Bloc 2 */}
            <div className='body-details-evenement'>
                <TitleEvenement title={details.description} customClass={'title-body-details-evenement'} />
                {/* Description */}
                <div className='body-content-details-evenement'>
                    <div>
                        <p>{details.descriptionWeb}</p>
                    </div>
                    <div>
                        <p>{details.notes}</p>
                    </div>
                    <div>
                        <p>{details.texteSupplementaire}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Details