import crypto  from './encryptDataProfileUser.js'

export function setFromStorage(key, data) {
    try {
        let encryptData = crypto.encryptObject({data: data});
        localStorage.setItem(key, encryptData);
        return true;
    } catch (error) {
        return false ;
    }
}

export function getFromStorage(key) {
    let  encryptData = localStorage.getItem(key) ;
    if(encryptData) {
        try {
            let decryptData = crypto.decryptObject(encryptData);
            return decryptData.data ;
        } catch (error) {
            return null;
        }
    }
}


export function removeFromLocalStorage(key) {
    localStorage.removeItem(key)
}