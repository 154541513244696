import React, { useState, useEffect } from "react";
import { InfoWindow, withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import data from "./data.json"

function Map(props) {

  const [selected, setSelected] = useState(null);

  const defaultCenter = {
    lat: 45.421532, lng: -75.697189
  }
  const [center, setCenter] = useState(defaultCenter)
  const [zoom, setZoom] = useState(8)


  useEffect(() => {
    setCenter(defaultCenter);
    setZoom(8);
  }, [null, props.selectedRegion]);

  const renderMarkers = (park) => {
    if (props.selectedRegion) {
      if (props.selectedRegion != park.region) return
    }
    return <Marker key={park.properties.PARK_ID}
      position={{ lat: park.geometry.coordinates[1], lng: park.geometry.coordinates[0] }}
      onClick={() => {
        setSelected(park)
      }}

    />
  }
  return (
    <GoogleMap
      defaultZoom={zoom}
      zoom={zoom}
      defaultCenter={center}
      center={center}
    >
      {data.features.map(renderMarkers
      )}
      {
        selected && (
          <InfoWindow
            onCloseClick={() => setSelected(null)}
            position={{ lat: selected.geometry.coordinates[1], lng: selected.geometry.coordinates[0] }}
          >
            <div>
              <h2>
                {selected.properties.NAME}
              </h2>
              <p>
                {selected.properties.DESCRIPTIO}
              </p>
            </div>
          </InfoWindow>
        )
      }
      {/* <Marker position={{ lat: -34.397, lng: 150.644 }} onClick={handleMarkerClick} /> */}
    </GoogleMap>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(Map));

export default WrappedMap;