import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import withNavigation from "./higher-order/navigation";
import UniteCard from "./unite-card.js";
//import Fetch from '../server/api.js';
import "../../css/choix-unite-liste.css";
import {useSeoTitle} from "../utils/hooks";
import {descriptionHebergement} from "../constants/reservation-constants";
import {format as formatDate} from "date-fns";

function formatTime(date) {
    const dateObj = new Date(date);
    return formatDate(dateObj, "HH:mm:ss");
}

//function appendSearchParam(search, param, value) {
//    return search === ""
//        ? search + "?" + param + "=" + value
//        : search + "&" + param + "=" + value
//}

function stringPeriodToDatePeriod({ debut, fin }) {
        return {
            debut: new Date(debut),
            fin: new Date(fin)
        };
}
function ChoixUniteListe(props) {

    useSeoTitle("Réservation", `Liste des ${descriptionHebergement[props.typeHebergement]}`);
    const [uniteLoaded, setUniteLoaded] = useState(props.unites.slice(0, 10));

    useEffect(() => {
        let isSubscribed = true;
        window.onscroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200 && uniteLoaded.length !== props.unites.length && isSubscribed) {
                setUniteLoaded([...uniteLoaded, ...props.unites.slice(uniteLoaded.length, uniteLoaded.length + 5)]);
            }
        };

        return () => (isSubscribed = false);
    }, [props.unites, uniteLoaded]);

    const className = props.showRecherche ? "has-recherche" : "no-recherche";
    const isFrancais = props.language.toLowerCase() === 'fr';

    return (
        <div id="choix-unite-liste" className={className} style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'flex-start' }}>
            <div style={{width: '100%'}}>
                {(uniteLoaded || []).map((unite) => (
                    <>
                        <UniteCard
                            key={ unite.id_unite}
                            idTypeUnite={unite.id_type_unite}
                            idUnite={unite.id_unite}
                            idUniteList={unite.idUniteList || []}
                            nom={unite.description_client === "" ? (isFrancais || unite.description_anglaise === '' ? unite.description : unite.description_anglaise) : unite.description_client}
                            description={isFrancais || unite.description_web_anglais === '' ? unite.description_web : unite.description_web_anglais}
                            minDate={props.minDate}
                            maxSearchDate={props.maxSearchDate}
                            minSearchDate={props.minSearchDate}
                            nombrePersonnes={unite.nombre_personne}
                            fumeur={unite.fumeur}
                            eau={unite.id_point_eau !== "00000000-0000-0000-0000-000000000000"}
                            animaux={!unite.interdire_animaux}
                            montant={unite.plan_base ? unite.plan_base.prix : 0}
                            indisponibilites={(unite.indisponibilites || []).map(stringPeriodToDatePeriod)}
                            heureArrivee={!props.isActivite ? formatTime(unite.heure_arrivee) : null}
                            heureDepart={!props.isActivite ? formatTime(unite.heure_depart) : null}
                            selectedArrivee={props.selectedArrivee}
                            selectedDepart={props.selectedDepart}
                            onDayClick={props.onDayClick}
                            maxJoursEnAvanceReservation={props.maxJoursEnAvanceReservation}
                            showCalendrier={props.showCalendrier}
                            showIcons={props.showIcons}
                            calendrierInitialMonth={props.calendrierInitialMonth}
                            isActivite={props.isActivite}
                            typeHebergement={props.typeHebergement}
                            availabilityLevel={unite.availabilityLevel}
                            availabilityLevelDescription={unite.availabilityLevelDescription}
                            onClickAjouterPanier={() => props.onClickAjouterPanier(unite.id_unite)}
                            showAddToShoppingCart={props.showAddToShoppingCart}
                        />
                    </>
                ))}
            </div>
        </div>
    );
}

ChoixUniteListe.defaultProps = {
    showRecherche: true,
    showCalendrier: true,
    showIcons: true,
    maxJoursEnAvanceReservation: null,
    unites: [],
    calendrierInitialMonth: null,
    isActivite: false,
    typeHebergement: "",
    onClickAjouterPanier: null,
};

function mapStateToProps(state, ownProps) {
    return {
        onCloseClick: ownProps.onCloseClick || state.genericPopup.onCloseClick,
        categoriesActivitelist: state.categoriesActivite.categorie
    };
}
export default withNavigation(connect(mapStateToProps)(ChoixUniteListe));