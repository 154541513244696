import {
  SET_AUTH_TOKEN,
  CLEAR_AUTH_TOKEN
} from "./types";

export const setAuthToken = authToken => async (dispatch) => {
  try {
    if(authToken !== null) {
    dispatch({
      type: SET_AUTH_TOKEN,
      authToken: authToken,
    })
  }
    else {
      dispatch({
        type: CLEAR_AUTH_TOKEN,
        authToken: null,
      })
    }
  } catch (err) {
      console.log(err);
  }
};