import React, { useEffect } from 'react';

//api
import { getEvenementList } from '../../server/services/evenement-service';

//component
import StepEvenement from './conteneur-etapes';
import LayoutEvenement from './layout-evenement';
import SommaireEvevenement from './sommaire-evenement';

//context
import { EvenementPovider } from '../../contexte/evenement-page';

//style
import './evenement.css';

const Evenement = () => {

    return (
        <LayoutEvenement>
            <EvenementPovider>
            {/* step */}
            <StepEvenement/>

            {/* Sommaire */}
            <SommaireEvevenement />
            </EvenementPovider>
        </LayoutEvenement>
    )
}
export default Evenement