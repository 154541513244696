import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { addToShoppingCart, updateShop } from "../../actions/shop";
import Popup from 'reactjs-popup';
import "./options-popup.css";
import { CartItemType } from "../../actions/shop";
import ButtonAddToCart from "../../buttons/buttonAddToCart.js";
import PopupHeader from "../../popups/popup-header.js";
import PopupSubHeader from "./popup-sub-header.js";
import OptionContainer from "./option-container.js";
// import OptionDesserts from "./option-desserts.js";
// import desserts from "../desserts.json";
// import OptionTitle from "./option-title.js";
// import OptionCard from './option-card.js';

class OptionsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsIndex: this.props.isModifying ? this.props.item.optionsIndex : Array(this.props.item.options.length).fill(null),
      optionsPrices: this.props.isModifying ? this.props.item.optionsPrices : Array(this.props.item.options.length).fill(null),
      montant: this.props.isModifying ? this.props.item.montantOriginal : this.props.item.montant
    }
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(name, index, montant) {
    let names = this.state.optionsIndex.slice();
    let prices = this.state.optionsPrices.slice();
    if (names[index] === name) { names[index] = null; montant = null; }
    else names[index] = name;
    if (montant) prices[index] = montant;
    else prices[index] = null;
    this.setState({
      optionsIndex: names,
      optionsPrices: prices
    });
  }

  validateAndClose(close, totalPrice) {
    const newItem = {};
    newItem["name"] = this.props.item.name;
    newItem["montant"] = totalPrice;
    if (this.props.item.options) {
      newItem["inclus"] = [];
      for (let i = 0; i < this.props.item.options.length; i++) {
        if (this.state.optionsPrices[i]) {
          newItem["inclus"].push({
            name: this.state.optionsIndex[i],
            montant: this.state.optionsPrices[i]
          });
        } else {
          newItem["inclus"].push({
            name: this.state.optionsIndex[i]
          });
        }
      }
    }
    newItem["optionsIndex"] = this.state.optionsIndex;
    newItem["optionsPrices"] = this.state.optionsPrices;
    if (this.props.isModifying) {
      this.props.updateShop(this.props.index, newItem);
    } else {
      newItem["type"] = CartItemType.CommandeRestaurant;
      newItem["thumbnail"] = 'https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350';
      newItem["options"] = this.props.item.options;
      newItem["montantOriginal"] = this.state.montant;
      this.props.addToShoppingCart(newItem);
    }
    close();
    this.props.handleClose && this.props.handleClose();
  }

  render() {
    let canValidate = true;
    for (let i = 0; i < this.state.optionsIndex.length; i++) {
      if (!this.state.optionsIndex[i]) canValidate = false
    }

    let totalPrice = this.state.montant;
    for (let i = 0; i < this.state.optionsPrices.length; i++) {
      if (this.state.optionsPrices[i]) totalPrice += this.state.optionsPrices[i]
    }

    return (
      <Popup
        trigger={
          this.props.content ?
            this.props.content :
            <div>
              <ButtonAddToCart
                classButton={this.props.classButton ? "buttonAddToCart" : "item-popup-btn btn-add-to-cart"}
                classSvg={this.props.classSvg && this.props.classSvg}
                message={this.props.message && this.props.message}
                item={this.props.item}
                handleClick={() => { }}
              />
              {this.props.content && this.props.content}
            </div>
        }
        modal
        nested
      >
        {close => (
          <div className="fixed-panel">
            <div className="dark-container" onClick={close}>
            </div>
            <div className="centered">
              <div className="options-popup-div">
                <PopupHeader
                  title="CHOIX DES ACCOMPAGNEMENTS"
                  onClick={close}
                />
                <PopupSubHeader name={this.props.item.name} />
                <div className="option-container-flex">
                  <OptionContainer
                    onClick={() => { }}
                    handleClick={this.handleClick}
                    optionsIndex={this.state.optionsIndex}
                    cards={this.props.item.options} />
                </div>
                {/* <div className="jss2581">
                    {this.props.item.hasDesserts &&
                      <div>
                        <div className="jss2612">
                          <OptionTitle
                            number='4'
                            name="Désirez-vous ajouter un dessert à votre plat principal ?"
                            isChosen={this.state.options[1]}
                          />
                          <OptionDesserts />
                        </div>
                      </div>
                    }
                  </div> */}
                <div className="options-popup-footer">
                  <button onClick={canValidate ? () => this.validateAndClose(close, totalPrice) : undefined}
                    className={`options-popup-btn btn-confirm ${canValidate ? 'allowed' : 'not-allowed'}`}>
                    <div className="div-price">
                      <p className="options-popup-font price-font">
                        {totalPrice.toFixed(2).toString()}&nbsp;$&nbsp;CA
                        </p>
                    </div>
                    <p className="options-popup-font text-white confirm-font">
                      {this.props.isModifying ? 'MODIFIER' : 'CONFIRMER'}
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}

OptionsPopup.propTypes = {
  addToShoppingCart: PropTypes.func.isRequired,
  updateShop: PropTypes.func.isRequired
};

OptionsPopup.defaultProps = {
  showAllMontant: false,
  isModifying: false
};

export default connect(null, { addToShoppingCart, updateShop })(OptionsPopup);