export function compareStrings(s1, s2) {
  return s1 <= s2 ? -1 : 1;
}

export function compareStringArrays(stringsA, stringsB) {
  for (let i = 0; i < stringsA.length; i++) {
    if (stringsA[i] < stringsB[i])
      return -1;

    if (stringsA[i] > stringsB[i])
      return 1;
  }

  return 0;
}