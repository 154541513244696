import React, { useState, useContext } from "react";

//context 
import { ChoixUnitePageContext } from '../contexte/choixUnitePage';

//components
import Calendar from "./calendar";
import Translator from "./translator";
import ChampsDeRecherche from "./reservation/champ-de-recherche";
// import ChampsDeRecherche from "./reservation/champ-de-recherche-old";
import ChampDeRechercheFieldError from "./reservation/champ-de-recherche-field-error";

const ChoixRecherchePanel = (props) => {

  //state
  const [showCalendrierArrivee, setShowCalendrierArrivee] = useState(false);
  const [showCalendrierDepart, setShowCalendrierDepart] = useState(false);
  const TEXTS = Translator(props.language, "TXT_CHOIX_RECHERCHE_PANEL");

  //contexte
  const {
    //data
    today,
    idUnite,
    fieldError,
    idTypeUnite,
    previewDate,
    searchOptions,
    showCalendrier,
    affichageClass,
    initialLocation,
    typeHebergement,
    peutAjouterPanier,
    isTypeAffichageUnite,
    consultationSeulement,
    maxJoursEnAvanceReservation,
    demandeDeReservationParCourriel,

    //methods
    _formatDate,
    _onSelectDate,
    _getInputClassName,
    _onDateHoverChange,
    _updateSearchField,
    _applySearchFilters,
    _resetSearchOptions,
    _blurCalendrierDepart,
    _blurCalendrierArrivee,
    _updateSearchFieldExtra,
    _toggleCalendrierDepart,
    _toggleCalendrierArrivee,
    _prepareForAddToShoppingCart,
  } = useContext(ChoixUnitePageContext);


  return (
    <div id="choix-unite-recherche" className={affichageClass}>
      <div className="choix-unite-recherche-section date">
        <div className="choix-unite-recherche-title-wrapper date">
          {fieldError !== null && <ChampDeRechercheFieldError errorMessage={fieldError.selectedDate} />}
          <span>{TEXTS.title0}</span>
        </div>
        <div className={() => _getInputClassName()}>
          <div className="choix-unite-recherche-demi-input-wrapper">
            <input type="text" placeholder={TEXTS.calendarTitle0} className="clickable" onMouseDown={(e) => _toggleCalendrierArrivee()} value={_formatDate(previewDate.arrivee || searchOptions.selectedArrivee) || ""} readOnly />
            <img name="image" className="calendar-icon clickable" src={require("../../images/icons/calendar-2.png")} alt="calendrier" onMouseDown={() => _toggleCalendrierArrivee()} />
            {showCalendrier.arrivee && (
              <div
                className="calendar-wrapper"
                tabIndex={0}
                onBlur={(date) => _blurCalendrierArrivee()}
              >
                <Calendar
                  minDate={today}
                  language={props.language}
                  isSelectingRangeBegin={true}
                  selectedRangeEnd={searchOptions.selectedDepart}
                  onDayClick={(date) => _onSelectDate(date, true)}
                  selectedRangeBegin={searchOptions.selectedArrivee}
                  onHoveredDayChange={(date) => _onDateHoverChange(date, true)}
                  maxJoursEnAvanceReservation={parseInt(maxJoursEnAvanceReservation || 0)}
                  initialMonth={searchOptions.selectedArrivee || searchOptions.selectedDepart}
                />
              </div>
            )}
          </div>
          <div className="choix-unite-recherche-demi-input-wrapper">
            <input type="text" placeholder={TEXTS.calendarTitle1} className="clickable" onMouseDown={(e) => _toggleCalendrierDepart()} value={_formatDate(previewDate.depart || searchOptions.selectedDepart) || ""} readOnly />
            <img className="calendar-icon clickable" src={require("../../images/icons/calendar-2.png")} alt="calendrier" onMouseDown={(e) => _toggleCalendrierDepart()} />
            {showCalendrier.depart && (
              <div
                className="calendar-wrapper"
                tabIndex={0}
                onBlur={(e) => _blurCalendrierDepart()}
              >
                <Calendar 
                  minDate={today}
                  language={props.language}
                  isSelectingRangeEnd={true}
                  selectedRangeEnd={searchOptions.selectedDepart}
                  selectedRangeBegin={searchOptions.selectedArrivee}
                  onDayClick={(date) => _onSelectDate(date, false)}
                  onHoveredDayChange={(date) => _onDateHoverChange(date, false)}
                  maxJoursEnAvanceReservation={parseInt(maxJoursEnAvanceReservation || 0)}
                  initialMonth={searchOptions.selectedDepart || searchOptions.selectedArrivee}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ChampsDeRecherche
        idUnite={idUnite}
        fieldError={fieldError}
        language={props.language}
        idTypeUnite={idTypeUnite}
        searchOptions={searchOptions}
        typeHebergement={typeHebergement}
        peutAjouterPanier={peutAjouterPanier}
        isTypeAffichageUnite={isTypeAffichageUnite}
        consultationSeulement={consultationSeulement}
        updateSearchField={(e) => _updateSearchField(e)}
        resetSearchOptions={() => _resetSearchOptions()}
        showAddToShoppingCart={!demandeDeReservationParCourriel}
        updateSearchFieldExtra={() => _updateSearchFieldExtra()}
        addUniteToShoppingCart={() => _prepareForAddToShoppingCart(idUnite)}
        applySearchFilters={() => _applySearchFilters(initialLocation)}
      />
    </div>
  );
}
export default ChoixRecherchePanel;