import React, { useEffect, useState, Fragment } from "react";
import withNavigation from "../higher-order/navigation";
import { getTermeEtCondition } from "../../server/services/catalogue-service";
import { getReservationClientInfo } from "../../server/services/reservation-service";
import { connect } from "react-redux";
import { getClientName } from "../../server/services/client-service";
import { getPlanEnteteById } from "../../server/services/plan-service";
import { getProductById } from "../../server/services/catalogue-service";
import { setAlert } from '../actions/alert';
import Translator from "../translator";
import SommaireRightPanel from "../achat-forfait/sommaire-right-panel";
import { PATH_PAIEMENT_COMPTES, PATH_PANIER } from "../../constants/navigation-constants";
import moment from "moment";
import { addToShoppingCart } from "../actions/shop";
import { STORAGE } from '../../constants/storage.js'
import { getFromStorage } from '../../utils/localStorageUtils';
import LoadingSvg from "../svg/loading.js";

function SelectedReservation({ selectedBooking, onClick, history, setAlert, match, shop, addToShoppingCart }) {
  const TEXTS = Translator(match.params.language, "TXT_BOOKING_UNITE");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reservation, setReservation] = useState({});
  const [termeEtCondition, setTermeEtCondition] = useState('');
  const [termeEtConditionText, setTermeEtConditionText] = useState('');
  console.log(reservation)

  useEffect(async () => {
    if (selectedBooking?.idReservationEntete) {
      try {
        const _reservation = await getReservationClientInfo(selectedBooking.idReservationEntete);
        await Promise.all(
          _reservation.reservationDetailSommaires
            .map(async (el) => {
              const client = await getClientName(el.idClient);
              _reservation.nom = client.prenom + ' ' + client.nom;
              el.client = client;

              if (el.typeAuxiliaire == 'UN') {
                const typeAuxiliaire = await getProductById(el.idAuxiliaire);
                // "UN" = unite,
                _reservation.extraFraisReservation = typeAuxiliaire;
              } else if (el.typeAuxiliaire == 'PR') {
                // "PR" = catalogue
                const typeAuxiliaire = await getProductById(el.idAuxiliaire);
                _reservation.extraFraisReservation = typeAuxiliaire;
              }
              return el;
            }));

        if (_reservation?.reservationDetailSommaires?.[0]?.reservationDetails?.[0]?.idPlanEntete) {
          const _plan = await getPlanEnteteById(_reservation.reservationDetailSommaires[0].reservationDetails[0].idPlanEntete);
          if (_plan?.[0])
            _reservation.planEntete = _plan[0];
        }

        if (selectedBooking.solde && !shop?.cart?.some(e => e.name == selectedBooking.noConfirmation)) {
          const fraisAdmin = _reservation?.reservationDetailSommaires?.[1]?.reservationDetails?.[0].prixParJourAdultes ?
            _reservation.reservationDetailSommaires[1].reservationDetails[0].prixParJourAdultes : 0
          const montant = fraisAdmin ? _reservation.sousTotal - fraisAdmin : _reservation.sousTotal;
          const hebergement = _reservation.taxeHebergement || 0;
          const item = {
            name: selectedBooking.noConfirmation,
            type: 'Reservation',
            // montantDepots: 270.62,
            // montant: montant,
            montant: montant + hebergement + fraisAdmin,
            fraisAdmin: fraisAdmin,
            hebergement: hebergement,
            montantDepotRecu: selectedBooking.montantDepotRecu || 0
          }
          addToShoppingCart(item);
        }
        setReservation(_reservation);
        setLoading(false);
        // const promiseClient = _reservation.reservationDetailSommaires
        //   .map(el =>
        //     getClient(el.idClient)
        //       .then(client => el.client = client)
        //   );
        // await Promise.all(promiseClient);

      } catch (error) {
        console.log(error);
      }
    }
    //ReadGestionByIdReservationEntete

    if (selectedBooking.idTermeEtCondition) {
      const terme = await getTermeEtCondition('58d0c200-056f-ef11-b4aa-10f60a435e44');
      // '092c6e79-9ab7-ed11-b471-82c5f260a79d'
      if (terme) {
        setTermeEtCondition(terme);
        if (match.params.language == 'fr')
          setTermeEtConditionText(terme.texteFr)
        else
          setTermeEtConditionText(terme.texteEn)
      }
    }
    await setLoading(false);
  }, []);

  // useEffect(async () => {
  //   if (selectedBooking.idTermeEtCondition && termeEtCondition) {
  //     if (match.params.language == 'fr')
  //       setTermeEtConditionText(termeEtCondition.texteFr)
  //     else
  //       setTermeEtConditionText(termeEtCondition.texteEn)
  //   }
  // }, [match.params.language]);

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  // const thumbnail = selectedBooking?.produitImage?.length === 0
  //   ? require("../../../images/no-image.png")
  //   : "data:image/png;base64," + selectedBooking.produitImage[0].image;

  const paiement = () => {
    if (selectedBooking.idTermeEtCondition && !termsAccepted) {
      setAlert(TEXTS.warning, "danger");
      return;
    }
    if (selectedBooking.depositDue) {
      setAlert(TEXTS.alertPaiement, "success");
      history.push(PATH_PAIEMENT_COMPTES, { data: 'test' });
    } else {
      history.push(PATH_PANIER);
    }
  }

  const isLoading = loading || (selectedBooking.idTermeEtCondition && !termsAccepted);
  console.log(match)
  return <>
    <div className="achat-forfait-card-container"
      style={{ width: 'auto' }}
    >
      <div className="card-sub-container">
        <div className="card-title achat-forfait-tab">
          <span>{TEXTS.reservation} #{selectedBooking.noConfirmation}</span>
        </div>
        <div style={{
          margin: 'auto', textAlign: 'center', margin: 'auto',
          justifyContent: 'center'
        }}>
          <h2>{TEXTS.title1}</h2>
          {/* <p>{`Booking from ${selectedBooking.startDate} to ${selectedBooking.endDate}`}</p> */}

          <table className='centered-middle reservation-unite-table'>
            <tbody>
              <tr>
                <th style={{ textAlign: 'left' }}>Description</th>
                <th style={{ textAlign: 'center' }}>Quantité</th>
                <th style={{ textAlign: 'right' }}>Prix</th>
                <th style={{ textAlign: 'center' }}>Catégorie d'âge</th>
                <th style={{ textAlign: 'right' }}>Sous-total</th>
              </tr>
              {loading
                ? <div style={{
                  margin: 'auto',
                  width: '0%'
                }}>
                  <LoadingSvg />
                </div>
                : <>
                  <tr>
                    {match?.params?.language &&
                      <td>
                        {
                          match.params.language == 'fr'
                            ? reservation.planEntete.descriptionFrancaise
                            : reservation.planEntete.descriptionAnglaise}
                      </td>
                    }
                    {reservation?.reservationDetailSommaires?.[0].reservationDetails?.[0] &&
                      <>
                        <td style={{ textAlign: 'center' }}>
                          {reservation.reservationDetailSommaires?.[0].reservationDetails[0].quantite},00
                        </td>
                        <td style={{ textAlign: 'right' }}>
                          {reservation.reservationDetailSommaires?.[0].reservationDetails[0].prixParJourAdultes},00
                        </td>
                        {reservation.reservationDetailSommaires?.[0].reservationDetails?.[0].
                          reservationAges.map(e => {
                            if (e.nombrePersonnes > 0)
                              return <>
                                <td style={{ textAlign: 'center' }}>
                                  {e.description}
                                </td>
                              </>
                          })}
                        <td style={{ textAlign: 'right' }}>
                          {reservation.reservationDetailSommaires?.[0].montant},00
                        </td>
                      </>
                    }
                  </tr>
                  <tr>
                    <td>{reservation?.nom}</td>
                    {reservation?.reservationDetailSommaires?.length &&
                      reservation.reservationDetailSommaires.map(e => {
                        if (e?.typeAuxiliaire == "UN")
                          return <Fragment key={e.idReservationDetailSommaire}>
                            <td colSpan='2' style={{ textAlign: 'left' }}>
                              <b>Nb jours/nuits:</b>
                              {e.reservationDetails[0].nombreJours},00
                            </td>
                            <td style={{ textAlign: 'center' }}><b>Nb pers.:</b> {selectedBooking.nombreAdultes + selectedBooking.nombreEnfants}</td>
                            <td style={{ textAlign: 'right' }}>
                              {selectedBooking.dateDebut
                                ? <>
                                  <b>Du :</b> {moment(selectedBooking.dateDebut).format("D MMM YYYY HH:mm")}
                                  <br />
                                  <b>Au :</b> {moment(selectedBooking.dateFin).format("D MMM YYYY HH:mm")}
                                </>
                                : ""}
                            </td>
                          </Fragment>
                        return <></>
                      })}
                  </tr>
                  {reservation?.reservationDetailSommaires?.[1]?.reservationDetails?.[0] &&
                    <tr>
                      <td>Frais de réservation (non remboursable)</td>
                      <td style={{ textAlign: 'center' }}>
                        {reservation?.reservationDetailSommaires?.[1]?.reservationDetails?.[0].quantite},00</td>
                      <td style={{ textAlign: 'right' }}>
                        {reservation?.reservationDetailSommaires?.[1]?.reservationDetails?.[0].prixParJourAdultes},00</td>
                      <td style={{ textAlign: 'right' }} colSpan='2'>
                        {reservation?.reservationDetailSommaires?.[1]?.reservationDetails?.[0].prixParJourAdultes},00</td>
                    </tr>
                  }
                  {/* {reservation?.extraFraisReservation &&
                  <tr>
                    <td>Frais de réservation (non remboursable)</td>
                    <td style={{ textAlign: 'center' }}>1,00</td>
                    <td style={{ textAlign: 'right' }}>5,95</td>
                    <td style={{ textAlign: 'right' }} colSpan='2'>5,95</td>
                  </tr>
                } */}

                  {/* idTypePaiement
: 
"b485a2c1-787a-ea11-aa67-98af6565a339 controller typePaiement*/}
                  {reservation?.paiements && reservation.paiements.map((el) =>
                    <tr>
                      <td>Paiement - Visa fait le {moment(el.datePaiement).format("D MMM YYYY")}</td>
                      <td style={{ textAlign: 'right' }} colSpan='4'>{el.montant} $</td>
                    </tr>
                  )}
                </>
              }
            </tbody>
          </table>

          {termeEtConditionText && (
            <div style={{ textAlign: 'left', margin: 'auto', padding: '25px' }}>
              {/* <h2>{selectedBooking.idTermeEtCondition}</h2> */}
              <div style={{ maxWidth: '75%' }} className='centered-left'>
                {termeEtConditionText?.split("\n").map((item, key) => (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                ))}
              </div>
              <br />
              <div className="input-container centered-middle">
                <label id="reservation-unite-checkbox-terme-et-condition"
                >
                  <input type="checkbox"
                    checked={termsAccepted}
                    onChange={handleTermsChange}
                    // onKeyDown={this.onLoginKeyDown}
                    className="profile-input"
                  />
                  {TEXTS.conditions}
                </label>
                {/* {this.state.errors.password && <span className="color-error profile-span">{this.state.errors.password}</span>} */}
              </div>
            </div>
          )}

          <div style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
            <button className="achat-forfait-card-buyable-list-item-montant button-achat-billet"
              style={{
                cursor: 'pointer', height: '51px',
                flex: '1',
                textAlign: 'center',
                whiteSpace: 'nowrap'

              }}
              onClick={onClick}
            >
              <span style={{ fontSize: '20px' }}> ↵ </span>
              {TEXTS.retour}
              {/* {viewAll && <span style={{ fontSize: '20px' }}> ✔</span>} */}
            </button>
            {selectedBooking.solde > 0 &&
              <button
                className={`achat-forfait-card-buyable-list-item-montant button-achat-billet ${isLoading && 'disabled'}`}
                style={{
                  cursor: 'pointer', height: '50px',
                  flex: '1',
                  textAlign: 'center',
                  whiteSpace: 'nowrap'
                }}
                onClick={paiement}
                disabled={isLoading}
              >{TEXTS.paiement}</button>
            }
          </div>
        </div>
      </div>
    </div>
    <div className="sommaire-right-panel max-width sommaire-large-screen">
      <SommaireRightPanel
        message={TEXTS.title1}
        showSousTotalAvantFrais={true}
        uniteIndividuelle={selectedBooking.noConfirmation}
        // confirm={() => props.goTo("/panier", "PANIER")}
        // confirmTitle={TEXTS.confirmTitle}
        // confirmError={TEXTS.confirmError}
        // showByType={CartItemType.Billetterie}
        // canConfirm={props.shop.cart.length > 0}
        showConfirm={false}
      />
    </div>
  </>
}

const mapStateToProps = (state) => ({
  shop: state.shop
});

export default withNavigation(connect(mapStateToProps, { setAlert, addToShoppingCart })(SelectedReservation));