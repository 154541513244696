import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import "./buttonAddToCart.css";
import { addToShoppingCart } from "../actions/shop";
import PlusSvg from "../svg/plus";
import { useDispatch } from 'react-redux';

function ButtonAddToCart(props) {

  const dispatch = useDispatch();

  const addToCart = (item) => {
    const newItem = {};
    newItem["name"] = item.name;
    newItem["thumbnail"] = item.thumbnail;
    newItem["montant"] = item.montant;
    newItem["type"] = "Commande restaurant";
    newItem["montantOriginal"] = item.montant;
    dispatch(addToShoppingCart(newItem));
  }

  return (
    <button className={props.classButton}
      onClick={() =>
        props.handleClick ?
          props.handleClick() :
          addToCart(props.item)}
    >
      {props.message &&
        <p className="item-popup-font font-size-15">
          {props.message}
        </p>
      }
      <PlusSvg fill="white" className={props.classSvg} />
    </button>
  );
}

export default ButtonAddToCart;