import React, { useState, createContext, useEffect } from 'react';

//component
import StepEvenement1 from '../components/evenements/etape-1';
import StepEvenement2 from '../components/evenements/etape-2';

//api
import { getEvenementList, getDetailsEvenementById } from '../server/services/evenement-service';

//date
import {
    isSameDay,
  } from "date-fns";
import PlanDeSalle from '../components/evenements/Plans-de-salle';

export const EvenementContext = createContext();

export const EvenementPovider = ({ children }) => {

    const [currentStep, setCurrentStep] = useState(0);
    const [visitedSteps, setVisitedSteps] = useState([0]);
    const [listEvenemt, setListEvenement] = useState([]);
    const [idEvenement, setIdEvenement] = useState(null);
    const [clickDates, setClikDates] =useState([])
    const [detailsEvenement, setDetailsEvenement] = useState()

    useEffect(() => {
        if (currentStep == 0) {
            try {
                getEvenementList()
                    .then((result) => {
                        if (result && result != undefined && result != null) {
                            setListEvenement(result)
                        }
                    })
                    .catch((err) => {
                        console.log('error', err)
                    })
            } catch (error) {
                console.log('error', error)
            }
        }

    }, [currentStep])

    useEffect(() => {
        if (currentStep === 1 && idEvenement !== null) {
            try {
                getDetailsEvenementById(idEvenement)
                    .then((result) => {
                        if (result) {
                            setDetailsEvenement(result);
                        }
                    })
                    .catch((err) => {
                        console.log('error', err)
                    })
            } catch (error) {
                console.log('error', error)
            }

        }
    }, [idEvenement])

    const steps = [
        { label: 'Choix', content: <StepEvenement1 /> },
        { label: 'Details ', content: <div><StepEvenement2 /></div> },
        { label: 'Step 3', content: <PlanDeSalle/> },
    ]

    const handleNext = (idEvent) => {
        if (currentStep === 0 && idEvent !== undefined && idEvent !== null) {
            setIdEvenement(idEvent)

        }
        if (currentStep < steps.length - 1) {
            setCurrentStep(prev => {
                const newStep = Math.min(prev + 1, steps.length - 1);
                if (!visitedSteps.includes(newStep)) {
                    setVisitedSteps([...visitedSteps, newStep]);
                }
                return newStep;
            });
        }
    };

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(prev => Math.max(prev - 1, 0));
        }
    };

    const goToStep = (index) => {
        if (visitedSteps.includes(index)) {
            setCurrentStep(index);
        }
    };

    const handleSelectDate = (date) => {
        setClikDates((prevDates) => {
          const dateExists = prevDates.some((clickDate) => isSameDay(clickDate, date));
    
          if(dateExists) {
            // si la date existe deja , la reitrer
            return prevDates.filter((clickDate) => !isSameDay(clickDate, date))
          } else {
            //sinn=on, l'ajouter
            return[...prevDates, date];
          }
        }) 
      }

    const contextData = {
        //data
        steps,
        clickDates,
        listEvenemt,
        currentStep,
        visitedSteps,
        detailsEvenement,

        //method
        goToStep,
        handleNext,
        setClikDates,
        handlePrevious,
        setCurrentStep,
        handleSelectDate,
    }

    return (
        <EvenementContext.Provider value={contextData}>
            {children}
        </EvenementContext.Provider>
    )
}
