import React, {useContext, useState} from 'react';

import {connect} from "react-redux";

//css
import "../../../css/choix-unite-liste.css";
import "../../../css/unite-card.css";

function cap(value, min, max) {
    if (value < min) return min;

    if (value > max) return max;

    return value;
}

const ActiviteList = (props) => {
    const [thumbnailIndex, setThumbnailIndex] = useState(0);
    const {images, thumbnail, classModifier, nbreDisponible, categotieUniteActivte, descriptionUniteACtivite} = props

    const rotateThumbnail = (change) => {
        let nextIndex = cap(thumbnailIndex + change, 0, (images || []).length - 1);
        setThumbnailIndex(nextIndex);
    }
    return (<>
        {/* Images */ }
            <div id="consulation-unite-thumbnail-wrapper" >
                { images.length > 1 && 0 !== thumbnailIndex ? <div id="consulation-unite-thumbnail-arrow-left" className={ "consulation-unite-thumbnail-arrow has-another-thumbnail" } onClick={ (e) => rotateThumbnail(-1) }>
                    <div id="consulation-unite-thumbnail-arrow-left-top" className={ "consulation-unite-thumbnail-arrow-part has-another-thumbnail" }/>
                    <div id="consulation-unite-thumbnail-arrow-left-bottom" className={ "consulation-unite-thumbnail-arrow-part has-another-thumbnail" }/>
                </div> : null }
                <div className={ "unite-card-thumbnail" + classModifier } style={{height:"296px"}}>
                    <img className={ images[thumbnailIndex] ? "" : "loading" } src={ images[thumbnailIndex] ? `data:image/jpeg;base64,${ images[thumbnailIndex] }` : require("../../../images/no-image.png") } alt="thumbnail"/>
                </div>
                { images.length > 1 && thumbnailIndex < images.length - 1 ? <div id="consulation-unite-thumbnail-arrow-right" className={ "consulation-unite-thumbnail-arrow has-another-thumbnail" } onClick={ (e) => rotateThumbnail(1) }>
                    <div id="consulation-unite-thumbnail-arrow-right-top" className={ "consulation-unite-thumbnail-arrow-part has-another-thumbnail" }/>
                    <div id="consulation-unite-thumbnail-arrow-right-bottom" className={ "consulation-unite-thumbnail-arrow-part has-another-thumbnail" }/>
                </div> : null }
            </div>
            {/* content */ }
            <div className={ "unite-card-info " + classModifier }>
                { nbreDisponible && (<div className={ `nbre-activite ${ nbreDisponible > 0 ? "nbre-activite-disponible" : "nbre-activite-non-disponible" }` }>
                    { nbreDisponible } Disponible{ nbreDisponible > 1 ? 's' : '' }
                </div>) }
                {/* title */ }
                <div className="unite-card-info-nom">
                    <span>{ categotieUniteActivte }</span>
                </div>
                {/* description */ }
                <div className={ "unite-card-info-description" + classModifier }>
                    <span>
                        { descriptionUniteACtivite }
                        <br/>
                    </span>
                </div>
            </div>
    </>)
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps)(ActiviteList)