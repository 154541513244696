import React, {Component} from "react";
import "./sommaire-item-options.css";
import OptionsPopup from "./options-popup/options-popup.js";
import withNavigation from "../higher-order/navigation";
import {CartItemType} from "../actions/shop";

function SommaireItemOptions(props) {
    let modifyOptions;
    if (props.item.type === CartItemType.ReservationActivite) {
        modifyOptions =
            <div className="sommaire-options-modify-div"
                 onClick={() => props.gotoActiviteReservationModification(props.item)}
            >
                <p className="sommaire-options-font sommaire-options-modify-font">
                    Modifier
                </p>
            </div>
    } else if (props.item.type === CartItemType.CommandeRestaurant) {
        modifyOptions =
            <div className="option-container-flex">
                <OptionsPopup
                    isModifying={true}
                    classButton="buttonAddToCart"
                    index={props.index}
                    item={props.item}
                    content={
                        <div className="sommaire-options-modify-div">
                            <p className="sommaire-options-font sommaire-options-modify-font">
                                Modifier
                            </p>
                        </div>
                    }
                />
            </div>
    }

    return (
        <div className="sommaire-item-options-div">
            {
                props.item.inclus.map((option, index) => {
                    return (
                        <div className="options-inclus" key={index}>
                            <p className="sommaire-options-font sommaire-options-item">
                                {option.name}
                                {option.textVal && `: ${option.textVal}` || option.value && `: ${option.value}`}{option.montant && <span > = ${option.montant.toFixed(2).toString()}</span>}
                            </p>
                            {/*{option.montant &&
                <div className="sommaire-options-flex">
                  <p className="sommaire-options-font sommaire-options-price-font">
                    +{option.montant.toFixed(2).toString()}&nbsp;$&nbsp;CA
                  </p>
                </div>
              }*/}
                        </div>
                    )
                })
            }
            {
                props.item.options && modifyOptions
            }
        </div>
    );
}

export default withNavigation(SommaireItemOptions);