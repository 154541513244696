import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import "./recherche-page.css";
import SearchSvg from "../svg/search.js";
import { setSeoTitle } from "../../utils/document";
import Map from "./googleMap.js";

function mapStateToProps(state) {
  return {
    entrepriseName: state.entreprise.nom
  };
}

const regions = ['Bas-St-Laurent', 'Saguenay-Lac-Saint-Jean', 'Capitale-Nationale', 'Mauricie', 'Estrie', 'Montréal', 'Outaouais', 'Abitibi-Témiscamingue', 'Côte-Nord', 'Nord-du-Quebec', 'Gaspésie-Îles-de-la-Madeleine', 'Chaudière-Appalaches', 'Laval', 'Lanaudière', 'Laurentides']

class RecherchePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRegion: null
    };
  }

  componentDidMount() {
    setSeoTitle(this.props.entrepriseName, "Recherche");
  }

  onChange(e) {
    this.setState({ selectedRegion: e.target.value });
  }

  render() {
    return (
      <Fragment>
        <div className="container-boutique nopadding">
          <div className="rechercheContainer">
            <h2 style={{ display: 'inline-block' }}>Rechercher une pouvoirie  <SearchSvg width='24px' height='24px' /></h2>

            <p>Découvrez les plus belles pourvoiries de la Côte-Nord</p>
            <p>Découvrez nos différentes pourvoiries toutes situées sur le territoire de la Côte-Nord : De la région de Manicouagan, incluant l’île d’Anticosti, jusqu’au Golfe-de-Saint-Laurent. En plein cœur d’une nature sauvage exceptionnelle, chacune de nos 26 pourvoiries, vous fera vivre des aventures inégalées.</p>
            <p>Mordus de pêche, de chasse, de nature ou pour répondre à un besoin d’évasion, vous serez charmé par chacune de nos pourvoiries, administrées par de véritables passionnés, ayant à cœur de vous faire vivre une aventure plus Grande que Nature.</p>

            <p>
              Sélectionner une région:
              <select onChange={(e) => this.onChange(e)}>
                <option value=''>
                  TOUTES LES RÉGIONS
                </option>
                {regions.map(r => (
                  <option key={r} value={r}>
                    {r.toUpperCase()}
                  </option>
                ))}
              </select>
            </p>
          </div>

          <Map
            selectedRegion={this.state.selectedRegion}
            googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCryfx5ctNT63vw5f_27nox4UvCqRm2msM"}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `600px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
          {/* <div id="achat-forfait-page">
                  <CardContainer onClick={() => { }} showSmallCardTitle={false} cards={this.state.items} />
                  <SmallContainer onClick={() => { }} showSmallCardTitle={false} cards={this.state.items} />
                </div> */}
          </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(RecherchePage);