import React from "react";

function BlockedSvg(props) {
  let { dispatch, fill, ...otherProps } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="#ff674c" width="40px" height="40px" viewBox="0 0 32 32">
      <g id="Group_30" data-name="Group 30" transform="translate(-310.001 -321.695)">
        <path id="Path_364" data-name="Path 364" d="M326,321.7a16,16,0,1,0,16,16A16,16,0,0,0,326,321.7Zm0,28a12,12,0,1,1,12-12A12,12,0,0,1,326,349.7Z" />
        <rect id="Rectangle_41" data-name="Rectangle 41" width="28.969" height="4" transform="translate(314.348 346.523) rotate(-45.001)" />
      </g>
      <script xmlns="" /></svg>
  );
}

export default BlockedSvg;