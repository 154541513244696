export const typesPermisPeche = [
  {id: -1, descriptionFr: 'Aucun', descriptionEn: 'None'},
  {id: 0, descriptionFr: 'Annuel pour un pêcheur de moins de 65 ans (sauf saumon atlantique)', descriptionEn: 'Annual, fishers under 65 years of age (except for Atlantic salmon)'},
  {id: 1, descriptionFr: 'Annuel pour un pêcheur de 65 ans et plus (sauf saumon atlantique)', descriptionEn: 'Annual, fishers 65 years of age or over (except for Atlantic salmon)'},
  {id: 2, descriptionFr: '7 jours consécutifs (sauf saumon atlantique)', descriptionEn: '7 consecutive days (except for Atlantic salmon)'},
  {id: 3, descriptionFr: '3 jours consécutifs (sauf saumon atlantique)', descriptionEn: '3 consecutive days (except for Atlantic salmon)'},
  {id: 4, descriptionFr: '1 jour (sauf saumon atlantique)', descriptionEn: '1 day (except for Atlantic salmon)'},
  {id: 5, descriptionFr: 'Remise à l’eau obligatoire (sauf saumon atlantique)', descriptionEn: 'Mandatory release (except for Atlantic salmon)'},
  {id: 6, descriptionFr: 'Annuel (saumon atlantique)', descriptionEn: 'Annual (Atlantic salmon)'},
  {id: 7, descriptionFr: '3 jours consécutifs (saumon atlantique)', descriptionEn: '3 consecutive days (Atlantic salmon)'},
  {id: 8, descriptionFr: 'Remise à l’eau obligatoire (saumon atlantique)', descriptionEn: 'Mandatory release (Atlantic salmon)'},
  {id: 9, descriptionFr: 'Pêche à la lotte au lac Saint-Jean', descriptionEn: 'Fishing for burbot in Lac Saint-Jean'},
];

export const typesPermisPecheByIds = typesPermisPeche.reduce((typesPermisPecheByIds, typePermisPeche) => (typesPermisPecheByIds[typePermisPeche.id] = typePermisPeche, typesPermisPecheByIds), {});