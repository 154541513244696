import React from "react";
import Popup from "reactjs-popup";
import SpinInput from "../inputs/SpinInput";
import "./demander-tranche-age-billetterie.css";
import PropTypes from "prop-types";


const DemanderTrancheAgeBilleterie = (props) => {

  const onTrancheAgeChange = (event) => {
    const ta = props.trancheAges.map((trancheAge) => {
      if (trancheAge.idTrancheAge === event.target.name) return {
        ...trancheAge,
        quantite: event.target.value
      };
      return {
        ...trancheAge,
        quantite: trancheAge.quantite || 0
      };
    });
    props.onValueChange(ta);
  };

  return (
    <div>
      <Popup on="click" open={props.isOpen} onClose={props.handleClose} modal={true} closeOnDocumentClick={false} nested className="popup-tranche-age-billetterie">
        <div className="tranche-age-header">
          <h3>Veuillez préciser le nombre d'adultes et d'enfants</h3>
        </div>
        <div className="tranche-age">
          {props.trancheAges.map((trancheAge) => {
            return (
              <div className="tranche-age-row" key={trancheAge.idTrancheAge}>
                <span>{`${trancheAge.isAdulte ? "Adultes" : "Enfants"}: ${trancheAge.ageDebut}${trancheAge.ageFin >= 999 ? " et plus" : ` à ${trancheAge.ageFin}`}`}</span>
                <SpinInput min={0} max={trancheAge.isAdulte ? (props.maxAdulte || 1) * 2 : 999} name={trancheAge.idTrancheAge} value={trancheAge.quantite || 0} onChange={onTrancheAgeChange}></SpinInput>
              </div>
            );
          })}
        </div>
        <div className="tranche-age-button">
          <button
            onClick={(event) => {
              props.handleClose();
              props.onConfirmation(props.unite);
            }}
          >
            Ok
          </button>
          <button onClick={() => props.handleClose()}>Annuler</button>
        </div>
      </Popup>
    </div>
  );
};

DemanderTrancheAgeBilleterie.prototype = {
  trancheAges: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  onValueChange: PropTypes.func,
  maxAdultes: PropTypes.number
}

export default DemanderTrancheAgeBilleterie;
