import React from "react";
import withNavigation from "../higher-order/navigation.js";
import { connect } from "react-redux";
import CloseSvg from "../svg/close.js";
import "./generic.css";
import { PATH_PANIER } from "../../constants/navigation-constants.js";
import PanierIcon from "../panier/panier-icon.js";

function GenericPopup(props) {
  return (
    <div className="generic-modal">
      {props.children}
      {props.show &&
        <div className="overlay">
          <div className={"content " + props.contentClass} style={props.contentStyle}>
            <div className="generic-modal-header">
              <span className="title" style={props.titleStyle}>{props.title}</span>
              <CloseSvg fill="white" onClick={props.onCloseClick} className="close" svgClassName="override-default" width={36} height={36} style={{ float: "right" }} />
            </div>
            <div className={`body ${props.bodyClass}`}>
              {props.body}
            </div>
            <div className="generic-modal-footer" style={props.footerStyle}>
              {props.footer}
            </div>
          </div>
        </div>
      }
      <span onClick={() => props.goTo(PATH_PANIER)}>
        <PanierIcon />
      </span>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    show: ownProps.show || state.genericPopup.show,
    contentStyle: ownProps.contentStyle || state.genericPopup.contentStyle,
    title: ownProps.title || state.genericPopup.title,
    onCloseClick: ownProps.onCloseClick || state.genericPopup.onCloseClick,
    body: ownProps.body || state.genericPopup.body,
    footerStyle: ownProps.footerStyle || state.genericPopup.footerStyle,
    contentClass: ownProps.contentClass || state.genericPopup.contentClass,
    footer: ownProps.footer || state.genericPopup.footer,
    bodyClass: ownProps.bodyClass || state.genericPopup.bodyClass
  };
}

export default withNavigation(connect(mapStateToProps)(GenericPopup));