import React from 'react'

const LayoutEvenement = ({children}) => {
    return(
        <div id="billetterie-page-container">
        <div className="recherche-component billeterie-div-filter"></div>
        {children}
        </div>
    )
}

export default LayoutEvenement