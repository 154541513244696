import React from "react";
import './boutique-card.css'
import { addToShoppingCart } from '../actions/shop';
import CartSvg from "../svg/cart";
import { useDispatch } from 'react-redux';

function BoutiqueCard(props) {

  const dispatch = useDispatch();

  const addToCart = (item) => {
    if (item?.produitPrix?.length) {
      props.setSelected(item);
    } else {
      const newItem = {};
      newItem["name"] = item.description;
      if (item.img) newItem["image"] = item.img
      newItem["montant"] = item.prix;
      newItem["type"] = "boutique";
      dispatch(addToShoppingCart(newItem));
    }
  }

  if (!props.card)
    return <></>

  return <div className="ais-hits--item">
    <div className="product-thumbnail height-100 ais-hit">

      <div className="product-thumbnail__image-wrap">
        <span className="product-link">
          <picture>
            {props?.card?.img
              ?
              <img src={props.card.img}
                onClick={props.onClick || (() => { })} alt="thumbnail"
              />
              : <img src={require("../../../images/no-image.png")}
                onClick={props.onClick || (() => { })} alt="thumbnail"
              />
            }
          </picture>
        </span>
      </div>

      <div className="product-thumbnail__header">

        {/* <div className="product-thumbnail__image-wrap">
              <a className="product-link">
                <picture>
                  <img src={require('../../../images/thumbnails/camping-576x384.jpg')}
                    onClick={props.onClick || (() => { })} alt="thumbnail"
                  />
                </picture>
              </a>
            </div> */}

        <div className="product-thumbnail__information-container">
          <div className="product-thumbnail__information">
            <div className="product-thumbnail__icon">
            </div>
            <span className="product-thumbnail__title product-link">
              {props.card.codeProduit}
            </span>

            <span className="product-thumbnail__description">
              {props.card.description}
            </span>

            {/* <div className="product-thumbnail__rating">
                <div>
                  <div>
                    <span className="bv_main_container bv_hover" aria-label="3.9 out of 5 stars. 261 reviews">
                      <div className="bv_stars_component_container" aria-hidden="true">
                        <div className="bv_stars_button_container">
                          <span className="bv_stars_svg_no_wrap" aria-hidden="true">
                            <svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 25 25" style={{ width: "14px !important", height: "14px !important" }}><polygon points="" style={{ fill: "url(&quot;#bv_inline_ratings_star_filled_0_99.99_qavYoVRorA&quot;) !important" }}></polygon><path d="M24.8676481,9.0008973 C24.7082329,8.54565507 24.2825324,8.23189792 23.7931772,8.20897226 L16.1009423,8.20897226 L13.658963,0.793674161 C13.4850788,0.296529881 12.9965414,-0.0267985214 12.4623931,0.00174912135 L12.4623931,0.00174912135 C11.9394964,-0.00194214302 11.4747239,0.328465149 11.3146628,0.81767189 L8.87268352,8.23296999 L1.20486846,8.23296999 C0.689809989,8.22949161 0.230279943,8.55030885 0.0640800798,9.0294023 C-0.102119784,9.50849575 0.0623083246,10.0383495 0.472274662,10.3447701 L6.69932193,14.9763317 L4.25734261,22.4396253 C4.08483744,22.9295881 4.25922828,23.4727606 4.68662933,23.7767181 C5.11403038,24.0806756 5.69357086,24.0736812 6.11324689,23.7595003 L12.6333317,18.9599546 L19.1778362,23.7595003 C19.381674,23.9119158 19.6299003,23.9960316 19.8860103,23.9994776 C20.2758842,24.0048539 20.6439728,23.8232161 20.8724402,23.5127115 C21.1009077,23.202207 21.1610972,22.8017824 21.0337405,22.4396253 L18.5917612,14.9763317 L24.6967095,10.3207724 C25.0258477,9.95783882 25.0937839,9.43328063 24.8676481,9.0008973 Z" style={{ fill: "url(&quot;#bv_inline_ratings_star_filled_0_99.99_qavYoVRorA&quot;) !important" }}></path><defs><linearGradient id="bv_inline_ratings_star_filled_0_99.99_qavYoVRorA" x1="99.99%" y1="0%" x2="100%" y2="0%"><stop offset="0%" style={{ stopColor: "rgb(3, 127, 150)", stopOpacity: "1" }}></stop><stop offset="1%" style={{ stopColor: "rgb(217, 217, 214)", stopOpacity: "1" }}></stop></linearGradient></defs></svg>
                            <svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 25 25" style={{ width: "14px !important", height: "14px !important" }}><polygon points="" style={{ fill: "url(&quot;#bv_inline_ratings_star_filled_1_99.99_ZWltPYfYuZ&quot;) !important" }}></polygon><path d="M24.8676481,9.0008973 C24.7082329,8.54565507 24.2825324,8.23189792 23.7931772,8.20897226 L16.1009423,8.20897226 L13.658963,0.793674161 C13.4850788,0.296529881 12.9965414,-0.0267985214 12.4623931,0.00174912135 L12.4623931,0.00174912135 C11.9394964,-0.00194214302 11.4747239,0.328465149 11.3146628,0.81767189 L8.87268352,8.23296999 L1.20486846,8.23296999 C0.689809989,8.22949161 0.230279943,8.55030885 0.0640800798,9.0294023 C-0.102119784,9.50849575 0.0623083246,10.0383495 0.472274662,10.3447701 L6.69932193,14.9763317 L4.25734261,22.4396253 C4.08483744,22.9295881 4.25922828,23.4727606 4.68662933,23.7767181 C5.11403038,24.0806756 5.69357086,24.0736812 6.11324689,23.7595003 L12.6333317,18.9599546 L19.1778362,23.7595003 C19.381674,23.9119158 19.6299003,23.9960316 19.8860103,23.9994776 C20.2758842,24.0048539 20.6439728,23.8232161 20.8724402,23.5127115 C21.1009077,23.202207 21.1610972,22.8017824 21.0337405,22.4396253 L18.5917612,14.9763317 L24.6967095,10.3207724 C25.0258477,9.95783882 25.0937839,9.43328063 24.8676481,9.0008973 Z" style={{ fill: "url(&quot;#bv_inline_ratings_star_filled_1_99.99_ZWltPYfYuZ&quot;) !important" }}></path><defs><linearGradient id="bv_inline_ratings_star_filled_1_99.99_ZWltPYfYuZ" x1="99.99%" y1="0%" x2="100%" y2="0%"><stop offset="0%" style={{ stopColor: "rgb(3, 127, 150)", stopOpacity: "1" }}></stop><stop offset="1%" style={{ stopColor: "rgb(217, 217, 214)", stopOpacity: "1" }}></stop></linearGradient></defs></svg>
                            <svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 25 25" style={{ width: "14px !important", height: "14px !important" }}><polygon points="" style={{ fill: "url(&quot;#bv_inline_ratings_star_filled_2_99.99_byS6qHR6rD&quot;) !important" }}></polygon><path d="M24.8676481,9.0008973 C24.7082329,8.54565507 24.2825324,8.23189792 23.7931772,8.20897226 L16.1009423,8.20897226 L13.658963,0.793674161 C13.4850788,0.296529881 12.9965414,-0.0267985214 12.4623931,0.00174912135 L12.4623931,0.00174912135 C11.9394964,-0.00194214302 11.4747239,0.328465149 11.3146628,0.81767189 L8.87268352,8.23296999 L1.20486846,8.23296999 C0.689809989,8.22949161 0.230279943,8.55030885 0.0640800798,9.0294023 C-0.102119784,9.50849575 0.0623083246,10.0383495 0.472274662,10.3447701 L6.69932193,14.9763317 L4.25734261,22.4396253 C4.08483744,22.9295881 4.25922828,23.4727606 4.68662933,23.7767181 C5.11403038,24.0806756 5.69357086,24.0736812 6.11324689,23.7595003 L12.6333317,18.9599546 L19.1778362,23.7595003 C19.381674,23.9119158 19.6299003,23.9960316 19.8860103,23.9994776 C20.2758842,24.0048539 20.6439728,23.8232161 20.8724402,23.5127115 C21.1009077,23.202207 21.1610972,22.8017824 21.0337405,22.4396253 L18.5917612,14.9763317 L24.6967095,10.3207724 C25.0258477,9.95783882 25.0937839,9.43328063 24.8676481,9.0008973 Z" style={{ fill: "url(&quot;#bv_inline_ratings_star_filled_2_99.99_byS6qHR6rD&quot;) !important" }}></path><defs><linearGradient id="bv_inline_ratings_star_filled_2_99.99_byS6qHR6rD" x1="99.99%" y1="0%" x2="100%" y2="0%"><stop offset="0%" style={{ stopColor: "rgb(3, 127, 150)", stopOpacity: "1" }}></stop><stop offset="1%" style={{ stopColor: "rgb(217, 217, 214)", stopOpacity: "1" }}></stop></linearGradient></defs></svg>
                            <svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 25 25" style={{ width: "14px !important", height: "14px !important" }}><polygon points="" style={{ fill: "url(&quot;#bv_inline_ratings_star_filled_3_89.66_hSoCsFzxmn&quot;) !important" }}></polygon><path d="M24.8676481,9.0008973 C24.7082329,8.54565507 24.2825324,8.23189792 23.7931772,8.20897226 L16.1009423,8.20897226 L13.658963,0.793674161 C13.4850788,0.296529881 12.9965414,-0.0267985214 12.4623931,0.00174912135 L12.4623931,0.00174912135 C11.9394964,-0.00194214302 11.4747239,0.328465149 11.3146628,0.81767189 L8.87268352,8.23296999 L1.20486846,8.23296999 C0.689809989,8.22949161 0.230279943,8.55030885 0.0640800798,9.0294023 C-0.102119784,9.50849575 0.0623083246,10.0383495 0.472274662,10.3447701 L6.69932193,14.9763317 L4.25734261,22.4396253 C4.08483744,22.9295881 4.25922828,23.4727606 4.68662933,23.7767181 C5.11403038,24.0806756 5.69357086,24.0736812 6.11324689,23.7595003 L12.6333317,18.9599546 L19.1778362,23.7595003 C19.381674,23.9119158 19.6299003,23.9960316 19.8860103,23.9994776 C20.2758842,24.0048539 20.6439728,23.8232161 20.8724402,23.5127115 C21.1009077,23.202207 21.1610972,22.8017824 21.0337405,22.4396253 L18.5917612,14.9763317 L24.6967095,10.3207724 C25.0258477,9.95783882 25.0937839,9.43328063 24.8676481,9.0008973 Z" style={{ fill: "url(&quot;#bv_inline_ratings_star_filled_3_89.66_hSoCsFzxmn&quot;) !important" }}></path><defs><linearGradient id="bv_inline_ratings_star_filled_3_89.66_hSoCsFzxmn" x1="89.66%" y1="0%" x2="100%" y2="0%"><stop offset="0%" style={{ stopColor: "rgb(3, 127, 150)", stopOpacity: "1" }}></stop><stop offset="1%" style={{ stopColor: "rgb(217, 217, 214)", stopOpacity: "1" }}></stop></linearGradient></defs></svg>
                            <svg focusable="false" xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 25 25" style={{ width: "14px !important", height: "14px !important" }}><polygon points="" style={{ fill: "url(&quot;#bv_inline_ratings_star_filled_4_0.00_3R0YEYysTt&quot;) !important" }}></polygon><path d="M24.8676481,9.0008973 C24.7082329,8.54565507 24.2825324,8.23189792 23.7931772,8.20897226 L16.1009423,8.20897226 L13.658963,0.793674161 C13.4850788,0.296529881 12.9965414,-0.0267985214 12.4623931,0.00174912135 L12.4623931,0.00174912135 C11.9394964,-0.00194214302 11.4747239,0.328465149 11.3146628,0.81767189 L8.87268352,8.23296999 L1.20486846,8.23296999 C0.689809989,8.22949161 0.230279943,8.55030885 0.0640800798,9.0294023 C-0.102119784,9.50849575 0.0623083246,10.0383495 0.472274662,10.3447701 L6.69932193,14.9763317 L4.25734261,22.4396253 C4.08483744,22.9295881 4.25922828,23.4727606 4.68662933,23.7767181 C5.11403038,24.0806756 5.69357086,24.0736812 6.11324689,23.7595003 L12.6333317,18.9599546 L19.1778362,23.7595003 C19.381674,23.9119158 19.6299003,23.9960316 19.8860103,23.9994776 C20.2758842,24.0048539 20.6439728,23.8232161 20.8724402,23.5127115 C21.1009077,23.202207 21.1610972,22.8017824 21.0337405,22.4396253 L18.5917612,14.9763317 L24.6967095,10.3207724 C25.0258477,9.95783882 25.0937839,9.43328063 24.8676481,9.0008973 Z" style={{ fill: "url(&quot;#bv_inline_ratings_star_filled_4_0.00_3R0YEYysTt&quot;) !important" }}></path><defs><linearGradient id="bv_inline_ratings_star_filled_4_0.00_3R0YEYysTt" x1="0.00%" y1="0%" x2="100%" y2="0%"><stop offset="0%" style={{ stopColor: "rgb(3, 127, 150)", stopOpacity: "1" }}></stop><stop offset="1%" style={{ stopColor: "rgb(217, 217, 214)", stopOpacity: "1" }}></stop></linearGradient></defs></svg>
                          </span>
                        </div>
                      </div>
                      <div className="bv_averageRating_component_container" aria-hidden="true"><meta itemProp="ratingValue" content="3.9" />
                      </div>
                      <div className="bv_numReviews_component_container" aria-hidden="true"><meta itemProp="reviewCount" content="261" /><div className="bv_text">(261)
                      </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <ul className="product-thumbnail__features">
                <li> Standard toner cartridge yields up to 1200 pages </li>
                <li> The package includes one black toner cartridge </li>
                <li> Brother's technology is the guarantee of continuously accurate printing in any situation </li>
              </ul> */}
          </div>
        </div>
      </div>
      <div className="product-thumbnail__footer">
        <div className="product-thumbnail__data-wrap marginbottom-1rem">
          <div className="product-thumbnail__price">
            <div className="money-details">
              <div className="top-product">
              </div>
            </div>
            <span className="money pre-money">
              {props.card?.prix &&
                props.card.prix.toFixed(2).toString().padStart(5, '0')} $
            </span>
            <div className="money-details">
            </div>
          </div>
          <div className="product-thumbnail__packsize">
            <span>
            </span>
          </div>
          <div className="product-thumbnail__amount">
            <span className="product-thumbnail__amount-data">
            </span>
          </div>
        </div>

        {/* <form className="product-thumbnail__form" action="#">
            <div className="d-none marginbottom-0 marginright-1 select product-quantity">
              <label htmlFor="select-element" className="select__label sr-only">Select inventory quantity
              </label>
              <input aria-label="Select inventory quantity"
                className="input__element input__element--product-quantity product-quantity__input"
                type="number" name="quantity" min="1" value="1"
              />
              <div className="product-quantity__dropdown">
                <select className="select__element product-quantity__list" id="select-element" name="quantity-selector">
                  <option value="0" className="product-quantity__dropdown-option"> 0
                  </option><option value="1" className="product-quantity__dropdown-option"> 1
                  </option><option value="2" className="product-quantity__dropdown-option"> 2
                  </option><option value="3" className="product-quantity__dropdown-option"> 3
                  </option><option value="4" className="product-quantity__dropdown-option"> 4
                  </option><option value="5" className="product-quantity__dropdown-option"> 5
                  </option><option value="6" className="product-quantity__dropdown-option"> 6
                  </option><option value="7" className="product-quantity__dropdown-option"> 7
                  </option><option value="8" className="product-quantity__dropdown-option"> 8 </option>
                  <option value="9" className="product-quantity__dropdown-option"> 9 </option>
                </select>
              </div>
            </div>
            <button className="button add product-thumbnail__cta submit" aria-label="Add to Cart" type="button">
              Add to Cart
            </button>
          </form> */}
      </div>
      <div className="boutique-card-item unselectable">
        <span className="clickable" onClick={() => addToCart(props.card)}>AJOUTER <CartSvg fill='white' style={{ position: 'relative', top: '7px' }} width="26" height="26" /></span>
      </div>
    </div>
  </div>
}
export default BoutiqueCard;