import React, { Component, useEffect } from "react";
import Popup from 'reactjs-popup';
import { annulerPolitique } from "../../server/services/politique-service";
import PopupHeader from "../popups/popup-header.js";
import PopupFooter from "../popups/popup-footer.js"

function AnnulationPopup(props) {

  const confirm = () => {
    annulerPolitique(props.facture[0].idReservationDetail, props.facture[0].idReservationEntete).then(({ politique }) => {
      console.log(politique)
    });
  }

  console.log(props)
  return (
    <Popup
      content="test"
      on='click'
      open={props.isOpen}
      onClose={props.handleClose}
      modal
      nested
    >
      {close => (
        <div className="fixed-panel" >
          <div className="dark-container" onClick={props.handleClose}>
          </div>
          <div className="item-popup-div">
            <div className="item-popup-background" style={{ borderRadius: '0px', maxWidth: '600px', minHeight: '256px', maxHeight: '100%' }}>
              <PopupHeader
                title="CONTRAT D'ANNULATION"
                onClick={props.handleClose}
              />
              {
                props.facture &&
                <div className="annulation-popup">
                  <p className="item-popup-name">
                    Réservation: {props.facture[0].idReservationDetail}
                  </p>
                  <p className="item-popup-description">
                    Nombre d'adultes : {props.facture[0].nombreAdultes}<br/>
                    Nombre d'enfants : {props.facture[0].nombreEnfants}
                    <br/>
                    <br/>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam phasellus vestibulum lorem sed risus. Eros donec ac odio tempor orci dapibus ultrices in. Eget magna fermentum iaculis eu non diam phasellus vestibulum lorem. Etiam tempor orci eu lobortis elementum nibh tellus molestie. Enim ut tellus elementum sagittis vitae et. Eleifend donec pretium vulputate sapien nec sagittis aliquam malesuada bibendum. Congue nisi vitae suscipit tellus. Sem et tortor consequat id porta nibh venenatis. Lacus laoreet non curabitur gravida arcu. Quis lectus nulla at volutpat. Sollicitudin ac orci phasellus egestas tellus rutrum. Varius morbi enim nunc faucibus a pellentesque. Posuere sollicitudin aliquam ultrices sagittis. Phasellus egestas tellus rutrum tellus pellentesque eu tincidunt. In ornare quam viverra orci sagittis eu. Aliquam id diam maecenas ultricies mi eget mauris pharetra et.
                    Elementum curabitur vitae nunc sed velit. Semper quis lectus nulla at volutpat diam ut. Fames ac turpis egestas maecenas pharetra convallis. Quis eleifend quam adipiscing vitae. Porta lorem mollis aliquam ut porttitor leo a. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Lectus magna fringilla urna porttitor rhoncus dolor. Lobortis elementum nibh tellus molestie nunc. Erat velit scelerisque in dictum non. Aenean euismod elementum nisi quis eleifend quam. Fermentum et sollicitudin ac orci phasellus. Integer vitae justo eget magna fermentum iaculis eu non diam. Sed arcu non odio euismod lacinia at quis. Aliquet nec ullamcorper sit amet. Neque viverra justo nec ultrices dui sapien. Suspendisse interdum consectetur libero id faucibus nisl tincidunt eget. Massa eget egestas purus viverra accumsan.
                  </p>
                </div>
              }
              <PopupFooter title="CONFIRMER" onClick={() => confirm()} />
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default AnnulationPopup;