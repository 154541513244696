import {
  GET_MEMBER,
} from '../components/actions/types';

const initialState = {
  isMember: false,
  loading: true,
  error: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_MEMBER:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}