import React from "react";
import {withRouter} from "react-router-dom";
import {
    PATH_ACTIVITE_RESERVATION, PATH_CHOIX_UNITE, PATH_CREER_COMPTE, PATH_PROFILE, PATH_BILLETTERIE_UNITE, PATH_PAIEMENT_COMPTES, PATH_PAIEMENT, PATH_BILLETTERIE, PATH_CAMPS_RESERVATION
} from "../../constants/navigation-constants";

export default function withNavigation(WrappedComponent) {
    function WithNavigationNoRouter(props) {
        const BASE_URI = `/${props.match.params.idZec}/${props.match.params.language || "fr"}`; // La langue par défaut est le fr si elle n'est pas spécifiée, ex.: localhost:3000/#/cedres_test

        const appendToQueryString = (query, key, value) => {
            return query === ""
                ? "?" + key + "=" + value
                : query + "&" + key + "=" + value;
        }

        const getTypeHebergement = () => {
            const subPath = props.location.pathname.replace(/(\/[A-Za-z0-9-_]+){3}\//, ""); // Est fragile. Si brise, utiliser plutôt un paramètre (e.g. <Route path="/:idZec/:language/choix-unite/:typeHebergement">) puis obtenir typeHebergement via this.props.match.params.typeHebergement.

            return subPath.substr(0, subPath.indexOf('/'));
        }

        const getUrlExcludingInitialParameters = () => {
            let initialParamsRegex = new RegExp("/" + props.match.params.idZec + "/" + props.match.params.language + "/");

            return props.location.pathname.replace(initialParamsRegex, "/");
        }

        const goTo = path => {
            if (path === PATH_PAIEMENT) { // Pour patcher le fait qu'il peut y avoir des goTo(PATH_PAIEMENT) restant dans le site. gotoPaiement() doit maintenant être utlisé plutôt que goTo(PATH_PAIEMENT) car gotoPaiement() assigne des valeurs au state de props.history. Si ce state est undefined, la page de paiement crash.
                console.log("Utiliser gotoPaiement() plutôt que goTo(PATH_PAIEMENT). gotoPaiement() assigne un state à props.history. Ce state est par la suite lut dans la page de paiement. Si ce state est undefined dans la page de paiement, ça crash.");
                gotoPaiement();
            } else
                props.history.push(BASE_URI + path);
        }

        const gotoPaiement = ({typePaiement, sousTotal, courriel, noReservation} = {}) => {
            props.history.push({
                pathname: `${BASE_URI}${PATH_PAIEMENT}`,
                state: {sousTotal, typePaiement, courriel, noReservation}
            })
        }
        const gotoReservationCampJours = () => {
            props.history.push({
                pathname: `${BASE_URI}${PATH_CAMPS_RESERVATION}`,
                state: {}
            })
        }

        const gotoActiviteReservation = ({idTypeUnite, idUnite} = {}) => {
            // Méthode modifiée pour lire idTypeUnite et idUnite depuis des arguments plutôt que via props. Voir code commenté.
            // Si ça crash quelque part (je ne sais pas si cette méthode est utilisée ailleur que dans unite-card), modifier pour envoyer idTypeUnite et idUnite en arguments.

            if (idUnite == null)
                goTo(`${PATH_ACTIVITE_RESERVATION}/${idTypeUnite}`);
            else
                goTo(`${PATH_ACTIVITE_RESERVATION}/${idTypeUnite}/${idUnite}`);

            //if (props.idUnite == null)
            //  goTo(`${PATH_ACTIVITE_RESERVATION}/${props.idTypeUnite}`);
            //else
            //  goTo(`${PATH_ACTIVITE_RESERVATION}/${props.idTypeUnite}/${props.idUnite}`);
        }

        const gotoActiviteReservationModification = (item) => {
            props.history.push({
                pathname: `${BASE_URI}${PATH_ACTIVITE_RESERVATION}/${item.idTypeUnite}/${item.id_unite}`,
                state: {
                    index: props.index,
                    selectedDate: item.selectedDate,
                    selectedFullDate: item.selectedFullDate,
                    selectedDay: item.selectedDay,
                    selectedMonth: item.selectedMonth,
                    selectedYear: item.selectedYear,
                    selectedStartHour: item.selectedStartHour,
                    selectedEndHour: item.selectedEndHour,
                    selectedAdults: item.selectedAdults,
                    selectedChildren: item.selectedChildren,
                    selectedProducts: item.selectedProducts,
                    originIdsReservationTemp: item.idsReservationTemp,
                    idUnite: item.idUnite,
                    idTypeUnite: item.idTypeUnite,
                }
            })
        }

        const gotoBilletUnite = (item) => {
            props.history.push({
                pathname: `${BASE_URI}${PATH_BILLETTERIE_UNITE}/${item.idProduit}`,
                state: item
            })
        }
        const gotoBillet = () => {
            goTo(`${PATH_BILLETTERIE}`)
        }
        const gotoChoixUnite = (typeHebergement, idTypeUnite, idUnite) => {
            goTo(`${PATH_CHOIX_UNITE}/${typeHebergement}/unite?idTypeUnite=${idTypeUnite}&idUnite=${idUnite}`);
        }

        const gotoChoixUniteCarte = (typeHebergement, searchQueryString) => {
            goTo(`${PATH_CHOIX_UNITE}/${typeHebergement}/carte${searchQueryString}`);
        }

        const gotoChoixUniteListe = (typeHebergement, searchQueryString) => {
            goTo(`${PATH_CHOIX_UNITE}/${typeHebergement}/liste${searchQueryString}`);
        }

        const gotoCreerCompte = (redirect = null) => { // L'argument redirect est utilisé pour rediriger vers un PATH après la création du compte
            goTo(`${PATH_CREER_COMPTE}${typeof redirect === "string" ? "?redirect=" + redirect : ""}`);
        }

        const gotoProfile = (redirect = null, show = null) => { // L'argument redirect est utilisé pour rediriger vers un PATH après connexion ou l'enregistrement du profile
            let queryString = "";

            if (typeof redirect === "string")
                queryString = appendToQueryString(queryString, "redirect", redirect);

            if (typeof show === "string")
                queryString = appendToQueryString(queryString, "show", show);

            goTo(`${PATH_PROFILE}${queryString}`);
        }

        const gotoTypeHebergement = typeHebergement => {
            goTo(`${PATH_CHOIX_UNITE}/${typeHebergement}/liste`);
        }

        const gotoPaiementComptes = () => {
            goTo(PATH_PAIEMENT_COMPTES);
        }

        const setLanguageEn = () => {
            setLanguage("en"); // Code ISO 639-1
        }

        const setLanguageFr = () => {
            setLanguage("fr"); // Code ISO 639-1
        }

        const setLanguage = language => {
            let languageRegex = new RegExp("/" + props.match.params.language + "/");
            props.history.push(props.location.pathname.replace(languageRegex, "/" + language + "/")); // Code ISO 639-1
        }

        return (
            <WrappedComponent
                idZec={props.match.params.idZec}
                language={props.match.params.language}
                getTypeHebergement={getTypeHebergement}
                getUrlExcludingInitialParameters={getUrlExcludingInitialParameters}
                gotoActiviteReservation={gotoActiviteReservation}
                gotoActiviteReservationModification={gotoActiviteReservationModification}
                gotoBilletUnite={gotoBilletUnite}
                gotoBillet={gotoBillet}
                gotoChoixUnite={gotoChoixUnite}
                gotoChoixUniteCarte={gotoChoixUniteCarte}
                gotoChoixUniteListe={gotoChoixUniteListe}
                gotoCreerCompte={gotoCreerCompte}
                goTo={goTo}
                gotoProfile={gotoProfile}
                gotoTypeHebergement={gotoTypeHebergement}
                gotoPaiementComptes={gotoPaiementComptes}
                gotoPaiement={gotoPaiement}
                setLanguageEn={setLanguageEn}
                setLanguageFr={setLanguageFr}
                gotoReservationCampJours={gotoReservationCampJours}
                {...props} />
        );
    }

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Unknown WrappedComponent Name';
    WithNavigationNoRouter.displayName = `withNavigation(${wrappedComponentName})`;

    return withRouter(WithNavigationNoRouter);
}