import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import "../../../css/paiement-page.css";
import {createReservation} from "../../server/services/reservation-service";
import {purchaseForfaits, getFraisAdminReservationDue, getFraisAdminComptesDues, payComptesDues, payReservationDue} from "../../server/services/paiement-service";
import jwtDecode from "jwt-decode";
import {setAlert} from '../actions/alert';
import {clearNoDossier} from '../actions/client';
import {showOverlay, hideOverlay} from '../actions/overlay';
import {getShop, removeFromShoppingCart, CartItemType, clearPromotion} from "../actions/shop.js";
import PaiementForm from "./paiement-form";
import withNavigation from "../higher-order/navigation";
import {useSeoTitle} from "../../utils/hooks";
import {STATUS_BAD_REQUEST, STATUS_NOT_FOUND, STATUS_OK, api} from "../../server/api";
import usePageTracking from "../../utils/use-page-tracking";
import gtag from "../../utils/gtag";
import {useLocation} from "react-router-dom";
import {PATH_BILLETTERIE} from "../../constants/navigation-constants";
import {factureTirage} from "../../server/services/facturation-service";
import * as navigation from "../../constants/navigation-constants";

export const TYPE_PAIEMENT_RESERVATION_DUE = "ReservationDue";
export const TYPE_PAIEMENT_COMPTES_DUES = "ComptesDues";
export const TYPE_PAIEMENT_BILLETS = "Billets";

function PaiementPage(props) {
    useSeoTitle("Paiement");

    const paymentFormRef = useRef(null);
    const [paiementCompleted, setPaiementCompleted] = useState(false);
    const [resultMsg, setResultMsg] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [fraisAdmin, setFraisAdmin] = useState({}); // structure: {fraisAdmin, fraisAdminTps, fraisAdminTvq, total}
    const location = useLocation();
    const [invoiceFilePackage, setInvoiceFilePackage] = useState(null)
    const [transactionPackageFinished, setTransactionPackageFinished] = useState(false);
    const isAnyPaiementCompte = props.location.state.typePaiement === TYPE_PAIEMENT_RESERVATION_DUE // Si ça crash ici, s'assurer d'utiliser props.gotoPaiement() plutôt que props.goTo(PATH_PAIEMENT).
        || props.location.state.typePaiement === TYPE_PAIEMENT_COMPTES_DUES;

    const decodedToken = props.authToken == null ? null : jwtDecode(props.authToken);
    const idProfile = props.authToken == null ? null : jwtDecode(props.authToken).idProfile;
    usePageTracking();
    useEffect(() => {
        return;
        if (!isAnyPaiementCompte) return;

        const getFraisAdmin = props.location.state.typePaiement === TYPE_PAIEMENT_RESERVATION_DUE ? () => getFraisAdminReservationDue(props.location.state.sousTotal, props.location.state.courriel, props.location.state.noReservation) : props.location.state.typePaiement === TYPE_PAIEMENT_COMPTES_DUES ? () => getFraisAdminComptesDues(props.location.state.sousTotal, idProfile) : null;

        props.showOverlay("PAIEMENT_CALCUL_FRAIS_ADMIN");

        getFraisAdmin()
            .then(result => {
                if (result.status === STATUS_BAD_REQUEST) throw new Error(result.json() || "Une erreur s'est produite et les frais d'administrations n'ont pas pu être chargées.");
                if (result.status !== STATUS_OK) throw new Error("Une erreur s'est produite et les frais d'administrations n'ont pas pu être chargées.");
                return result.json();
            }).then(({
                         fraisAdmin, fraisAdminTps, fraisAdminTvq, total
                     }) => {
            setFraisAdmin({
                fraisAdmin, fraisAdminTps, fraisAdminTvq, total
            });
        }).catch(e => {
            console.log(e);
            props.setAlert(e.message, "danger");
        }).finally(() => {
            props.hideOverlay("PAIEMENT_CALCUL_FRAIS_ADMIN");
        })
    }, [props.location.state.typePaiement, props.location.state.sousTotal, props.location.state.courriel, props.location.state.noReservation, idProfile])

    useEffect(() => {
        if (submitted) props.showOverlay("SUBMITTED"); else props.hideOverlay("SUBMITTED");
    }, [submitted]);

    function handleSubmit() {
        if (submitted) return;

        setSubmitted(true);

        if (props.location.state.typePaiement === TYPE_PAIEMENT_RESERVATION_DUE) validateAndPayReservationDue(); else if (props.location.state.typePaiement === TYPE_PAIEMENT_COMPTES_DUES) validateAndPayComptesDues(); else {
            const cartHasReservations = props.shop.cart.some(item => item.type === CartItemType.ReservationHebergement || item.type === CartItemType.ReservationActivite || item.type === CartItemType.ReservationCamping);
            const cartHasProduit = props.shop.cart.some(item => item.type === CartItemType.Forfait || item.type === CartItemType.Billetterie);
            const cartHasTirage = props.shop.cart.some(item => item.type === CartItemType.Tirage);
            const cartHasCampJour = props.shop.cart.some(item => item.type === CartItemType.CampJour);
            if (cartHasReservations) {
                validateAndMakeReservation();
            } else if (cartHasTirage) {
                validateAndMakeFactureTirage();
            } else if (cartHasProduit || cartHasCampJour) {
                validateAndProcessTransactionForfaits()
            }
        }
    }

    function validateAndMakeFactureTirage() {
        const {
            isInfoClientValid, isInfoCarteValid
        } = paymentFormRef.current.validate();

        if (!isInfoCarteValid) {
            props.setAlert(`Assurez-vous que vos informations financières sont valides puis réessayez.`, 'danger');
            setSubmitted(false);
            return false;
        }

        if (props.authToken == null && !isInfoClientValid) {
            props.setAlert("Il y a des erreurs dans les champs requis. Veuillez réessayer !!", 'danger');
            setSubmitted(false);
            return false;
        }
        const currentClient = paymentFormRef.current.getInfoClient();
        const shoppingCart = {
            ...props.shop, cart: props.shop.cart.filter(item => item.type === CartItemType.Tirage)
        };

        return factureTirage({
            shop: shoppingCart, customer: {
                clientInfo: currentClient, noDossier: props.noDossier, noPermisOrignal: currentClient.noPermisOrignal, noPermisCerf: currentClient.noPermisCerf
            }, creditCard: paymentFormRef.current.getInfoCarte()
        }).then(result => {
            if (Object.keys(result).length === 0) {
                props.setAlert("Une erreur à l'interne s'est produite; Veuillez nous contacter pour continuer!", 'danger');
                return false;
            }
            if (!result.idFacturationEntete || result.idFacturationEntete === "00000000-0000-0000-0000-000000000000") {
                if (result.processError) props.setAlert(result.processError, 'danger'); else {
                    props.setAlert("Il y a des erreurs dans les champs requis. Veuillez réessayer !!", 'danger');
                    paymentFormRef.current.setErrors(result);
                }
                setSubmitted(false);
                return false;
            }
            const cartHasProduit = props.shop.cart.some(item => item.type === CartItemType.Forfait || item.type === CartItemType.Billetterie);

            gtag("set", {
                page_title: document.title + ' confirmation'
            });
            gtag("event", "page_view", {
                page_path: location.pathname + '/confirmation' + location.search,
            });
            props.clearNoDossier();
            props.clearPromotion();
            setPaiementCompleted(true);
            setResultMsg(!result.warning ? `Paiement completé avec succès. Courriel envoyé à ${currentClient.email} .` // paymentFormRef.current.getInfoClient().Email => null error
                : `Paiement completé avec des erreurs: ${result.warning}.` /*+
        "cliquer plus bas pour télécharger votre confirmation"*/);
            for (var i = props.shop.cart.length - 1; i >= 0; i--) {
                if (props.shop.cart[i].type === CartItemType.Tirage) props.removeFromShoppingCart(props.shop.cart[i], false);
            }

            setSubmitted(false);
        }).catch(e => {
            console.log(e);
            props.setAlert(e.message || "Une erreur à l'interne s'est produite; Veuillez nous contacter pour continuer!", "danger");
            setSubmitted(false);
            return false;
        });
    }

    function validateAndPayReservationDue() {
        const {isInfoCarteValid} = paymentFormRef.current.validate();

        if (!isInfoCarteValid) {
            props.setAlert(`Assurez-vous que vos informations financières sont valides puis réessayez.`, 'danger');
            setSubmitted(false);
            return false;
        }

        props.showOverlay("PAIEMENT_RESERVATION_DUE");

        payReservationDue({
            creditCard: paymentFormRef.current.getInfoCarte(), courriel: props.location.state.courriel, noConfirmation: props.location.state.noReservation, cardType: null, // Types de cartes non gérés. Seulement le paiement via Bambora fonctionne pour l'instant.
            sousTotal: props.location.state.sousTotal,
        }).then(async result => {
            if (result.status === STATUS_BAD_REQUEST) throw new Error(await result.json() || "Une erreur s'est produite et la transaction a échouée.");
            if (result.status === STATUS_NOT_FOUND) throw new Error(await result.json() || "Une erreur s'est produite et la transaction a échouée.");
            if (result.status !== STATUS_OK) throw new Error("Une erreur s'est produite et la transaction a échoué.");
            return result.json();
        }).then(processTransactionResult => {
            if (!processTransactionResult.success) throw new Error(processTransactionResult.errorMessageFr || "Une erreur s'est produite et la transaction a échouée.");
            setSubmitted(false);
            gtag("event", "page_view", {
                page_path: location.pathname + '/confirmation' + location.search,
            });
            props.setAlert(`La transaction s'est complétée avec succès.`, 'success');
            if (processTransactionResult.errorMessageFr && processTransactionResult.errorMessageFr !== "") props.setAlert(processTransactionResult.errorMessageFr, 'danger');
            props.hideOverlay("PAIEMENT_RESERVATION_DUE");
            props.gotoPaiementComptes();
        }).catch(e => {
            console.log(e);
            props.setAlert(e.message, "danger");
            props.hideOverlay("PAIEMENT_RESERVATION_DUE");
            setSubmitted(false);
        });
    }

    function validateAndPayComptesDues() {
        const {isInfoCarteValid} = paymentFormRef.current.validate();

        if (!isInfoCarteValid) {
            props.setAlert(`Assurez-vous que vos informations financières sont valides puis réessayez.`, 'danger');
            return false;
        }

        props.showOverlay("PAIEMENT_COMPTES_DUES");

        payComptesDues({
            creditCard: paymentFormRef.current.getInfoCarte(), idProfile: idProfile, cardType: null, // Types de cartes non gérés. Seulement le paiement via Bambora fonctionne pour l'instant.
            sousTotal: props.location.state.sousTotal,
        }).then(async result => {
            if (result.status === STATUS_BAD_REQUEST) throw new Error(await result.json() || "Une erreur s'est produite et la transaction a échoué.");
            if (result.status !== STATUS_OK) throw new Error("Une erreur s'est produite et la transaction a échoué.");
            return result.json();
        }).then(processTransactionResult => {
            if (!processTransactionResult.success) throw new Error(processTransactionResult.errorMessageFr || "Une erreur s'est produite et la transaction a échoué.");
            setSubmitted(false);
            props.setAlert(`La transaction s'est complétée avec succès.`, 'success');
            props.hideOverlay("PAIEMENT_COMPTES_DUES");
            props.gotoPaiementComptes();
        }).catch(e => {
            console.log(e);
            props.setAlert(e.message, "danger");
            props.hideOverlay("PAIEMENT_COMPTES_DUES");
            setSubmitted(false);
        });
    }

    const imprimerCodeQR = (codeQR) => {
        let nouvelleFenetre = window.open('', "_blank");

        nouvelleFenetre.onload = () => {
            nouvelleFenetre.print();
            nouvelleFenetre.close();
        }
    }

    function ImageToPrint(source) {

        let htmlContent = "<html>";
        htmlContent += "<head>";
        htmlContent += "<scri" + "pt>function step1(){\n" + "setTimeout('step2()', 10);}\n" + "function step2(){window.print();window.close()}\n" + "</scri" + "pt>";
        htmlContent += "<style>";
        htmlContent += "body {";
        htmlContent += "  display: flex;";
        htmlContent += "  flex-direction: column;";
        htmlContent += "  align-items: center;";
        htmlContent += "  justify-content: center;";
        htmlContent += "}";
        htmlContent += "</style>";
        htmlContent += "</head>";
        htmlContent += "<body onload='step1()'>";
        htmlContent += "<img width='200px' src='" + source + "' />";
        htmlContent += "</body>";
        htmlContent += "</html>";
        return htmlContent;
    }

    function PrintImage(source) {
        let pwa = window.open(`${window.location.href}`, "");
        pwa.document.open();
        pwa.document.write(ImageToPrint(source));
        pwa.document.close();
        pwa.addEventListener('afterprint', function (event) {
            // La fenêtre conceptuelle d'impression est fermée
            props.gotoBillet(PATH_BILLETTERIE)
        });
    }

    function validerFromCampUour() {
        return props.shop.cart.some((item) => {
            if (item.type !== CartItemType.CampJour) return true;
            const form = item.CampInscription;
            if (!form) return false;
            if (form.tuteur.nom.length === 0) {
                props.setErrorAlert('Le nom du parent/tuteur est obligatoire');
                return false;
            }
            if (form.tuteur.prenom.length === 0) {
                props.setErrorAlert('Le prénom du parent/tuteur est obligatoire');
                return false;
            }
            if (form.tuteur.telephone1.length < 10) {
                props.setErrorAlert('Le téléphone du parent/tuteur est obligatoire');
                return false;
            }
            if (form.tuteur.adresse.length === 0) {
                props.setErrorAlert("L'adresse du parent/tuteur est obligatoire");
                return false;
            }
            if (form.tuteur.ville.length === 0) {
                props.setErrorAlert('La ville du parent/tuteur est obligatoire');
                return false;
            }
            if (form.tuteur.codePostal.length === 0) {
                props.setErrorAlert('Le code postal du parent/tuteur est obligatoire');
                return false;
            }
            if (form.tuteur.email.length === 0 || !form.tuteur.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                props.setErrorAlert('Le courriel du parent/tuteur est obligatoire');
                return false;
            }

            if (form.inscription.recuImpotNom.length > 0 && form.inscription.noAssuranceSociale.length < 9) {
                props.setErrorAlert("Le Numéro d’assurance sociale pour le reçu d'impôt est obligatoire");
                return false;
            }

            if (form.inscription.enfantNom === '') {
                props.setErrorAlert("Le nom de l'enfant est obligatoire");
                return false;
            }
            if (form.inscription.enfantPrenom === '') {
                props.setErrorAlert("Le prénom de l'enfant est obligatoire");
                return false;
            }
            if (!form.inscription.enfantDateNaissance || form.inscription.enfantDateNaissance === '') {
                props.setErrorAlert("La date de naissance de l'enfant est obligatoire");
                return false;
            }
            if (form.inscription.enfantSexe === '') {
                props.setErrorAlert("Le sexe de l'enfant est obligatoire");
                return false;
            }

            if (form.inscription.assuranceMaladieNumero === '') {
                props.setErrorAlert("Le numéro d'assurance maladie de l'enfant est obligatoire");
                return false;
            }
            if (!form.inscription.assuranceMaladieExpiration || form.inscription.assuranceMaladieExpiration === '') {
                props.setErrorAlert("La date d'expiration du numéro d'assurance maladie de l'enfant est obligatoire");
                return false;
            }
            if (form.inscription.contact1Nom === '') {
                props.setErrorAlert("Le nom de la personne à rejoindre en cas d’urgence est obligatoire");
                return false;
            }
            if (form.inscription.contact1Relation === '') {
                props.setErrorAlert("La relation de la personne à rejoindre en cas d’urgence est obligatoire");
                return false;
            }
            if (form.inscription.contact1Telephone1 === '') {
                props.setErrorAlert("Le téléphone de la personne à rejoindre en cas d’urgence est obligatoire");
                return false;
            }

            if (form.inscription.allergies && form.inscription.allergiesNotes.length === 0) {
                props.setErrorAlert("Veuillez spécifier les allergies de l'enfant");
                return false;
            }

            if (form.inscription.medication && form.inscription.medicationNotes.length === 0) {
                props.setErrorAlert("Veuillez spécifier les médications de l'enfant");
                return false;
            }
            return true;
        })
    }

    function validateAndProcessTransactionForfaits() {
        setTransactionPackageFinished(false)
        const inclusProduitForfait = props.shop.cart.filter(item => item.type === CartItemType.Forfait).length > 0;
        const inclusProduitCampJour = props.shop.cart.filter(item => item.type === CartItemType.CampJour).length > 0;

        if (props.authToken == null && inclusProduitForfait) {
            props.setAlert(`Vous devez être connecté à votre compte pour acheter des forfaits.`, 'danger');
            setSubmitted(false);
            return false;
        }
        const montantPaiement = paymentFormRef.current?.getPaymentAmount();
        const montantProduitCampJourInitial = props.shop.cart.reduce((mnt, item) => {
            if (item.type === CartItemType.CampJour) {
                return mnt + item.total;
            }
        }, 0);
        if (inclusProduitCampJour && (montantPaiement < montantProduitCampJourInitial * 0.3)) {

            props.setAlert(`Un montant minimum de ${Math.round((montantProduitCampJourInitial * 0.3 + Number.EPSILON) * 100) / 100}$ est exigé pour complèter la transaction.`, 'danger');
            setSubmitted(false);
            return false;

        }
        if (inclusProduitCampJour && !validerFromCampUour()) {
            props.setAlert(`Certaines informations entrées dans le formulaire sont invalides.`, 'danger');
            setSubmitted(false);
            props.history.push({pathname: `/${props.match.params.idZec}/${props.match.params.language || "fr"}/reservation-camps/formulaire`});
            return false;
        }
        const {isInfoCarteValid} = paymentFormRef.current.validate();

        if (!isInfoCarteValid) {
            props.setAlert(`Assurez-vous que vos informations financières sont valides puis réessayez.`, 'danger');
            setSubmitted(false);
            return false;
        }

        props.showOverlay();
        let forfaits = [];
        const testshop = props.shop.cart.filter(item => item.type === CartItemType.Forfait || item.type === CartItemType.Billetterie).forEach(item => {
            (item.idsProduitPrix || [{id: item.idProduitPrix}]).forEach(id => {
                forfaits.push({
                    idProduit: item.idProduit, idProduitPrix: id.id || '00000000-0000-0000-0000-000000000000', quantite: id.quantite || 1, tranchesAge: item.tranchesAge || []
                });
            });
        });
        const shoppingCart = {
            ...props.shop, cart: props.shop.cart.filter(item => item.type === CartItemType.Forfait || item.type === CartItemType.Billetterie || item.type === CartItemType.CampJour)
        };

        return purchaseForfaits({
            creditCard: paymentFormRef.current.getInfoCarte(), shop: shoppingCart, customer: {
                clientInfo: paymentFormRef.current.getInfoClient(), noDossier: props.noDossier
            }, idProfile: decodedToken && decodedToken.idProfile, idClient: decodedToken && decodedToken.idClient, forfaits, montantPaiement: paymentFormRef.current?.getPaymentAmount() || 0, cardType: null, // Types de cartes non géré. On envoie null. En attendant que ça soit géré, le process transaction se fera correctement seulement pour les clients utilisant Bambora.
            codeDepanneur: null, // Mode dépanneur pas encore implémenté
            accessCode: null, // Paiement par code d'accès pour le mode dépanneur pas encore implémenté
            paypalOrderId: null, // Paiement Paypal pas encore implémenté
        }).then(async result => {
            if (result.status === STATUS_BAD_REQUEST) throw new Error(await result.json() || "Une erreur s'est produite et la transaction a échouée.");
            if (result.status === STATUS_NOT_FOUND) throw new Error(await result.json() || "Une erreur s'est produite et la transaction a échouée.");
            if (result.status !== STATUS_OK) throw new Error("Une erreur s'est produite et la transaction a échoué.");

            return result.json();
        }).then(processTransactionResult => {
            if (!processTransactionResult.success) throw new Error(processTransactionResult.errorMessageFr || "Une erreur s'est produite et la transaction a échoué.");
            //implementation de l'auto impression ici 
            try {
                //ce Bloc check si dans le panier il existe un produit venant de la section de la billeterie pour afficher ou non le Code QR
                props.shop.cart.map(elem => {
                    if (elem.type == "Billet") PrintImage("data:image/png;base64," + processTransactionResult.codeQR)
                })
                props.setAlert(`La transaction s'est complété avec succès.`, 'success');

            } catch (error) {
                console.log("Erreur impression Code QR", error)
            }

            for (var i = props.shop.cart.length - 1; i >= 0; i--) {
                if (props.shop.cart[i].type === CartItemType.Forfait || props.shop.cart[i].type === CartItemType.Billetterie || props.shop.cart[i].type === CartItemType.CampJour)
                    props.removeFromShoppingCart(props.shop.cart[i], false);
            }

            setSubmitted(false);
            if (inclusProduitCampJour) {
                props.gotoReservationCampJours();
            }
            setTransactionPackageFinished(true); // sauvegade de l'etat de la transaction des forfaits
            setInvoiceFilePackage(processTransactionResult.pdfFacture) // sauvegarde dela facture
            return processTransactionResult.success;
        }).catch(e => {
            console.log(e);
            props.setAlert(e.message, "danger");
            setSubmitted(false);
            return false;
        }).finally(() => {
            props.hideOverlay();
        });
    }

    function validateAndMakeReservation() {
        const {
            isInfoClientValid, isInfoCarteValid
        } = paymentFormRef.current.validate();

        if (!isInfoCarteValid) {
            props.setAlert(`Assurez-vous que vos informations financières sont valides puis réessayez.`, 'danger');
            setSubmitted(false);
            return false;
        }

        if (props.authToken == null && !isInfoClientValid) {
            props.setAlert("Il y a des erreurs dans les champs requis. Veuillez réessayer !!", 'danger');
            setSubmitted(false);
            return false;
        }
        const currentClient = paymentFormRef.current.getInfoClient();
        const shoppingCart = {
            ...props.shop, cart: props.shop.cart.filter(item => item.type === CartItemType.ReservationHebergement || item.type === CartItemType.ReservationActivite || item.type === CartItemType.ReservationCamping)
        };

        return createReservation({
            shop: shoppingCart, customer: {
                clientInfo: currentClient, noDossier: props.noDossier
            }, creditCard: paymentFormRef.current.getInfoCarte()
        }).then(result => {
            if (Object.keys(result).length === 0) {
                props.setAlert("Une erreur à l'interne s'est produite; Veuillez nous contacter pour continuer!", 'danger');
                return false;
            }
            if (!result.reservationEntete) {
                if (result.processError) props.setAlert(result.processError, 'danger'); else {
                    props.setAlert("Il y a des erreurs dans les champs requis. Veuillez réessayer !!", 'danger');
                    paymentFormRef.current.setErrors(result);
                }
                setSubmitted(false);
                return false;
            }
            const cartHasProduit = props.shop.cart.some(item => item.type === CartItemType.Forfait || item.type === CartItemType.Billetterie);
            if (cartHasProduit) validateAndProcessTransactionForfaits()
            gtag("set", {
                page_title: document.title + ' confirmation'
            });
            gtag("event", "page_view", {
                page_path: location.pathname + '/confirmation' + location.search,
            });
            props.clearNoDossier();
            props.clearPromotion();
            setPaiementCompleted(true);
            setResultMsg(!result.warning ? `Paiement completé avec succès. Courriel envoyé à ${currentClient.email} .` // paymentFormRef.current.getInfoClient().Email => null error
                : `Paiement completé avec des erreurs: ${result.warning}.` /*+
        "cliquer plus bas pour télécharger votre confirmation"*/);
            for (var i = props.shop.cart.length - 1; i >= 0; i--) {
                if (props.shop.cart[i].type === CartItemType.ReservationHebergement || props.shop.cart[i].type === CartItemType.ReservationActivite || props.shop.cart[i].type === CartItemType.ReservationCamping) props.removeFromShoppingCart(props.shop.cart[i], false);
            }

            setSubmitted(false);
        }).catch(e => {
            console.log(e);
            props.setAlert(e.message || "Une erreur à l'interne s'est produite; Veuillez nous contacter pour continuer!", "danger");
            setSubmitted(false);
            return false;
        });
    }

    if (paiementCompleted) {
        return (<div id="paiement-page" className="paiement-success">
            <div className="paiement-success-titre">
                <h1>Confirmation
                    de
                    paiement</h1>
            </div>
            <div className="paiement-success-message">
                <h2>
                    <br/><br/>{resultMsg}
                </h2>
            </div>
            {/*<div className="paiement-submit-button" onClick={gotoConfirmationPdf}>*/}
            {/*  <span>TELECHARGER CONFIRMATION</span>*/}
            {/*</div>*/}
        </div>);
    }

    if (isAnyPaiementCompte) {
        return (<div id="paiement-page">
            <form autoComplete="on" id="paiement-form" onSubmit={handleSubmit}>
                <PaiementForm
                    ref={paymentFormRef}
                    disablePaiementsMultiples={true}
                    montantSousTotal={parseFloat(props.location.state.sousTotal)}
                    montantFraisAdmin={fraisAdmin.fraisAdmin || 0}
                    montantFraisAdminTps={fraisAdmin.fraisAdminTps || 0}
                    montantFraisAdminTvq={fraisAdmin.fraisAdminTvq || 0}
                    montantTotal={parseFloat(props.location.state.sousTotal) + (fraisAdmin.fraisAdmin || 0) + (fraisAdmin.fraisAdminTps || 0) + (fraisAdmin.fraisAdminTvq || 0)}
                    showInfoClient={false}
                    typePaiement={props.location.state}
                    idZec={props.idZec}/>
                <div className="paiement-submit-button" onClick={handleSubmit}>
                    <span disabled>CONTINUER</span>
                </div>
            </form>
        </div>);
    }

    if (props.shop.cart.length === 0) return <><PanierVideMessage/> {transactionPackageFinished && invoiceFilePackage &&
        <embed src={`data:application/pdf;base64, ${invoiceFilePackage}`} type="application/pdf" width="100%" height="800"/>
    } </>

    if (props.shop.cart.some(item => item.type === CartItemType.Forfait) && props.authToken == null) {
        return (<div id="paiement-page" className="paiement-success-titre" style={{padding: '0px'}}>
            <div style={{
                margin: 'auto', display: 'table', marginTop: '48px', padding: '16px', textAlign: 'center'
            }}>
                <div>
            <span className="message">
              Vous devez être connecté à votre profil pour pouvoir faire l'achat de forfait.
            </span>
                </div>
                <div>
            <span className="message">
              Cliquez ici pour <span className="clickable message primary-color" onClick={e => props.gotoCreerCompte("paiement")}>créer un nouveau profile</span> ou ici pour vous <span className="clickable message primary-color" onClick={e => props.gotoProfile("paiement")}>connecter à votre profil</span>.
            </span>
                </div>
            </div>
            <br/><br/><br/>
        </div>)
    }
    const showPermisOrignal = props.shop.cart.some(item => item.type === CartItemType.Tirage && item.orignal);
    const showPermisCerf = props.shop.cart.some(item => item.type === CartItemType.Tirage && item.cerf);
    return (<div id="paiement-page">
        <form autoComplete="on" id="paiement-form" onSubmit={handleSubmit}>
            <PaiementForm ref={paymentFormRef} montantTotal={props.shop.montantDepots} showInfoClient={props.authToken == null} idZec={props.idZec} showPermisOrignal={showPermisOrignal} showPermisCerf={showPermisCerf}/>
            <div className="paiement-submit-button" onClick={handleSubmit}>
                <span>CONTINUER</span>
            </div>
        </form>
    </div>);
}

const PanierVideMessage = () => {
    return (<div className="paiement-success-titre">
        <h2 style={{
            margin: 'auto', display: 'table', marginTop: '48px', padding: '16px', textAlign: 'center'
        }}>
            Votre
            panier
            est
            vide,
            veuillez
            faire
            une
            sélection
            appropriée
            et
            recommencer.
            Merci!
        </h2>
        <br/><br/><br/>
    </div>);
}

const mapStateToProps = (state) => ({
    shop: state.shop, configuration: state.configuration, authToken: state.authToken, noDossier: state.client.noDossier
})

const dispatchProps = {
    clearNoDossier, getShop, removeFromShoppingCart, showOverlay, hideOverlay, setAlert, clearPromotion
};

export default withNavigation(connect(mapStateToProps, dispatchProps)(PaiementPage));