import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import withNavigation from "./higher-order/navigation";
import UniteIcons from "./unite-icons.js";
import "../../css/unite.css";
import Translator from "./translator";
import {getUniteById, getUnitePrixBase} from "../server/services/unite-service";
import {format, format as formatDate} from "date-fns";

function mapStateToProps(state, ownProps) {
    return {};
}

function cap(value, min, max) {
    if (value < min) return min;

    if (value > max) return max;

    return value;
}

function formatTime(time) {
    const datetime = new Date(time);
    return formatDate(datetime, "HH'H'mm");
}

function Unite(props) {
    const [thumbnailIndex, setThumbnailIndex] = useState(0);
    const [unite, setUnite] = useState(null);
    const [prix, setPrix] = useState(0);
    const myRef = useRef();
    const TEXTS = Translator(props.match.params.language, "TXT_UNITE");
    const isFrancais = props.match.params.language.toLowerCase() === 'fr'
    useEffect(() => {
        getUniteById(props.idUnite, "arriveeDepartTypeUnite,planBase,equipements,services")
            .then(async (uniteResponse) => {
                setPrix(await getUnitePrixBase(props.idUnite, (props.selectedArrivee ? format(props.selectedArrivee, "yyyy-MM-dd") : null), (props.selectedDepart ? format(props.selectedDepart, "yyyy-MM-dd") : null)));
                setUnite({
                    ...uniteResponse,
                    images: [{
                        key: "-1",
                        image: null
                    }]
                });
            })
            .then((uniteResponse) => getUniteById(props.idUnite, "arriveeDepartTypeUnite,planBase,equipements,services,images").then((image) => setUnite(image)))
            .then(() => {
                if (myRef && myRef.current) myRef.current.scrollIntoView();
            });
    }, [props.idUnite, props.selectedArrivee, props.selectedDepart]);

    useEffect(() => {
        if (unite) {
            props.setPeutAjouterPanier(prix != 0);
        }
    }, [unite, prix])

    const rotateThumbnail = (change) => {
        let nextIndex = cap(thumbnailIndex + change, 0, (unite.images || []).length - 1);
        setThumbnailIndex(nextIndex);
    };

    if (unite === null) return <div/>;
    const descriptionWeb = (isFrancais || unite.description_web_anglais === "" ? unite.description_web : unite.description_web_anglais);
    return (
        <div ref={myRef} id="consulation-unite">
            <div id="consulation-unite-header-wrapper">
                <div id="consulation-unite-header-left">
                    <div id="consulation-unite-nom-wrapper">
                        <span>{isFrancais || unite.description_anglaise === "" ? unite?.description?.toUpperCase() : unite?.description_anglaise?.toUpperCase()}</span>
                    </div>
                    {!unite.cacher_heures &&
                        <div id="consulation-unite-heures-wrapper">
                            <span>{`${TEXTS.title0} : ` + formatTime(unite.heure_arrivee) + ` • ${TEXTS.title1} : ` + formatTime(unite.heure_depart)}</span>
                        </div>}
                </div>
                <div id="consulation-unite-header-right">
                    <span id="consulation-unite-a-partir-de">{TEXTS.title2}</span>
                    <span id="consulation-unite-montant">{prix != 0 ? `${prix.toFixed(2)} $` : '-'} </span>
                </div>
            </div>
            <div id="consulation-unite-description-thumbnail-wrapper">
                <div id="consulation-unite-description-wrapper">
                    {descriptionWeb &&
                        <div>
                            <span id="consulation-unite-description-label">DESCRIPTION</span>
                            <span id="consulation-unite-description-text">
            {descriptionWeb.split("\n").map((item, key) => (
                <span key={key}>
                {item}
                    <br/>
              </span>))}
          </span>
                        </div>}

                    <span id="consulation-unite-description-icons">
            <UniteIcons nombrePersonnes={unite.nombre_personne} afficherNbPersonne={true} animaux={!unite.interdire_animaux} eau={unite.id_point_eau !== "00000000-0000-0000-0000-000000000000"} fumeur={unite.fumeur}/>
          </span>
                    {unite.equipements.length > 0 && (
                        <div className="consulation-unite-liste-wrapper">
                            <span>ÉQUIPEMENTS AUTORISÉS</span>
                            <ul>
                                {unite.equipements.map((equipement, index) => (
                                    <li key={index}>{equipement.description}</li>))}
                            </ul>
                        </div>)}
                    {unite.services.length > 0 && (
                        <div className="consulation-unite-liste-wrapper">
                            <span>SERVICES</span>
                            <ul>
                                {unite.services.map((service, index) => (
                                    <li key={index}>{service.description}</li>))}
                            </ul>
                        </div>)}
                </div>
                <div>
                    {(unite.images || []).length > 0 && (
                        <div id="consulation-unite-thumbnail-wrapper">
                            <div id="consulation-unite-thumbnail-arrow-left" className={"consulation-unite-thumbnail-arrow" + (thumbnailIndex > 0 ? " has-another-thumbnail" : "")} onClick={(e) => rotateThumbnail(-1)}>
                                <div id="consulation-unite-thumbnail-arrow-left-top" className={"consulation-unite-thumbnail-arrow-part" + (thumbnailIndex > 0 ? " has-another-thumbnail" : "")}/>
                                <div id="consulation-unite-thumbnail-arrow-left-bottom" className={"consulation-unite-thumbnail-arrow-part" + (thumbnailIndex > 0 ? " has-another-thumbnail" : "")}/>
                            </div>
                            {(unite.images || []).map((image, index) => (
                                <img key={index} src={image.image ? "data:image/jpeg;base64," + image.image : require("../../images/loading.svg")} style={{opacity: thumbnailIndex === index ? "1" : "0"}} alt="thumbnail"/>))}
                            <div id="consulation-unite-thumbnail-arrow-right" className={"consulation-unite-thumbnail-arrow" + (thumbnailIndex < unite.images.length - 1 ? " has-another-thumbnail" : "")} onClick={(e) => rotateThumbnail(1)}>
                                <div id="consulation-unite-thumbnail-arrow-right-top" className={"consulation-unite-thumbnail-arrow-part" + (thumbnailIndex < unite.images.length - 1 ? " has-another-thumbnail" : "")}/>
                                <div id="consulation-unite-thumbnail-arrow-right-bottom" className={"consulation-unite-thumbnail-arrow-part" + (thumbnailIndex < unite.images.length - 1 ? " has-another-thumbnail" : "")}/>
                            </div>
                        </div>)}
                    {!props.carteIsEmpty &&
                        <div className={"choix-unite-ajouter-au-panier-wrapper"} onClick={props.history.goBack}>
                            <span>RETOUR</span>
                        </div>}
                </div>
            </div>
        </div>);
}

Unite.propTypes = {
    idUnite: PropTypes.string.isRequired,
    setPeuxAjouterPanier: PropTypes.func
};

Unite.defaultProps = {};

export default withNavigation(connect(mapStateToProps)(Unite));