import React, { Component } from "react";
//import { Document, Page, pdfjs } from 'react-pdf';
import ReactDOM from "react-dom";
import Stepper from './stepper/Stepper';
import TimePicker from 'rc-time-picker';
import { connect } from "react-redux";
import { setAuthToken } from './actions/auth-token';
import { showOverlay, hideOverlay } from './actions/overlay';
import { showPopup, showYesNoPopup, showOkPopup, hidePopup } from './actions/generic-popup';
import { isValidEmail, isValidPhoneNumber, isValidPostalCode, isEmptyObject } from "../utils/validation";
import { compareStringArrays } from "../utils/sorting";
import { setAlert } from './actions/alert';
import Calendar from "./calendar.js";
import { get } from '../server/api.js';
import jwtDecode from "jwt-decode";
import { toDateString, isUnformattedPhoneNumber, formatPhoneNumber, formatageDatePourDroitDacces, compareDate, compareHeure } from "../utils/formatting";
import { typesPermisPeche } from "../enums/types-permis-peche.js";
import withNavigation from "./higher-order/navigation";
import PaiementForm from "./paiement/paiement-form";
import moment, { invalid } from 'moment';
import {generateUUID} from "../utils/generatedUUID"
import theme from '../theme.js'
import "../../css/droit-acces-page.css";
import 'rc-time-picker/assets/index.css';
import { SaveProfilClient } from './actions/infoClient'
import { getPaysConfigWeb } from "./actions/pays-config-web";
import { getProvincesConfigWeb } from "./actions/provinces-config-web";
import { relations, relationsByIds } from "../enums/relations.js";
import { ID_PROVINCE_QUEBEC_CONFIG_WEB, ID_PAYS_CANADA_CONFIG_WEB } from "../constants.js";
import EditSvg from "./svg/edit.js";
import DeleteSvg from "./svg/delete.js";
import { setSeoTitle } from "../utils/document";
import { periodsOverlap } from "../utils/dates";
import { calculateDetails, processTransactionDroitAcces } from "../server/services/enregistrement-service";
import { getConfigurationByKeyName, getFeuilletNonRemis, getIdClient , deleteVehicule, getProfilClient} from "../server/services/configuration-service";
import _ from "lodash";
import SessionKeys from '../session'
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import AddCompanion from './droit-acces/add-companion';
import CompanionSelectOrAddVehicle from "./droit-acces/companion-select-addVehicle.js"

import {setFromStorage, getFromStorage} from '../utils/localStorageUtils.js';


const ERREUR_CHAMP_VIDE = "* Champ obligatoire";
const ERREUR_COURRIEL_INVALIDE = "* Ce courriel est invalide";
const ERREUR_VALEUR_INVALIDE = "* Valeur invalide";
const ERREUR_AU_MOINS_UNE_PERSONNE = "* Veuillez sélectionner au moins une personne";
const ERREUR_AU_MOINS_UNE_JOURNEE = "* Veuillez sélectionner au moins une journée par personne";
const ERREUR_PEUT_PAS_ARRIVER_AVANT_MAINTENANT = "* L'heure d'arrivée ne peut pas précéder l'heure actuelle";
const ERREUR_PEUT_PAS_PARTIR_AVANT_DEPART = "* L'heure de départ ne peut pas précéder l'heure d'arrivée";

// NOTE: Ne sont pas des index. Certaines zecs n'ont pas toutes ces étapes. Ces valeurs doivent demeurer constantes et ne doivent pas être traité comme des index.
const STEP_INFO_RESPONSABLE = 0;
const STEP_DATE_HEURE_SEJOUR_ENREGISTREMENT = 1;
const STEP_VEHICULES = 2;
const STEP_ACCOMPAGNATEURS = 3;
const STEP_ACTIVITES = 4;
const STEP_SOMMAIRE = 5;
const STEP_PAIEMENT = 6;
const STEP_CONFIRMATION = 7;

const ACTIVITE_STEP_SECTEUR = 0;
const ACTIVITE_STEP_PARTICIPANTS = 1;
const ACTIVITE_STEP_JOURNEES = 2;

const TYPE_ACCOMPAGNATEUR = "accompagnateur";
const TYPE_DEPENDANT = "dependant";

let tempId = 0;
function generateTempId() {
  return ++tempId;
}

function vehiculeSorter(v1, v2) {
  return compareStringArrays([v1.description.toUpperCase(), v1.noPlaque.toUpperCase()], [v2.description.toUpperCase(), v2.noPlaque.toUpperCase()]);
}

function accompagnateurSorter(a1, a2) {
  return compareStringArrays([a1.nom.toUpperCase(), a1.prenom.toUpperCase()], [a2.nom.toUpperCase(), a2.prenom.toUpperCase()]);
}

function getAccompagnateurId(accompagnateur) {
  return accompagnateur.idProfile + "|" + accompagnateur.idClient;
}

function mapAccompagnateur(a) {
  const accompagnateur = {
    idClient: a.idClient || "00000000-0000-0000-0000-000000000000",
    idProfile: a.idProfile || "00000000-0000-0000-0000-000000000000",
    relation: a.relation || null,
    idAccompagnateur : generateUUID(),
    nom: a.nom || "",
    prenom: a.prenom || "",
    isSelected: false, // True lorsque l'accompagnateur est sélectionné.
    isSelecting: false, // True lorsque l'accompagnateur est sélectionné dans le popup. Après fermeture du popup, isSelecting est copié dans isSelected si on ferme le popup via CONFIRMER (plutôt que via le X qui sert plutôt à annuler).
    idVehicule: '00000000-0000-0000-0000-000000000000',
    fqcq: "",
    vehiculesSansModif: a.vehicules,
    vehicules: a.vehicules.map(mapVehicule).sort(vehiculeSorter),
  };

  accompagnateur.idAccompagnateur = getAccompagnateurId(accompagnateur);
  return accompagnateur;
}

function mapVehicule(dbVehicule) {
  return {
    idVehicule: dbVehicule.idVehicule,
    description: (dbVehicule.description || "").trim(), // Dans la BD, description et no_plaque peuvent être NULL, et comme ils sont des nchar, ils arrivent paddés d'espace. On sanitize ces champs.
    noPlaque: (dbVehicule.noPlaque || "").trim(), // Dans la BD, description et no_plaque peuvent être NULL, et comme ils sont des nchar, ils arrivent paddés d'espace. On sanitize ces champs.
    type: dbVehicule.type === "" ? "TE" : dbVehicule.type,
    isSelected: false,
    fqcq: '', // Champ utilisé seulement pour la personne principale (car elle peut choisir plusieurs véhicules). Pour les accompagnateur, on utilise plutôt le champ this.state.accompagnateurs[n].fqcq
  };
}

function roundTwoDecimals(n) {
  return Math.round(n * 100) / 100;
}




class DroitAccesPage extends Component {
  constructor(props) {
    super(props);

    this.paiementFormRef = React.createRef();

    this.mounted = false; // Pour corriger un memory leak

    const currentDate = new Date();
    let parsedDateArrive = new Date(JSON.parse(sessionStorage.getItem(SessionKeys.DROIT_ACCES_DATE_ARRIVEE)))
    let parsedDateDepart = new Date(JSON.parse(sessionStorage.getItem(SessionKeys.DROIT_ACCES_DATE_DEPART)))
    if (currentDate > parsedDateArrive || currentDate > parsedDateDepart) {
      parsedDateArrive = parsedDateDepart = null;
      sessionStorage.removeItem(SessionKeys.DROIT_ACCES_DATE_ARRIVEE);
      sessionStorage.removeItem(SessionKeys.DROIT_ACCES_DATE_DEPART);
    }

    this.setStep = this.setStep.bind(this);
    this.escape = this.escape.bind(this);
    
    this.state = {
      stepId: 0,
      etapeMaximaleAtteinte: 0,
      errorsFromGoingBack: [],
      stepperSteps: [
        // Nouvel ordre des étapes demandés par Alain:
        // 1 responsable
        // 2 dates et séjour
        // 3 véhicules
        // 4 accompagnateur
        // 5 activités
        // 6 sommaire
        // 7 paiement
        // 8 confirmation
        { id: STEP_INFO_RESPONSABLE, title: 'Responsable' },
        { id: STEP_DATE_HEURE_SEJOUR_ENREGISTREMENT, title: 'Dates et séjour' },
        (this.props.configuration.cacherVehiculesSurPav || "FALSE").toUpperCase() === "TRUE" ? null : { id: STEP_VEHICULES, title: 'Véhicules' },
        (this.props.configuration.cacherAccompagnateursSurPav || "FALSE").toUpperCase() === "TRUE" ? null : { id: STEP_ACCOMPAGNATEURS, title: 'Accompagnateurs' },
        { id: STEP_ACTIVITES, title: 'Activités' },
        { id: STEP_SOMMAIRE, title: 'Sommaire' },
        { id: STEP_PAIEMENT, title: 'Paiement' },
        { id: STEP_CONFIRMATION, title: 'Confirmation' },
      ].filter(ss => ss !== null),
      infoResponsable: {
        nom: "",
        prenom: "",
        telephone: "",
        adresse: "", // Affiché seulement lorsque continue sans compte
        ville: "", // Affiché seulement lorsque continue sans compte
        codePostal: "", // Affiché seulement lorsque continue sans compte
        courriel: "",
        dateNaissance: null, // Affiché seulement lorsque continue sans compte
        idProvince: ID_PROVINCE_QUEBEC_CONFIG_WEB,
        idPays: ID_PAYS_CANADA_CONFIG_WEB,
      },
      errors: {},
      dateArrivee: sessionStorage.getItem(SessionKeys.DROIT_ACCES_DATE_ARRIVEE) ? parsedDateArrive : null,
      dateDepart: sessionStorage.getItem(SessionKeys.DROIT_ACCES_DATE_DEPART) ? parsedDateDepart : null,
      heureArrivee: new Date().getHours().toString().padStart(2, '0') + ":" + new Date().getMinutes().toString().padStart(2, '0'),
      heureDepart: "00:00",
      idLieuSejour: '00000000-0000-0000-0000-000000000000',
      idTypeSejour: '00000000-0000-0000-0000-000000000000',
      showCalendrierDateArrivee: false,
      showCalendrierDateDepart: false,
      showCalendrierDateNaissance: false,
      showCalendrierDateNaissanceAccompagnateur: false,
      isCalendarBlurred: false,
      pays: [],
      provinces: [],
      lieuxSejours: [],
      typesSejours: [],
      activites: [],
      secteurs: null,
      pointsEaux: null,
      eauQuotasDisponible:false,
      pointEauQuotas: [{}],
      gibiers: null,
      disponibilites: null,
      activitesHorsServices: null,
      vehicules: [],
      dependantVehicules : [],
      pdfEnregistrement: "",
      pdfFacture: "",
      accompagnateurs: [],
      listeDependant : [],
      accompagnateursDeBaseSelectionne: [],
      listeDependantHasChanged:false,
      accompagnateursHasChanged: false,
      hadDependants: undefined,
      messageInfoActivite: '',
      maxJour: 0,
      delaisEnregistrement: 0,
      maxActiviteParJour: 0,
      resevationCompletetd: false,
      feuilletNonRemis: [],
      IdClientPrincipal: "",
      hasAnythingFromStepTwoChanged: false,
      hasTheFifthStageChangedAnyThing: false,
      /*
       * Structure de activitesChoisies (champs non utilisés ignorés): [{
       *  idActiviteChoisie: int,
       *  activite: {
       *    idActivite: guid,
       *    description: string
       *  },
       *  secteur: null ou {
       *    idSecteur: guid,
       *    nomSecteur: string
       *  },
       *  pointEau: null ou {
       *    idPointEau: guid,
       *    description: string,
       *    joursDisponibilite: int (bitmask correspondant aux jours de la semaine. Lundi = 1, Mardi = 2, Mercredi = 4, etc.. À noter qu'en JS, new Date().getDay() retourne 0 pour dimanche, 1 pour lundi, 2 pour mardi, etc.. En C#, (int)DateTime.Now.DayOfWeek donne 0 pour dimanche, 1 pour lundi, 2 pour mardi, etc. comme new Date().getDay() en JS.)
       *  },
       *  gibiers: [{
       *    idGibier: guid,
       *    nom: string
       *  }],
       *  participants: [{
       *    idAccompagnateur: guid|guid (deux guid séparés par un '|'. Le premier correspond au idProfile et l'autre au idClient. C'est parce qu'il est possible qu'un accompagnateur n'aille qu'un idClient ou qu'un idProfile) si accompagnateur pronvenant de la DB ou int si accompagnateur avec id temporaire ou null si personne principale,
       *    prenom: string,
       *    nom: string,
       *    jours: [Date]
       *  }]
       * }]
       */
      activitesChoisies: [],
      selectedIdActivite: "00000000-0000-0000-0000-000000000000",
      addVehiculeDescription: '',
      addVehiculeNoPlaque: '',
      addVehiculeAerien: false,
      dependantAddVehiculeDescription: '',
      dependantAddVehiculeNoPlaque: '',
      dependantAddVehiculeAerien: false,
      continuerSansCompte: false,
      details: [],
      submitted: false,
      mainCustomerVehicles: [],
      deleteVehicules : [],
      finishTransaction: false,
      registrationFile: null,
      invoiceFile:null,
      activeTabPreviewFile: 1
    };
  }

  escape(event) {
    if (event.key === "Escape") {
      this.setState({ errors: {} });
      this.props.hidePopup();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escape, false);
    setSeoTitle(this.props.entrepriseName, "Droit d'accès");
    this.mounted = true;

    this.getProfileInfo();
    this.getVehicules();
    this.getAccompagnateurs();
    this.getPays();
    this.getProvinces();
    this.getLieuxSejours();
    this.getTypesSejours();
    this.getActivites();
    this.getMessageInformation('MESSAGE_FENETRE_ACCUEIL');
    this.getNombreJourMax('NOMBRE_JOURS_MAX');
    this.getDelaisEnregistrement();
    this.getMaxActiviteParEnregistrement();
    // this.getfeuilletRemis();
    this.getIdClientPrincipal();
    this.getMessageInformation('MESSAGE_ACTIVITE')
    //this.getSecteurs();
    //this.getPointsEaux();
    //this.getGibiers();

    // // // temporaire juste pour le test
    const registrationFile = getFromStorage('registrationFile')
    const invoiceFile= getFromStorage('invoiceFile')

    if (registrationFile || invoiceFile) {
 
      this.setState({
        registrationFile:registrationFile,
        invoiceFile: invoiceFile,
       })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escape, false);
    this.mounted = false;

    this.props.hideOverlay({ key: ["DROIT_ACCES_CONFIGURATION", "DROIT_ACCES_PARAMETRES_WEB", "DROIT_ACCES_VEHICULES", "DROIT_ACCES_ACCOMPAGNATEURS", "DROIT_ACCES_ADD_VEHICULE"] });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.stepId > this.state.etapeMaximaleAtteinte)
      this.setState({ etapeMaximaleAtteinte: this.state.stepId });

    if (this.state.showCalendrierDateNaissance)
      if (ReactDOM.findDOMNode(this.refs.calendarDateNaissanceWrapperDiv) !== null)
        ReactDOM.findDOMNode(this.refs.calendarDateNaissanceWrapperDiv).focus();

    if (this.state.showCalendrierDateNaissanceAccompagnateur)
      if (ReactDOM.findDOMNode(this.refs.calendarDateNaissanceAccompagnateurWrapperDiv) !== null)
        ReactDOM.findDOMNode(this.refs.calendarDateNaissanceAccompagnateurWrapperDiv).focus();

    if (this.state.showCalendrierDateArrivee)
      ReactDOM.findDOMNode(this.refs.calendarDateArriveeWrapperDiv).focus();

    if (this.state.showCalendrierDateDepart)
      ReactDOM.findDOMNode(this.refs.calendarDateDepartWrapperDiv).focus();

    if (isEmptyObject(prevProps.configuration) && !isEmptyObject(this.props.configuration) && (this.props.overlay.showByKey || {}).DROIT_ACCES_CONFIGURATION !== undefined) { // Configurations chargées. On cache l'overlay des configurations et MAJ certains states qui dépendent des configs.
      this.props.hideOverlay("DROIT_ACCES_CONFIGURATION");

      if (this.props.configuration.heureDepartDefautEnregistrement != null) {
        this.setState({
          heureDepart: (this.props.configuration.heureDepartDefautEnregistrement + ":00").padStart(5, "0"),
        });
      }

      this.setState({
        stepperSteps: this.state.stepperSteps.filter(ss => {
          if (ss.id === STEP_VEHICULES)
            return this.props.configuration.cacherVehiculesSurPav.toUpperCase() !== "TRUE";

          if (ss.id === STEP_ACCOMPAGNATEURS)
            return this.props.configuration.cacherAccompagnateursSurPav.toUpperCase() !== "TRUE";

          return true;
        })
      });
    }

    if (isEmptyObject(this.props.configuration) && (this.props.overlay.showByKey || {}).DROIT_ACCES_CONFIGURATION === undefined) // Configurations non chargées. On affiche l'overlay des configurations.
      this.props.showOverlay("DROIT_ACCES_CONFIGURATION");

    if (isEmptyObject(prevProps.parametresWeb) && !isEmptyObject(this.props.parametresWeb) && (this.props.overlay.showByKey || {}).DROIT_ACCES_PARAMETRES_WEB !== undefined) // Paramètres webs chargés. On cache l'overlay des paramètres web.
      this.props.hideOverlay("DROIT_ACCES_PARAMETRES_WEB");

    if (isEmptyObject(this.props.parametresWeb) && (this.props.overlay.showByKey || {}).DROIT_ACCES_PARAMETRES_WEB === undefined) // Paramètres webs non chargés. On affiche l'overlay des paramètres web.
      this.props.showOverlay("DROIT_ACCES_PARAMETRES_WEB");

    //console.log(prevProps)
    if (prevProps.history.action === 'POP') {
      // const { location } = this.props;
      // const query = new URLSearchParams(location.search);
      // const section = query.get('section');
      // if (section === 'Dates') this.setState({ stepId: 0 })
      // if (section === 'Véhicules') this.setState({ stepId: 1 })
      // console.log(nextProps.history)
      // console.log(section)
      // console.log(nextProps.history.location.search)
    }


    // verifier si un element de chaque etape ci-dessous a ete modifie quand on fais un retour: 

    /** Etape 2 
     * Les elements du state a observer : this.state.dateArrivee, this.state.dateDepart,this.state.heureArrivee, this.state.heureDepart
     */
    if (this.state.stepId === STEP_DATE_HEURE_SEJOUR_ENREGISTREMENT) {
      if ((prevState.dateArrivee !== this.state.dateArrivee) || (prevState.dateDepart !== this.state.dateDepart) || (prevState.heureArrivee !== this.state.heureArrivee) || (prevState.heureDepart !== this.state.heureDepart)) {
        this.setState({ hasAnythingFromStepTwoChanged: true })
      }
    }

    /** Etape 5
     * Les elements du state a observer :selectedIdActivite
     */
    if (this.state.stepId === STEP_ACTIVITES) {
      if ((prevState.selectedIdActivite !== this.state.selectedIdActivite)) {
        this.setState({ hasTheFifthStageChangedAnyThing: true })
      }
    }
  }

  //Cette fonction permet de recuperer le message a afficher quand on entre dans le composant et quand on est sur l'etape de l'activite
  getMessageInformation(keyName) {
    getConfigurationByKeyName(keyName)
      .then(response => {
        if (response.keyValue && !_.isEmpty(this.props.authToken)) {
          //message accueil
          if (keyName === "MESSAGE_FENETRE_ACCUEIL") {
            const messageIsDone = sessionStorage.getItem(SessionKeys.MESSAGE_ACCUEIL_DEJA_AFFICHE)
            if (_.isEmpty(messageIsDone)) {
              sessionStorage.setItem(SessionKeys.MESSAGE_ACCUEIL_DEJA_AFFICHE, 'done')
              this.props.showOkPopup({
                title: "NOTES",
                bodyText: response.keyValue
              });
            }
          }
          //message activite
          else {
            this.setState({
              messageInfoActivite: response.keyValue
            })
          }
        }
      })
      .catch((e) => {
        console.log('getConfigurationByKeyName meessage errorrr', e)
      })
  }
  getNombreJourMax(keyName) {
    getConfigurationByKeyName(keyName)
      .then(response => {
        this.setState({
          maxJour: response?.keyValue
        })
      })
      .catch((e) => {
        console.log('getConfigurationByKeyName max jour errorrr', e)
      })
  }

  getDelaisEnregistrement() {
    getConfigurationByKeyName('DELAI_AUTO_ENREGISTREMENT_AVANCE')
      .then(response => {
        let inValue = parseInt(response?.keyValue, 10);
        this.setState({
          delaisEnregistrement: !isNaN(inValue) ? inValue : 0
        })
      })
      .catch((e) => {
        console.log('getConfigurationByKeyName delais enregistrement errorrr', e)
      })
  }

  getIdClientPrincipal() {
    if (this.props.authToken == null)
      return;
    let decodedToken = jwtDecode(this.props.authToken);
    getIdClient(decodedToken.idProfile)
      .then(IdClientPrincipal => {
        this.setState({ IdClientPrincipal: IdClientPrincipal }, () => {
          this.feuilletNonRemis()
        });
      })
      .catch(err => {
        console.log('Error get idClient', err)
      })
  }

  getMaxActiviteParEnregistrement() {
    getConfigurationByKeyName('MAX_ACTIVITE_PAR_ENREGISTREMENT')
      .then(response => {
        this.setState({
          maxActiviteParJour: response?.keyValue
        })
      })
      .catch((e) => {
        console.log('getConfigurationByKeyName max Activite par jour errorrr', e)
      })
  }

  feuilletNonRemis(){
    getFeuilletNonRemis(this.state.IdClientPrincipal)
    .then(responseFeuilleNonRemis => {
      this.setState({
        feuilletNonRemis: responseFeuilleNonRemis.length === 1 && responseFeuilleNonRemis[0] == '0' ? [] : responseFeuilleNonRemis //verifie que le tableau ne contient pas uniquement 0
      })
    })
    .catch((e) => {
      console.log('getFeuilletNonRemis idProfile', e)
    })
  }

  //#region DB getters
  getProfileInfo() {
    if (this.props.authToken == null)
      return;

    var decodedToken = jwtDecode(this.props.authToken);

    this.props.showOverlay("DROIT_ACCES_PROFILE");

    getProfilClient(this.props.idZec, decodedToken.idProfile, this.props.authToken).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et le profil n'a pas pu être chargé.");

      return result.json();
    }).then(profile => {
      this.setState({
        infoResponsable: {
          nom: profile.nom,
          prenom: profile.prenom,
          telephone: profile.telephone1,
          courriel: profile.email,
        }
      });
      // this.props.SaveProfilClient(profile);
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("DROIT_ACCES_PROFILE");
    });
  }

  getVehicules() {
    if (this.props.authToken == null)
      return;

    var decodedToken = jwtDecode(this.props.authToken);

    this.props.showOverlay("DROIT_ACCES_VEHICULES");

    get("/" + this.props.idZec + '/Profile/' + decodedToken.idProfile + "/Vehicule", false, this.props.authToken).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et les véhicules n'ont pas pu être chargés.");

      return result.json();
    }).then(vehicules => {
      this.setState({
        vehicules: vehicules.map(mapVehicule).sort(vehiculeSorter),
        mainCustomerVehicles: vehicules
      });
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("DROIT_ACCES_VEHICULES");
    });
  }

  getAccompagnateurs() {
    if (this.props.authToken == null)
      return;

    var decodedToken = jwtDecode(this.props.authToken);

    this.props.showOverlay("DROIT_ACCES_ACCOMPAGNATEURS");

    get("/" + this.props.idZec + '/Profile/' + decodedToken.idProfile + "/AccompagnateurDependant", false, this.props.authToken).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et les accompagnateurs n'ont pas pu être chargés.");

      return result.json();
    }).then(accompagnateurs => {
      const accompaganteurWithId = accompagnateurs.map(accompagnateur => ({
        ...accompagnateur,
        idAccompagnateur: generateUUID(),
      }))
      const { accompagnateurSimple, accompagnateurDependant } = accompaganteurWithId.reduce((acc, accompagnateur) => {
        const { relation } = accompagnateur;

        if (relation === 0) {
          acc.accompagnateurSimple.push(accompagnateur);
        } else {
          acc.accompagnateurDependant.push(accompagnateur);
        }
        return acc;
      }, { accompagnateurSimple: [], accompagnateurDependant: [] });
      
      this.setState({ 
        accompagnateurs: accompagnateurSimple.map(mapAccompagnateur).sort(accompagnateurSorter), // Plutôt que de directement copier les accompagnateurs de la BD dans this.state.accompagnateurs (this.state.accompagnateurs = accompagnateurs), on crée une nouvelle structure de donnée en copiant les champs des accompagnateurs. Ça permet de découpler le code serveur et le code client (par exemple, si accompagnateur obtient un champ idVehicule, isSelected, fqcq... côté serveur, la partie cliente sera innafectée), et ça fait en sorte que les champs important pour cette page sont explicit. Pareil pour les véhicules.
        hadDependants: accompagnateurs.some(a => a.type === TYPE_DEPENDANT),
        listeDependant : accompagnateurDependant.map(mapAccompagnateur).sort(accompagnateurSorter),
      });
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("DROIT_ACCES_ACCOMPAGNATEURS");
    });
  }

  getPays() {
    return this.props.getPaysConfigWeb({ idZec: this.props.idZec }).then(({ pays }) => this.mounted && this.setState({
      pays: pays.filter(p => p.idPays !== '00000000-0000-0000-0000-000000000000'), // Ce filter enlève le pays Aucun
    })).catch(e => {
      console.log(e);
      this.props.setAlert("Une erreur s'est produite. Veuillez réessayer plus tard ou contacter l'administrateur si l'erreur persiste.", "danger");
    });
  }

  getProvinces() {
    return this.props.getProvincesConfigWeb({ idZec: this.props.idZec }).then(({ provinces }) => this.mounted && this.setState({
      provinces: provinces.filter(p => p.idProvince !== '00000000-0000-0000-0000-000000000000'), // Ce filter enlève la province Aucune
    })).catch(e => {
      console.log(e);
      this.props.setAlert("Une erreur s'est produite. Veuillez réessayer plus tard ou contacter l'administrateur si l'erreur persiste.", "danger");
    });
  }

  getLieuxSejours() {
    this.props.showOverlay("DROIT_ACCES_LIEUX_SEJOUR");

    get("/" + this.props.idZec + '/LieuSejour/', false).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et les lieux de séjours n'ont pas pu être chargées.");

      return result.json();
    }).then(lieuxSejours => {
      this.setState({
        lieuxSejours: lieuxSejours.sort((a, b) => a.description.toUpperCase().localeCompare(b.description.toUpperCase(), 'en')), // localeCompare(string, locale) permet d'ignorer les accents (sans quoi les accents viennent après z)
      });
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("DROIT_ACCES_LIEUX_SEJOUR");
    });;
  }

  getTypesSejours() {
    this.props.showOverlay("DROIT_ACCES_TYPES_SEJOUR");

    get("/" + this.props.idZec + '/TypeSejours/', false).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et les types de séjours n'ont pas pu être chargées.");

      return result.json();
    }).then(typesSejours => {
      this.setState({
        typesSejours: typesSejours.sort((a, b) => a.description.toUpperCase().localeCompare(b.description.toUpperCase(), 'en')), // localeCompare(string, locale) permet d'ignorer les accents (sans quoi les accents viennent après z)
      });
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("DROIT_ACCES_TYPES_SEJOUR");
    });
  }

  getActivites() {
    this.props.showOverlay("DROIT_ACCES_ACTIVITES");

    get("/" + this.props.idZec + '/Activite/', false).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et les activités n'ont pas pu être chargées.");

      return result.json();
    }).then(activites => {
      this.setState({
        activites: activites
          .filter(a => a.actif && a.disponibleEnLigne)
          .sort((a, b) => a.description.toUpperCase().localeCompare(b.description.toUpperCase(), 'en')), // localeCompare(string, locale) permet d'ignorer les accents (sans quoi les accents viennent après z)
      });
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("DROIT_ACCES_ACTIVITES");
    });
  }

  getActivitesHorsService(idActivite, activite = null) {
    this.props.showOverlay("DROIT_ACCES_ACTIVITES_HORS_SERVICE");

    //const debut = this.state.dateArrivee + " " + this.state.heureArrivee;
    //const fin = this.state.dateDepart + " " + this.state.heureDepart;

    //console.log({debut, fin});

    //get(`/${this.props.idZec}/Activite/${idActivite}/ActiviteHorsService?debut=${debut}&fin=${fin}`, false).then(result => {
    get(`/${this.props.idZec}/Activite/${idActivite}/ActiviteHorsService`, false).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et les disponibilités des activités n'ont pas pu être chargées.");
      return result.json();
    }).then(activitesHorsServices => {
      console.log({ activitesHorsServices });
      this.setState({
        activitesHorsServices: activitesHorsServices.filter(ahs => ahs.idActiviteHorsService !== '00000000-0000-0000-0000-000000000000' && ahs.idActiviteHorsService !== 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF') // Enlève le Aucun
      }, () => this.handleActiviteChampsRequisLoaded(activite));
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("DROIT_ACCES_ACTIVITES_HORS_SERVICE");
    });
  }

  getSecteurs(idActivite, activite = null) {
    this.props.showOverlay("DROIT_ACCES_SECTEURS");

    get("/" + this.props.idZec + '/Activite/' + idActivite + '/Secteur/', false).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et les secteurs n'ont pas pu être chargés.");

      return result.json();
    }).then(secteurs => {
      this.setState({
        secteurs: secteurs
          .filter(s => s.idSecteur !== '00000000-0000-0000-0000-000000000000' && s.idSecteur !== 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF') // Enlève le Aucun
          .sort((a, b) => a.nomSecteur.toUpperCase().localeCompare(b.nomSecteur.toUpperCase(), 'en')), // localeCompare(string, locale) permet d'ignorer les accents (sans quoi les accents viennent après z)
      }, () => this.handleActiviteChampsRequisLoaded(activite));
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("DROIT_ACCES_SECTEURS");
    });
  }

  getPointsEaux(idActivite, activite = null) {
    this.props.showOverlay("DROIT_ACCES_POINTS_EAUX");

    get("/" + this.props.idZec + '/Activite/' + idActivite + '/PointEau/', false).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et les activités n'ont pas pu être chargées.");

      return result.json();
    }).then(pointsEaux => {
      this.setState({
        pointsEaux: pointsEaux
          .filter(pe => pe.idPointEau !== '00000000-0000-0000-0000-000000000000' && pe.idPointEau !== 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF' && pe.disponibleEnLigne && pe.actif) // Enlève le Aucun, les inactifs, et les non isponible en ligne
          .sort((a, b) => a.description.toUpperCase().localeCompare(b.description.toUpperCase(), 'en')), // localeCompare(string, locale) permet d'ignorer les accents (sans quoi les accents viennent après z)
      }, () => this.handleActiviteChampsRequisLoaded(activite));
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("DROIT_ACCES_POINTS_EAUX");
    });
  }

  getPointEauQuotas(idPointEau, callback) {
    this.props.showOverlay("DROIT_ACCES_POINTS_EAUX_QUOTAS");
    get("/" + this.props.idZec + '/PointEau/' + idPointEau + '/PointEauQuota/', false).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et les quotas du point d'eau n'ont pas pu être chargées.");
      return result.json();
    }).then(pointEauQuotas => {
    
      this.setState({
        eauQuotasDisponible: true,
        pointEauQuotas: pointEauQuotas
          .filter(peq => peq.idPointEauQuota !== '00000000-0000-0000-0000-000000000000' && peq.idPointEauQuota !== 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF') // Enlève le Aucun
          .map(peq => {
            peq.dateOuverture = peq.dateOuverture === '0001-01-01T00:00:00' ? null : peq.dateOuverture; // Le serveur retourne '0001-01-01T00:00:00' comme date lorsque null dans la BD.
            peq.dateFermeture = peq.dateFermeture === '0001-01-01T00:00:00' ? null : peq.dateFermeture; // On remet à null côté client. Plus loin, ces dates sont ignorées si null.
            return peq
          })
        // .map(peq => (peq.dateOuverture = '0001-01-01T00:00:00' ? null : peq.dateOuverture, peq.dateFermeture = '0001-01-01T00:00:00' ? null : peq.dateFermeture, peq)) 
      }, callback);
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("DROIT_ACCES_POINTS_EAUX_QUOTAS");
    });
  }

  getGibiers(idActivite, activite = null) {
    this.props.showOverlay("DROIT_ACCES_GIBIERS");

    get("/" + this.props.idZec + '/Activite/' + idActivite + '/Gibier/', false).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et les gibiers n'ont pas pu être chargées.");

      return result.json();
    }).then(gibiers => {
      this.setState({
        gibiers: gibiers
          .filter(g => g.idGibier !== '00000000-0000-0000-0000-000000000000' && g.idGibier !== 'FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF') // Enlève le Aucun
          .sort((a, b) => a.nom.toUpperCase().localeCompare(b.nom.toUpperCase(), 'en')), // localeCompare(string, locale) permet d'ignorer les accents (sans quoi les accents viennent après z)
      }, () => this.handleActiviteChampsRequisLoaded(activite));
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("DROIT_ACCES_GIBIERS");
    });
  }
  //#endregion

  //#region popups
  showConfirmDeleteAccompagnateurPopup({ idAccompagnateur, nom, prenom }) {
    this.props.showYesNoPopup({
      title: "CONFIRMATION",
      bodyText: `Êtes-vous certain de vouloir supprimer ${prenom} ${nom} de vos accompagnateurs?`,
      bodyClass: "droit-acces-page",
      footerClass: "droit-acces-page",
      onYesClick: e => {
        this.setState({ accompagnateurs: this.state.accompagnateurs.filter(d => d.idAccompagnateur !== idAccompagnateur) }, () => {
          this.props.hidePopup();
        });
      }
    });
  }

  showConfirmDeleteVehiculePopup({ idVehicule, description, noPlaque }) {
    this.props.showYesNoPopup({
      title: "CONFIRMATION",
      bodyText: `Êtes-vous certain de vouloir supprimer ${description} (${noPlaque}) de vos véhicules?`,
      bodyClass: "droit-acces-page",
      footerClass: "droit-acces-page",
      onYesClick: e => {
        this.setState({ 
          vehicules: this.state.vehicules.filter(v => v.idVehicule !== idVehicule),
          // marquer les véhicules supprimés à avec isDeleted à true 
          mainCustomerVehicles : this.state.mainCustomerVehicles.map(vehicule => {
            if(vehicule.idVehicule === idVehicule) {
              return {...vehicule, isDeleted: true}
            } else {
              return vehicule;
            }
          }),
          // deleteVehicules: this.state.mainCustomerVehicles.filter(v => v.idVehicule === idVehicule) // la suppression se fait au back end
        }, () => {
          this.props.hidePopup();
        });
      }
    });
  }

  showAddAccompagnateurPopup(accompagnateur = null, typeAccompagnateur) {
    if (accompagnateur === null) {
      accompagnateur = {
        idAccompagnateur: generateTempId(),
        nom: "",
        prenom: "",
        email: "",
        relation: 0,
        telephone1: "",
        telephone2: "",
        portable: "",
        idProvince: ID_PROVINCE_QUEBEC_CONFIG_WEB,
        idPays: ID_PAYS_CANADA_CONFIG_WEB,
        adresse: "",
        ville: "",
        codePostal: "",
        dateNaissance: null,
        permisPeche: "",
        permisPetitGibier: "",
        typePermisPeche: -1,
        isSelected: true,
        vehicules: [],
        fqcq: "",
        idVehicule: "00000000-0000-0000-0000-000000000000",
      }
    }

    const styleDivGroups = { marginBottom: '16px', textAlign: 'left' };
    const styleInputs = { width: '100%' };
    const styleLabels = { textAlign: 'left' };

    const updateAccompagnateur = property => e => {
      let value = e.target.value;
      if(property === "codePostal") {
        value = value.toUpperCase(); // mise en majuscule du code postal
      }
      accompagnateur[property] = value;

      this.showAddAccompagnateurPopup(accompagnateur, typeAccompagnateur);
    };

    const renderInput = (label, property, errorProp = null, input = <input type="text" />) => (
      <div style={styleDivGroups}>
        <div className="label" style={styleLabels}>{label}</div>
        {{ ...input, props: { style: styleInputs, value: accompagnateur[property], onChange: updateAccompagnateur(property), ...input.props } }}
        {errorProp !== null && this.state.errors[errorProp] && <span className="color-error" style={{ fontFamily: 'Barlow-Bold', fontSize: '26px' }}>{this.state.errors[errorProp]}</span>}
      </div>
    );
    this.props.showPopup({
      title: "AJOUT " + (typeAccompagnateur === "simple" ? "ACCOMPAGNATEUR" : "DÉPENDANT"),
      body: (
        <>
          {/* duplication section  */}
          <div className="droit-acces-page btn-span-wrapper" style={{ textAlign: 'center', marginTop: '0px',  marginBottom: '30px' }} onClick={e => {
            const accompagnateurDuplique = {
              ...accompagnateur,
              codePostal: this.props.infoClient.codePostal,
              ville: this.props.infoClient.ville,
              adresse: this.props.infoClient.adresse,
              telephone1: this.props.infoClient.telephone1,
              telephone2: this.props.infoClient.telephone2,
            }
            this.showAddAccompagnateurPopup(accompagnateurDuplique, typeAccompagnateur);;
          } }>
          <span>DUPLIQUER LES INFORMATIONS DE MON PROFIL</span>
          </div>
          {/* form */}
          <div className="droit-acces-page" style={{ position: 'relative' }}>
            {typeAccompagnateur == "dependant" &&
              renderInput("RELATION : *", "relation", 'accompagnateurRelation',
                <select value={accompagnateur.relation} onChange={updateAccompagnateur("relation")}>
                  {relations.filter(r => [3, 7, 8, accompagnateur.relation].includes(r.id)).map(r => ( // On n'affiche que conjoint, enfant, petit-enfant, et la relation actuelle si elle est différent de ces choix.
                    <option key={r.id} value={r.id}>
                      {r.id === 0 ? "" : r.descriptionFr}
                    </option>
                  ))}
                </select>)
            }
            {renderInput("NOM : *", "nom", 'accompagnateurNom')}
            {renderInput("PRÉNOM : *", "prenom", 'accompagnateurPrenom')}
            {renderInput("COURRIEL :", "email", 'accompagnateurEmail')}
            {renderInput("PAYS :", "idPays", null,
              <select>
                {(this.state.pays || []).map(pays => (
                  <option key={pays.idPays} value={pays.idPays}>
                    {pays.description.toUpperCase()}
                  </option>
                ))}
              </select>
            )}
            {renderInput("PROVINCE :", "idProvince", null,
              <select>
                {(this.state.provinces || []).filter(p => p.idPays === accompagnateur.idPays).map(province => (
                  <option key={province.idProvince} value={province.idProvince}>
                    {province.description.toUpperCase()}
                  </option>
                ))}
              </select>
            )}
            {renderInput("CODE POSTAL : *", "codePostal", 'accompagnateurCodePostal')}
            {renderInput("VILLE : *", "ville", 'accompagnateurVille')}
            {renderInput("ADRESSE : *", "adresse", 'accompagnateurAdresse')}
            {renderInput("TÉLÉPHONE : *", "telephone1", 'accompagnateurTelephone1', <input type="text" maxLength="11" />)}
            {renderInput("TÉLÉPHONE 2 :", "telephone2", 'accompagnateurTelephone2', <input type="text" maxLength="11" />)}
            {renderInput("PORTABLE :", "portable", 'accompagnateurPortable', <input type="text" maxLength="11" />)}
            {renderInput("DATE DE NAISSANCE : *", "dateNaissance", 'accompagnateurDateNaissance',
              <input value={(accompagnateur.dateNaissance || "").substring(0, 10)} ref={this.calendarDateNaissanceAccompagnateurInputRef} readOnly={true} className="clickable" onMouseDown={e => {
                this.toggleCalendrierDateNaissanceAccompagnateur(() => this.showAddAccompagnateurPopup(accompagnateur, typeAccompagnateur));
              }} />
            )}
            <div className="calendar-wrapper" ref={this.calendarDateNaissanceAccompagnateurWrapperDivRef} tabIndex={0} style={this.state.showCalendrierDateNaissanceAccompagnateur ? {} : { display: "none" }}>
              <Calendar
                onDayClick={date => {
                  if (date < new Date("1900-01-01 00:00:00.000") || date > new Date())
                    return;

                  this.setState({
                    showCalendrierDateNaissanceAccompagnateur: false,
                  }, () => {
                    accompagnateur.dateNaissance = toDateString(date);

                    this.showAddAccompagnateurPopup(accompagnateur, typeAccompagnateur);
                  })
                }}
                initialMonth={accompagnateur.dateNaissance || new Date()}
                minDate={new Date("1900-01-01 00:00:00.000")}
                maxDate={new Date()}
                allowChangeYear={true}
              />
            </div>
            {renderInput("PERMIS DE PÊCHE :", "permisPeche")}
            {renderInput("TYPE DE PERMIS DE PÊCHE :", "typePermisPeche", null,
              <select>
                {typesPermisPeche.filter(p => p.id !== -1 || accompagnateur.typePermisPeche === -1).map(typePermisPeche => ( // Le filter fait en sorte que l'élément vide n'est affiché que lorsque le type de permis de pêche n'a pas encore été sélectionné
                  <option key={typePermisPeche.id} value={typePermisPeche.id}>
                    {typePermisPeche.id === -1 ? "" : typePermisPeche.descriptionFr.toUpperCase()}
                  </option>
                ))}
              </select>)}
            {renderInput("PERMIS DE PETIT GIBIER :", "permisPetitGibier")}
          </div>
        </>
      ),
      footer: (
        <div className="droit-acces-page btn-span-wrapper" style={{ textAlign: 'center', marginTop: '0px' }} onClick={e => {
          this.validateAccompagnateur(accompagnateur, typeAccompagnateur,  valid => {
            if (valid) {
              if (typeAccompagnateur === "simple") {
                this.setState({
                  accompagnateurs: this.state.accompagnateurs.some(a => a.idAccompagnateur === accompagnateur.idAccompagnateur)
                    ? this.state.accompagnateurs.map(d => d.idAccompagnateur === accompagnateur.idAccompagnateur ? accompagnateur : d)
                    : [...this.state.accompagnateurs, { ...accompagnateur, idAccompagnateur: generateTempId() }]
                }, () => {
                  this.props.hidePopup();
                });
              } else {
                this.setState({
                  listeDependant: [...this.state.listeDependant, accompagnateur]
                }, () => {
                  this.props.hidePopup();
                });
              }
            }
            else {
              this.showAddAccompagnateurPopup(accompagnateur, typeAccompagnateur); // Refresh
            }
          });
        }}>
          <span>CONFIRMER</span>
        </div>
      ),
    });
  }

  showAccompagnateursVehiculesPopup() {

    this.props.showPopup({
      title: "CHOIX DES ACCOMPAGNATEURS",
      body: (
        <div className="droit-acces-page">
          <span className="label" style={{ textTransform: 'initial', marginBottom: '16px', display: 'block' }}>Si des accompagnateurs apporteront leurs propres véhicules, veuillez les sélectionner.</span>
          <table>
            <tbody>

              {/* ACCOMPAGNATEURS - DEPENDANTS */}
              <CompanionSelectOrAddVehicle
                accompagnateurList={this.state.listeDependant}
                desactiverNumeroFqcq={this.props.configuration.desactiverNumeroFqcq}
                setNewVehicleRegistrationDetails={(accompagnateur) =>
                  this.setState({ dependantAddVehiculeDescription: "", dependantAddVehiculeNoPlaque: "", dependantAddVehiculeAerien: false }, () => {
                    this.showAddVehiculePopup(accompagnateur, null, "dependant");
                  })
                }
                updateListVehicle={(e, accompagnateur) =>
                  this.setState({ listeDependant: this.state.listeDependant.map(a => (a.idVehicule = a.idAccompagnateur === accompagnateur.idAccompagnateur ? e.target.value : a.idVehicule, a)) }, () => {
                    this.showAccompagnateursVehiculesPopup();
                  })
                }
                onchangeInputText={(e, accompagnateur) =>
                  this.setState({
                    listeDependant: this.state.listeDependant.map(a => (a.fqcq = a.idAccompagnateur === accompagnateur.idAccompagnateur ? e.target.value : a.fqcq, a))
                  }, () => {
                    this.showAccompagnateursVehiculesPopup()
                  })
                }
              />
              {/* ACCOMPAGNATEURS - SIMPLE */}
              <CompanionSelectOrAddVehicle
                accompagnateurList={this.state.accompagnateurs}
                desactiverNumeroFqcq={this.props.configuration.desactiverNumeroFqcq}
                setNewVehicleRegistrationDetails={(accompagnateur) =>
                  this.setState({ dependantAddVehiculeDescription: "", dependantAddVehiculeNoPlaque: "", dependantAddVehiculeAerien: false }, () => {
                    this.showAddVehiculePopup(accompagnateur, null, "simple");
                  })
                }
                updateListVehicle={(e, accompagnateur) =>
                  this.setState({ accompagnateurs: this.state.accompagnateurs.map(a => (a.idVehicule = a.idAccompagnateur === accompagnateur.idAccompagnateur ? e.target.value : a.idVehicule, a)) }, () => {
                    this.showAccompagnateursVehiculesPopup();
                  })
                }
                onchangeInputText={(e, accompagnateur) =>
                  this.setState({
                    accompagnateurs: this.state.accompagnateurs.map(a => (a.fqcq = a.idAccompagnateur === accompagnateur.idAccompagnateur ? e.target.value : a.fqcq, a))
                  }, () => {
                    this.showAccompagnateursVehiculesPopup()
                  })
                } />
            </tbody>
          </table>
        </div>
      ),
      footer: (
        <div>
          <div className="droit-acces-page btn-span-wrapper" style={{ textAlign: 'center', marginTop: '0px' }} onClick={e => {
            this.validateAndGoToNextStep(); // Comme la validation vérifie si le popup de choix des véhicules des accompagnateurs est ouverte, cette validation doit être avant le this.props.hidePopup()
            this.props.hidePopup();
          }}>
            <span>CONFIRMER</span>
          </div>
        </div>
      ),
      footerStyle: { backgroundColor: 'var(--light-gray)' },
    });
  }

  showAddVehiculePopup(accompagnateur = null, vehicule = null, typeAccompagnateur) {
    this.props.showPopup({
      title: "AJOUT D'UN VÉHICULE",
      onCloseClick: () => {
        if (accompagnateur === null)
          this.props.hidePopup();
        else
          this.showAccompagnateursVehiculesPopup();
      },
      body: (
        <div className="droit-acces-page">
          <div className="label" style={{ textAlign: 'left' }}>DESCRIPTION :</div>
          <input type="text" style={{ width: '100%', marginBottom: '16px' }} value={ typeAccompagnateur !== "dependant" ? this.state.addVehiculeDescription : this.state.dependantAddVehiculeDescription} onChange={e => {
            if(typeAccompagnateur !== "dependant") {
              this.setState({ addVehiculeDescription: e.target.value }, () => {
                this.showAddVehiculePopup(accompagnateur, vehicule, typeAccompagnateur);
              });
            } else {
              this.setState({ dependantAddVehiculeDescription: e.target.value }, () => {
                this.showAddVehiculePopup(accompagnateur, vehicule, typeAccompagnateur);
              });
            }
          }} />
          <div className="label" style={{ textAlign: 'left' }}>NO. PLAQUE :</div>
          <input type="text" style={{ width: '100%', marginBottom: '16px' }} value={typeAccompagnateur !== "dependant" ? this.state.addVehiculeNoPlaque : this.state.dependantAddVehiculeNoPlaque} onChange={e => {
            if(typeAccompagnateur !== "dependant") {
              this.setState({ addVehiculeNoPlaque: e.target.value }, () => {
                this.showAddVehiculePopup(accompagnateur, vehicule, typeAccompagnateur);
              });
            } else {
              this.setState({ dependantAddVehiculeNoPlaque: e.target.value }, () => {
                this.showAddVehiculePopup(accompagnateur, vehicule, typeAccompagnateur);
              });
            }

          }} />
          {this.props.configuration.pavAfficherCaseTransportAerienVehicule === "True" &&
            <>
              <div className="label" style={{ float: 'left' }}>AÉRIEN :</div>
              <input type="checkbox" style={{ float: 'left', marginLeft: '16px' }} checked={ typeAccompagnateur !== "dependant" ? this.state.addVehiculeAerien : this.state.dependantAddVehiculeAerien} readOnly onClick={e => {
                if(typeAccompagnateur !== "dependant") {
                  this.setState({ addVehiculeAerien: !this.state.addVehiculeAerien}, () => {
                    this.showAddVehiculePopup(accompagnateur, vehicule);
                  });
                } else {   
                  this.setState({ dependantAddVehiculeAerien: !this.state.dependantAddVehiculeAerien}, () => {
                    this.showAddVehiculePopup(accompagnateur, vehicule);
                  });
                }
              }}
              />
            </>
          }
        </div>
      ),
      footer: (
        <div className="droit-acces-page btn-span-wrapper" style={{ textAlign: 'center', marginTop: '0px' }} onClick={e => {
          const newVehicule = {
            ...vehicule,
            idVehicule: vehicule === null ? generateTempId() : vehicule.idVehicule,
            description: typeAccompagnateur !== "dependant" ? this.state.addVehiculeDescription.trim() : this.state.dependantAddVehiculeDescription,
            noPlaque: typeAccompagnateur !== "dependant" ? this.state.addVehiculeNoPlaque.trim() : this.state.dependantAddVehiculeNoPlaque,
            type: this.state.addVehiculeAerien ? 'AE' : 'TE',
            isSelected: true,
          };
          if(typeAccompagnateur !== "dependant") {
            if (this.state.addVehiculeDescription.trim() === "" || this.state.addVehiculeNoPlaque.trim() === "") {
              this.props.setAlert("Assurez-vous que tous les champs sont remplis puis ressayez.", "danger");
              return;
            }
  
            if (accompagnateur === null) {
              this.setState({
                vehicules: vehicule === null
                  ? [...this.state.vehicules, newVehicule]
                  : this.state.vehicules.map(v => v.idVehicule === newVehicule.idVehicule ? newVehicule : v)
              }, () => {
                this.props.hidePopup();
              });
            }
            else {
              this.setState({
                accompagnateurs: this.state.accompagnateurs.map(a => a.idAccompagnateur !== accompagnateur.idAccompagnateur
                  ? a
                  : {
                    ...a, idVehicule: newVehicule.idVehicule, vehicules: vehicule === null
                      ? [...a.vehicules, newVehicule]
                      : a.vehicules.map(v => v.idVehicule === newVehicule.idVehicule ? newVehicule : v)
                  }
                )
              }, () => {
                this.showAccompagnateursVehiculesPopup();
              });
            }
          } else{
            
            if (this.state.dependantAddVehiculeDescription.trim() === "" || this.state.dependantAddVehiculeNoPlaque.trim() === "") {
              this.props.setAlert("Assurez-vous que tous les champs sont remplis puis ressayez.", "danger");
              return;
            }
  
            if (accompagnateur === null) {
              this.setState({
                vehicules: vehicule === null
                  ? [...this.state.vehicules, newVehicule]
                  : this.state.vehicules.map(v => v.idVehicule === newVehicule.idVehicule ? newVehicule : v)
              }, () => {
                this.props.hidePopup();
              });
            }
            else {
              this.setState({
                listeDependant: this.state.listeDependant.map(a => a.idAccompagnateur !== accompagnateur.idAccompagnateur
                  ? a
                  : {
                    ...a, idVehicule: newVehicule.idVehicule, vehicules: vehicule === null
                      ? [...a.vehicules, newVehicule]
                      : a.vehicules.map(v => v.idVehicule === newVehicule.idVehicule ? newVehicule : v)
                  }
                )
              }, () => {
                this.showAccompagnateursVehiculesPopup();
              });
            }
          }
        }}>
          <span>{vehicule === null ? "AJOUTER" : "MODIFIER"}</span>
        </div>
      )
    });
  }
  //Cette fonction permet de vérifier si une activité a déjà ete ajoute.
  ActiviteExisteDeja = (choixActivite, listActivite) => {
    const idActiviteChoisie = choixActivite.activite.idActivite;
    const idPointEauActiviteChoisie = choixActivite?.pointEau?.idPointEau ? choixActivite?.pointEau?.idPointEau : null;
    const idSecteurActiviteChoisie = choixActivite?.secteur?.idSecteur ? choixActivite?.secteur?.idSecteur : null
    for (const item of listActivite) {
      const { activite, pointEau, secteur } = item;
      if (activite.idActivite !== idActiviteChoisie) {
        return false;
      }
      if ((pointEau === null && secteur === null) ||
        (pointEau !== null && pointEau.idPointEau === idPointEauActiviteChoisie) ||
        (secteur !== null && secteur.idSecteur === idSecteurActiviteChoisie)) {
        this.props.setAlert("Cette activité existe déjà", "danger")
        return true;
      }
    }
    return false;

  }

  showActivitePopup(choixActivite, secteurs, pointsEaux, gibiers, disponibilites, currentStep = ACTIVITE_STEP_SECTEUR - 1, stepChange = 1, isModification = false, participants = [[], []]) { // stepChange est utilisé pour indiquer la prochaine étape à afficher. stepChange doit être -1 pour reculer d'une étape ou 1 pour avancer d'une étape. Si l'étape n'est pas disponible avec les paramètres d'activités choisis, alors stepChange est utilisé pour déterminer dans quel direction on doit passer l'étape.
    let nextStep = currentStep + stepChange;
    if (isModification) {
      if (participants[0].length > 0) {
        if (nextStep === ACTIVITE_STEP_JOURNEES) {
          this.showActivitePopupJournees(choixActivite, secteurs, pointsEaux, gibiers, disponibilites, participants);
          return;
        }
        if (nextStep === ACTIVITE_STEP_PARTICIPANTS) {
          this.showActivitePopupParticipants(choixActivite, secteurs, pointsEaux, gibiers, disponibilites);
          return;
        }
      }
      if (nextStep === ACTIVITE_STEP_SECTEUR && stepChange === 1 && choixActivite.activite.description !== "Villégiature") {
        this.showActivitePopupSecteur(choixActivite, secteurs, pointsEaux, gibiers, disponibilites);
        return;
      }

      if (stepChange === 1) {

        for (let activite in this.state.activitesChoisies) {
          if (this.state.activitesChoisies[activite].idActiviteChoisie === choixActivite.idActiviteChoisie) {
            this.state.activitesChoisies[activite] = choixActivite;
            this.setState({ activitesChoisies: this.state.activitesChoisies }, () => {
            });
            break;
          }
        }
      }
      else
        this.setState({ errors: {} });

      this.props.hidePopup();
      return;
    }

    while (true) {
      switch (nextStep) {
        case ACTIVITE_STEP_SECTEUR - 1: {
          this.setState({ errors: {} });
          this.props.hidePopup();
          return;
        }
        case ACTIVITE_STEP_SECTEUR: {
          const showChoixSecteurPointEauGibiers = (secteurs.length > 0 && choixActivite.activite.demanderSecteur)
            || (pointsEaux.length > 0 && choixActivite.activite.demanderPointEau)
            || (gibiers.length > 0 && choixActivite.activite.chasse);

          if (showChoixSecteurPointEauGibiers)
            this.showActivitePopupSecteur(choixActivite, secteurs, pointsEaux, gibiers, disponibilites);
          else {
            nextStep += stepChange; // Aucun secteur, point d'eau, ou gibier à choisir. On passe cette étape.
            continue;
          }

          return;
        }
        case ACTIVITE_STEP_PARTICIPANTS: {
          if (this.state.accompagnateurs.filter(a => a.isSelected).length === 0) {
            nextStep += stepChange; // Un seul participant (la personne principale). On pass cette étape.
            continue;
          }
          else
            this.showActivitePopupParticipants(choixActivite, secteurs, pointsEaux, gibiers, disponibilites);

          return;
        }
        case ACTIVITE_STEP_JOURNEES: {
          var arrive = new Date(this.state.dateArrivee.getTime());
          arrive.setHours(0, 0, 0, 0);

          var depart = new Date(this.state.dateDepart.getTime());
          depart.setHours(0, 0, 0, 0);

          var timeDiff = Math.abs(depart.getTime() - arrive.getTime());
          var days = Math.round(timeDiff / (1000 * 60 * 60 * 24)) + 1; // 1000 * 60 * 60 * 24 === le nombre de millisecondes en une journée. Date.getTime() retourne un timestamp en millisecondes.

          if (days === 1) {
            nextStep += stepChange; // Une seule journée. On pass cette étape.
            continue;
          }
          else
            this.showActivitePopupJournees(choixActivite, secteurs, pointsEaux, gibiers, disponibilites);

          return;
        }
        case ACTIVITE_STEP_JOURNEES + 1: {

          let activiteExisteDeja = this.ActiviteExisteDeja(choixActivite, this.state.activitesChoisies)
          this.setState({
            activitesChoisies: activiteExisteDeja ? this.state.activitesChoisies : [...this.state.activitesChoisies, choixActivite]
          }, () => {
            this.props.showYesNoPopup({
              title: "PASSER AU SOMMAIRE",
              bodyText: `L'activité a été correctement ajoutée. Voulez-vous passer au sommaire ou ajouter une autre activité?`,
              bodyClass: "droit-acces-page",
              footerClass: "droit-acces-page",
              footer: (
                <div className="binary-btn-holder">
                  <div className="binary-btn-wrapper positive-left" onClick={e => this.props.hidePopup()}>
                    <span>AJOUTER UNE AUTRE ACTIVITÉ</span>
                  </div>
                  <div className="binary-btn-wrapper positive" onClick={e => {
                    this.props.hidePopup();
                    this.validateAndGoToNextStep();
                  }}>
                    <span>PASSER AU SOMMAIRE</span>
                  </div>
                </div>
              ),
            });
          });

        }
        default: {
          console.log(new Error("Étape inconnue: " + nextStep));
          return;
        }
      }
    }
  }

  showActivitePopupSecteur(choixActivite, secteurs, pointsEaux, gibiers, disponibilites) {
    const styleDivGroups = { marginBottom: '16px', textAlign: 'left' };
    const styleInputs = { width: '100%' };
    const styleLabels = { textAlign: 'left' };
    const errorStyle = { fontFamily: 'Barlow-Bold', fontSize: '26px' };
    const isModification = this.state.activitesChoisies.find(activite => activite.idActiviteChoisie === choixActivite.idActiviteChoisie) !== undefined;
    const choixActiviteCopy = _.cloneDeep(choixActivite);

    //write logic here for filtre multiple activites
    if (isModification && choixActiviteCopy.gibiers.length !== 0)
      gibiers = choixActivite.gibiers;

    this.props.showPopup({
      onCloseClick: () => {
        this.setState({ errors: {} });
        this.props.hidePopup();
      },
      title: `${isModification ? "MODIFICATION" : "AJOUT"} ACTIVITÉ`,
      contentClass: "droit-acces-activite-popup-content",
      body: (
        <div className="droit-acces-page" style={{ position: 'relative' }}>
          {secteurs.length > 0 && choixActiviteCopy.activite.demanderSecteur &&
            <div style={styleDivGroups}>
              <div className="label" style={styleLabels}>SECTEUR: {this.state.activites.map(elem => elem.description == "Pêche" ? elem.validerSecteur ? '(obligatoire)' : '(optionnel)' : "")}</div>
              <select value={(choixActiviteCopy.secteur || {}).idSecteur || ""} style={styleInputs} onChange={e => {
                choixActiviteCopy.secteur = secteurs.find(s => s.idSecteur === e.target.value);

                this.showActivitePopupSecteur(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites);
                return;
              }}>
                <option value="00000000-0000-0000-0000-000000000000"></option>
                {secteurs.map(s => (
                  <option key={s.idSecteur} value={s.idSecteur}>
                    {s.nomSecteur}
                  </option>
                ))}
              </select>
              {this.state.errors.activiteSecteur && <span className="color-error" style={errorStyle}>{this.state.errors.activiteSecteur}</span>}
            </div>
          }
          {pointsEaux.length > 0 && choixActiviteCopy.activite.demanderPointEau &&
            <div style={styleDivGroups}>
              <div className="label" style={styleLabels}>POINT D'EAU: {this.state.activites.map(elem => elem.description == "Pêche" ? elem.validerPointEau ? '(obligatoire)' : '(optionnel)' : "")} </div>
              <select value={(choixActiviteCopy.pointEau || {}).idPointEau || ""} style={styleInputs} onChange={e => {
                disponibilites.pointEau = [...disponibilites.periode]; // Reset les disponibilités du point d'eau.
                if (e.target.value === '00000000-0000-0000-0000-000000000000') {
                  choixActiviteCopy.pointEau = null;

                  if (!isModification)
                    choixActiviteCopy.participants.forEach(p => p.jours = [...disponibilites.activite]); // Reset les jours sélectionnés par défaut de chaque participant.

                  this.showActivitePopupSecteur(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites);
                  return;
                }
                else {
                  const idPointEau = e.target.value;
                  const pointEau = pointsEaux.find(s => s.idPointEau === idPointEau);
                  this.getPointEauQuotas(idPointEau, (datas) => { // NOTE: this.getPointEauQuotas update this.state.pointEauQuotas.
                    // On détermine les journées disponibles pour ce point d'eau (et fonction des quotas et journées disponibles du point d'eau)
                    const disponibilitesPointEau = [];

                    const jourArrivee = new Date(this.state.dateArrivee.getTime());
                    jourArrivee.setHours(0, 0, 0, 0);

                    const jourDepart = new Date(this.state.dateDepart.getTime());
                    jourDepart.setHours(0, 0, 0, 0);

                    for (let jour = jourArrivee; jour.getTime() <= jourDepart; jour.setDate(jour.getDate() + 1)) {
                      const jourDebut = new Date(jour.getTime()); // jourDebut: le moment où la journée d'activité débute.
                      if (jour === jourArrivee)
                        jourDebut.setHours(this.state.heureArrivee.substr(0, 2), this.state.heureArrivee.substr(3, 2), 0, 0);

                      const jourFin = new Date(jour.getTime()); // jourFin: le moment où la journée d'activité se termine.
                      if (jourFin.getTime() === jourDepart.getTime())
                        jourFin.setHours(this.state.heureDepart.substr(0, 2), this.state.heureDepart.substr(3, 2), 59, 999);
                      else
                        jourFin.setHours(23, 59, 59, 999);

                      const jourIndexManisoft = jour.getDay() == 0 ? 6 : jour.getDay() - 1; // Dans Manisoft, le jours sont mappé du Lundi au Dimanche alors que JS (et C#) mappent les jours du Dimanche au Samedi.
                      const isPointEauOuvertCeJour = ((1 << jourIndexManisoft) & pointEau.joursDisponibilite) > 0; // Certains points d'eaux ne sont ouvert que certains jours de la semaine (e.g.: seulement les samedi et dimanche).
                      const isAnyQuotaOuvert = this.state.pointEauQuotas.length === 0 || this.state.pointEauQuotas.some(q => periodsOverlap(new Date(q.dateOuverture || '1999-01-01'), new Date(q.dateFermeture || '2099-01-01'), jourDebut, jourFin)); // Certaines espèces de poissons ne sont ouvert qu'une portion de l'année.

                      const isDisponible = isPointEauOuvertCeJour && isAnyQuotaOuvert;
                      if (isDisponible)
                        disponibilitesPointEau.push(new Date(jour.getTime()));
                    }

                    // On valide qu'il y a au moins une journée disponible
                    const joursDisponibles = disponibilites.periode.filter(dp => disponibilites.activite.some(da => da.getTime() === dp.getTime()) && disponibilitesPointEau.some(dpe => dpe.getTime() === dp.getTime()));

                    if (joursDisponibles.length === 0) {
                      this.props.setAlert("Ce point d'eau n'est pas disponible durant la période de votre enregistrement. Veuillez choisir un point d'eau différent.", "danger");
                      this.setState({eauQuotasDisponible: false})
                      this.showActivitePopupSecteur(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites);
                      return;
                    }

                    // On sélectionne le point d'eau et met à jour les champs qui en dépendent
                    choixActiviteCopy.pointEau = pointEau;
                    disponibilites.pointEau = disponibilitesPointEau;

                    if (!isModification)
                      choixActiviteCopy.participants.forEach(p => p.jours = [...joursDisponibles]);
                    else {
                      for (let p of Object.values(choixActiviteCopy.participants)) {
                        for (let indexJour = p.jours.length - 1; indexJour > -1; indexJour--) {
                          if (!joursDisponibles.find(date => date.toDateString() === p.jours[indexJour].toDateString()))
                            p.jours.splice(indexJour, 1);
                        }
                      }
                    }

                    // Refresh
                    this.showActivitePopupSecteur(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites);
                    return;
                  });
                }
              }}>
                <option value="00000000-0000-0000-0000-000000000000"></option>
                {pointsEaux.map(s => (
                  <option key={s.idPointEau} value={s.idPointEau}>
                    {s.description}
                  </option>
                ))}
              </select>
              {this.state.errors.activitePointEau && <span className="color-error" style={errorStyle}>{this.state.errors.activitePointEau}</span>}
            </div>
          }
          {gibiers.length > 0 && choixActiviteCopy.activite.chasse &&
            <div style={styleDivGroups}>
              <div className="label" style={styleLabels}>GIBIERS: </div>
              <div>
                {gibiers.map(g => (
                  <div key={g.idGibier} style={{ marginTop: '16px' }}>
                    <input type="checkbox" id={g.idGibier} checked={choixActiviteCopy.gibiers.some(cag => cag.idGibier === g.idGibier)} onChange={e => {
                      const wasSelected = choixActiviteCopy.gibiers.some(cag => cag.idGibier === g.idGibier);
                      if (wasSelected)
                        choixActiviteCopy.gibiers = choixActiviteCopy.gibiers.filter(cag => cag.idGibier !== g.idGibier);
                      else
                        choixActiviteCopy.gibiers.push(g);

                      this.showActivitePopupSecteur(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites);
                      return;
                    }} />
                    <label htmlFor={g.idGibier} className="unselectable">
                      <span className="clickable" style={{ fontFamily: 'Barlow-Bold', fontSize: '26px', paddingLeft: '24px' }}>{g.nom}</span>
                    </label>
                  </div>
                ))}
              </div>
              {this.state.errors.activiteGibiers && <span className="color-error" style={errorStyle}>{this.state.errors.activiteGibiers}</span>}
            </div>
          }
        </div>
      ),
      footer: (
        <div className="droit-acces-page binary-btn-holder">
          <div className="binary-btn-wrapper previous" onClick={e => {
            this.showActivitePopup(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites, ACTIVITE_STEP_SECTEUR, -1, isModification);
          }}>
            <span>ANNULER</span>
          </div>
          <div className="binary-btn-wrapper positive" onClick={e => {
            this.validateActiviteEtapeSecteur(choixActiviteCopy, secteurs, pointsEaux, gibiers, valid => {
              if (valid)
                this.showActivitePopup(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites, ACTIVITE_STEP_SECTEUR, 1, isModification);
              else {
                this.showActivitePopupSecteur(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites); // Refresh
                return;
              }
            });
          }}>
            <span>{isModification ? "MODIFIER" : this.state.accompagnateurs.filter(a => a.isSelected).length === 0 && this.state.dateArrivee.toDateString() === this.state.dateDepart.toDateString() ? "CONFIRMER" : "SUIVANT"}</span>
          </div>
        </div>
      ),
    });
  }

  renderAccompagnateurs = (accompagnateurs, isModification, choixActiviteCopy, disponibilites, secteurs, pointsEaux, gibiers) => {
   
    let accompaganteurList = isModification ? this.state.accompagnateursDeBaseSelectionne : accompagnateurs;

    return accompaganteurList.map(a => (
      <div key={a.idAccompagnateur} style={{ marginTop: '16px' }}>
        <input
          type="checkbox"
          id={a.idAccompagnateur}
          checked={choixActiviteCopy.participants.some(cap => cap.idAccompagnateur === a.idAccompagnateur)}
          onChange={e => {
            const wasSelected = choixActiviteCopy.participants.some(cap => cap.idAccompagnateur === a.idAccompagnateur);
  
            if (wasSelected)
              choixActiviteCopy.participants = choixActiviteCopy.participants.filter(cap => cap.idAccompagnateur !== a.idAccompagnateur);
            else
              choixActiviteCopy.participants.push({
                idAccompagnateur: a.idAccompagnateur,
                nom: a.nom,
                prenom: a.prenom,
                jours: [...disponibilites.activite],
              });
  
            this.showActivitePopupParticipants(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites);
            return;
          }}
        />
        <label htmlFor={a.idAccompagnateur} className="unselectable">
          <span className="clickable" style={{ fontFamily: 'Barlow-Bold', fontSize: '26px', paddingLeft: '24px', wordBreak: 'break-word' }}>{a.prenom} {a.nom}</span>
        </label>
      </div>
    ));
  };

  showActivitePopupParticipants(choixActivite, secteurs, pointsEaux, gibiers, disponibilites) {
    const styleDivGroups = { marginBottom: '16px', textAlign: 'left' };
    const styleInputs = { width: '100%' };
    const styleLabels = { textAlign: 'left' };
    const errorStyle = { fontFamily: 'Barlow-Bold', fontSize: '26px' };
    const activiteOrigine = this.state.activitesChoisies.find(activite => activite.idActiviteChoisie === choixActivite.idActiviteChoisie);
    const isModification = activiteOrigine !== undefined;
    const choixActiviteCopy = _.cloneDeep(choixActivite);

    let participants = [[], []];
    if (activiteOrigine) {
      for (let participantCopy of choixActiviteCopy.participants) {
        let participantOrigine = activiteOrigine.participants.find(participant => participantCopy.idAccompagnateur === participant.idAccompagnateur);

        if (participantOrigine)
          participants[1].push(participantCopy);
        else
          participants[0].push(participantCopy);
      }
    }
    this.props.showPopup({
      onCloseClick: () => {
        this.setState({ errors: {} });
        this.props.hidePopup();
      },
      title: `${isModification ? "MODIFICATION" : "AJOUT"} ACTIVITÉ`,
      contentClass: "droit-acces-activite-popup-content",
      body: (
        <div className="droit-acces-page" style={{ position: 'relative' }}>
          <div style={styleDivGroups}>
            <div className="label" style={styleLabels}>QUI PARTICIPENT À L'ACTIVITÉ ?</div>
            <div>
              <div style={{ marginTop: '16px' }}>
                <input type="checkbox" id="chk-participant-personne-principale" checked={choixActiviteCopy.participants.some(cap => cap.idAccompagnateur === null)} onChange={e => {
                  const wasSelected = choixActiviteCopy.participants.some(cap => cap.idAccompagnateur === null);
                  
                  if (wasSelected) 
                    choixActiviteCopy.participants = choixActiviteCopy.participants.filter(cap => cap.idAccompagnateur !== null);
                  else
                    choixActiviteCopy.participants.push({
                      idAccompagnateur: null,
                      nom: this.state.infoResponsable.nom,
                      prenom: this.state.infoResponsable.prenom,
                      jours: [...disponibilites.activite],
                    }); 

                  this.showActivitePopupParticipants(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites);
                  return;
                }} />
                <label htmlFor="chk-participant-personne-principale" className="unselectable">
                  <span className="clickable" style={{ fontFamily: 'Barlow-Bold', fontSize: '26px', paddingLeft: '24px', wordBreak: 'break-word' }}>{this.state.infoResponsable.prenom} {this.state.infoResponsable.nom}</span>
                </label>
              </div>
              
              {/* Gestion des accompagnateurs et dependants dans ce bloc */}

              {/* accompagnateurs */}
              {this.renderAccompagnateurs(this.state.listeDependant.concat(this.state.accompagnateurs) , isModification, choixActiviteCopy, disponibilites, secteurs, pointsEaux, gibiers)}

            </div>
            {this.state.errors.activiteParticipants && <span className="color-error" style={errorStyle}>{this.state.errors.activiteParticipants}</span>}
          </div>
        </div>
      ),
      footer: (
        <div className="droit-acces-page binary-btn-holder">
          <div className="binary-btn-wrapper previous" onClick={e => {
            this.showActivitePopup(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites, ACTIVITE_STEP_PARTICIPANTS, -1, isModification)
          }}>
            <span>{isModification || choixActiviteCopy.activite.description === "Villégiature" ? "ANNULER" : "PRÉCÉDENT"}</span>
          </div>
          <div className="binary-btn-wrapper positive" onClick={e => {
            this.validateActiviteEtapeParticipants(choixActiviteCopy, valid => {
              if (valid)
                this.showActivitePopup(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites, ACTIVITE_STEP_PARTICIPANTS, 1, isModification, participants);
              else {
                this.showActivitePopupParticipants(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites); // Refresh
                return;
              }
            });
          }}>
            <span>{participants[0].length === 0 && isModification ? "MODIFIER" : this.state.dateArrivee.toDateString() === this.state.dateDepart.toDateString() ? "CONFIRMER" : "SUIVANT"}</span>
          </div>
        </div>
      ),
    });
  }

  showActivitePopupJournees(choixActivite, secteurs, pointsEaux, gibiers, disponibilites, participants = [[], []]) {
    const styleDivGroups = { marginBottom: '16px', textAlign: 'left' };
    const styleInputs = { width: '100%' };
    const styleLabels = { textAlign: 'left', wordBreak: 'break-word' };
    const errorStyle = { fontFamily: 'Barlow-Bold', fontSize: '26px' };
    const isModification = this.state.activitesChoisies.find(activite => activite.idActiviteChoisie === choixActivite.idActiviteChoisie) !== undefined;
    const choixActiviteCopy = _.cloneDeep(choixActivite);
    const dateOuverture = this.state.pointEauQuotas[0]?.dateOuverture;
    const dateFermeture = this.state.pointEauQuotas[0]?.dateFermeture;
    const newDateOuverture = new Date(this.state.pointEauQuotas[0].dateOuverture);
    const newDateFermeture = new Date(this.state.pointEauQuotas[0].dateFermeture);

    if (participants[0].length > 0)
      choixActiviteCopy.participants = participants[0];
    this.props.showPopup({
      onCloseClick: () => {
        this.setState({ errors: {} });
        this.props.hidePopup();
      },
      title: `${isModification ? "MODIFICATION" : "AJOUT"} ACTIVITÉ`,
      contentClass: "droit-acces-activite-popup-content",
      body: (
        <div className="droit-acces-page" style={{ position: 'relative' }}>
          <div style={styleDivGroups}>
            <div className="label" style={styleLabels}>QUELS SONT LES JOURS DE PARTICIPATION DE CHACUN DES PARTICIPANTS ?</div>
            <div>
              {choixActiviteCopy.participants.map(p => (
                <div key={p.idAccompagnateur || 0} style={{ marginTop: '16px', marginLeft: '32px' }}>
                  <div className="label" style={styleLabels}>{p.prenom} {p.nom} :</div>
                  {disponibilites.periode
                    .filter(elem =>
                    /* Condition de filtrage basée sur la selection d'un point eau ou pas 
                    Si eauQuotasDisponible est true un point eau a ete selectionne
                    */
                    (this.state.eauQuotasDisponible && ((dateFermeture && dateFermeture) && ((newDateOuverture.getTime() <= elem.getTime()) && (newDateFermeture.getTime() > elem.getTime()))) ||
                      (this.state.eauQuotasDisponible && ((dateOuverture && !dateFermeture) && (newDateOuverture.getTime() <= elem.getTime())) ||
                        (this.state.eauQuotasDisponible && ((dateFermeture && !dateOuverture) && (newDateFermeture.getTime() > elem.getTime())))
                        || !this.state.eauQuotasDisponible // Si eauQuotasDisponible est faux, ne pas filtrer et laisser passer tous les éléments
                      )))
                    .map(j => (
                      <div key={j.getTime()} style={{ marginTop: '16px', marginLeft: '32px' }}>
                        <input type="checkbox" id={(p.idAccompagnateur || 0) + " " + j.getTime()} checked={p.jours.some(pj => pj.getTime() === j.getTime())} onChange={e => {
                          const canSelect = disponibilites.activite.some(da => da.getTime() === j.getTime()) && disponibilites.pointEau.some(dpa => dpa.getTime() === j.getTime())

                          if (!canSelect)
                            this.props.setAlert("Le point d'eau n'est pas disponible cette journée.", "danger");
                          else {
                            const wasSelected = p.jours.some(pj => pj.getTime() === j.getTime());

                            if (wasSelected)
                              p.jours = p.jours.filter(pj => pj.getTime() !== j.getTime());
                            else {
                              p.jours.push(new Date(j.getTime()));
                              p.jours.sort((a, b) => a.getTime() - b.getTime());
                            }
                          }

                          this.showActivitePopupJournees(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites, participants);
                          return;
                        }} />
                        <label htmlFor={(p.idAccompagnateur || 0) + " " + j.getTime()} className="unselectable">
                          <span className="clickable" style={{ fontFamily: 'Barlow-Bold', fontSize: '26px', paddingLeft: '24px' }}>{toDateString(j)}</span>
                        </label>
                      </div>
                    ))}
                </div>
              ))}
            </div>
            {this.state.errors.activiteJournees && <span className="color-error" style={errorStyle}>{this.state.errors.activiteJournees}</span>}
          </div>
        </div>
      ),
      footer: (
        <div className="droit-acces-page binary-btn-holder">
          <div className="binary-btn-wrapper previous" onClick={e => {
            if (participants[0].length > 0) {
              choixActiviteCopy.participants = [...participants[0], ...participants[1]];
            }
            this.showActivitePopup(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites, ACTIVITE_STEP_JOURNEES, -1, isModification, participants);
          }}>
            <span>{participants[0].length === 0 && (isModification || choixActiviteCopy.activite.description === "Villégiature" && this.state.accompagnateurs.filter(a => a.isSelected).length === 0) ? "ANNULER" : "PRÉCÉDENT"}</span>
          </div>
          <div className="binary-btn-wrapper positive" onClick={e => {
            this.validateActiviteEtapeJournees(choixActiviteCopy, valid => {
              if (valid) {
                if (participants[0].length > 0)
                  choixActiviteCopy.participants = [...participants[0], ...participants[1]];

                  //Verifie que tout les participants n'on pas de jours sans activites
                let joursParticipantNonComplet = choixActiviteCopy.participants.filter((participant) => participant.jours.length < disponibilites.periode.length)

                joursParticipantNonComplet.length > 0 ?
                  this.props.setAlert(`Un ou plusieurs participants n'ont pas sélectionné de jours.`, 'danger')
                  :
                  this.showActivitePopup(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites, ACTIVITE_STEP_JOURNEES, 1, isModification);
              }
              else {
                this.showActivitePopupJournees(choixActiviteCopy, secteurs, pointsEaux, gibiers, disponibilites, participants); // Refresh
                return;
              }
            });
          }}>
            <span>{isModification ? "MODIFIER" : "CONFIRMER"}</span>
          </div>
        </div>
      ),
    });
  }
  //#endregion

  validateAccompagnateur(accompagnateur, typeAccompagnateur, callback) {
    
    const countryCode = this.state.pays.find(p => p.idPays === accompagnateur.idPays).code.toUpperCase();
    const errors = {
      accompagnateurRelation: typeAccompagnateur == "dependant" ? accompagnateur.relation === 0 ? ERREUR_CHAMP_VIDE : "" : "", // si le type d'accompaganteur est un dependant on bloque la generation d'erreur pour ce champs
      accompagnateurNom: accompagnateur.nom.trim() === "" ? ERREUR_CHAMP_VIDE : "",
      accompagnateurPrenom: accompagnateur.prenom.trim() === "" ? ERREUR_CHAMP_VIDE : "",
      accompagnateurTelephone1: accompagnateur.telephone1.trim() === "" ? ERREUR_CHAMP_VIDE : !isValidPhoneNumber(accompagnateur.telephone1.trim()) ? ERREUR_VALEUR_INVALIDE : "",
      accompagnateurTelephone2: accompagnateur.telephone2.trim() === "" ? "" : !isValidPhoneNumber(accompagnateur.telephone2.trim()) ? ERREUR_VALEUR_INVALIDE : "",
      accompagnateurEmail: accompagnateur.email.trim() === "" ? "" : !isValidEmail(accompagnateur.email.trim()) ? ERREUR_COURRIEL_INVALIDE : "",
      accompagnateurPortable: accompagnateur.portable.trim() === "" ? "" : !isValidPhoneNumber(accompagnateur.portable.trim()) ? ERREUR_VALEUR_INVALIDE : "",
      accompagnateurAdresse: accompagnateur.adresse.trim() === "" ? ERREUR_CHAMP_VIDE : "",
      accompagnateurVille: accompagnateur.ville.trim() === "" ? ERREUR_CHAMP_VIDE : "",
      accompagnateurCodePostal: accompagnateur.codePostal.trim() === "" ? ERREUR_CHAMP_VIDE : !isValidPostalCode(accompagnateur.codePostal.trim(), countryCode) ? ERREUR_VALEUR_INVALIDE : "",
      accompagnateurDateNaissance: accompagnateur.dateNaissance === null ? ERREUR_CHAMP_VIDE : accompagnateur.dateNaissance.trim() === "" ? ERREUR_CHAMP_VIDE : isNaN(new Date(accompagnateur.dateNaissance).getTime()) ? ERREUR_VALEUR_INVALIDE : new Date(accompagnateur.dateNaissance.trim()) > new Date() ? ERREUR_VALEUR_INVALIDE : "",
    };

    const valid = Object.values(errors).every(err => (err || "") === "");

    if (!valid)
      this.props.setAlert(`Assurez-vous tous les champs sont valides puis réessayez à nouveau.`, 'danger');

    this.setState({ errors: errors }, () => callback(valid));
  }



  validateActiviteEtapeSecteur(choixActivite, secteurs, pointsEaux, gibiers, callback) {
    const errors = {
      activiteSecteur: choixActivite.activite.validerSecteur == 1 && secteurs.length > 0 && choixActivite.secteur === null ? ERREUR_CHAMP_VIDE : "",
      activitePointEau: choixActivite.activite.validerPointEau == 1 && pointsEaux.length > 0 && choixActivite.pointEau === null ? ERREUR_CHAMP_VIDE : "",
      activiteGibiers: choixActivite.activite.chasse == 1 && gibiers.length > 0 && choixActivite.gibiers.length === 0 ? ERREUR_CHAMP_VIDE : "",
    };

    const valid = Object.values(errors).every(err => (err || "") === "");

    if (!valid)
      this.props.setAlert(`Assurez-vous tous les champs sont valides puis réessayez à nouveau.`, 'danger');

    this.setState({ errors: errors }, () => callback(valid));
  }

  validateActiviteEtapeParticipants(choixActivite, callback) {
    const errors = {
      activiteParticipants: choixActivite.participants.length === 0 ? ERREUR_AU_MOINS_UNE_PERSONNE : "",
    };

    const valid = (errors.activiteParticipants || "") === "";

    if (!valid)
      this.props.setAlert(`Assurez-vous tous les champs sont valides puis réessayez à nouveau.`, 'danger');

    this.setState({ errors: errors }, () => callback(valid));
  }

  validateActiviteEtapeJournees(choixActivite, callback) {
    const errors = {
      activiteJournees: choixActivite.participants.some(p => p.jours.length === 0) ? ERREUR_AU_MOINS_UNE_JOURNEE : "",
    };

    const valid = (errors.activiteJournees || "") === "";

    if (!valid)
      this.props.setAlert(`Assurez-vous tous les champs sont valides puis réessayez à nouveau.`, 'danger');

    this.setState({ errors: errors }, () => callback(valid));
  }

  validateAndGoToNextStep() {
    if (this.validateCanGoNextStep()) {
      this.saveSessionState();
      this.goToNextStep();
    }
  }

  validateCanGoNextStep(direction = 1) {
    switch (this.state.stepId) {
      case STEP_INFO_RESPONSABLE: {
        
        if ((this.state.feuilletNonRemis.length >= this.props.parametresWeb.maxFeuilletsNonRemis) && (this.props.parametresWeb.maxFeuilletsNonRemis > 0)) {
          this.props.setAlert(`Vous avez atteint le nombre maximal de feuillets d'enregistrement non remis. Voici la liste des feuillets: ${this.state.feuilletNonRemis.map(feuilet => (feuilet))}. Veuillez nous contacter pour régulariser votre situation`, 'danger');
          return false;
        } else {
          const countryCode = this.props.authToken != null ? "" : this.state.pays.find(p => p.idPays === this.state.infoResponsable.idPays).code.toUpperCase();
          const errors = {
            nom: this.props.authToken != null ? "" : this.state.infoResponsable.nom.trim() === "" ? ERREUR_CHAMP_VIDE : "",
            prenom: this.props.authToken != null ? "" : this.state.infoResponsable.prenom.trim() === "" ? ERREUR_CHAMP_VIDE : "",
            telephone: this.props.authToken != null ? "" : this.state.infoResponsable.telephone.trim() === "" ? ERREUR_CHAMP_VIDE : !isValidPhoneNumber(this.state.infoResponsable.telephone.trim()) ? ERREUR_VALEUR_INVALIDE : "",
            ville: this.props.authToken != null ? "" : this.state.infoResponsable.ville.trim() === "" ? ERREUR_CHAMP_VIDE : "",
            adresse: this.props.authToken != null ? "" : this.state.infoResponsable.adresse.trim() === "" ? ERREUR_CHAMP_VIDE : "",
            codePostal: this.props.authToken != null ? "" : this.state.infoResponsable.codePostal.trim() === "" ? ERREUR_CHAMP_VIDE : !isValidPostalCode(this.state.infoResponsable.codePostal.trim(), countryCode) ? ERREUR_VALEUR_INVALIDE : "",
            dateNaissance: this.props.authToken != null ? "" : this.state.infoResponsable.dateNaissance === null ? ERREUR_CHAMP_VIDE : isNaN(new Date(this.state.infoResponsable.dateNaissance).getTime()) ? ERREUR_VALEUR_INVALIDE : this.state.infoResponsable.dateNaissance > new Date() ? ERREUR_VALEUR_INVALIDE : "",
            courriel: this.state.infoResponsable.courriel.trim() === "" ? ERREUR_CHAMP_VIDE : !isValidEmail(this.state.infoResponsable.courriel.trim()) ? ERREUR_COURRIEL_INVALIDE : "",
          };
          this.setState({ errors });
          const valid = Object.values(errors).every(err => (err || "") === "");

          if (!valid) {
            let errorMessage = "Assurez-vous que toutes les informations requises sont complétées et valides puis réessayez à nouveau.";
            let errorType = "danger";

            if (direction === 1)
              this.props.setAlert(errorMessage, errorType);
            else if (!this.state.errorsFromGoingBack.find(error => error.stepId === this.state.stepId))
              this.setState({
                errorsFromGoingBack: [
                  { stepId: this.state.stepId, errorMessage, errorType, errors },
                  ...this.state.errorsFromGoingBack
                ]
              });
          }
          else {
            let index = this.state.errorsFromGoingBack.findIndex(error => error.stepId === this.state.stepId);

            if (index !== -1) {
              this.state.errorsFromGoingBack.splice(index, 1);
              this.setState({ errorsFromGoingBack: this.state.errorsFromGoingBack });
            }
          }

          return valid;
        }
      }
      case STEP_ACCOMPAGNATEURS:
        return true;
      case STEP_DATE_HEURE_SEJOUR_ENREGISTREMENT: {

        const now = new Date();
        now.setHours(now.getHours(), now.getMinutes() - 10, 0, 0); // On enlève 10 minutes pour empêcher une erreur ERREUR_PEUT_PAS_ARRIVER_AVANT_MAINTENANT lorsque l'utilisateur garde l'heure d'arrivée par défaut

        const arrivee = new Date(this.state.dateArrivee);
        arrivee.setHours(this.state.heureArrivee.substring(0, 2), this.state.heureArrivee.substring(3, 5), 0, 0);

        const depart = new Date(this.state.dateDepart);
        depart.setHours(this.state.heureDepart.substring(0, 2), this.state.heureDepart.substring(3, 5), 0, 0);

        const differenceJours = Math.floor((new Date(this.state.dateDepart) - new Date(this.state.dateArrivee)) / (1000 * 60 * 60 * 24));

        const errors = {
          dateArrivee: !this.state.dateArrivee ? ERREUR_CHAMP_VIDE : "",
          dateDepart: !this.state.dateDepart ? ERREUR_CHAMP_VIDE : "",
          heureArrivee: !this.state.heureArrivee ? ERREUR_CHAMP_VIDE : this.state.dateArrivee && arrivee < now ? ERREUR_PEUT_PAS_ARRIVER_AVANT_MAINTENANT : "",
          // ***old heureDepart: !this.state.heureDepart ? ERREUR_CHAMP_VIDE : this.state.dateArrivee && this.state.dateDepart && depart < arrivee ?  ERREUR_PEUT_PAS_PARTIR_AVANT_DEPART : "", 
          heureDepart: !this.state.heureDepart ? ERREUR_CHAMP_VIDE : this.state.dateArrivee && this.state.dateDepart && compareDate(depart, arrivee) ? compareHeure(this.state.heureArrivee, this.state.heureDepart) ? '' : ERREUR_PEUT_PAS_PARTIR_AVANT_DEPART : '',
          idLieuSejour: this.props.configuration.forcerLieuSejourEnregistrement.toUpperCase() === 'TRUE' && (this.state.idLieuSejour || '00000000-0000-0000-0000-000000000000') === '00000000-0000-0000-0000-000000000000' ? ERREUR_CHAMP_VIDE : "",
          idTypeSejour: this.props.configuration.forcerTypeSejourEnregistrement.toUpperCase() === 'TRUE' && (this.state.idTypeSejour || '00000000-0000-0000-0000-000000000000') === '00000000-0000-0000-0000-000000000000' ? ERREUR_CHAMP_VIDE : "",
        };
        this.setState({ errors });
        const valid = Object.values(errors).every(err => (err || "") === "");


        if ((differenceJours > this.state.maxJour) && (this.state.maxJour > 0)) {
          this.props.setAlert(`Votre enregistrement dépasse le nombre maximum de jours permis (maximum ${this.state.maxJour})`, "danger");
          return false;
        }

        //gestion du nombre de jour a l'avance
        if (this.state.delaisEnregistrement && this.state.delaisEnregistrement != null && this.state.delaisEnregistrement != 0) {
          if(this.state.dateArrivee != null && this.state.dateArrivee != undefined) {
            let today = new Date();
        
          //soustraction des jours
          
          let dateArrivees = new Date(this.state.dateArrivee)
          dateArrivees.setDate(this.state.dateArrivee.getDate() - this.state.delaisEnregistrement);
          // comparaison
          if (dateArrivees >= today) {
            this.props.setAlert(`Vous ne pouvez pas vous inscrire plus de ${this.state.delaisEnregistrement} jour${this.state.delaisEnregistrement > 1 ? '(s)' : ''} à l'avance`, "danger");
            return false;
          }
          }
        }

        if (!valid) {
          let errorMessage = "Assurez-vous que les dates d'arrivée et de départ sont valides puis réessayez à nouveau.";
          let errorType = "danger";

          if (direction === 1)
            this.props.setAlert(errorMessage, errorType);
          else if (!this.state.errorsFromGoingBack.find(error => error.stepId === this.state.stepId))
            this.setState({
              errorsFromGoingBack: [
                { stepId: this.state.stepId, errorMessage, errorType, errors },
                ...this.state.errorsFromGoingBack
              ]
            });
        }
        else {
          let index = this.state.errorsFromGoingBack.findIndex(error => error.stepId === this.state.stepId);

          if (index !== -1) {
            this.state.errorsFromGoingBack.splice(index, 1);
            this.setState({ errorsFromGoingBack: this.state.errorsFromGoingBack });
          }
        }
        this.setState({ hasAnythingFromStepTwoChanged: false })
        return valid;
      }
      case STEP_VEHICULES:
        if (this.props.configuration.cacherVehiculesSurPav.toUpperCase() !== "TRUE" && (this.props.authToken == null ? this.state.vehicules.length == 0 : this.state.vehicules.filter(v => v.isSelected).length === 0)) {
          let errorMessage = "Sélectionnez au moins un véhicule puis réessayez à nouveau.";
          let errorType = "danger";

          const errors = {
            vehicules: this.state.vehicules.length === 0 ? ERREUR_CHAMP_VIDE : "",
          };
          this.setState({ errors });

          if (direction === 1)
            this.props.setAlert(errorMessage, errorType);
          else if (!this.state.errorsFromGoingBack.find(error => error.stepId === this.state.stepId))
            this.setState({
              errorsFromGoingBack: [
                { stepId: this.state.stepId, errorMessage, errorType, errors },
                ...this.state.errorsFromGoingBack
              ]
            });

          return false;
        }

        let index = this.state.errorsFromGoingBack.findIndex(error => error.stepId === this.state.stepId);

        if (index !== -1) {
          this.state.errorsFromGoingBack.splice(index, 1);
          this.setState({ errorsFromGoingBack: this.state.errorsFromGoingBack });
        }

        return true;

      case STEP_ACTIVITES: {
        const idsAccompagnateursWithoutAnyActivite = [null] // null pour la personne principale
          .concat(this.state.accompagnateurs
            .filter(a => a.isSelected)
            .map(a => a.idAccompagnateur))
          .filter(idAccompagnateur => !this.state.activitesChoisies.some(ac => ac.participants.some(p => p.idAccompagnateur === idAccompagnateur)));
        if (idsAccompagnateursWithoutAnyActivite.length > 0) {
          const accompagnateurWithoutAnyActivites = idsAccompagnateursWithoutAnyActivite[0] === null
            ? this.state.infoResponsable.prenom + ' ' + this.state.infoResponsable.nom
            : this.state.accompagnateurs.filter(a => a.idAccompagnateur === idsAccompagnateursWithoutAnyActivite[0]).map(a => a.prenom + ' ' + a.nom)[0];

          let errorMessage = {
            title: "ERREUR",
            bodyText: `${accompagnateurWithoutAnyActivites} n'a pas d'activité. Veuillez choisir au moins une activité pour chaque personne.`
          };
          let errorType = "popup";

          if (direction === 1)
            this.props.showOkPopup(errorMessage);
          else if (!this.state.errorsFromGoingBack.find(error => error.stepId === this.state.stepId)) {
            this.setState({
              errorsFromGoingBack: [
                { stepId: this.state.stepId, errorMessage, errorType, errors: this.state.errors },
                ...this.state.errorsFromGoingBack
              ]
            });
          }

          return false;
        }

        let index = this.state.errorsFromGoingBack.findIndex(error => error.stepId === this.state.stepId);

        if (index !== -1) {
          this.state.errorsFromGoingBack.splice(index, 1);
          this.setState({ errorsFromGoingBack: this.state.errorsFromGoingBack });
        }
        return true;
      }
      case STEP_PAIEMENT:
        const paymentStatus = this.paiementFormRef.current.validate().isInfoCarteValid;
        if (paymentStatus) {  // Cet extrait de code permet de vérifier si le paiement a réussi ou pas afin de bloquer le retour vers les étapes précédentes
          this.setState({ resevationCompletetd: true })
          return paymentStatus
        } else {
          return paymentStatus
        }
      case STEP_CONFIRMATION:
        this.setState({ resevationCompletetd: true })
        return false;
      default:
        return true;
    }
  }

  saveSessionState() {
    switch (this.state.stepId) {
      case STEP_INFO_RESPONSABLE: // todo
        break;

      case STEP_ACCOMPAGNATEURS: // todo
        break;

      case STEP_DATE_HEURE_SEJOUR_ENREGISTREMENT: // todo (à compléter - partiellement fait)
        sessionStorage.setItem(SessionKeys.DROIT_ACCES_DATE_ARRIVEE, JSON.stringify(this.state.dateArrivee));
        sessionStorage.setItem(SessionKeys.DROIT_ACCES_DATE_DEPART, JSON.stringify(this.state.dateDepart));
        break;

      case STEP_VEHICULES: // todo
        break;

      case STEP_ACTIVITES: // todo
        break;
      case STEP_SOMMAIRE:
        sessionStorage.removeItem(SessionKeys.DROIT_ACCES_DATE_ARRIVEE)
        sessionStorage.removeItem(SessionKeys.DROIT_ACCES_DATE_DEPART)
    }
  }

  goToNextStep() {
    const nextStepId = this.getNextStepId(this.state.stepId);
    if (nextStepId === this.state.stepId)
      return;

    const demanderVehiculeAccompagnateur = (this.state.stepId === STEP_ACCOMPAGNATEURS
      && this.state.accompagnateurs.some(a => a.isSelected)
      && this.props.configuration.cacherVehiculesSurPav.toUpperCase() !== "TRUE"
      && !this.props.genericPopup.show // Si (!this.props.genericPopup.show), alors le popup de choix de véhicules (pour accompagnateurs) n'est pas déjà affiché.
      ) || (
        this.state.stepId === STEP_ACCOMPAGNATEURS 
        && this.state.listeDependant.some(a => a.isSelected) 
        && this.props.configuration.cacherVehiculesSurPav.toUpperCase() !== "TRUE"
        && !this.props.genericPopup.show)
    if (demanderVehiculeAccompagnateur) {
      this.showAccompagnateursVehiculesPopup();
      return;
    }

    if (this.state.stepId === STEP_ACTIVITES) {
      this.setState({
        stepId: nextStepId,
      }, () => this.calculateDetails());
      return;
    }

    const processTransaction = this.state.stepId <= STEP_PAIEMENT && nextStepId > STEP_PAIEMENT; // Si le montant est à zéro (par exemple à cause de livrets, forfaits, ou gratuités), alors on va passer l'étape de paiement. Cette condition gère le cas où on passe par l'étape de paiement autant que le cas où on passe l'étape de paiement.
    if (processTransaction) {
      this.props.showOverlay("DROIT_ACCES_PROCESS_TRANSACTION");

      processTransactionDroitAcces({
        enregistrement: this.getCalculateDetailsParams(),
        creditCard: this.getMontants().total === 0 ? null : this.paiementFormRef.current.getInfoCarte(),
        codeDepanneur: null, // Mode dépanneur non implémenté dans ce site
        accessCode: null, // Code d'accès non implémenté dans ce site
        paypalOrderId: null, // Paiement Paypal non implémenté dans ce site
        cardType: null, // Types de cartes non géré. On envoie null. En attendant que ça soit géré, le process transaction se fera correctement seulement pour les clients utilisant Bambora.
        montantPaiement: this.getMontants().total === 0 ? 0 : this.paiementFormRef.current.getPaymentAmount(),
      }).then(result => {
        
        //suppression des vehicules ici
        // if (this.state.deleteVehicules.length > 0) {
        //   try {
        //     deleteVehicule(this.state.deleteVehicules).then(response => {
        //       if (response?.status !== 200) throw new Error(`Une erreur s'est produite lors de la suppression d${this.state.deleteVehicules.length >1 ? "es": "u"} vehicule${this.state.deleteVehicules.length >1 ? "s": ""}. Veuillez contacter la zec pour continuer`) 
        //     })
        //       .catch(err => {
        //         console.log("error delete vehicle ", err)
        //       })
        //   } catch (error) {
        //     console.log("error try catch error ", error)
        //   }
        // }
        if (result.status !== 200)
          throw new Error("Une erreur s'est produite lors de la transaction. Veuillez contacter la zec pour continuer.");

        return result.json();
      }).then(result => {
        if (!result.success)
          throw new Error((this.props.language === "en" ? result.errorMessageEn : result.errorMessageFr) || "Une erreur s'est produite lors de la transaction. Veuillez contacter la zec pour continuer.");
          this.setState({
          // pdfEnregistrement: result.pdfEnregistrement,
          // pdfFacture: result.pdfFacture,
          stepId: nextStepId,
          finishTransaction: true,
          resevationCompletetd: true,
          registrationFile: result.pdfEnregistrement,
          invoiceFile: result.pdfFacture 
        });
        setFromStorage('registrationFile', result.pdfEnregistrement) 
        setFromStorage('invoiceFile', result.pdfFacture) 
      }).catch(e => {
        console.log(e);
        this.setState({submitted: false});
        this.props.setAlert(e.message, "danger");
      }).finally(() => {
        this.props.hideOverlay("DROIT_ACCES_PROCESS_TRANSACTION");
      });

      return;
    }

    // Section commentée puisque partiellement implémentée (ne fait rien, et cliquer précédant ne met pas le search à jour). Est commenté pour ne pas porter à confusion comme quoi ça fait quelque chose.
    //const params = new URLSearchParams();
    //params.append('section', nextStepId);
    //this.props.history.push({ search: params.toString() });

    this.setState({
      stepId: nextStepId,
    });

    // if (this.state.stepId === 4) return;

    // this.setState(prevState => ({
    //   stepId: prevState.stepId + 1
    // }))
  }

  goToPreviousStep() {
    if (this.state.stepId === 0) return;
    this.validateCanGoNextStep(-1);

    this.setState(prevState => ({
      stepId: this.getPreviousStepId(prevState.stepId)
    }))
  }

  getPreviousStepId(currentStep) {
    switch (currentStep - 1) {
      case STEP_ACCOMPAGNATEURS:
        if (this.props.configuration.cacherAccompagnateursSurPav.toUpperCase() === "TRUE")
          return this.getPreviousStepId(currentStep - 1);
        return currentStep - 1;

      case STEP_VEHICULES:
        if (this.props.configuration.cacherVehiculesSurPav.toUpperCase() === "TRUE")
          return this.getPreviousStepId(currentStep - 1);
        return currentStep - 1;

      default:
        return currentStep - 1;
    }
  }

  getNextStepId(currentStep) {
    switch (currentStep + 1) {
      case STEP_ACCOMPAGNATEURS:
        if (this.props.configuration.cacherAccompagnateursSurPav.toUpperCase() === "TRUE")
          return this.getNextStepId(currentStep + 1);
        return currentStep + 1;

      case STEP_VEHICULES:
        if (this.props.configuration.cacherVehiculesSurPav.toUpperCase() === "TRUE")
          return this.getNextStepId(currentStep + 1);
        return currentStep + 1;

      case STEP_PAIEMENT:
        if (this.getMontants().total === 0)
          return this.getNextStepId(currentStep + 1);
        return currentStep + 1;

      default:
        return currentStep + 1;
    }
  }

  onChangeProfilePropertyText(propertyName, newValue, maxLength, required) {
    if (newValue.length > maxLength)
      return;

    this.setState({
      infoResponsable: {
        ...this.state.infoResponsable, [propertyName]: newValue
      },
      errors: {
        [propertyName]: required && newValue.length === 0 ? ERREUR_CHAMP_VIDE : ""
      },
    });
  }

  toggleCalendrierDateNaissance() {
    this.setState({
      isCalendarBlurred: true,
      showCalendrierDateNaissance: !this.state.showCalendrierDateNaissance
    });
  }

  toggleCalendrierDateNaissanceAccompagnateur(callback = () => { }) {
    this.setState({
      isCalendarBlurred: true,
      showCalendrierDateNaissanceAccompagnateur: !this.state.showCalendrierDateNaissanceAccompagnateur
    }, callback);
  }

  toggleCalendrierDateArrivee() {
    this.setState({
      isCalendarBlurred: true,
      showCalendrierDateArrivee: !this.state.showCalendrierDateArrivee
    });
  }

  toggleCalendrierDateDepart() {
    this.setState({
      isCalendarBlurred: true,
      showCalendrierDateDepart: !this.state.showCalendrierDateDepart
    });
  }

  setStep(stepId) {

    if (this.state.resevationCompletetd) {
      return true
    }
    else {
      let valid = true;
      if (this.state.stepId < stepId) {
        if (this.state.hasAnythingFromStepTwoChanged) this.setState({ stepId: STEP_DATE_HEURE_SEJOUR_ENREGISTREMENT })
        if (this.state.hasTheFifthStageChangedAnyThing) {
          this.calculateDetails();
          this.setState({ hasTheFifthStageChangedAnyThing: false })
        }
        valid = this.validateCanGoNextStep();
        if (valid) {
          if (this.state.errorsFromGoingBack.length !== 0) {
            let error = this.state.errorsFromGoingBack[0];
            let oldStepId = stepId;

            if (stepId > error.stepId)
              stepId = error.stepId;

            if (oldStepId > stepId) {
              if (this.state.stepId < stepId) {
                if (error.errorType === "danger")
                  this.props.setAlert(error.errorMessage, error.errorType);
                else
                  this.props.showOkPopup(error.errorMessage);
              }

              this.setState({ errors: error.errors });
            }
          }
        }
      }
      else
        this.validateCanGoNextStep(-1);

      if (valid) {
        if (this.state.hasAnythingFromStepTwoChanged) {
          this.setState({ stepId: STEP_DATE_HEURE_SEJOUR_ENREGISTREMENT });
        } else {
          this.setState({ stepId: stepId });
        }
      }
    }
  }

  handleActiviteChampsRequisLoaded(activite = null) {
    const everythingLoaded = this.state.gibiers !== null
      && this.state.secteurs !== null
      && this.state.pointsEaux !== null
      && this.state.activitesHorsServices !== null;
    //console.log({state: this.state, everythingLoaded: everythingLoaded})
    if (!everythingLoaded)
      return;

    const { gibiers, secteurs, pointsEaux, activitesHorsServices, selectedIdActivite } = this.state; // On copie l'état du state avant de le resetter.

    this.setState({ // handleActiviteChampsRequisLoaded() vérifie si this.state.gibiers, this.state.secteur, etc sont null pour déterminer si les données requisent sont chargées. On reset ces champs pour être prêt au prochain appel. On reset également selectedIdActivite pour que le drop down associé soit résetté, de quoi être prêt à sélectionner une prochaine activité.
      gibiers: null,
      secteurs: null,
      pointsEaux: null,
      activitesHorsServices: null,
      selectedIdActivite: "00000000-0000-0000-0000-000000000000",
    }, () => {
      const jourArrivee = new Date(this.state.dateArrivee.getTime());
      jourArrivee.setHours(0, 0, 0, 0);

      const jourDepart = new Date(this.state.dateDepart.getTime());
      jourDepart.setHours(0, 0, 0, 0);

      const disponibilites = {
        periode: [], // Liste de int correspondant à aux timestamps retournés par Date.getTime(). Correspond aux jours compris entre this.state.dateArrivee et this.state.dateDepart.
        activite: [], // Liste de int correspondant à aux timestamps retournés par Date.getTime(). Correspond aux jours compris entre this.state.dateArrivee et this.state.dateDepart où l'activité est disponible.
        pointEau: [], // Liste de int correspondant à aux timestamps retournés par Date.getTime(). Correspond aux jours compris entre this.state.dateArrivee et this.state.dateDepart où le point d'eau est disponible. Si aucun point d'eau n'est sélectionné, est identique à disponibilites.periode.
      };

      for (let jour = jourArrivee; jour.getTime() <= jourDepart; jour.setDate(jour.getDate() + 1)) {
        const jourDebut = new Date(jour.getTime()); // jourDebut: le moment où l'activité début cette journée.
        if (jour === jourArrivee)
          jourDebut.setHours(this.state.heureArrivee.substr(0, 2), this.state.heureArrivee.substr(3, 2), 0, 0);

        const jourFin = new Date(jour.getTime()); // jourFin: le moment où l'activité termine cette journée.
        if (jourFin.getTime() === jourDepart.getTime())
          jourFin.setHours(this.state.heureDepart.substr(0, 2), this.state.heureDepart.substr(3, 2), 59, 999);
        else
          jourFin.setHours(23, 59, 59, 999);
        const isIndisponible = activitesHorsServices.some(ahs => { // Pour simplicité, on considère une journée comme étant entièrement indisponible même si l'indisponibilité ne couvre qu'une portion de la journée.
          const indisponibiliteDebut = new Date(ahs.dateDebut);
          const indisponibiliteFin = new Date(ahs.dateFin);

          return periodsOverlap(indisponibiliteDebut, indisponibiliteFin, jourDebut, jourFin);
        });

        disponibilites.periode.push(new Date(jour.getTime()));
        disponibilites.pointEau.push(new Date(jour.getTime()));

        if (!isIndisponible)
          disponibilites.activite.push(new Date(jour.getTime()));
      }

      const selectedActivite = this.state.activites.find(a => a.idActivite === selectedIdActivite);

      const choixActivite = {
        idActiviteChoisie: generateTempId(),
        activite: selectedActivite,
        secteur: null,
        pointEau: null,
        gibiers: gibiers.length === 1 ? [gibiers[0]] : [], // S'il n'y a qu'un seul gibier disponible, il est sélectionné par défaut.
        participants: [{ // Par défaut, la personne principale et chaque accompagnateur sélectionné est participant. L'utilisateur pourra par la suite décocher des participants.
          idAccompagnateur: null,
          nom: this.state.infoResponsable.nom,
          prenom: this.state.infoResponsable.prenom,
          jours: [...disponibilites.activite],
        }, 
        ...this.state.accompagnateurs.filter(a => a.isSelected).map(a => ({
          idAccompagnateur: a.idAccompagnateur,
          nom: a.nom,
          prenom: a.prenom,
          jours: [...disponibilites.activite],
          
        })), 
        ...this.state.listeDependant.filter(a => a.isSelected).map(a => ({
          idAccompagnateur: a.idAccompagnateur,
          nom: a.nom,
          prenom: a.prenom,
          jours: [...disponibilites.activite],
          
        }))],
      };
      this.setState({
        secteurs: secteurs,
        pointsEaux: pointsEaux,
        gibiers: gibiers,
        disponibilites: disponibilites
      });

      if (activite === null) {
        this.showActivitePopup(choixActivite, secteurs, pointsEaux, gibiers, disponibilites);
      }
      else {
        let activiteCopy = _.cloneDeep(activite);
        activiteCopy.activite = selectedActivite;
        activiteCopy.secteur = null;
        activiteCopy.pointEau = null;
        activiteCopy.gibiers = gibiers.length === 1 ? [gibiers[0]] : [];
        this.showActivitePopup(activiteCopy, secteurs, pointsEaux, gibiers, disponibilites, ACTIVITE_STEP_SECTEUR - 1, 1, true);
      }
    });
  }

  getCalculateDetailsParams() {
    const arrive = new Date(this.state.dateArrivee.getTime());
    arrive.setHours(this.state.heureArrivee.substr(0, 2), this.state.heureArrivee.substr(3, 2), 0, 0);

    const depart = new Date(this.state.dateDepart.getTime());
    depart.setHours(this.state.heureDepart.substr(0, 2), this.state.heureDepart.substr(3, 2), 0, 0);
    return {
      token: 0, // Contrairement au PAV, le nouveau site ne charge actuellement pas les montants en temps réel. Le paramètre token n'est donc actuellement pas utilisé.
      courriel: this.state.infoResponsable.courriel,
      idLieuSejour: this.state.idLieuSejour,
      idTypeSejour: this.state.idTypeSejour,
      arrive: arrive,
      depart: depart,
      participants: [
        { // Contient la personne principale au premier index suivit des accompagnateurs aux indexes subséquents
        profile: { // La personne principale
          idProfile: this.props.authToken == null ? null : jwtDecode(this.props.authToken).idProfile, // Si idProfile n'est pas null, les champs du profile (nom, prénom, etc.) ici-bas seront rechargés de la BD côté serveur.
          nom: this.state.infoResponsable.nom,
          prenom: this.state.infoResponsable.prenom,
          telephone: this.state.infoResponsable.telephone,
          adresse: this.state.infoResponsable.adresse,
          ville: this.state.infoResponsable.ville,
          codePostal: this.state.infoResponsable.codePostal,
          courriel: this.state.infoResponsable.courriel,
          dateNaissance: this.state.infoResponsable.dateNaissance,
          idProvince: this.state.infoResponsable.idProvince,
          idPays: this.state.infoResponsable.idPays,
          vehicules : this.state.mainCustomerVehicles
        },
        vehicules: this.state.vehicules.filter(v => v.isSelected).map(v => ({
          fqcq: v.fqcq,
          vehicule: {
            idVehicule: Number.isInteger(v.idVehicule) ? null : v.idVehicule, // si Number.isInteger(v.idVehicule), alors il s'agit d'un véhicule n'existant pas dans la BD et ayant un id temporaire côté client
            description: v.description,
            noPlaque: v.noPlaque,
            type: v.type,
          }
        }))
      }, 
      //Accompagnateur dependants
      ...this.state.accompagnateurs.filter(a => a.isSelected).map(a => ({
        profile: {
          idProfile: Number.isInteger(a.idProfile) || a.idProfile === "00000000-0000-0000-0000-000000000000" ? null : a.idProfile, // si Number.isInteger(a.idProfile), alors il s'agit d'un accompagnateur n'existant pas dans la BD et ayant un id temporaire côté client
          nom: a.nom,
          prenom: a.prenom,
          adresse: a.adresse, // important lorsque le client et profile n'existent pas dans la BD
          codePostal: a.codePostal, // important lorsque le client et profile n'existent pas dans la BD
          dateNaissance: a.dateNaissance, // important lorsque le client et profile n'existent pas dans la BD
          email: a.email, // important lorsque le client et profile n'existent pas dans la BD
          idPays: a.idPays, // important lorsque le client et profile n'existent pas dans la BD
          idProvince: a.idProvince, // important lorsque le client et profile n'existent pas dans la BD
          permisPeche: a.permisPeche, // important lorsque le client et profile n'existent pas dans la BD
          permisPetitGibier: a.permisPetitGibier, // important lorsque le client et profile n'existent pas dans la BD
          portable: a.portable, // important lorsque le client et profile n'existent pas dans la BD
          telephone1: a.telephone1, // important lorsque le client et profile n'existent pas dans la BD
          telephone2: a.telephone2, // important lorsque le client et profile n'existent pas dans la BD
          typePermisPeche: a.typePermisPeche, // important lorsque le client et profile n'existent pas dans la BD
          ville: a.ville, // important lorsque le client et profile n'existent pas dans la BD
          vehicules : a.vehiculesSansModif,
          client: {
            idClient: a.idClient === "00000000-0000-0000-0000-000000000000" ? null : a.idClient,
          },
          dependant: {
            relation: a.relation,
          }
        },
        vehicules: a.vehicules.filter(v => v.idVehicule === a.idVehicule).map(v => ({
          fqcq: a.fqcq,
          vehicule: {
            idVehicule: Number.isInteger(v.idVehicule) ? null : v.idVehicule, // si Number.isInteger(v.idVehicule), alors il s'agit d'un véhicule n'existant pas dans la BD et ayant un id temporaire côté client
            description: v.description,
            noPlaque: v.noPlaque,
            type: v.type,
          }
        }))
      })),
      //Accompagnateur simple
      ...this.state.listeDependant.filter(a => a.isSelected).map(a => (
        {profile: {
          idProfile: Number.isInteger(a.idProfile) || a.idProfile === "00000000-0000-0000-0000-000000000000" ? null : a.idProfile, // si Number.isInteger(a.idProfile), alors il s'agit d'un accompagnateur n'existant pas dans la BD et ayant un id temporaire côté client
          nom: a.nom,
          prenom: a.prenom,
          adresse: a.adresse, // important lorsque le client et profile n'existent pas dans la BD
          codePostal: a.codePostal, // important lorsque le client et profile n'existent pas dans la BD
          dateNaissance: a.dateNaissance, // important lorsque le client et profile n'existent pas dans la BD
          email: a.email, // important lorsque le client et profile n'existent pas dans la BD
          idPays: a.idPays, // important lorsque le client et profile n'existent pas dans la BD
          idProvince: a.idProvince, // important lorsque le client et profile n'existent pas dans la BD
          permisPeche: a.permisPeche, // important lorsque le client et profile n'existent pas dans la BD
          permisPetitGibier: a.permisPetitGibier, // important lorsque le client et profile n'existent pas dans la BD
          portable: a.portable, // important lorsque le client et profile n'existent pas dans la BD
          telephone1: a.telephone1, // important lorsque le client et profile n'existent pas dans la BD
          telephone2: a.telephone2, // important lorsque le client et profile n'existent pas dans la BD
          typePermisPeche: a.typePermisPeche, // important lorsque le client et profile n'existent pas dans la BD
          ville: a.ville, // important lorsque le client et profile n'existent pas dans la BD
          vehicules : a.vehiculesSansModif,
          client: {
            idClient: a.idClient === "00000000-0000-0000-0000-000000000000" ? null : a.idClient,
          },
          dependant: {
            relation: a.relation, 
          }
        },
        vehicules: a.vehicules.filter(v => v.idVehicule === a.idVehicule).map(v => ({
          fqcq: a.fqcq,
          vehicule: {
            idVehicule: Number.isInteger(v.idVehicule) ? null : v.idVehicule, // si Number.isInteger(v.idVehicule), alors il s'agit d'un véhicule n'existant pas dans la BD et ayant un id temporaire côté client
            description: v.description,
            noPlaque: v.noPlaque,
            type: v.type,
          }
        }))
      }))
    ],
      
      activites: this.state.activitesChoisies.map(a => ({
        idActivite: a.activite.idActivite,
        idSecteur: (a.secteur || {}).idSecteur || "00000000-0000-0000-0000-000000000000",
        idPointEau: (a.pointEau || {}).idPointEau || "00000000-0000-0000-0000-000000000000",
        idsGibiers: a.gibiers.map(g => g.idGibier),
        selectedDays: [null, ...this.state.accompagnateurs.filter(acc => acc.isSelected).map(acc => acc.idAccompagnateur), ...this.state.listeDependant.filter(acc => acc.isSelected).map(acc => acc.idAccompagnateur)].map(idAccompagnateur => (a.participants.find(p => p.idAccompagnateur === idAccompagnateur) || {}).jours || []),
      })),
    };
  }

  getSortedDetails() {
    return this.state.details
      .filter(d => d.montantAvantSpeciaux != 0)
      .sort((a, b) => {
        return a.idProduit < b.idProduit ? -1
          : a.idProduit > b.idProduit ? 1
            : a.indexParticipant < b.indexParticipant ? -1
              : a.indexParticipant > b.indexParticipant ? 1
                : a.gratuites + a.livrets < b.gratuites + b.livrets ? -1
                  : a.gratuites + a.livrets > b.gratuites + b.livrets ? 1
                    : 0;
      });
  }

  getMontants() {
    const details = this.getSortedDetails();
    const sousTotal = roundTwoDecimals(details.reduce((total, detail) => total + detail.montantApresSpeciaux, 0));
    const fraisAdmin = sousTotal === 0 ? 0 : roundTwoDecimals(this.props.parametresWeb.montantFraisFixe + sousTotal * this.props.parametresWeb.montantFraisPourcent * 0.01);
    const montantAvantTaxes = sousTotal + fraisAdmin;
    const tps = roundTwoDecimals(montantAvantTaxes * this.props.shop.tauxTps * 0.01);
    const tvq = roundTwoDecimals(montantAvantTaxes * this.props.shop.tauxTvq * 0.01 + montantAvantTaxes * this.props.shop.tauxTps * 0.01 - tps); // On additionne la tps puis soustrait la tps arrondi afin d'inclure, dans la tvq, toute cent perdu quand on additionne la tps arrondi et la tvq arrondi. e.g.: tps avant arrondissement = 10.4444, tvq avant arrondissement = 10.4444, tps avant arrondissement + tvq avant arrondissement = 20.8888 ~= 20.89, alors que tps arrondi + tvq arrondi = 10.44 + 10.44 = 20.88.
    const total = sousTotal + fraisAdmin + tps + tvq;
    return { sousTotal, fraisAdmin, montantAvantTaxes, tps, tvq, total };
  }

  getMontantTotalDetails() {
    return this.state.details.reduce((total, detail) => total + detail.montantApresSpeciaux, 0);
  }

  getStepperIndex() {
    let stepperIndex = 0;
    while (this.state.stepperSteps[stepperIndex].id !== this.state.stepId) {
      stepperIndex++;
    }

    return stepperIndex;
  }

  calculateDetails() {
    this.props.showOverlay("DROIT_ACCES_CALCUL_DETAILS");

    calculateDetails(this.getCalculateDetailsParams()).then(result => {
      console.log(result);

      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et les détails de l'enregistrement n'ont pas pu être chargés.");

      return result.json();
    }).then(({ details }) => {

      this.setState({ details });
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("DROIT_ACCES_CALCUL_DETAILS");
    });
  }

  getDatesEtParticipants(participants) {
    let dictDatesNbParticipants = {};
    let lsDatesNbParticipants = [];

    for (let p of Object.values(participants)) {
      for (let j of p.jours) {
        if (j in dictDatesNbParticipants)
          dictDatesNbParticipants[j] += `, ${p.prenom} ${p.nom}`;
        else
          dictDatesNbParticipants[j] = `${p.prenom} ${p.nom}`;
      }
    }

    for (let date in dictDatesNbParticipants) {
      lsDatesNbParticipants.push([date, dictDatesNbParticipants[date]]);
    }

    lsDatesNbParticipants.sort((a, b) => new Date(a[0]) - new Date(b[0]));
    return lsDatesNbParticipants;
  }

  //#region renderers
  renderStepInfoResponsable() {
    return (
      <div className="droit-acces-section">
        <div className="droit-acces-page-separator" />
        <div className="droit-acces-label-wrapper-title">
          <span className="droit-acces-span">RESPONSABLE</span>
        </div>
        <div className="center-droit-acces-div">
          <div className="droit-acces-div move-width">
            <div className="droit-acces-div droit-acces-label-wrapper">
              <span className="droit-acces-span">NOM :</span>
              
            </div>
            <div className="droit-acces-div input-container">
              <input type="text" value={this.state.infoResponsable.nom} disabled={!this.state.continuerSansCompte} onChange={e => this.onChangeProfilePropertyText("nom", e.target.value, 40, true)} className="droit-acces-input" />
              {this.state.errors.nom && <span className="color-error droit-acces-span">{this.state.errors.nom}</span>}
            </div>
          </div>
          <div className="droit-acces-div move-width">
            <div className="droit-acces-div droit-acces-label-wrapper">
              <span className="droit-acces-span">PRÉNOM :</span>
              
            </div>
            <div className="droit-acces-div input-container">
              <input type="text" value={this.state.infoResponsable.prenom} disabled={!this.state.continuerSansCompte} onChange={e => this.onChangeProfilePropertyText("prenom", e.target.value, 40, true)} className="droit-acces-input" />
              {this.state.errors.prenom && <span className="color-error droit-acces-span">{this.state.errors.prenom}</span>}
            </div>
          </div>
          {this.state.continuerSansCompte &&
            <>
              <div className="droit-acces-div">
                <div className="droit-acces-div droit-acces-label-wrapper">
                  <span className="droit-acces-span">PAYS :</span>
                  
                </div>
                <div className="droit-acces-div input-container">
                  <select value={this.state.infoResponsable.idPays} className="droit-acces-input" onChange={e => this.onChangeProfilePropertyText("idPays", e.target.value, 32 * 8 + 4, true)}>
                    {(this.state.pays || []).map(pays => (
                      <option key={pays.idPays} value={pays.idPays}>
                        {pays.description.toUpperCase()}
                      </option>
                    ))}
                  </select>
                  {this.state.errors.idPays && <span className="color-error droit-acces-span">{this.state.errors.idPays}</span>}
                </div>
              </div>
              <div className="droit-acces-div">
                <div className="droit-acces-div droit-acces-label-wrapper">
                  <span className="droit-acces-span">PROVINCE :</span>
                  
                </div>
                <div className="droit-acces-div input-container">
                  <select value={this.state.infoResponsable.idProvince} className="droit-acces-input" onChange={e => this.onChangeProfilePropertyText("idProvince", e.target.value, 32 * 8 + 4, true)}>
                    {(this.state.provinces || []).filter(p => p.idPays === this.state.infoResponsable.idPays).map(province => (
                      <option key={province.idProvince} value={province.idProvince}>
                        {province.description.toUpperCase()}
                      </option>
                    ))}
                  </select>
                  {this.state.errors.idProvince && <span className="color-error droit-acces-span">{this.state.errors.idProvince}</span>}
                </div>
              </div>

              <div className="droit-acces-div">
                <div className="droit-acces-div droit-acces-label-wrapper">
                  <span className="droit-acces-span">ADRESSE :</span>
                  
                </div>
                <div className="droit-acces-div input-container">
                  <input type="text" value={this.state.infoResponsable.adresse} onChange={e => this.onChangeProfilePropertyText("adresse", e.target.value, 100, true)} className="droit-acces-input" />
                  {this.state.errors.adresse && <span className="color-error droit-acces-span">{this.state.errors.adresse}</span>}
                </div>
              </div>
              <div className="droit-acces-div">
                <div className="droit-acces-div droit-acces-label-wrapper">
                  <span className="droit-acces-span">VILLE :</span>
                </div>
                <div className="droit-acces-div input-container">
                  <input type="text" value={this.state.infoResponsable.ville} onChange={e => this.onChangeProfilePropertyText("ville", e.target.value, 40, true)} className="droit-acces-input" />
                  {this.state.errors.ville && <span className="color-error droit-acces-span">{this.state.errors.ville}</span>}
                </div>
              </div>
              <div className="droit-acces-div">
                <div className="droit-acces-div droit-acces-label-wrapper">
                  <span className="droit-acces-span">CODE POSTAL :</span>
                  
                </div>
                <div className="droit-acces-div input-container">
                  <input type="text" value={this.state.infoResponsable.codePostal} onChange={e => this.onChangeProfilePropertyText("codePostal", e.target.value, 9, true)} className="droit-acces-input" />
                  {this.state.errors.codePostal && <span className="color-error droit-acces-span">{this.state.errors.codePostal}</span>}
                </div>
              </div>
            </>
          }
          <div className="droit-acces-div move-width">
            <div className="droit-acces-div droit-acces-label-wrapper">
              <span className="droit-acces-span">TÉLÉPHONE :</span>
              
            </div>
            <div className="droit-acces-div input-container">
              <input type="tel" maxLength="11" value={this.state.infoResponsable.telephone} disabled={!this.state.continuerSansCompte} onChange={e => this.onChangeProfilePropertyText("telephone", e.target.value, 13, true)} className="droit-acces-input" />
              {this.state.errors.telephone && <span className="color-error droit-acces-span">{this.state.errors.telephone}</span>}
            </div>
          </div>
          <div className="droit-acces-div move-width">
            <div className="droit-acces-div droit-acces-label-wrapper">
              <span className="droit-acces-span">COURRIEL :</span>
              
            </div>
            <div className="droit-acces-div input-container">
              <input type="email" value={this.state.infoResponsable.courriel} onChange={e => this.onChangeProfilePropertyText("courriel", e.target.value, 60, true)}
                className={`droit-acces-input ${this.state.errors.courriel && 'droit-acces-input-error'}`} />
              {this.state.errors.courriel && <span className="color-error droit-acces-span">{this.state.errors.courriel}</span>}
            </div>
          </div>
          {this.state.continuerSansCompte &&
            <div className="droit-acces-div">
              <div className="droit-acces-div droit-acces-label-wrapper">
                <span className="droit-acces-span">DATE DE NAISSANCE :</span>
                
              </div>
              <div className="droit-acces-div input-container">
                <input type="text" value={toDateString(this.state.infoResponsable.dateNaissance)} readOnly={true} className="droit-acces-input clickable" onMouseDown={e => this.toggleCalendrierDateNaissance()} />
                {
                  this.state.showCalendrierDateNaissance &&
                  <div className="calendar-wrapper" ref="calendarDateNaissanceWrapperDiv" tabIndex={0} onBlur={e => {
                    // Désactivé. Est incomptable avec Calendar.props..allowChangeYear
                    //if (!this.state.isCalendarBlurred)
                    //  this.setState({ showCalendrierDateNaissance: false })
                    //else
                    //  this.setState({ isCalendarBlurred: false });
                  }}>
                    <Calendar
                      onDayClick={date => {
                        if (date >= new Date())
                          return;

                        this.setState({
                          infoResponsable: {
                            ...this.state.infoResponsable, dateNaissance: date
                          },
                          showCalendrierDateNaissance: false,
                        })
                      }}
                      initialMonth={this.state.infoResponsable.dateNaissance || new Date()}
                      minDate={new Date("1900-01-01 00:00:00.000")}
                      maxDate={new Date()}
                      allowChangeYear={true}
                    />
                  </div>
                }
                {this.state.errors.dateNaissance && <span className="color-error droit-acces-span">{this.state.errors.dateNaissance}</span>}
              </div>
              <img src={require('../../images/icons/calendar-2.png')} alt="calendrier" onMouseDown={e => this.toggleCalendrierDateNaissance()} className="droit-acces-img" />
            </div>
          }
        </div>
      </div>
    )
  }


  renderStepAccompagnateurs() {
    const canAddDeleteModifyDependants = this.state.hadDependants === false || (this.props.configuration.empecherModificationDependants || 'false').toUpperCase() === 'FALSE';
    return (
      <>
        {/* ACCOMPAGNATEURS - DEPNDANTS */}
        <AddCompanion
          title={"DÉPENDANTS"}
          relationsByIds={relationsByIds}
          authToken={this.props.authToken}
          accompagnateurList={this.state.listeDependant}
          entrepriseContact={this.props.entrepriseContact}
          canAddDeleteModifyDependants={canAddDeleteModifyDependants}
          canManagerDependants={() =>
            this.setState({ errors: {} }, () => {
              this.showAddAccompagnateurPopup(null, 'dependant');
            })
          }
          onChangeCheckbox={(accompagnateur) =>
            this.setState((prevState) => {
              let updateaccompagnateursDeBaseSelectionne = [...prevState.accompagnateursDeBaseSelectionne]
              const updatedListeDependant = prevState.listeDependant.map(item => {
                if (item.idAccompagnateur === accompagnateur.idAccompagnateur) {
                  // mise a jour uniquement si la valeur est differente
                  if (item.isSelected !== !accompagnateur.isSelected) {
                    updateaccompagnateursDeBaseSelectionne.push(accompagnateur);
                    // copie de l'objet avec isSelected mis à jour
                    return { ...item, isSelected: !accompagnateur.isSelected };
                  }
                }
                return item; // Renvoie l'objet inchangé s'il ne correspond pas à l'ID client
              });
              const hasChanged = prevState.listeDependant !== updatedListeDependant;

              updateaccompagnateursDeBaseSelectionne = updateaccompagnateursDeBaseSelectionne.filter((elem, index, self) =>
              index === self.findIndex((t) => t.idAccompagnateur === elem.idAccompagnateur)
            );

              return { listeDependant: updatedListeDependant, listeDependantHasChanged : hasChanged, accompagnateursDeBaseSelectionne: updateaccompagnateursDeBaseSelectionne };
            }, () => {
              // Cette fonction de rappel est appelée après la mise à jour de l'état
              if (this.state.listeDependantHasChanged) {
                this.setState({ activitesChoisies: [] });
              }
            })
          }          
          EditComponent={
            <EditSvg className="clickable primary-color-on-hover" style={{ marginLeft: '16px' }} onClick={e => {
              this.setState({ errors: {} }, () => {
                // this.showAddAccompagnateurPopup(dependant);
              });
            }} />}
          DeleteComponent={
            <DeleteSvg className="clickable primary-color-on-hover" style={{ marginLeft: '16px' }} onClick={e => {
              // this.showConfirmDeleteAccompagnateurPopup(dependant);
            }} />
          }
        />

        {/* ACCOMPAGNATEURS - SIMPLE*/}
        <AddCompanion
          title={"ACCOMPAGNATEURS"}
          relationsByIds={relationsByIds}
          authToken={this.props.authToken}
          accompagnateurList={this.state.accompagnateurs}
          entrepriseContact={this.props.entrepriseContact}
          canAddDeleteModifyDependants={canAddDeleteModifyDependants}
          canManagerDependants={() =>
            this.setState({ errors: {} }, () => {
              this.showAddAccompagnateurPopup(null, 'simple');
            })
          }
          onChangeCheckbox={(accompagnateur) =>
            this.setState((prevState) => { 
              let updateaccompagnateursDeBaseSelectionne = [...prevState.accompagnateursDeBaseSelectionne]
              const updatedAccompagnateurs = prevState.accompagnateurs.map(item => {
                if (item.idAccompagnateur === accompagnateur.idAccompagnateur) {
                  // mise a jour uniquement si la valeur est differente
                  if(item.isSelected !== !accompagnateur.isSelected){
                    updateaccompagnateursDeBaseSelectionne.push(accompagnateur);
                  // copie de l'objet avec isSelected mis à jour
                  return { ...item, isSelected: !accompagnateur.isSelected };
                  }
                }
            
                return item; // Renvoie l'objet inchangé s'il ne correspond pas à l'ID client
              });
              const hasChanged = prevState.accompagnateurs !== updatedAccompagnateurs;

              updateaccompagnateursDeBaseSelectionne = updateaccompagnateursDeBaseSelectionne.filter((elem, index, self) =>
              index === self.findIndex((t) => t.idAccompagnateur === elem.idAccompagnateur)
              );

              return { accompagnateurs: updatedAccompagnateurs, accompagnateursHasChanged: hasChanged, accompagnateursDeBaseSelectionne : updateaccompagnateursDeBaseSelectionne };
            }, () => {
              // Cette fonction de rappel est appelée après la mise à jour de l'état
              if (this.state.accompagnateursHasChanged) {
                this.setState({ activitesChoisies: [] });
              }
            })
          }
          EditComponent={
            <EditSvg className="clickable primary-color-on-hover" style={{ marginLeft: '16px' }} onClick={e => {
              this.setState({ errors: {} }, () => {
                // this.showAddAccompagnateurPopup(dependant);
              });
            }} />}
          DeleteComponent={
            <DeleteSvg className="clickable primary-color-on-hover" style={{ marginLeft: '16px' }} onClick={e => {
              // this.showConfirmDeleteAccompagnateurPopup(dependant);
            }} />
          }
        />
      </>
    );
  }

  renderStepDateHeureSejourEnregistrement() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return (
      <div className="droit-acces-section">
        <div className="droit-acces-page-separator" />
        <div className="droit-acces-label-wrapper-title">
          <span className="droit-acces-span">DATES ET SÉJOUR</span>
        </div>
        <div className="center-droit-acces-div">
          {/* date arrivee */}
          <div className="droit-acces-div move-width">
            <div className="droit-acces-div droit-acces-label-wrapper">
              <span className="droit-acces-span">DATE D'ARRIVÉE :</span>
              
            </div>
            <div className="droit-acces-div input-container" style={{ display: 'flex' }}>
              <div style={{width:'100%'}}>
                <input type="text" value={toDateString(this.state.dateArrivee)} readOnly={true} style={{width:'100%'}}
                  className={`clickable ${this.state.errors.dateArrivee && 'droit-acces-input-error'}`}
                  onMouseDown={e => this.toggleCalendrierDateArrivee()}
                />
                {this.state.errors.dateArrivee && <span className="color-error droit-acces-span" style={{ display: 'block' }}>{this.state.errors.dateArrivee}</span>}
              </div>
              {this.state.showCalendrierDateArrivee &&
                <div className="calendar-wrapper" ref="calendarDateArriveeWrapperDiv" tabIndex={0} onBlur={e => {
                  if (!this.state.isCalendarBlurred)
                    this.setState({ showCalendrierDateArrivee: false })
                  else
                    this.setState({ isCalendarBlurred: false });
                }}>
                  <Calendar
                    onDayClick={date => {
                      if (date < today)
                        return;
                      this.setState({
                        dateArrivee: date,
                        activitesChoisies: [],
                        dateDepart: date > this.state.dateDepart ? null : this.state.dateDepart,
                        showCalendrierDateArrivee: false,
                      });
                    }}
                    initialMonth={this.state.dateArrivee || new Date()}
                    minDate={today}
                    maxDate={null}
                  />
                </div>
              }
              <img src={require('../../images/icons/calendar-2.png')} alt="calendrier" onMouseDown={e => this.toggleCalendrierDateArrivee()} className="droit-acces-img" style={{ marginTop: '0px' }} />
            </div>
          </div>
          {/* date de sortie */}
          <div className="droit-acces-div move-width">
            <div className="droit-acces-div droit-acces-label-wrapper">
              <span className="droit-acces-span">DATE DE SORTIE :</span>
              
            </div>
            <div className="droit-acces-div input-container" style={{ display: 'flex' }}>
              <div style={{width:'100%'}}>
                <input type="text" value={toDateString(this.state.dateDepart)} readOnly={true} style={{width:'100%'}}
                  className={`clickable ${this.state.errors.dateDepart && 'droit-acces-input-error'}`}
                  onMouseDown={e => this.toggleCalendrierDateDepart()}
                />
                {this.state.errors.dateDepart && <span className="color-error droit-acces-span" style={{ display: 'block' }}>{this.state.errors.dateDepart}</span>}
              </div>
              {this.state.showCalendrierDateDepart &&
                <div className="calendar-wrapper" ref="calendarDateDepartWrapperDiv" tabIndex={1} onBlur={e => {
                  if (!this.state.isCalendarBlurred)
                    this.setState({ showCalendrierDateDepart: false })
                  else
                    this.setState({ isCalendarBlurred: false });
                }}>
                  <Calendar
                    onDayClick={date => {
                      if (date < today || date < this.state.dateArrivee)
                        return;
                      this.setState({
                        dateDepart: date,
                        activitesChoisies: [],
                        showCalendrierDateDepart: false,
                      })
                    }}
                    initialMonth={this.state.dateDepart || new Date()}
                    minDate={this.state.dateArrivee || today}
                    maxDate={null}
                  />
                </div>
              }
              <img src={require('../../images/icons/calendar-2.png')} alt="calendrier" onMouseDown={e => this.toggleCalendrierDateDepart()} className="droit-acces-img" style={{ marginTop: '0px' }} />
            </div>
          </div>
          {/* Heure arrivee */}
          {this.props.parametresWeb.bloquerChangementHeuresEnregistrement ||
            <div className="droit-acces-div move-width">
              <div className="droit-acces-div droit-acces-label-wrapper">
                <span className="droit-acces-span">HEURE D'ARRIVÉE :</span>
                
              </div>
              <div className="droit-acces-div input-container">
                <TimePicker showSecond={false} inputReadOnly={true} value={moment(this.state.heureArrivee, 'HH:mm')} onChange={value => this.setState({ heureArrivee: value.format('HH:mm') })} />
              </div>
              {this.state.errors.heureArrivee && <span className="color-error droit-acces-span">{this.state.errors.heureArrivee}</span>}
            </div>
          }
          {/* Heure de sortie */}
          {this.props.parametresWeb.bloquerChangementHeuresEnregistrement ||
            <div className="droit-acces-div move-width">
              <div className="droit-acces-div droit-acces-label-wrapper">
                <span className="droit-acces-span">HEURE DE SORTIE :</span>
                
              </div>
              <div className="droit-acces-div input-container">
                <TimePicker showSecond={false} inputReadOnly={true} value={moment(this.state.heureDepart, 'HH:mm')} onChange={value => this.setState({ heureDepart: value.format('HH:mm') })} />
              </div>
              {this.state.errors.heureDepart && <span className="color-error droit-acces-span">{this.state.errors.heureDepart}</span>}
            </div>
          }
          {/* Lieur de sejour */}
          {this.props.configuration.forcerLieuSejourEnregistrement.toUpperCase()  === 'TRUE' &&
            <div className="droit-acces-div move-width">
              <div className="droit-acces-div droit-acces-label-wrapper">
                <span className="droit-acces-span">LIEU DU SÉJOUR :</span>
                
              </div>
              <div className="droit-acces-div input-container">
                <select value={this.state.idLieuSejour} onChange={e => this.setState({ idLieuSejour: e.target.value })} className="droit-acces-input">
                  <option value='00000000-0000-0000-0000-000000000000'></option>
                  {(this.state.lieuxSejours || []).map(lieuSejour => (
                    <option key={lieuSejour.idLieuSejour} value={lieuSejour.idLieuSejour}>
                      {lieuSejour.description.toUpperCase()}
                    </option>
                  ))}
                </select>
                {this.state.errors.idLieuSejour && <span className="color-error droit-acces-span">{this.state.errors.idLieuSejour}</span>}
              </div>
            </div>
          }
          {/* Type de sejour */}
          {this.props.configuration.forcerTypeSejourEnregistrement.toUpperCase()  === 'TRUE' &&
            <div className="droit-acces-div move-width">
              <div className="droit-acces-div droit-acces-label-wrapper">
                <span className="droit-acces-span">TYPE DE SÉJOUR :</span>
                
              </div>
              <div className="droit-acces-div input-container">
                <select value={this.state.idTypeSejour} onChange={e => this.setState({ idTypeSejour: e.target.value })} className="droit-acces-input">
                  <option value='00000000-0000-0000-0000-000000000000'></option>
                  {(this.state.typesSejours || []).map(typeSejour => (
                    <option key={typeSejour.idTypeSejour} value={typeSejour.idTypeSejour}>
                      {typeSejour.description.toUpperCase()}
                    </option>
                  ))}
                </select>
                {this.state.errors.idTypeSejour && <span className="color-error droit-acces-span">{this.state.errors.idTypeSejour}</span>}
              </div>
            </div>
          }
        </div>
      </div>
    )
  }

  renderStepVehicules() {
    return (
      <div className="droit-acces-section">
        <div className="droit-acces-page-separator" />
        <div className="droit-acces-label-wrapper-title">
          <span className="droit-acces-span">VÉHICULES</span>
        </div>
        <div className="droit-acces-div">
          <div className="droit-acces-div droit-acces-label-wrapper droit-acces-label-wrapper-customize">
            <span className="droit-acces-span">CHOIX DES VÉHICULES :</span>
          </div>
          <div className="droit-acces-div input-container input-container-customize">
            <div>
              <table style={{ paddingRight: '16px' }}>
                <tbody>
                  {this.state.vehicules.map((vehicule, iVehicule) => {
                    const marginBottom = iVehicule === this.state.vehicules.length - 1 ? '0px' : '4px';
                    
                    return (
                      <tr key={vehicule.idVehicule} className="droit-acces-vehicules-tr">
                        {this.props.authToken != null &&
                          <td className="droit-acces-vehicules-td-select">
                            <input type="checkbox" checked={vehicule.isSelected} style={{ marginBottom: marginBottom }} onChange={e => {
                              this.setState({
                                vehicules: this.state.vehicules.map(v => (v.isSelected = v.idVehicule === vehicule.idVehicule ? !vehicule.isSelected : v.isSelected, v))
                              });
                            }} />
                          </td>
                        }
                        <td className="droit-acces-vehicules-td-description">
                          <span style={{ float: 'left', fontFamily: 'Barlow-Bold', fontSize: '26px', paddingLeft: this.props.authToken == null ? '4px' : '16px', marginBottom: marginBottom }}>{vehicule.description} {vehicule.noPlaque}</span>
                        </td>
                        {this.props.configuration.desactiverNumeroFqcq.toUpperCase() === "FALSE" &&
                          <td className="droit-acces-vehicules-td-fqcq">
                            <input type="text" placeholder="VIGNETTE FQCQ" style={{ width: '100%', marginLeft: '16px', marginBottom: marginBottom }} maxLength="10" value={vehicule.fqcq} disabled={this.props.authToken != null && !vehicule.isSelected} onChange={e => this.setState({
                              vehicules: this.state.vehicules.map(v => (v.fqcq = v.idVehicule === vehicule.idVehicule ? e.target.value : v.fqcq, v))
                            })} />
                          </td>
                        }
                        <td className="droit-acces-vehicules-td-edit">
                          <EditSvg className="clickable primary-color-on-hover" style={{ marginLeft: '40px' }} onClick={e => {
                            this.setState({ errors: {}, addVehiculeDescription: vehicule.description, addVehiculeNoPlaque: vehicule.noPlaque, addVehiculeAerien: vehicule.type === "AE" }, () => {
                              this.showAddVehiculePopup(null, vehicule);
                            });
                          }} />
                        </td>
                        <td className="droit-acces-vehicules-td-delete">
                          <DeleteSvg className="clickable primary-color-on-hover" style={{ marginLeft: '16px' }} onClick={e => {
                            this.showConfirmDeleteVehiculePopup(vehicule);
                          }} />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div>
              <div style={{ display: 'grid', padding: '8px', marginTop: '8px' }}>
                <span className="label primary clickable" style={{ textTransform: 'initial' }} onClick={e => {
                  // if (this.props.authToken == null) { // Si l'utlisateur se trouve ici avec un authToken null, alors il a cliqué pour continuer sans compte. Plutôt que de le rediriger à son compte (où il peut gérer ses véhicules), on affiche un popup pour créer un véhicule.
                  this.setState({ errors: {}, addVehiculeDescription: "", addVehiculeNoPlaque: "", addVehiculeAerien: false }, () => {
                    this.showAddVehiculePopup();
                  });
                  // }
                  // else {
                  // this.props.gotoProfile("droit-acces");
                  // }
                }}>Cliquez ici pour ajouter un véhicule
                  {/* {this.props.authToken == null ? "Cliquez ici pour ajouter un véhicule" : "Cliquez ici pour ajouter ou modifier des véhicules"} */}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
selectActivite () {
  
  return(
    <div className="droit-acces-div">
    <div className="droit-acces-div droit-acces-label-wrapper">
      <span className="droit-acces-span">AJOUTER UNE ACTIVITÉ :</span>
    </div>
    <div className="droit-acces-div input-container">
      <div style={{ padding: '3px' }}>
        <select
          style={{ marginLeft: '16px', width: '100%' }}
          onChange={e => this.setState({ selectedIdActivite: e.target.value }, () => {
            this.setState({
              secteurs: null,
              pointsEaux: null,
              gibiers: null,
              disponibilites: null
            });
            // Va automatiquement afficher le popup d'activité après avoir chargé toutes ces données
            const activiteExist = this.state.activitesChoisies.find(item => item.activite.idActivite === e.target.value);
            this.getSecteurs(this.state.selectedIdActivite);
            this.getPointsEaux(this.state.selectedIdActivite);
            this.getGibiers(this.state.selectedIdActivite);
            this.getActivitesHorsService(this.state.selectedIdActivite);
          })}
          className="droit-acces-input"
          value={this.state.selectedIdActivite}
        >
          <option value='00000000-0000-0000-0000-000000000000'></option>
          {this.state.activites.map(activite => (
            <option key={activite.idActivite} value={activite.idActivite}>
              {activite.description.toUpperCase()}
            </option>
          ))}
        </select>
      </div>
    </div>
  </div>
  )
}
  renderStepActivites() {
    return (
      <div className="droit-acces-section">
        <div className="droit-acces-page-separator" />
        {this.state.messageInfoActivite && <div className="droit-acces-div" style={{ textAlign: "center" }}>
          <span className="droit-acces-span message">{this.state.messageInfoActivite}</span>
        </div>
        }
        <div className="droit-acces-label-wrapper-title">
          <span className="droit-acces-span">ACTIVITÉS</span>
        </div>
        {this.state.activitesChoisies.length > 0 &&
          <div className="droit-acces-div">
            <div className="droit-acces-div droit-acces-label-wrapper droit-acces-label-wrapper-customize">
              <span className="droit-acces-span">ACTIVITÉS CHOISIES :</span>
            </div>
            <div className="droit-acces-div input-container input-container-customize">
              <div style={{ padding: '3px' }}>
                {
                  this.state.activitesChoisies.map(activiteChoisie => {
                    const updatedParticipants = activiteChoisie.participants.filter(participant => {
                      // if (participant.idAccompagnateur) {
                      //   const isSelected = this.state.accompagnateurs.find(dataItem => dataItem.idAccompagnateur === participant.idAccompagnateur)?.isSelected;
                      //   return isSelected !== false;
                      // } else{
                      // }
                      return true;
                    })
                    // const participants = activiteChoisie.participants.reduce((participants, p) => (participants === '' ? '' : participants + ', ') + p.prenom + ' ' + p.nom, '');
                    const participants = updatedParticipants.reduce((participants, p) => (participants === '' ? '' : participants + ', ') + p.prenom + ' ' + p.nom, '');
                    const gibiers = activiteChoisie.gibiers.reduce((gibiers, g) => (gibiers === '' ? '' : gibiers + ', ') + g.nom, '');
                    const lsDatesEtParticipants = this.getDatesEtParticipants(updatedParticipants);
                    return (
                      <div key={activiteChoisie.idActiviteChoisie}>
                        <div>
                          <span className="droit-acces-span">{activiteChoisie.activite.description.toUpperCase()}</span>
                          <div className="droit-acces-div input-container">
                            <div style={{ padding: '3px' }}>
                              <select
                                style={{ marginLeft: '16px', }}
                                onChange={e => this.setState({ selectedIdActivite: e.target.value }, () => {
                                  this.setState({
                                    secteurs: null,
                                    pointsEaux: null,
                                    gibiers: null,
                                    disponibilites: null
                                  });
                                  // Va automatiquement afficher le popup d'activité après avoir chargé toutes ces données
                                  this.getSecteurs(this.state.selectedIdActivite, activiteChoisie);
                                  this.getPointsEaux(this.state.selectedIdActivite, activiteChoisie);
                                  this.getGibiers(this.state.selectedIdActivite, activiteChoisie);
                                  this.getActivitesHorsService(this.state.selectedIdActivite, activiteChoisie);
                                })}
                                className="droit-acces-input"
                                value={this.state.selectedIdActivite}
                              >
                                <option value='00000000-0000-0000-0000-000000000000'>Modifier</option>
                                {this.state.activites.map(activite => (
                                  <option key={activite.idActivite} value={activite.idActivite}>
                                    {activite.description.toUpperCase()}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        {activiteChoisie.secteur !== null &&
                          <div>
                            <span className="droit-acces-span">Secteur : </span>
                            <span className="droit-acces-span" style={{ fontFamily: 'Barlow-Regular' }}>{activiteChoisie.secteur.nomSecteur}</span>
                            <button type="button" className="modifier" onClick={e => { this.showActivitePopupSecteur(activiteChoisie, this.state.secteurs, this.state.pointsEaux, this.state.gibiers, this.state.disponibilites) }}>Modifier</button>
                          </div>
                        }
                        {activiteChoisie.pointEau !== null &&
                          <div>
                            <span className="droit-acces-span">Point d'eau : </span>
                            <span className="droit-acces-span" style={{ fontFamily: 'Barlow-Regular' }}>{activiteChoisie.pointEau.description}</span>
                            <button type="button" className="modifier" onClick={e => { this.showActivitePopupSecteur(activiteChoisie, this.state.secteurs, this.state.pointsEaux, this.state.gibiers, this.state.disponibilites) }}>Modifier</button>
                          </div>
                        }
                        {gibiers !== '' &&
                          <div>
                            <span className="droit-acces-span">Gibier{activiteChoisie.gibiers.length > 1 ? 's' : ''} : </span>
                            <span className="droit-acces-span" style={{ fontFamily: 'Barlow-Regular' }}>{gibiers}</span>
                            <button type="button" className="modifier" onClick={e => { this.showActivitePopupSecteur(activiteChoisie, this.state.secteurs, this.state.pointsEaux, this.state.gibiers, this.state.disponibilites) }}>Modifier</button>
                          </div>
                        }
                        <div>
                          <span className="droit-acces-span">Participant{activiteChoisie.participants.length > 1 ? 's' : ''} : </span>
                          <span className="droit-acces-span" style={{ fontFamily: 'Barlow-Regular', wordBreak: 'break-word' }}>{participants}</span>
                          <button type="button" className="modifier" onClick={e => { this.showActivitePopupParticipants(activiteChoisie, this.state.secteurs, this.state.pointsEaux, this.state.gibiers, this.state.disponibilites) }}>Modifier</button>
                        </div>
                        <div>
                          <span className="droit-acces-span">{lsDatesEtParticipants.length > 1 ? "Dates sélectionnées" : "Date sélectionnée"} et participant{activiteChoisie.participants.length > 1 ? 's' : ''} :</span>
                          <button type="button" className="modifier" onClick={e => { this.showActivitePopupJournees(activiteChoisie, this.state.secteurs, this.state.pointsEaux, this.state.gibiers, this.state.disponibilites) }}>Modifier</button>
                        </div>
                        {
                          lsDatesEtParticipants.map(data => {
                            return (
                              <div>
                                <span className="droit-acces-span" style={{ fontFamily: 'Barlow-Regular', marginLeft: "3em" }}>{formatageDatePourDroitDacces(data[0])}, {data[1]}</span>
                              </div>
                            )
                          })
                        }
                        <div>
                          <span className="label primary clickable" style={{ textTransform: 'initial' }} onClick={e => {
                            this.props.showYesNoPopup({
                              title: "CONFIRMATION",
                              bodyText: `Êtes-vous certain de vouloir supprimer cette activité?`,
                              bodyClass: "droit-acces-page",
                              footerClass: "droit-acces-page",
                              onYesClick: e => {
                                this.setState({ activitesChoisies: this.state.activitesChoisies.filter(a => a.idActiviteChoisie !== activiteChoisie.idActiviteChoisie) }, () => {
                                  this.props.hidePopup();
                                });
                              }
                            });
                          }}>Cliquez ici pour supprimer cette activité</span>
                        </div>
                        <div className="droit-acces-page-separator" />
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        }
        {/* this.state.activitesChoisies.length */}

        {((this.state.activitesChoisies.length == this.state.maxActiviteParJour) && (this.state.maxActiviteParJour != 0)) ?
          <div className="droit-acces-div" style={{ textAlign: "center" }}>
            <span className="droit-acces-span message">Vous ne pouvez pas ajouter plus de {this.state.maxActiviteParJour} activités pour cet enregistrement</span>
          </div>
          :
          this.selectActivite()
        }
      </div>
    );
  }

  

  renderStepSommaire() {
    const details = this.getSortedDetails();

    const participants = [{ nom: this.state.infoResponsable.nom, prenom: this.state.infoResponsable.prenom }, ...this.state.accompagnateurs.filter(a => a.isSelected), ...this.state.listeDependant.filter(a => a.isSelected)];
    const rowStyle = { clear: 'both' };
    const spanStyle = { fontFamily: 'Barlow-Regular' };
    //const sousTotauxStyle = {fontFamily: 'Barlow-Regular'};

    const { sousTotal, fraisAdmin, tps, tvq, total } = this.getMontants();



    return (
      <div className="droit-acces-section">
        <div className="droit-acces-page-separator" />
        <div className="droit-acces-label-wrapper-title">
          <span className="droit-acces-span">SOMMAIRE</span>
        </div>

        {details.map((d, detailIndex) => {
          const participant = participants[d.indexParticipant];
          //const leftSpanStyle = {fontFamily: 'Barlow-Regular'};
          //const rightSpanStyle = {fontFamily: 'Barlow-Regular'};
          //const leftSpanStyleSpeciaux = {fontFamily: 'Barlow-Regular'};
          //const rightSpanStyleSpeciaux = {fontFamily: 'Barlow-Regular'};

          return (
            <div key={detailIndex}>
              <div style={detailIndex === 0 ? rowStyle : { ...rowStyle, marginTop: '16px', display: 'inline-block' }} className="label-sommaire">
                <span className="droit-acces-span droit-acces-sommaire-span-left" style={spanStyle}>{d.quantiteInitiale} x {d.description} /{participant?.prenom || null} {participant?.nom || null}</span>
                <span className="droit-acces-span droit-acces-sommaire-span-right" style={spanStyle}>{d.montantAvantSpeciaux.toFixed(2)}$</span>
              </div>
              {d.gratuites > 0 &&
                <div style={{ ...rowStyle, marginTop: '16px', display: 'inline-block' }}  className="label-sommaire">
                  <span className="droit-acces-span droit-acces-sommaire-span-left" style={spanStyle}>{d.gratuites} x {d.description} (Gratuités) /{participant?.prenom || null} {participant?.nom || null}</span>
                  <span className="droit-acces-span droit-acces-sommaire-span-right" style={spanStyle}>-{roundTwoDecimals((d.montantAvantSpeciaux - d.montantApresSpeciaux) * d.gratuites / (d.livrets + d.gratuites)).toFixed(2)}$</span>
                </div>
              }
              {d.livrets > 0 &&
                <div style={{ ...rowStyle, marginTop: '16px', display: 'inline-block' }}  className="label-sommaire">
                  <span className="droit-acces-span droit-acces-sommaire-span-left" style={spanStyle}>{d.livrets} x {d.description} (Livrets) /{participant?.prenom || null} {participant?.nom || null}</span>
                  <span className="droit-acces-span droit-acces-sommaire-span-right" style={spanStyle}>-{roundTwoDecimals((d.montantAvantSpeciaux - d.montantApresSpeciaux) * d.livrets / (d.livrets + d.gratuites)).toFixed(2)}$</span>
                </div>
              }
            </div>
          )
        })}

        <div className="droit-acces-page-separator" />

        <div style={rowStyle}>
          <span className="droit-acces-span droit-acces-sommaire-span-left" style={spanStyle}>Sous-total</span>
          <span className="droit-acces-span droit-acces-sommaire-span-right" style={spanStyle}>{sousTotal.toFixed(2)}$</span>
        </div>

        <div style={rowStyle}>
          <span className="droit-acces-span droit-acces-sommaire-span-left" style={spanStyle}>Frais d'administration</span>
          <span className="droit-acces-span droit-acces-sommaire-span-right" style={spanStyle}>{fraisAdmin.toFixed(2)}$</span>
        </div>

        <div style={rowStyle}>
          <span className="droit-acces-span droit-acces-sommaire-span-left" style={spanStyle}>TPS</span>
          <span className="droit-acces-span droit-acces-sommaire-span-right" style={spanStyle}>{tps.toFixed(2)}$</span>
        </div>

        <div style={rowStyle}>
          <span className="droit-acces-span droit-acces-sommaire-span-left" style={spanStyle}>TVQ</span>
          <span className="droit-acces-span droit-acces-sommaire-span-right" style={spanStyle}>{tvq.toFixed(2)}$</span>
        </div>

        <div className="droit-acces-page-separator" />

        <div style={rowStyle}>
          <span className="droit-acces-span droit-acces-sommaire-span-left" style={{ ...spanStyle, fontFamily: 'Barlow-Bold' }}>Total</span>
          <span className="droit-acces-span droit-acces-sommaire-span-right" style={{ ...spanStyle, fontFamily: 'Barlow-Bold' }}>{total.toFixed(2)}$</span>
        </div>
      </div>
    );
  }

  renderStepPaiement() {
    return (
      <div className="droit-acces-section">
        <div className="droit-acces-page-separator" />
        <div className="droit-acces-label-wrapper-title">
          <span className="droit-acces-span">PAIEMENT</span>
        </div>
        <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <div style={{width:'70%'}}>
          <PaiementForm ref={this.paiementFormRef} montantTotal={this.getMontants().total} showInfoClient={true} idZec={this.props.idZec}/>
          </div>
        </div>
      </div>
    );
  }

  renderStepConfirmation() {
    return (
      <div className="droit-acces-section">
        <div className="droit-acces-page-separator" />
        <div className="droit-acces-label-wrapper-title">
          <span className="droit-acces-span">CONFIRMATION</span>
        </div>
        <div>
          <span className="droit-acces-span message">La transaction s'est effectuée avec succès et une copie de votre enregistrement vous a été envoyé par courriel.</span>
        </div>
        {this.state.finishTransaction &&
              <div style={{width:'100%'}}>
                <div style={{width:'100%'}}>
                  <div className="tabs tab-button-view-pdf">
                    <button className={`tab-button ${this.state.activeTabPreviewFile === 1 ? 'active-tab' : ''}`} onClick={() => this.handleTabChanges(1)}>Fiche D'enregistrement</button>
                    <button className={`tab-button ${this.state.activeTabPreviewFile === 2 ? 'active-tab' : ''}`} onClick={() => this.handleTabChanges(2)}>Facture</button>
                  </div>
                  {((this.state.activeTabPreviewFile === 1 && this.state.registrationFile) || (this.state.activeTabPreviewFile === 2 && this.state.invoiceFile)) ? (
                    <embed src={`data:application/pdf;base64, ${this.state.activeTabPreviewFile === 1 ? this.state.registrationFile : this.state.invoiceFile}`} type="application/pdf" width="100%" height="800" />
                  ) : (
                    <div className="noFile">
                      <p>Aucun fichier disponible pour l'onglet sélectionné.</p>
                    </div>
                  )}
                </div>
                <div id="droit-acces-next-wrapper" className="droit-acces-div-next" onClick={e => window.location.reload(true)}>
                  <span className="droit-acces-span btn">FAIRE UN NOUVEAU DROIT D'ACCÈS</span>
                </div>
              </div>
            }
      </div>
    );
  }
  handleTabChanges(indexTab) {
    this.setState((prevState) => ({
      activeTabPreviewFile: indexTab
    }));
  }
  

  render() {
    if (this.props.authToken == null && !this.state.continuerSansCompte) {
      // Utilisateur non authentifié (et n'ayant pas choisi de se connecter ou créer un compte). On affiche un message et lien pour se connecter, créer un compte, ou continuer sans compter.
      return (
        <div id="droit-acces-page" className="droit-acces-page">
          <div className="droit-acces-div" style={{ textAlign: "center" }}>
            <span className="droit-acces-span message">Vous n'êtes pas connecté à votre compte.</span>
          </div>
          <div className="d
          roit-acces-div" style={{ textAlign: "center" }}>
            <span className="droit-acces-span message">Cliquez ici pour vous </span>
            <span className="clickable message primary-color droit-acces-span droit-acces-span-primary" onClick={e => this.props.gotoProfile("droit-acces")}>connecter à votre compte</span>
            <span className="droit-acces-span message"> ou pour </span>
            <span className="clickable message primary-color droit-acces-span droit-acces-span-primary" onClick={e => this.props.gotoCreerCompte("droit-acces")}>créer un nouveau compte</span>
            <span className="droit-acces-span message">.</span>
          </div>
          <div className="droit-acces-div" style={{ textAlign: "center" }}>
            <span className="droit-acces-span message">Vous pouvez également </span>
            <span className="clickable message primary-color droit-acces-span droit-acces-span-primary" onClick={e => this.setState({ continuerSansCompte: true })}>continuer sans compte</span>
            <span className="droit-acces-span message">.</span>
          </div>
        </div>
      );
    };

    return (
      // Utilisateur authentifié (ou ayant accepté de continuer sans compte). On affiche les champs pour s'enregistrer.
      <div id="droit-acces-page" className="droit-acces-page">
        <div className="stepper-wrapper">
          <Stepper
            steps={this.state.stepperSteps}
            activeStep={this.getStepperIndex()}
            maximalStepReached={this.state.etapeMaximaleAtteinte}
            activeColor={"rgb(0, 152, 179)"}
            completeColor={theme.primaryColor}
            circleFontSize={18}
            titleFontSize={18}
            onClick={this.setStep}
          />
        </div>
        <div className="stepper-wrapper-small-screen">
          <Stepper
            steps={this.state.stepperSteps.map(step => ({ ...step, title: "" }))}
            activeStep={this.getStepperIndex()}
            maximalStepReached={this.state.etapeMaximaleAtteinte}
            activeColor={"rgb(0, 152, 179)"}
            completeColor={theme.primaryColor}
            circleFontSize={18}
            titleFontSize={18}
            onClick={this.setStep}
          />
        </div>

        <div id="droit-acces-page-main-content">
          {this.state.stepId === STEP_INFO_RESPONSABLE && this.renderStepInfoResponsable()}
          {this.state.stepId === STEP_DATE_HEURE_SEJOUR_ENREGISTREMENT && this.renderStepDateHeureSejourEnregistrement()}
          {this.state.stepId === STEP_VEHICULES && this.props.configuration.cacherVehiculesSurPav.toUpperCase() === "FALSE" && this.renderStepVehicules()}
          {this.state.stepId === STEP_ACCOMPAGNATEURS && this.renderStepAccompagnateurs()}
          {this.state.stepId === STEP_ACTIVITES && this.renderStepActivites()}
          {this.state.stepId === STEP_SOMMAIRE && this.renderStepSommaire()}
          {this.state.stepId === STEP_PAIEMENT && this.renderStepPaiement()}
          {this.state.stepId === STEP_CONFIRMATION && this.renderStepConfirmation()}

          <div className={!this.state.finishTransaction ? 'step-buttons unselectable' :''}>
            {this.state.stepId !== STEP_INFO_RESPONSABLE && this.state.stepId !== STEP_CONFIRMATION &&
              <div id="droit-acces-next-wrapper" className="droit-acces-div-previous" onClick={e => this.goToPreviousStep()}>
                <span className="droit-acces-span btn">PRÉCÉDENT</span> {/* &laquo; enlevé car sinon le texte ne s'affiche pas au complet sur petits écrans (320px) */}
              </div>
            }
            {this.state.stepId !== STEP_CONFIRMATION &&
              <div id="droit-acces-next-wrapper" className="droit-acces-div-next" onClick={e => this.validateAndGoToNextStep()}>
                <span className="droit-acces-span btn">{this.state.stepId === STEP_SOMMAIRE && this.getMontants().total !== 0 ? "PASSER AU PAIEMENT" : this.state.stepId === STEP_PAIEMENT ? "PAYER" : "SUIVANT"}</span>  {/* &raquo; enlevé car sinon le texte du précédant ne s'affiche pas au complet sur petits écrans (320px) */}
              </div>
            }
          </div>
        </div>
      </div>
    );

  }
  //#endregion
}

function mapStateToProps(state, ownProps) {
  return {
    authToken: state.authToken,
    configuration: state.configuration,
    parametresWeb: state.parametresWeb,
    overlay: state.overlay,
    genericPopup: state.genericPopup,
    entrepriseName: state.entreprise.nom,
    shop: state.shop,
    infoClient : state.infoClient,
    entrepriseContact: isUnformattedPhoneNumber(state.entreprise.contact) ? formatPhoneNumber(state.entreprise.contact) : state.entreprise.contact, // state.entreprise.contact peut être un courriel ou un numéro de téléphone
  };
}

export default withNavigation(connect(mapStateToProps, { getProvincesConfigWeb, getPaysConfigWeb, setAuthToken, setAlert, showOverlay, hideOverlay, showPopup, hidePopup, showYesNoPopup, showOkPopup, SaveProfilClient })(DroitAccesPage));
