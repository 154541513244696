import React from "react";
import { connect } from "react-redux";
import "../../css/main-banner.css";

function mapStateToProps(state, ownProps) {
  return {
    configuration: state.configuration
  };
}

function MainBanner(props) {
  if (!props.configuration.affichageIframe || props.configuration.affichageIframe === "True") return <div />;

  if (props.configuration.imageBanniereRpa) {
    return (
      <div id="main-banner">
        <img src={"data:image/png;base64," + props.configuration.imageBanniereRpa} style={{height: 'inherit'}} alt="banner"></img>
      </div>
    )
  }

  return (
    <div id="main-banner">
      <img className="transition" src={require("../../images/main-banner/1-1972x1306.jpg")} alt="banner"></img>
      <img className="transition" src={require("../../images/main-banner/2-2177x1452.jpg")} alt="banner"></img>
      <img className="transition" src={require("../../images/main-banner/3-2355x1570.jpg")} alt="banner"></img>
      <img className="transition" src={require("../../images/main-banner/4-2400x681.jpg")} alt="banner"></img>
    </div>
  );
}

export default connect(mapStateToProps)(MainBanner);
