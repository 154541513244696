import React from "react";

function NextSvg(props) {
  let { dispatch, fill, ...otherProps } = props

  fill = fill || "rgb(0, 0, 0)";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="48px" height="48px" {...otherProps}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path fill={fill} d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
    </svg>
  );
}

export default NextSvg;
