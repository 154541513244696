import React from "react";
import Popup from "reactjs-popup";
import "./confirmation-option-reservation-auto.css";

const ConfirmationOptionReservation = (props) => {
  const onConfirmation = () => {
    props.onConfirmation(props.selectedUnite);
  };

  return (
    <div>
      <Popup on="click" open={props.isOpen} onClose={props.handleClose} modal={true} closeOnDocumentClick={false} position={"center center"} nested className="confirmation-option-reservation-auto">
        <div className="tranche-age-header">
          <h3>Attention</h3>
        </div>
        <div className="confirmation-option-reservation-auto">
          <p dangerouslySetInnerHTML={{ __html: props.message }}></p>
        </div>
        <div className="buttons">
          <button onClick={onConfirmation}>Oui</button>
          <button onClick={props.handleClose}>Non</button>
        </div>
      </Popup>
    </div>
  );
};

export default ConfirmationOptionReservation;
