import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { setSeoTitle } from "./document";

export function useSeoTitle(mainPage, section = "", detail = "") {
  const [titleChanged, setTitleChanged] = useState(false);
  const entrepriseName = useSelector(state => state.entreprise.nom);

  useEffect(() => {
    if (!titleChanged) {
      setSeoTitle(entrepriseName, mainPage, section, detail);
      setTitleChanged(true);
    }
  }, [entrepriseName, mainPage, section, detail])
}
