import React, { Component } from 'react';
import HeaderTitle from "./header-title.js";

class ContainerDelivery extends Component {

  addressTypes = ['Pourvoirie', 'Chalet', 'Roulotte', 'Tente', 'Autre']

  constructor(props) {
    super(props);
    this.confirm = this.confirm.bind(this);
    this.validate = this.validate.bind(this);
  }

  confirm() {
    if (this.props.type === "livrer") {
      if (this.validate()) {
        this.props.handleChange('isDeliveryConfirmed', true);
        this.props.close();
      } else {
        this.validate();
        this.props.handleChange('areErrorsDisplayed', true);
      }
    }
  }

  validate() {
    let errors = this.props.errors;
    let canContinue = true;
    if (!this.props.addressType) {
      errors.addressType = "Entrez un type d'adresse";
      canContinue = false;
    }
    if (!this.props.addressNumber) {
      errors.addressNumber = "Entrez votre adresse";
      canContinue = false;
    }
    if (!this.props.addressPostal) {
      errors.addressPostal = "Entrez votre adresse postale";
      canContinue = false;
    }
    if (!this.props.addressStreet) {
      errors.addressStreet = "Entrez votre adresse civique";
      canContinue = false;
    }
    if (!this.props.city) {
      errors.city = "Entrez votre ville";
      canContinue = false;
    }
    return canContinue
  }

  renderAddressTypes() {
    let rows = [];
    this.addressTypes.map((cur) =>
      rows.push(
        <option value={cur}>
          {cur}
        </option>
      )
    );
    return rows;
  }

  render() {
    return (
      <React.Fragment>
        <HeaderTitle number={2} text="Livrer la commande au" />
        <div className="" style={{ margin: "auto" }}>
          <div className="row" style={{ margin: "auto" }}>
            <div className="autocomplete-search">
              <span style={{ display: "table", width: "100%" }}>
                <span className="autocomplete-icon"><i className="fa fa-search"></i>
                </span>
                <span style={{ display: "table-cell" }}>
                  <div className="autocomplete" value="">
                    <input type="text" placeholder="Recherche de votre adresse" className="autocomplete" />
                    <div>
                    </div>
                  </div>
                </span>
              </span>
            </div>
            <div style={{ marginTop: "40px" }}>
              <div>
                <div className="form-group has-float-label">
                  <label>Type d'adresse*</label>
                  <select
                    value={this.props.addressType}
                    className={`details-form-control`}
                    onChange={(e) => this.props.handleChange('addressType', e.target.value)}
                  >
                    {this.renderAddressTypes()}
                  </select>
                </div>
                <div className="form-group" style={{ display: "table", width: "100%" }}>
                  <div className="form-group has-float-label" style={{ display: "table-cell", width: "29%", minWidth: "100px" }}>
                    <input value={this.props.addressNumber}
                      className={`details-form-control ${this.props.errors.addressNumber && "focus"}`}
                      onChange={(e) => this.props.handleChange('addressNumber', e.target.value)}
                      type="text" placeholder=" "
                    />
                    <label>Adresse civ.*
                    </label>
                  </div>
                  <div className="form-group has-float-label" style={{ display: "table-cell" }}>
                    <input
                      type="text"
                      placeholder=" "
                      className={`details-form-control ${this.props.errors.addressStreet && "focus"}`}
                      value={this.props.addressStreet}
                      onChange={(e) => this.props.handleChange('addressStreet', e.target.value)}
                    />
                    <label>Adresse*
                    </label>
                  </div>
                </div>
                <div className="form-group " style={{ display: "table", width: "100%" }}>
                  <div className="form-group has-float-label" style={{ display: "table-cell", width: "50%", minWidth: "130px" }}>
                    <input type="text" placeholder=" "
                      className={`details-form-control ${this.props.errors.city && "focus"}`}
                      value={this.props.city}
                      onChange={(e) => this.props.handleChange('city', e.target.value)}
                    />
                    <label>
                      Ville*
                    </label>
                  </div>
                  <div className="form-group has-float-label" style={{ display: "table-cell", width: "20%", minWidth: "60px" }}>
                    <label>
                      Province*
                    </label>
                    <select
                      className={`details-form-control`}
                      value={this.props.province}
                      onChange={(e) => this.props.handleChange('province', e.target.value)}
                    >
                      <option value="QC">
                        QC
                      </option>
                      <option value="ON">
                        ON
                      </option>
                      <option value="NB">
                        NB
                      </option>
                    </select>
                  </div>
                  <div className="form-group has-float-label" style={{ display: "table-cell" }}>
                    <input
                      type="text" placeholder=" "
                      className={`details-form-control ${this.props.errors.addressPostal && "focus"}`}
                      value={this.props.addressPostal}
                      onChange={(e) => this.props.handleChange('addressPostal', e.target.value)}
                    />
                    <label>Code postal*
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group has-float-label">
                <input maxLength={40} type="text" placeholder=" " className="details-form-control"
                  value={this.props.instructionsDelivery}
                  onChange={(e) => this.props.handleChange('instructionsDelivery', e.target.value)}
                />
                <label>Instructions pour la livraison
                </label>
              </div>
              <br />
              <button onClick={this.confirm} className="btn-popup btn-confirm">
                Valider l'adresse
                </button>
              <br />
              <span >
                <button className="btn-popup btn-primary">
                  Sauvegarder les détails
                </button>
              </span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContainerDelivery;