import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import "./Alert.css";
import { removeAlert } from '../actions/alert';

const Alert = ({ alerts, removeAlert }) =>
  alerts !== null &&
  alerts.length > 0 &&
  <div id="notifications">
    {alerts.map(alert => {
      const isString = typeof alert.msg === "string";
      return (
        <div key={alert.id}
          className={`success  alert-${alert.alertType}`}
        >
          <span className="bell" aria-label="ring" role="img">🔔</span>
          <span className="alert-font">{isString ? alert.msg : alert.msg.description}</span>
          {!isString &&
            <span>
              {alert.msg.details.map((detail, key) => (<><br key={key} />{detail}</>))}
            </span>}
          <span className="closebtn"
            onClick={() => removeAlert(alert.id)}
          >&times;
          </span>
        </div>
      )
    })
    }
  </div >

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
  removeAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  alerts: state.alert
});

export default connect(mapStateToProps, { removeAlert })(Alert);