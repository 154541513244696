import React, { Component } from 'react';
import "./details-commande.css";
import DetailsPopup from "./item-details-popup/details-popup.js";
import InputDay from "./inputDay.js";
import InputTime from "./inputTime.js";

class DetailsCommande extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      type: "",
      optionNow: true
    }
    this.selectType = this.selectType.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.handleNowOrLater = this.handleNowOrLater.bind(this);
    this.handleType = this.handleType.bind(this);
  }

  selectType(event) {
    this.setState({
      type: event.target.value
    })
  }

  handleType(event){
    this.setState({
      type: event
    })
  }

  closePopup(event) {
    this.setState({
      isOpen: false
    })
  }

  openPopup(event) {
    this.setState({
      isOpen: true,
      type: event,
    })
  }

  handleNowOrLater(bool) {
    this.setState({
      optionNow: bool
    })
  }

  render() {
    let isOpen = this.state.isOpen;
    let type = this.state.type;
    return (
      <div className="details-container">
        <div className={`type-selector`}>
          <p className="normal-font">Soumettre une commande pour</p>
          <select value={this.state.type} className="normal-font select-type" onChange={(e) => this.openPopup(e.target.value)}>
            <option value="" disabled>- sélectionner -</option>
            <option value="livrer">livrer</option>
            <option value="emporter">emporter</option>
          </select>
          <DetailsPopup
            handleNowOrLater={this.handleNowOrLater}
            optionNow={this.state.optionNow}
            isOpen={isOpen}
            type={type}
            selectType={this.selectType}
            handleType={this.handleType}
            handleChange={this.props.handleChange}
            closePopup={this.closePopup}

            addressType={this.props.addressType}
            addressNumber={this.props.addressNumber}
            addressStreet={this.props.addressStreet}
            addressPostal={this.props.addressPostal}
            city={this.props.city}
            province={this.props.province}
            instructionsDelivery={this.props.instructionsDelivery}
            
            takeoutDay={this.props.takeoutDay}
            takeoutTime={this.props.takeoutTime}
            instructionsTakeout={this.props.instructionsTakeout}
            errors={this.props.errors}
          />
        </div>
        {/* {this.props.isConfirmed && */}
          <div className="div-second-area">
            <p className="normal-font">Heure de cueillette</p>
            <div className="div-boxes">
              <InputDay className="box" day={this.props.day} handleChange={this.props.handleChange} />
              <div className="display-box">
                <InputTime className="box" time={this.props.time} handleChange={this.props.handleChange} />
              </div>
            </div>
          </div>
        {/* } */}
      </div>
    );
  }
}

export default DetailsCommande;