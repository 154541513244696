import React, { Component } from 'react';

class OptionTitle extends Component {

  render() {

    return (
      <div className="jss2613">
        <div className="jss2614">
          <p className="options-popup-font jss2615">{this.props.number}
          </p>
        </div>
        <div className="jss2616">
          <p className="options-popup-font jss2617">{this.props.name}
          </p>
          <p className="options-popup-font jss2618">
            Choix: {this.props.isChosen ? 1 : <span className="text-red">0</span>} / 1
          </p>
        </div>
      {/* <div className="jss2613">
            <div className="jss2614">
              <p className="options-popup-font jss2615">4
                                                      </p>
            </div>
            <div className="jss2616">
              <p className="options-popup-font jss2617">désirez-vous ajouter un dessert à votre plat principal ?
                </p><p className="options-popup-font jss2618">Choix: 5 / 10</p>
            </div>
          </div> */}
      </div>
    );
  }
}

export default OptionTitle;