import React from 'react';

const SommaireEvevenement = () => {
    return (
        <div className="sommaire-right-panel max-width sommaire-large-screen">
            <div className="position-relative">
                <div className="facture-top-first"></div>
                <div className="header-sommaire">
                    <p className="normal-font">FACTURE</p>
                    <p className="normal-font"></p>
                    <p className="normal-font"></p>
                </div>
                <div className="facture-top-second"></div>
            </div>
            <div className="containerItems">
                <div className="display-flex">
                    <div className="flex-1" style={{ width: '100%' }}>
                        <div className="empty-commande">
                            <p className="normal-font empty-commande-font">Votre commande est vide</p>
                        </div>
                        <div className="sommaire-taxes-div">
                            <div className="sommaire-flex-row">
                                <div style={{ display: 'flex', flex: '1 1 0%' }}>
                                    <p className="display-text font-category">SOUS-TOTAL (0 articles)</p>
                                </div>
                                <p className="display-text font-price">0.00 $ CA</p>
                            </div>
                            <div className="sommaire-flex-row">
                                <div style={{ display: 'flex', flex: '1 1 0%' }}>
                                    <p className="display-text font-category">FRAIS D'ADMINISTRATION</p>
                                </div>
                                <p className="display-text font-price">0.00 $ CA</p>
                            </div>
                            <div className="sommaire-flex-row">
                                <div style={{ display: 'flex', flex: '1 1 0%' }}>
                                    <p className="display-text font-category">TPS</p>
                                </div>
                                <p className="display-text font-price">0.00 $ CA</p>
                            </div>
                            <div className="sommaire-flex-row">
                                <div style={{ display: 'flex', flex: '1 1 0%' }}>
                                    <p className="display-text font-category">TVQ</p>
                                </div>
                                <p className="display-text font-price">0.00 $ CA</p>
                            </div>
                            <div className="sommaire-flex-row" style={{ borderTop: '2px solid rgb(204, 204, 204)' }}>
                                <div style={{ display: 'flex', flex: '1 1 0%' }}>
                                    <p className="display-text font-category">TOTAL</p>
                                </div>
                                <p className="display-text font-price">0.00 $ CA</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="position-relative">
                <div className="facture-bottom-first"></div>
                <div className="jss567">
                    <div className="jss569">
                        <p className="normal-font jss572">MONTANT DU DÉPÔT</p>
                        <p className="normal-font jss573">0.00 $ CA</p>
                    </div>
                </div>
                <div className="facture-bottom-second"></div>
            </div>
            <div className="divConfirm">
                <button className="mainButton payButton not-allowed">
                    <p className="payButton-trigger normal-font">Aller au panier</p>
                    <div className="sommaire-error-msg hide">Sélectionner un billet</div>
                </button>
            </div>
        </div>
    )
}

export default SommaireEvevenement