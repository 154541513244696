import {
  SET_CONFIGURATION,
} from "./types";

export const setConfiguration = configuration => async (dispatch) => {
  try {
    dispatch({
      type: SET_CONFIGURATION,
      configuration: configuration,
    });
  } catch (err) {
      console.log(err);
  }
};