import React from "react";
import Popup from 'reactjs-popup';
import PopupFooter from "./popup-footer";
import SommaireRightPanel from "../achat-forfait/sommaire-right-panel";

function FacturePanierPopup(props) {
  return (
    <Popup
      content="test"
      on='click'
      open={props.isOpen}
      onClose={props.handleClose}
      modal
      nested
    >
      {close => (
        <div className="fixed-panel">
          <div className="dark-container" onClick={close}>
          </div>
          <div className="item-popup-div">
            <div className="item-popup-background" style={{ borderRadius: '0px', maxWidth: '600px', minHeight: '256px', maxHeight: '100%' }}>
              <div className="popup-main-panel">
                <SommaireRightPanel
                  confirmTitle='Procéder au paiement'
                  showByType={props.showByType}
                  showConfirm={false}
                  plusUpdate={props.plusUpdate}
                />
              </div>
              <PopupFooter title="FERMER" onClick={() => props.handleClose()} />
            </div>
          </div>
        </div>
      )}
    </Popup>
  )
}

export default FacturePanierPopup;