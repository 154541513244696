import * as dateFns from "date-fns";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import withNavigation from "../higher-order/navigation";
import { getUniteImageById, getUniteById, getDatesHorsService } from "../../server/services/unite-service";
import { getTypesUnites } from "../../server/services/type-unite-service";
import { addReservationTemp } from "../../server/services/reservation-service";
import { toISODateTimeString, toSelectedISODateTimeString } from "../../utils/formatting";
import { addToShoppingCart, setErrorAlert, updateShop, setTimeoutShop, CartItemType } from "../actions/shop";
import MemberPopup from "../popups/member.js";
import OptionalProductPopup from "../popups/produit-optionnel.js";
import "./activity-reservation-page.css";
import CalendarActivity from "./calendar-activity";
import dates from "../../constants/dates.js";
import Select from "./select.js";
import { PATH_PANIER } from "../../constants/navigation-constants";
import Translator from "../translator";

const today = new Date();
today.setHours(0);
today.setMinutes(0);
today.setSeconds(0);
today.setMilliseconds(0);

function getRoundedDateHours(date) {
  return date.getMinutes() > 50 ? date.getHours() + 1 : date.getHours();
}

function getHoursRange(startHour, endHour) {
  const unavailableHours = [];
  for (let hour = startHour; hour < endHour; hour++) unavailableHours.push(hour);

  return unavailableHours;
}
function getOutServiceHours(outOfServiceDay) {
  // les heures hors services se terminent par 59
  let hours = [];
  for (let i = 0; i < outOfServiceDay.length; i++) {
    hours = hours.concat(getHoursRange(getRoundedDateHours(outOfServiceDay[i].start), getRoundedDateHours(outOfServiceDay[i].end)));
  }
  return [...new Set(hours)];
}
function mapStateToProps(state, ownProps) {
  return {};
}

function stringHourToArrHour(stringHour) {
  //stringHour = 15:59
  let values = stringHour.split(":");
  const minutes = parseInt(values[1]);
  return [parseInt(values[0]) + (minutes > 30 ? 1 : 0), minutes > 25 && minutes < 35 ? 30 : 0];
}

function stringPeriodToDatePeriod({ debut, fin }) {
  return {
    start: new Date(debut),
    end: new Date(fin)
  };
}
function stringArrPeriodToDatePeriod(arr) {
  let length = arr.length;
  let result = [];
  for (let i = 0; i < length; i++) {
    result.push({
      start: new Date(arr[i].dateDebut),
      end: new Date(arr[i].dateFin)
    });
  }

  return result;
}
function toSelectedISOString(selectedDate, selectedHour) {
  return toSelectedISODateTimeString(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), parseInt(selectedHour), 0, 0, 0));
}

function toISOString(selectedDate, selectedHour) {
  return toISODateTimeString(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), parseInt(selectedHour), 0, 0, 0));
}

class ActivityReservationPage extends Component {
  constructor(props) {
    super(props);
    const current = new Date();
    // const tomorrow = new Date(current.getFullYear(), current.getMonth(), current.getDate() + 1);
    const isReservationModification = this.props.location.state !== undefined;
    this.state = {
      dataLoaded: false,
      unavailablePeriodsBase: [],
      unavailablePeriods: [
        // {
        //   date: dateFns.format(current, 'yyyy/MM/dd'),
        //   hours: [8, 9, 10],
        //   hasOnlyOutOfServiceHours,
        //   isFull: true // or undefined
        //},
      ],
      initialDate: current,
      dateFin: null,
      selectedDate: isReservationModification && this.props.location.state.selectedDate ? this.props.location.state.selectedDate : null,
      selectedStartHour: isReservationModification && this.props.location.state.selectedStartHour ? this.props.location.state.selectedStartHour : "",
      selectedEndHour: isReservationModification && this.props.location.state.selectedEndHour ? this.props.location.state.selectedEndHour : "",
      selectedAdults: isReservationModification && this.props.location.state.selectedAdults ? this.props.location.state.selectedAdults : 0,
      selectedChildren: isReservationModification && this.props.location.state.selectedChildren ? this.props.location.state.selectedChildren : 0,
      selectedOptionalProducts: isReservationModification && this.props.location.state.selectedProducts ? this.props.location.state.selectedProducts : [],
      isReservationModification: isReservationModification,
      isOpen: false,
      openOptionalProduct: false,
      originalReservation: { selectedDate: null, selectedHours: null }
    };
    this.handleChange = this.handleChange.bind(this);
    this.addToShoppingCart = this.addToShoppingCart.bind(this);
    this.changePeriodSelection = this.changePeriodSelection.bind(this);
    this.changeProductSelection = this.changeProductSelection.bind(this);
    this.closeMemberPopup = this.closeMemberPopup.bind(this);
    this.closeOptionalProductPopup = this.closeOptionalProductPopup.bind(this);
    this.getTypeUniteInfo = this.getTypeUniteInfo.bind(this);
    this.hasAnyUnavailableProduct = this.hasAnyUnavailableProduct.bind(this);
    this.renderFirstHours = this.renderFirstHours.bind(this);
    this.renderLastHours = this.renderLastHours.bind(this);
    this.openMemberPopup = this.openMemberPopup.bind(this);
    this.openOptionalProductPopup = this.openOptionalProductPopup.bind(this);
    this.setSelectedOptionalProducts = this.setSelectedOptionalProducts.bind(this);
    this.unlockSelectedDateAndHours = this.unlockSelectedDateAndHours.bind(this);
    this.updateUnavailablePeriods = this.updateUnavailablePeriods.bind(this);
  }
  TEXTS = Translator(this.props.match.params.language, "TXT_ACTIVITY_RESERVATION_PAGE");
  monthsArr = this.props.language === 'fr' ? dates.MONTHS : dates.MONTHS_EN;
  daysArr = this.props.language === 'fr' ? dates.DAYS : dates.DAYS_EN;

  componentDidMount() {
    if (this.state.isReservationModification) {
      this.setState({
        originalReservation: {
          selectedDate: new Date(this.state.selectedDate),
          selectedHours: getHoursRange(Number(this.state.selectedStartHour), Number(this.state.selectedEndHour))
        }
      });
    }

    this.getTypeUniteInfo();
    getUniteImageById(this.props.match.params.idUnite).then((image) => {
      this.setState({
        image: image
        // typePeriode: typeUnite[0].typePeriode,
      });
    });

    getUniteById(this.props.match.params.idUnite, "planBase,indisponibilite").then((unite) => {
      const unavailablePeriodsBase = (unite.indisponibilites || []).map(stringPeriodToDatePeriod); // must not be empty
      this.setState({
        idUnite: unite.id_unite,
        uniteDescription: unite.description,
        descriptionWeb: unite.description_web,
        unavailablePeriodsBase: unavailablePeriodsBase,
        dateFin: unavailablePeriodsBase.length > 0 ? unavailablePeriodsBase[unavailablePeriodsBase.length - 1].end : new Date(today.getFullYear() + 1, today.getMonth(), today.getDate()), //ajouter 1 ans pour la fin de l'unité
        nombre_adultes_maximum: unite.nombre_adultes_maximum,
        nombre_enfants_maximum: unite.nombre_enfants_maximum,
        nombrePersonne: unite.nombre_personne,
        costAdults: unite.plan_base.prix,
        costChildren: unite.plan_base.prix_enfant,
        uniteLoaded: true
        // typePeriode: typeUnite[0].typePeriode,
      });
    });
  }

  changePeriodSelection() {
    this.props.setErrorAlert("L'unité n'est pas disponible pour cette période de réservation. Veuillez Faire une autre selection'");
    this.getTypeUniteInfo();
    this.closeMemberPopup();
    this.closeOptionalProductPopup();
    this.setState({ unavailablePeriodsUpdated: false });
  }

  changeProductSelection() {
    this.closeMemberPopup();
    this.openOptionalProductPopup();
  }

  handleChange(stateName, event) {
    this.setState({
      [stateName]: event
    });
  }

  getTypeUniteInfo() {
    const { idTypeUnite, idUnite } = this.props.match.params;
    getTypesUnites(idTypeUnite)
      .then(async (typeUnite) => {
        const startHour = stringHourToArrHour(typeUnite.calDateDebut.substring(11, 16));
        const endHour = stringHourToArrHour(typeUnite.calDateFin.substring(11, 16));
        const current = new Date();
        let outOfServiceDates;
        try {
          outOfServiceDates = await getDatesHorsService(idUnite);
        } catch (e) {
          outOfServiceDates = [];
          console.log(e);
        }
        current.setHours(startHour[0], startHour[1], 0);
        this.setState({
          idTypeUnite: typeUnite.idTypeUnite,
          typeUniteDescription: typeUnite.description,
          typePeriode: typeUnite.typePeriode,
          dureePeriode: typeUnite.dureePeriode,
          heureDebut: startHour[0],
          heureFin: endHour[0],
          periodsNumber: getHoursRange(startHour[0], endHour[0]).length,
          initialDate: current,
          outOfServiceDates: stringArrPeriodToDatePeriod(outOfServiceDates),
          dataLoaded: true
        });
      })
      .catch(console.log);
  }

  hasAnyUnavailableProduct(verifiedProducts) {
    const selectedProducts = this.state.selectedOptionalProducts;
    for (let i = 0; i < verifiedProducts.length; i++) {
      for (let j = 0; j < selectedProducts.length; j++) {
        if (verifiedProducts[i].idProduit === selectedProducts[j].idProduit && verifiedProducts[i].quantiteDisponible < selectedProducts[j].quantite) return true;
      }
    }
    return false;
  }

  updateUnavailablePeriods(calenderYear, calenderMonth) {
    const firstMonthDate = new Date(calenderYear, calenderMonth, 1);
    const firstCalendarDate = dateFns.startOfWeek(dateFns.startOfMonth(firstMonthDate));
    const lastCalendarDate = dateFns.endOfWeek(dateFns.endOfMonth(firstMonthDate));
    const { heureDebut, heureFin } = this.state;
    const newUnavailablePeriods = [];
    const nowDate = new Date();

    const outOfServiceDays = this.state.outOfServiceDates.filter(
      (outOfService) => dateFns.isWithinInterval(outOfService.start, { start: firstCalendarDate, end: lastCalendarDate }) || dateFns.isWithinInterval(outOfService.end, { start: firstCalendarDate, end: lastCalendarDate })
    );

    for (let iteratorDate = firstMonthDate.getMonth() === this.state.initialDate.getMonth() ? new Date(this.state.initialDate) : firstCalendarDate; iteratorDate <= lastCalendarDate; iteratorDate.setDate(iteratorDate.getDate() + 1)) {
      let hours = [];
      let isFull = false;

      for (let i = 0; i < this.state.unavailablePeriodsBase.length; i++) {
        const start = this.state.unavailablePeriodsBase[i].start;
        const end = this.state.unavailablePeriodsBase[i].end;
        const iteratorMonth = iteratorDate.getMonth();
        const iteratorYear = iteratorDate.getFullYear();
        // laisser disponibles les iteratorDate hors de intervalle ]start, end[
        if (
          (start > iteratorDate && !(start.getFullYear() === iteratorYear && start.getMonth() === iteratorMonth && start.getDate() === iteratorDate.getDate())) || // comparer les dates sans les heures dateFns.isSameDay()
          (end < iteratorDate && !(end.getFullYear() === iteratorYear && end.getMonth() === iteratorMonth && end.getDate() === iteratorDate.getDate()))
        )
          // comparer les dates sans les heures
          continue;

        // iteratorDate est entre [start, end].
        const startHour = iteratorDate.getDate() === start.getDate() ? getRoundedDateHours(start) : heureDebut;
        const endHour = iteratorDate.getDate() === end.getDate() ? getRoundedDateHours(end) : heureFin;
        if (startHour === heureDebut && endHour === heureFin) {
          isFull = true;
          break;
        }

        // Ne pas considérer si l'heure indisponible < heure debut du type d'unite ou = l'heure de fin
        if (
          /*startHour < heureDebut && endHour === heureDebut ||*/ //ligne décomentée car l'heure de debut peut etre différent pour chaque unité
          startHour === endHour &&
          startHour === heureFin
        )
          continue;
        hours = hours.concat(getHoursRange(startHour, endHour));
      }

      // Marquer indisponibles les heures <= à l'heure actuelle
      if (dateFns.isSameDay(nowDate, iteratorDate)) hours = hours.concat(getHoursRange(heureDebut, nowDate.getHours() + 1));

      // Extraire les heures hors services du jour actuel.
      const outOfServiceDay = outOfServiceDays.filter((outOfSrvcDay) => dateFns.isSameDay(iteratorDate, outOfSrvcDay.start) || dateFns.isSameDay(iteratorDate, outOfSrvcDay.end));
      const outOfServiceHours = getOutServiceHours(outOfServiceDay).sort((a,b)=>a-b);

      // Vérifier que les indisponibilités ne sont pas que des hors services
      let hasOnlyOutOfServiceHours = true;
      hours = [...new Set(hours)];
      for (let i = 0; i < hours.length; i++) {
        if (!outOfServiceHours.includes(hours[i])) {
          hasOnlyOutOfServiceHours = false;
          break;
        }
      }

      if (hours.length > 0 || isFull) {
        newUnavailablePeriods.push({
          date: dateFns.format(iteratorDate, "yyyy/MM/dd"),
          hours,
          hasOnlyStartOrEndOOS: hasOnlyOutOfServiceHours && startOrEndWithOoS(outOfServiceHours),
          isFull: isFull || hours.length >= this.state.periodsNumber //periodsNumber doit etre calculée en fonction de l'hors service.
        });
      }
    }
    if (dateFns.isSameMonth(firstMonthDate, this.state.originalReservation.selectedDate)) this.unlockSelectedDateAndHours(newUnavailablePeriods);

    //console.log(newUnavailablePeriods);
    this.setState({
      unavailablePeriods: newUnavailablePeriods,
      unavailablePeriodsInitialized: true,
      unavailablePeriodsUpdated: true
    });

    function startOrEndWithOoS(sortedOosHours) {
      let moreThanOneHourIntervalCount = 0;
      const startsWithOos = sortedOosHours[0] === heureDebut || sortedOosHours[0] < heureDebut; // si heureDebut = 9h, et h.s a 8h
      const endsWithOos = sortedOosHours[sortedOosHours.length - 1] === heureFin - 1; // heureFin - 1 car 16h = 17h - 1

      for (let i = 1; i < sortedOosHours.length; i++) {
        if (sortedOosHours[i] - sortedOosHours[i - 1] > 1)
          //heures hors services [8,9,16,17], [8,10,15,16,17]
          moreThanOneHourIntervalCount++;
      }
      return (startsWithOos && endsWithOos && moreThanOneHourIntervalCount === 1) || ((startsWithOos || endsWithOos) && moreThanOneHourIntervalCount === 0);
    }
  }

  unlockSelectedDateAndHours(newUnavailablePeriods) {
    let reservationDate = dateFns.format(this.state.originalReservation.selectedDate, "yyyy/MM/dd");
    let reservationHours = this.state.originalReservation.selectedHours;
    for (let i = 0; i < newUnavailablePeriods.length; i++) {
      if (newUnavailablePeriods[i].date === reservationDate) {
        newUnavailablePeriods[i].hours = newUnavailablePeriods[i].hours.filter((hour) => !reservationHours.includes(hour));
        newUnavailablePeriods[i].isFull = false;
        break;
      }
    }
  }

  addToShoppingCart() {
    const newItem = {};
    newItem["image"] = "data:image/jpeg;base64," + this.state.image;
    newItem["name"] = this.state.uniteDescription;
    newItem["inclus"] = [];
    newItem["inclus"].push({ name: `adulte(s) x ${this.state.selectedAdults}` });
    if (this.state.selectedChildren) newItem["inclus"].push({ name: `enfant(s) x ${this.state.selectedChildren}` });

    newItem["selectedDate"] = this.state.selectedDate;
    newItem["selectedStartHour"] = this.state.selectedStartHour;
    newItem["selectedEndHour"] = this.state.selectedEndHour;
    newItem["selectedAdults"] = this.state.selectedAdults;
    newItem["selectedChildren"] = this.state.selectedChildren;
    newItem["inclus"].push(
      { name: `Date:  ${this.renderSelectedDate()}` },
      { name: `Heure d'arrivée:  ${this.state.selectedStartHour} h` },
      {
        name: `Heure de fin:  ${this.state.selectedEndHour ? this.state.selectedEndHour : parseInt(this.state.selectedStartHour) + 1} h`
      }
    );
    // Dans une commit, les items du panier vont être centralisés dans une classe.
    const startDate = toSelectedISOString(this.state.selectedDate, this.state.selectedStartHour);
    const endDate = toSelectedISOString(this.state.selectedDate, this.state.selectedEndHour);
    newItem["selectedUnite"] = {
      id_unite: this.state.idUnite, // id_nite car même nom que les unites fetchées de choix-unite-page
      selectedArrivee: startDate,
      selectedDepart: endDate,
      selectedAdultes: this.state.selectedAdults,
      selectedEnfants: this.state.selectedChildren,
      selectedProducts: this.state.selectedOptionalProducts
    };
    let timeIntervals = this.state.selectedEndHour - this.state.selectedStartHour;
    newItem["montant"] = this.state.costAdults * timeIntervals;
    newItem["inclus"].push({ name: `Durée: ${timeIntervals} h` }, { name: `Montant de base:  ${newItem["montant"]} $` });

    let montantProduits = 0;
    const selectedProducts = this.state.selectedOptionalProducts;
    for (let i = 0; i < selectedProducts.length; i++) {
      const montantProduit = selectedProducts[i].prix * selectedProducts[i].quantite;
      newItem["inclus"].push({
        name: `${selectedProducts[i].description}:   x ${selectedProducts[i].quantite}${selectedProducts[i].quantiteTypeHoraire ? "h" : ""}`,
        montant: montantProduit
      });
      montantProduits += montantProduit;
    }

    newItem["montant"] = montantProduits + newItem["montant"];
    newItem["options"] = true;
    newItem["type"] = CartItemType.ReservationActivite;
    newItem["id_unite"] = this.state.idUnite; // sera updaté par idUnite lorsque tous les sorties de l'api seront des viewModels
    newItem["idTypeUnite"] = this.state.idTypeUnite;
    newItem["selectedProducts"] = this.state.selectedOptionalProducts;
    let infoReservation = {
      dateDebut: startDate,
      dateFin: endDate,
      idUnite: this.state.idUnite,
      selectedProducts: this.state.selectedOptionalProducts,
      originIdsReservationTemp: this.state.isReservationModification ? this.props.location.state.originIdsReservationTemp : null
    };
    addReservationTemp(infoReservation) // devrait être un patch lors de la modification de la réservation
      .then((result) => {
        if (result.uniteIsUnavailable) {
          this.changePeriodSelection();
          return;
        }

        if (this.hasAnyUnavailableProduct(result.produitsOption)) {
          this.changeProductSelection();
          return;
        }

        newItem["idsReservationTemp"] = result.idsReservationTemp;
        newItem["selectedUnite"].idsReservationTemp = result.idsReservationTemp;
        if (this.state.isReservationModification) this.props.updateShop(this.props.location.state.index, newItem);
        else this.props.addToShoppingCart(newItem);
        this.props.goTo(PATH_PANIER);
        this.props.setTimeoutShop(result.idsReservationTemp);
      });
  }

  renderFirstHours(initialTime) {
    const rows = [];
    let unavailableHours = this.checkForUnavailablePeriods();
    for (let i = this.state.heureDebut; i < this.state.heureFin; i++) {
      let isHourAvailable = true;
      if (unavailableHours) {
        for (let y = 0; y < unavailableHours.length; y++) {
          if (unavailableHours[y] === i) {
            if (initialTime) return rows;
            isHourAvailable = false;
          }
        }
      }
      if (isHourAvailable) {
        rows.push(
          <option value={i} key={i}>
            {i} h
          </option>
        );
      }
    }
    return rows;
  }

  renderLastHours() {
    const rows = [];
    let unavailableHours = this.checkForUnavailablePeriods();
    for (let i = parseInt(this.state.selectedStartHour) + 1; i <= this.state.heureFin; i++) {
      rows.push(
        <option value={i} key={i}>
          {i} h
        </option>
      );
      if (unavailableHours) {
        for (let y = 0; y < unavailableHours.length; y++) {
          if (i === unavailableHours[y]) return rows;
        }
      }
    }
    return rows;
  }

  checkForUnavailablePeriods() {
    const formattedSelectedDate = dateFns.format(this.state.selectedDate, "yyyy/MM/dd");
    let unavailablePeriods = [];
    for (let i = 0; i < this.state.unavailablePeriods.length; i++) {
      if (this.state.unavailablePeriods[i].date === formattedSelectedDate) {
        unavailablePeriods = unavailablePeriods.concat(this.state.unavailablePeriods[i].hours);
      }
    }
    return unavailablePeriods;
  }

  renderSelect(type) {
    const rows = [];
    const max = type === "ADULTES" ? this.state.nombre_adultes_maximum : this.state.nombre_enfants_maximum;

    for (let i = 1; i <= max; i++) {
      rows.push(
        <option key={i} value={i}>
          {`${i}   ${i === 1 ? type.substring(0, type.length - 1) : type}`}
        </option>
      );
    }
    return rows;
  }

  openMemberPopup() {
    this.setState({
      isOpen: true,
      openOptionalProduct: false
    });
  }

  closeMemberPopup() {
    this.setState({
      isOpen: false
    });
  }

  openOptionalProductPopup() {
    this.setState({
      isOpen: false,
      openOptionalProduct: true
    });
  }

  closeOptionalProductPopup() {
    this.setState({
      openOptionalProduct: false
    });
  }

  renderSelectedDate() {
    return `${this.daysArr[this.state.selectedDate.getDay()]} ${this.state.selectedDate.getDate()} ${this.monthsArr[this.state.selectedDate.getMonth()]} ${this.state.selectedDate.getFullYear()}`;
  }

  setSelectedOptionalProducts(productQuantity) {
    this.setState({ selectedOptionalProducts: productQuantity });
  }

  render() {
    if (!this.state.uniteLoaded || !this.state.dataLoaded) return <div />;

    if (!this.state.unavailablePeriodsInitialized) this.updateUnavailablePeriods(this.state.initialDate.getFullYear(), this.state.initialDate.getMonth());

    if (this.state.unavailablePeriodsInitialized && !this.state.unavailablePeriodsUpdated) this.updateUnavailablePeriods(this.state.selectedDate.getFullYear(), this.state.selectedDate.getMonth());

    let confirmBtn = (
      <button
        className="btn-confirm-reservation"
        onClick={this.openOptionalProductPopup}
        disabled={!this.state.selectedStartHour || !this.state.selectedEndHour || this.selectedDate || !this.state.selectedAdults || Number(this.state.selectedAdults) + Number(this.state.selectedChildren) > this.state.nombrePersonne}
      >
        {this.props.location.state ? this.TEXTS.confirmTitle0 : this.TEXTS.confirmTitle1}
      </button>
    );

    let img;
    if (this.state.dataLoaded && this.state.image) img = `data:image/jpeg;base64,${this.state.image}`;
    else img = "https://reservation-dev.manisoft.ca/static/media/no-image.d89f94dc.png";

    return (
      <span className="containerWidth">
        {this.state.openOptionalProduct && (
          <OptionalProductPopup
            idUnite={this.state.idUnite}
            open
            endDate={toISOString(this.state.selectedDate, this.state.selectedStartHour)}
            startDate={toISOString(this.state.selectedDate, this.state.selectedStartHour)}
            selectedHours={this.state.selectedEndHour - this.state.selectedStartHour}
            selectedProducts={this.state.selectedOptionalProducts}
            handleClose={this.closeOptionalProductPopup}
            openMemberGroup={this.openMemberPopup}
            setSelectedProducts={this.setSelectedOptionalProducts}
          />
        )}
        {this.state.isOpen && <MemberPopup item={this.props.item} isOpen startDate={toISOString(this.state.selectedDate, this.state.selectedStartHour)} handleClose={this.closeMemberPopup} confirmFunction={this.addToShoppingCart} />}
        <div className="member-pageContainer" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
          {this.state.dataLoaded && (
            <h1 className="calendar-title">
              {this.state.uniteDescription}
              {" ( "}
              {this.state.typeUniteDescription})
            </h1>
          )}
          <div className="flex-details">
            <div className="activity-left-div">
              <div className="activity-prices">
                <div>
                  <h2>{this.TEXTS.title0} ${this.state.costAdults}</h2>
                </div>
              </div>
              <p>
                {this.state.dataLoaded &&
                  this.state.descriptionWeb.split("\n").map((item, key) => (
                    <span key={key}>
                      {item}
                      <br />
                    </span>
                  ))}
              </p>
              <div className="activity-img">
                <img src={img || require("../../../images/loading.svg")} alt="thumbnail" />
              </div>
            </div>

            <div className="calendar-container">
              <div className="wc-bookings-booking-form">
                <fieldset className="wc-bookings-date-picker">
                  {this.state.dataLoaded && <CalendarActivity unavailablePeriods={this.state.unavailablePeriods} dateFin={this.state.dateFin} selectedDate={this.state.selectedDate} handleChange={this.handleChange} updateUnavailablePeriods={this.updateUnavailablePeriods} language={this.props.match.params.language} />}
                  <div className="legend">
                    <div>
                      <span className="square ended" />
                      &#160;{this.TEXTS.calendarLegend0}
                    </div>
                    <div>
                      <span className="square partially" />
                      &#160;{this.TEXTS.calendarLegend1}
                    </div>
                    <div>
                      <span className="square available" />
                      &#160;{this.TEXTS.calendarLegend2}
                    </div>
                    <div>
                      <span className="square unavailable" />
                      &#160;{this.TEXTS.calendarLegend3}
                    </div>
                  </div>
                </fieldset>
                <ul className="block-picker">
                  {this.state.selectedDate ? (
                    <li>
                      {this.TEXTS.text0} &nbsp;
                      <b>{this.renderSelectedDate()}</b>
                    </li>
                  ) : (
                    <li>{this.TEXTS.text1}</li>
                  )}
                </ul>
                <div className="activity-selectors">
                  <div className="activity-time">
                    <span>
                      {this.TEXTS.title1} <b>{this.TEXTS.title2}</b>
                    </span>{" "}
                    <br />
                    <br />
                    <Select
                      title={this.TEXTS.selectTitle0}
                      option={this.TEXTS.selectOption0}
                      disabled={!this.state.selectedDate}
                      name="selectedStartHour"
                      value={this.state.selectedStartHour}
                      onChange={(e) => {
                        this.handleChange("selectedStartHour", e.target.value);
                        this.handleChange("selectedEndHour", "");
                      }}
                      renderSelect={this.state.selectedDate && this.renderFirstHours()}
                    />
                    <Select
                      title={this.TEXTS.selectTitle1}
                      option={this.TEXTS.selectOption1}
                      disabled={!this.state.selectedStartHour || !this.state.selectedDate}
                      name="selectedEndHour"
                      value={this.state.selectedEndHour}
                      onChange={(e) => this.handleChange("selectedEndHour", e.target.value)}
                      renderSelect={this.state.selectedDate && this.renderLastHours()}
                    />
                  </div>
                  <div className="activity-time">
                    <span>{this.TEXTS.text2} {this.state.nombrePersonne} MAX ( {this.TEXTS.text3} ) </span>
                    <div className="choix-unite-recherche-input-wrapper">
                      <Select
                        option={this.TEXTS.selectOption2}
                        disabled={!this.state.selectedStartHour || !this.state.selectedEndHour}
                        name="selectedAdultes"
                        value={this.state.selectedAdults}
                        onChange={(e) => this.handleChange("selectedAdults", e.target.value)}
                        renderSelect={this.renderSelect("ADULTES")}
                        plusOption={this.state.nombre_adultes_maximum > 98}
                      />
                      <Select
                        option={this.TEXTS.selectOption3}
                        disabled={!this.state.selectedStartHour || !this.state.selectedEndHour}
                        name="selectedEnfants"
                        value={this.state.selectedChildren}
                        onChange={(e) => this.handleChange("selectedChildren", e.target.value)}
                        renderSelect={this.renderSelect("ENFANTS")}
                        plusOption={this.state.nombre_enfants_maximum > 98}
                      />
                    </div>
                  </div>
                </div>
                {confirmBtn}
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  }
}

ActivityReservationPage.propTypes = {
  addToShoppingCart: PropTypes.func.isRequired,
  updateShop: PropTypes.func.isRequired
};

ActivityReservationPage.defaultProps = {
  isModifying: false
};

export default withNavigation(connect(mapStateToProps, { addToShoppingCart, setErrorAlert, updateShop, setTimeoutShop })(ActivityReservationPage));
