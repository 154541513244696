import React from "react";
import Popup from "reactjs-popup";
import SpinInput from "../inputs/SpinInput";
import PropTypes from "prop-types";
import { addToShoppingCart } from '../actions/shop';
import { useDispatch } from 'react-redux';

const DemanderProduitPrix = (props) => {
  const dispatch = useDispatch();

  const onChange = (i, e) => {
    if (e?.target?.value) {
      const temp = props.selected.produitPrix;
      temp[i].quantite = e.target.value;
      props.setSelected(prevSelected => ({
        ...prevSelected,
        produitPrix: temp
      }));
    }
  };

  const onConfirm = () => {
    if (!props.selected) return
    const newItem = {};
    newItem["type"] = "boutique";
    if (props.selected.img) newItem["image"] = props.selected.img
    newItem["prix"] = props.selected.prix;
    newItem["name"] = props.selected.description;
    newItem["montant"] = props.selected.prix;
    newItem["produitPrix"] = props.selected.produitPrix;
    if (newItem?.produitPrix?.length) {
      newItem.produitPrix.forEach(e => {
        if (e.quantite) {
          if (e.prix)
            newItem["montant"] += (e.prix * e.quantite)
          else
            newItem["montant"] += (newItem.prix * e.quantite)
        }
      });
    }
    dispatch(addToShoppingCart(newItem));
    props.handleClose();
  }

  return (
    <div>
      <Popup on="click" open={props.isOpen} onClose={props.handleClose} modal={true} closeOnDocumentClick={false} nested className="popup-tranche-age-billetterie">
        <div className="tranche-age-header">
          <h3>Format</h3>
        </div>
        <div className="tranche-age">
          {props.selected?.produitPrix.map((produit, i) => {
            return (
              <div className="tranche-age-row" key={produit.idProduitPrix}>
                <span>{produit.description || 'non défini'}</span>
                <SpinInput min={0}
                  max={produit.isAdulte ? (props.maxAdulte || 1) * 2 : 999}
                  name={produit.idProduitPrix}
                  value={produit.quantite || 0}
                  onChange={(e) => onChange(i, e)}
                />
              </div>
            );
          })}
        </div>
        <div className="tranche-age-button">
          <button onClick={onConfirm}>Ok</button>
          <button onClick={() => props.handleClose()}>Annuler</button>
        </div>
      </Popup>
    </div>
  );
};

DemanderProduitPrix.prototype = {
  trancheAges: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  onValueChange: PropTypes.func,
  selected: PropTypes.object
}

export default DemanderProduitPrix;
