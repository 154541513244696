import React from "react";

function ListSvg(props) {
    let { dispatch, fill, width, height, viewBox, ...otherProps } = props

    fill = fill || "rgb(0, 0, 0)";
    width = width || "48px";
    height = height || "48px";
    viewBox = viewBox || "0 0 24 24";

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} fill="black" width={width} height={height} {...otherProps}>
            <path d="M0 0h24v24H0z" fill="none" />
            <path fill={fill} d="M4 14h4v-4H4v4zm0 5h4v-4H4v4zM4 9h4V5H4v4zm5 5h12v-4H9v4zm0 5h12v-4H9v4zM9 5v4h12V5H9z" />
        </svg>
    );
}

export default ListSvg;
