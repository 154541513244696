import React, { useCallback, useContext, useEffect, useState } from "react";

//css
import "./calendrier.css";

//date
import {
  addMonths,
  subMonths,
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  isSameMonth,
  isSameDay,
  parseISO
} from "date-fns";
import { frCA, enCA } from "date-fns/locale";

//hooks
import useDateSelection from "./useDateSelection";
import { EvenementContext } from "../../../contexte/evenement-page";


const CalendarCustom = ({
  locale = "fr",
  dataSource,
  disabled,
})=> {
  // Sélectionner la localisation en fonction de la langue choisie
  const currentLocale = locale === "fr" ? frCA : enCA;

  // États du composant
  const [currentDate, setCurrentDate] = useState(new Date());
  
  const [selectedDates, setSelectedDates] = useState([]);
  const [direction, setDirection] = useState("next");
  const {
    clickDates,
    handleSelectDate
  } = useContext(EvenementContext)


  // useEffect -- Met à jour le calendrier en fonction du dataSource qui change
  useEffect(() => {
    updateCalendarFromDateArray();
  }, [dataSource]);

  // const handleSelectDate = (date) => {
  //   setClikDates((prevDates) => {
  //     const dateExists = prevDates.some((clickDate) => isSameDay(clickDate, date));

  //     if(dateExists) {
  //       // si la date existe deja , la reitrer
  //       return prevDates.filter((clickDate) => !isSameDay(clickDate, date))
  //     } else {
  //       //sinn=on, l'ajouter
  //       return[...prevDates, date];
  //     }
  //   }) 
  // }

  const updateCalendarFromDateArray = useCallback(() => {
    if (dataSource.length > 0) {
      // Extraire les dates de début et de fin du dataSource
      const { dateDebut, dateFin } = dataSource[0];
      const convertDateDebut = parseISO(dateDebut) ;
      const convertDateFin = parseISO(dateFin) ;
      setSelectedDates(onSelectDatesBetween(convertDateDebut, convertDateFin));

    } else {
      // Réinitialiser le calendrier si le dataSource est vide
      setSelectedDates([new Date()]);
    }
  }, [dataSource]);
  // Fonction -- Sélectionne toutes les dates entre deux dates données
  const onSelectDatesBetween = (startDate, endDate) => {
    const dates = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays(currentDate, 1);
    }
    return dates;
  };

  // Fonction -- Permet la navigation arrière
  const handlePrevMonth = () => {
    const newDate = subMonths(currentDate, 1);
    setCurrentDate(newDate);
    setDirection("prev");
  };

  // Fonction -- Permet la navigation avant
  const handleNextMonth = () => {
    const newDate = addMonths(currentDate, 1);
    setCurrentDate(newDate);
    setDirection("next");
  };

  // Fonction -- Génère le calendrier
  const generateCalendar = () => {
    const startDate = startOfWeek(startOfMonth(currentDate), {
      locale: currentLocale,
    });
    const endDate = endOfWeek(endOfMonth(currentDate), {
      locale: currentLocale,
    });
    const rows = [];
    let days = [];
    let day = startDate;
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        days.push(day);
        day = addDays(day, 1);
      }
      rows.push(days);
      days = [];
    }
    return rows;
  };

  // Générer les rangées du calendrier
  const calendarRows = generateCalendar();
  // Générer les jours de la semaine en français
  const daysOfWeek = Array.from({ length: 7 }, (_, i) =>
    format(
      addDays(startOfWeek(new Date(), { locale: currentLocale }), i),
      "EEE",
      { locale: currentLocale }
    )
  );

  return (
    <div
      className={`calendrier-custum-container ${
        disabled ? "disabled-calendar" : ""
      }`}
    >
      <div className=" calendar-custum-content">
        <div className="header">
          <button onClick={handlePrevMonth}>
          <img className="calendar-previous-month-btn" src="/../../images/icons/drop-down.png" alt="precedent"/>
          </button>
          <span>
            {format(currentDate, "MMMM yyyy", { locale: currentLocale }).charAt(0).toUpperCase() + format(currentDate, "MMMM yyyy", { locale: currentLocale }).slice(1)}
          </span>
          <button onClick={handleNextMonth}>
          <img className="calendar-next-month-btn" src="/../../images/icons/drop-down.png" alt="suivant"/>
          </button>
        </div>

        <div className="days">
          {daysOfWeek.map((day, index) => (
            <div
              key={index}
              className="day"
            >
              {day}
            </div>
          ))}
        </div>

        <div
          className={`dates ${direction}`}
        >
          {calendarRows.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              {row.map((date, dateIndex) => (
                <div
                key={dateIndex}
                className={`
                  date-calendrrier-evenement 
                  ${isSameMonth(date, currentDate) ? "" : "disabled"} 
                  ${
                    selectedDates.some((selectedDate) =>
                      isSameDay(selectedDate, date)
                    ) ? clickDates.some((clickedDate) =>
                        isSameDay(clickedDate, date)
                      ) ? "clicked" : "selected" : ""
                  }`}
                onClick={() => handleSelectDate(date)}
              >
                {format(date, "d", { locale: currentLocale })}
              </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CalendarCustom;
