import React from "react";
import { connect } from "react-redux";
import "../../css/footer.css";

function mapStateToProps(state, ownProps) {
  return {
    configuration:state.configuration
  };
}

function Footer() {
  return (
    <footer>
      <span>© RESERVATIONPLEINAIR.CA</span>
      <span>•</span>
      <span>TOUS DROITS RÉSERVÉS</span>
      <span>•</span>
      <span>RÉALISATION : </span>
      <img src={require('../../images/logo-manisoft-texte-noir.png')} alt="footer-logo" />
    </footer>
  );
}

export default connect(mapStateToProps)(Footer);
