import {
    QTE,
    HEURE_FIN,
    SET_RELOAD,
    HEURE_DEBUT,
    SET_ID_UNITE,
    SET_IDUNITECATEGORIE,
    SET_CATEGORIE_ACTIVITE,
    DISPONIBILITES_UNITES,
    SET_IDTYPEUNITE_ACTIVITE,
    INITIALIZE_STATE_MODAL_ACTIVITE,
    QTEMAX,
} from "./types";

export const setQte = (data) => dispatch => {

    dispatch({ type: QTE, value: data })
}

export const setQteMax = (data) => dispatch => {

    dispatch({ type: QTEMAX, value: data })
}

export const setHeureDebut = (data) => dispatch => {

    dispatch({ type: HEURE_DEBUT, value: data })
}

export const setHeureFin = (data) => dispatch => {
    dispatch({ type: HEURE_FIN, value: data })
}

export const setInitializeStatModalActivite = () => dispatch => {
    dispatch({ type: INITIALIZE_STATE_MODAL_ACTIVITE })
}

export const setCategorieActivite = (categories) => dispatch => {
    dispatch({ 
        type: SET_CATEGORIE_ACTIVITE ,
        value: categories
    });
  }

  export const  setIdtypeUniteActivite = (idTypeUniteActivite) =>  dispatch => {
    dispatch({
        type :SET_IDTYPEUNITE_ACTIVITE ,
        value : idTypeUniteActivite
    })
}

export const setIdUniteCategorie = (idUniteCategorie) => dispatch => {
    dispatch({
        type: SET_IDUNITECATEGORIE,
        value: idUniteCategorie
    })
}

// export const setIdUnite = (idUnite) => dispatch => {
//     dispatch({
//         type: SET_ID_UNITE,
//         value: idUnite
//     })
// }

export const setReload = (data) => dispatch => {
    dispatch({
        type: SET_RELOAD,
        value: data
    })
}
export const setDisponibilitesUnites = (data) => dispatch => {
    dispatch({
        type: DISPONIBILITES_UNITES,
        value: data
    })
}