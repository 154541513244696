
import React from "react";
import "./calendar-activity.css";
import * as dateFns from "date-fns";
import PreviousSvg from "../svg/previous.js";
import NextSvg from "../svg/next.js";
import dates from "../../constants/dates.js";

class CalendarActivity extends React.Component {
  state = {
    currentMonth: new Date(),
    currentDate: new Date()
  };
  monthsArr = this.props.language === 'fr' ? dates.MONTHS : dates.MONTHS_EN;
  daysArr = this.props.language === 'fr' ? dates.DAYS : dates.DAYS_EN;

  nextMonth = () => {
    this.setState({
      currentMonth: dateFns.addMonths(this.state.currentMonth, 1)
    });
    this.props.updateUnavailablePeriods(this.state.currentMonth.getFullYear(), this.state.currentMonth.getMonth() + 1);
  };

  prevMonth = () => {
    if (this.state.currentMonth.getMonth() === this.state.currentDate.getMonth() && this.state.currentMonth.getFullYear() === this.state.currentDate.getFullYear()) return
    this.setState({ currentMonth: dateFns.subMonths(this.state.currentMonth, 1) });
    this.props.updateUnavailablePeriods(this.state.currentMonth.getFullYear(), this.state.currentMonth.getMonth() - 1);
  };

  handleClick = (selectedDate) => {
    this.props.handleChange('selectedDate', selectedDate);
    this.props.handleChange('selectedStartHour', "");
    this.props.handleChange('selectedEndHour', "");
  }

  renderHeader() {
    return (
      <div className="calendar-header calendar-row flex-middle">
        <button
          onClick={this.prevMonth}
          className={`calendar-icon  
          ${this.state.currentDate.getMonth() === this.state.currentMonth.getMonth() ? 'calendar-icon-state-disabled' : 'calendar-icon-transition'}`}
          title="Mois précédent">
          <PreviousSvg />
        </button>
        {/* <div className="col-center">
          <span>{dateFns.format(this.state.currentMonth, dateFormat)}</span>
          <span>{this.months[this.state.currentMonth.getMonth()] + ' ' + this.state.currentMonth.getFullYear()}</span>
        </div> */}
        <div className="ui-datepicker-title">
          <span>
            {this.monthsArr[this.state.currentMonth.getMonth()]}
          </span>&nbsp;
          <span>
            {this.state.currentMonth.getFullYear()}
          </span>
        </div>
        <button onClick={this.nextMonth}
          className="calendar-icon calendar-icon-transition" title="Mois suivant" >
          <NextSvg />
        </button>
      </div>
    );
  }

  renderDays() {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {this.daysArr[i]}
        </div>
      );
    }
    return <div className="days calendar-row">{days}</div>;
  }

  renderCells() {
    const { currentMonth, currentDate } = this.state;
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDay = null;
    let formattedDate = null;
    let formattedCurrentDate = dateFns.format(this.state.currentDate, 'yyyy/MM/dd');
    let formattedDateFin = dateFns.format(this.props.dateFin, 'yyyy/MM/dd');
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDay = dateFns.format(day, "d");
        const cloneDay = day;
        formattedDate = dateFns.format(cloneDay, 'yyyy/MM/dd');
        let isAvailable = true;
        let isFull = false
        let hasAnyReservedHour = false;
        this.props.unavailablePeriods.forEach(currentPeriod => {
          if (currentPeriod.date === dateFns.format(cloneDay, 'yyyy/MM/dd')) {
            hasAnyReservedHour = !currentPeriod.hasOnlyStartOrEndOOS;
            if (currentPeriod.isFull) {
              isAvailable = false
              isFull = true;
            }
          }
        })
        if (formattedDate < formattedCurrentDate || formattedDate > formattedDateFin) isAvailable = false; // !dateFns.isSameMonth(day, monthStart) 
        days.push(
          <div
            className={`col cell
              ${!isAvailable
                ? `disabled ${(formattedDate > formattedCurrentDate || isFull) && 'cell-unavailable'}`
                : dateFns.isSameDay(day, this.props.selectedDate)
                  ? "cell-selected"
                  : hasAnyReservedHour ? "cell-partially" : "cell-selectable"
              }
            `}
            key={day}
            title={`${cloneDay.getDate()} ${this.monthsArr[currentDate.getMonth()]} est disponible`}
            onClick={() => this.handleClick(cloneDay)}
          >
            <span className="number">{formattedDay}</span>
            {/* <span className="bg">{formattedDay}</span> */}
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className="calendar-row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="calendar-body">{rows}</div>;
  }

  render() {
    return (
      <div className="calendar-div">
        {this.renderHeader()}
        {this.renderDays()}
        {this.renderCells()}
      </div>
    );
  }
}

export default CalendarActivity