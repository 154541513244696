import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "../../../css/info-client.css";
import { getPays } from "../../server/services/client-service";
import { DIV_NAME, LABEL_NAME, SELECT_NAME } from "./info-client-constants";

function InfoClientPays(props) {
  const [countries, setCountries] = useState([]);
  const [defaultIdPays, setDefaultIdPays] = useState("");

  useEffect(() => {
    getPays()
      .then(countries => {
        setCountries(countries.filter(p => p.idPays !== '00000000-0000-0000-0000-000000000000')); // Ce filter enlève le pays Aucun
        const countryCanada = countries.find(country => country.description === "CANADA")
        setDefaultIdPays(countryCanada.idPays);
        const event = {
          target: {
            value: countryCanada.idPays,
            selectedOptions: [{ innerText: countryCanada.description }]
          }
        };
        props.onChange(event)
      });
  }
  ,[props.match.params.idZec]);
  
  return (
    <div className={DIV_NAME}>
      <label className={LABEL_NAME}>PAYS :</label>
      <select className={SELECT_NAME} style={{fontSize: "2em"}} name={props.name} value={props.value || defaultIdPays} onChange={props.onChange}>
        {
          countries.length > 0 && countries.map(pays => (
            <option key={pays.idPays} value={pays.idPays}>
              {pays.description}
            </option>
          ))
        }
      </select>
    </div >
  );
}

// champs requis
export default withRouter(InfoClientPays);

