import React from "react";
import { connect } from "react-redux";
import "../../css/zoomer.css";

function mapStateToProps(state, ownProps) {
  return {
  };
}

function Zoomer(props) {
  return (
    <div className="zoomer">
      <div className="zoomer-button plus" onClick={props.onPlusClick || (() => {})}>
        +
      </div>
      <div className="zoomer-button minus" onClick={props.onMinusClick || (() => {})}>
        -
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(Zoomer);
