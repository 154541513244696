import React, { useState, useEffect, useMemo } from 'react';
import { connect } from "react-redux";
import withNavigation from "../higher-order/navigation";
import { getShop, CartItemType } from "../actions/shop";
import FacturePanierPopup from "../popups/facture-panier-popup";
import { getReservationListe } from "../../server/services/reservation-service";
import { setAlert } from '../actions/alert';
import CartSvg from "../svg/cart";
import { useSeoTitle } from "../../utils/hooks";
import Translator from "../translator";
import SelectedReservation from './reservation-unite';
import PaiementComptesPage from '../paiement-comptes/paiement-comptes-page';
import LoadingSvg from "../svg/loading.js";
import "./reservation-liste.css";
import moment from "moment";
import Pagination from '../pagination/pagination';
import { PATH_PAIEMENT_COMPTES } from "../../constants/navigation-constants";
import { STORAGE } from '../../constants/storage.js'
import { getFromStorage } from '../../utils/localStorageUtils'

const BookingPage = (props) => {
  useSeoTitle(`Liste de réservations`);
  const TEXTS = Translator(props.match.params.language, "TXT_BOOKING_PAGE");
  const [viewAll, setViewAll] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const TODAY = new Date();
  const ITEMS_PAR_PAGE = 8;
  const [state, setState] = useState({
    items: [],
    itemsPerPage: ITEMS_PAR_PAGE,
    currentPage: 1
  });

  const changePage = (page) => {
    setState(prevState => ({
      ...prevState,
      currentPage: page,
      items: state.catalogue.slice((page - 1) * ITEMS_PAR_PAGE, page * ITEMS_PAR_PAGE)
    }));
    window.scrollTo(0, 800)
  }

  const Page = !selectedBooking &&
    state?.catalogue?.length > 7
    ? <Pagination
      pages={state.pages}
      totalItems={state.totalItems}
      currentTotalItems={state.items.length}
      currentPage={state.currentPage}
      changePage={changePage}
    />
    : null

  const getListe = async (id, tout) => {
    try {
      const liste = await getReservationListe(id, tout);
      setState(prevState => ({
        ...prevState,
        items: liste?.length ? liste.slice(0, ITEMS_PAR_PAGE) : prevState.items,
        catalogue: liste,
        totalItems: liste.length,
        pages: Math.ceil(liste.length / ITEMS_PAR_PAGE)
      }));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const reservationNo = params.get('reservation');
    if (reservationNo && state?.catalogue) {
      const booking = state.catalogue.find(b => b.noConfirmation === reservationNo);
      setSelectedBooking(booking);
    } else {
      setSelectedBooking(null);
    }
  }, [props.location.search]);

  useEffect(async () => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('idProfile');
    const idProfile = id ? id : '00000000-0000-0000-0000-000000000000';
    props.history.push(`?idProfile=${idProfile}`);
    await getListe("00000000-0000-0000-0000-000000000000", "limite");
    setLoading(false);
  }, [])

  const payer = (noConfirmation) => {
    const clientInfo = getFromStorage(STORAGE.info_profile_client)
    if (noConfirmation) {
      props.setAlert(TEXTS.alertPaiement, "success");
      props.history.push('paiement-comptes',
        { noConfirmation: noConfirmation, email: clientInfo.email || '' }
      );
    }
  }

  const selectReservation = (reservation) => {
    setSelectedBooking(reservation);
    // props.history.push(`&reservation=${reservation.noConfirmation}`);

    props.history.push({
      search: `${props.location.search}&reservation=${reservation.noConfirmation}`
    });
  }

  const afficherTout = async () => {
    setLoading(true);
    setViewAll(!viewAll);
    const liste = viewAll
      ? await getListe("00000000-0000-0000-0000-000000000000", "limite")
      : await getListe("00000000-0000-0000-0000-000000000000", "tout");
    if (liste)
      setState({ ...state, items: liste })
    await setLoading(false);
  }

  const renderContents = (item, index) => {
    const isOver = new Date(item.dateFin) < TODAY;
    return (
      <tr key={item.idReservationEntete + index}>
        <td>
          <span className="billetterie-header marginRight"># {item.noConfirmation}</span>
          {isOver && <small className="over-small-text marginRight">({TEXTS.terminer})</small>}
        </td>
        <td>
          <span>
            {item.dateDebut ? `${moment(item.dateDebut).format("yyyy-MM-DD")} au ${moment(item.dateFin).format("yyyy-MM-DD")}` : ""}
          </span>
        </td>
        <td style={{ textAlign: 'center' }}>
          {item.nombreAdultes}
        </td>
        <td style={{ textAlign: 'center' }}>
          {item.nombreEnfants}
        </td>
        <td style={{ textAlign: 'center' }}>
          {item.montant}
        </td>
        <td style={{ textAlign: 'center' }}>
          {item.montantDepotRecu}
        </td>
        <td style={{ textAlign: 'center' }}>
          {item.solde}
        </td>
        <td>
          <button className="achat-forfait-card-buyable-list-item-montant button-reservation-liste marginRight"
            style={{ float: 'right', marginTop: '0px' }}
            onClick={() => selectReservation(item)}>
            {TEXTS.consult}
          </button>
        </td>
        <td>
          {!isOver && item.solde > 0 &&
            <button className="achat-forfait-card-buyable-list-item-montant button-reservation-liste"
              style={{ float: 'right', marginTop: '0px' }}
              onClick={() => payer(item.noConfirmation)}>
              {TEXTS.payer}
            </button>
          }
        </td>
      </tr >
    );
  };

  const returnToList = () => {
    const searchParams = new URLSearchParams(props.location.search);
    searchParams.delete('reservation');
    props.history.push(`?${searchParams.toString()}`);
    setSelectedBooking(null);
  }

  const handleViewAll = () => {
    setViewAll(true);
  };

  const handlePayment = () => {
    if (selectedBooking && selectedBooking.depositDue) {
      window.location.href = `/payment/booking/${selectedBooking.id}`;
    }
  };

  const titleSpanClass = props.onTitleClick ? "clickable" : "";

  return (
    <div id="billetterie-page-container">

      {selectedBooking
        ? <>
          <SelectedReservation selectedBooking={selectedBooking} onClick={returnToList} />
        </>
        : <>
          <div className="achat-forfait-card-container"
            style={{ width: '90%' }}
          >
            <div className="card-sub-container" >
              {state.items.length && !isLoading
                ? <>
                  {!selectedBooking &&
                    <>
                      <div className="card-title achat-forfait-tab">
                        <span className={titleSpanClass}>{TEXTS.title0}</span>
                      </div>
                      <button className="achat-forfait-card-buyable-list-item-montant button-achat-billet"
                        style={{ cursor: 'pointer', marginTop: '15px' }}
                        onClick={afficherTout}
                      > {TEXTS.text3}
                        {viewAll && <span style={{ fontSize: '20px' }}> ✔</span>}
                      </button>
                    </>
                  }
                  <table id="reservation-liste-table" style={{ margin: 'auto' }}>
                    <tbody>
                      {selectedBooking
                        ? <SelectedReservation selectedBooking={selectedBooking} onClick={returnToList} />
                        : state?.items?.length &&
                        <>
                          <tr>
                            <th style={{ textAlign: 'left' }}>No. de réservation</th>
                            <th style={{ textAlign: 'left' }}>Date</th>
                            <th>Nb d'adultes</th>
                            <th>Nb d'enfants</th>
                            <th>Montant</th>
                            <th>Dernier montant</th>
                            <th>Solde restant</th>
                          </tr>
                          {state.items.map(renderContents)}
                        </>
                      }
                      {/* {items} */}
                    </tbody>
                  </table>
                </>
                : <span className="boutique-page-no-result">
                  <br />
                  {isLoading
                    ? <span className="billetterie-header"><LoadingSvg />
                      {TEXTS.loading}</span>
                    : <span className="billetterie-header">{TEXTS.noData}</span>
                  }
                </span>
              }
              {Page}
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default withNavigation(connect(null, { setAlert })(BookingPage));