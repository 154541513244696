import React, {useEffect, useState} from "react";
import withNavigation from "./higher-order/navigation";
import {format} from "date-fns";
import {connect} from "react-redux";
import {setAlert} from './actions/alert';
import "../../css/tirage-page.css";
import {getTirage, getProductById} from "../server/services/catalogue-service";
import {useSeoTitle} from "../utils/hooks";
import Translator from "./translator";
import CartSvg from "./svg/cart";
import {getPaysConfigWeb} from "./actions/pays-config-web";
import {getProvincesConfigWeb} from "./actions/provinces-config-web";
import FacturePanierPopup from "./popups/facture-panier-popup";
import SommaireRightPanel from "./achat-forfait/sommaire-right-panel";
import {addToShoppingCart, updateShop, removeFromShoppingCart, CartItemType} from "./actions/shop";
import SpinInput from "./inputs/SpinInput";

const initialSubstitut = {
    nom: "",
    prenom: "",
    telephone1: "",
    adresse: "",
    ville: "",
    idProvince: "a72dc95f-ae7a-e211-8d2c-685d4378ba44",
    codePostal: "",
    email: "",
    noPermis: "",
    idPays: "132bc95f-ae7a-e211-8d2c-685d4378ba44"
};

function TiragePage(props) {
    useSeoTitle("Tirage");
    const TEXTS = Translator(props.match.params.language, "TXT_TIRAGE_PAGE");
    const [mounted, setMounted] = useState(true);
    const [isShoppingCartOpen, setIsShoppingCartOpen] = useState(false);
    const [state, setState] = useState({
        tirages: {},
        pays: [],
        provinces: [],
        errors: {}
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const paysResponse = await props.getPaysConfigWeb({idZec: props.idZec});
                const provincesResponse = await props.getProvincesConfigWeb({idZec: props.idZec});
                const tiragesResponse = await getTirage();
                //actif DispoEnLigne, officialise == false (tirages retournés du serveur)
                tiragesResponse.map(async e => {
                    const product = await getProductById(e.idProduitTirage);
                    e.product = product;
                })
                tiragesResponse.forEach(e => {
                    const tiragePanier = props?.shop?.cart.find(f => f.id == e.idTirage)
                    e.id = e.idTirage;
                    e.quantity = tiragePanier && tiragePanier.SelectedProduct.Quantite || 0;
                    e.isSelected = tiragePanier !== undefined
                    e.maxQuantity = e.limiteChance;
                    e.hasSubstitut = tiragePanier && tiragePanier.SelectedProduct && tiragePanier.SelectedProduct.PersonnesAssociees && tiragePanier.SelectedProduct.PersonnesAssociees[0] || false

                    e.substitut = tiragePanier && tiragePanier.SelectedProduct && tiragePanier.SelectedProduct.PersonnesAssociees && tiragePanier.SelectedProduct.PersonnesAssociees[0] || initialSubstitut;
                });

                var tiragePanier = props?.shop?.cart.find(f => f.id == tiragesResponse[0].idTirage)

                if (mounted) {
                    setState((prevState) => ({
                        ...prevState,
                        pays: paysResponse.pays.filter(p => p.idPays !== '00000000-0000-0000-0000-000000000000'),
                        provinces: provincesResponse.provinces.filter(p => p.idProvince !== '00000000-0000-0000-0000-000000000000'),
                        tirages: tiragesResponse
                    }));
                }
            } catch (e) {
                props.setAlert("Une erreur s'est produite. Veuillez réessayer plus tard ou contacter l'administrateur si l'erreur persiste.", "danger");
            }
        };
        fetchData();
    }, []);

    // useEffect(async () => {
    //   await getTirage()
    //     .then((_tirages) => {
    //       console.log(_tirages)
    //       setState({
    //         ...state,
    //         tirages: _tirages
    //       });
    //     }).catch(console.log);
    //   console.log(state)
    // }, []);

    const renderContent = (card, index) => {
        const onChangeProfile = (e, index, card) => {
            setState(prev => {
                const currentTirage = prev.tirages.find(f => f.id === card.idTirage);
                const tiragesFiltered = prev.tirages.filter(f => f.id !== card.idTirage);
                const newValue = {...prev, tirages: [...tiragesFiltered, {...currentTirage, substitut: {...currentTirage.substitut, [e.target.name]: e.target.value}}].sort((a, b) => a.description.localeCompare(b.description))};

                return newValue;
            })
            ;

            const updatedItems = [...state.tirages];
            addToShoppingCart(updatedItems[index]);
        }

        return (
            <div key={card.idTirage} id='tirage-page'>
                <div className="tirage-item"
                     style={{padding: "1rem", display: "flex", flexDirection: "column", height: "auto"}}>
                    <div className="tirage-card-buyable-list-item-name-wrapper">
            <span className="achat-forfait-card-buyable-list-item-name">
              {card.description}
            </span>
                    </div>
                    <br/>
                    <br/>
                    <div style={{display: 'flex', gap: "1rem", justifyContent: 'space-between'}}>
                        <div className="achat-forfait-card-buyable-list-item-description-wrapper">
                            <strong>{TEXTS.text0}</strong>
                            <span
                                style={{whiteSpace: "nowrap"}}>{card.dateTirage && format(new Date(card.dateTirage), "yyyy-MM-dd HH:mm")}</span>
                            <br/>
                            <strong>{TEXTS.text1}</strong>
                            <span
                                style={{whiteSpace: "nowrap"}}>{card.dateVenteFin && format(new Date(card.dateVenteFin), "yyyy-MM-dd HH:mm")}</span>
                            {card.isSelected && <>
                                <br/>
                                <br/>
                                <span style={{whiteSpace: "nowrap"}}>{TEXTS.text2}{card.maxQuantity})</span>
                                <br/>
                                <SpinInput
                                    name={"quantite"}
                                    style={{width: "80px"}}
                                    type="number"
                                    value={card.quantity}
                                    min={0}
                                    max={card.maxQuantity}
                                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                    onChange={(e) => onChangeQuantity(e, index, card)}
                                />

                                {card?.quantity > 0 && <>
                                    <br/>
                                    <div className="container" style={{display: "flex", gap: "1rem"}}>
                                        <div>
                                            <input
                                                name="substitut"
                                                type="checkbox"
                                                checked={card.hasSubstitut}
                                                style={{marginRight: '80px', marginTop: '12px'}}
                                                onChange={(e) => onChange(index, 'hasSubstitut')}
                                            />
                                        </div>
                                        <div htmlFor="substitut" style={{}}>{TEXTS.text3}</div>
                                    </div>
                                </>}
                            </>}
                        </div>
                        <div style={{float: 'right', display: "flex", flexDirection: "column"}}>
                            <div style={{float: 'right', textAlign: 'right', display: "flex"}}>
                                <strong>{TEXTS.text4}</strong>
                                <div>{card?.prix.length && card.prix.map((e, i) => {
                                    const key = Object.keys(e);
                                    return (<span key={i}>
                    {e.quantite} {TEXTS.text5} {e.prix.toFixed(2)}$
                    <br/>
                  </span>)
                                })}
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <div className="container">
                                <input
                                    type="checkbox"
                                    checked={card.isSelected}
                                    onChange={(e) => onChange(index, 'isSelected')}
                                />
                                <label style={{
                                    position: 'relative',
                                    top: '10px',
                                    marginLeft: '10px'
                                }}>{TEXTS.text6}</label>
                            </div>
                        </div>
                    </div>
                    {card.isSelected && card.hasSubstitut && card.quantity > 0 && <div>
                        <br/>
                        <div className="container">
                            <input type="text" name={"nom"} className="input" placeholder={TEXTS.input0}
                                   value={card.substitut.nom} onChange={(e) => onChangeProfile(e, index, card)}/>
                            <input type="text" name={"prenom"} className="input" placeholder={TEXTS.input7}
                                   value={card.substitut.prenom} onChange={(e) => onChangeProfile(e, index, card)}/>
                            <input type="text" name={"telephone1"} className="input" placeholder={TEXTS.input1}
                                   value={card.substitut.telephone1} onChange={(e) => onChangeProfile(e, index, card)}/>
                        </div>
                        <div className="container">
                            <input type="text" name={"adresse"} className="input" placeholder={TEXTS.input2}
                                   value={card.substitut.adresse} onChange={(e) => onChangeProfile(e, index, card)}/>
                            <select
                                name={"idPays"}
                                value={card.substitut.idPays}
                                onChange={(e) => onChangeProfile(e, index, card)}
                                className="profile-input">
                                {(state.pays || []).map(pays => (<option key={pays.idPays} value={pays.idPays}>
                                    {pays.description.toUpperCase()}
                                </option>))}
                            </select>
                        </div>
                        <div className="container">
                            <input type="text" name={"ville"} className="input" placeholder={TEXTS.input3}
                                   value={card.substitut.ville} onChange={(e) => onChangeProfile(e, index, card)}/>
                            <select
                                name={"idProvince"}
                                value={card.substitut?.idProvince}
                                onChange={(e) => onChangeProfile(e, index, card)}
                                className="profile-input">
                                {/* {(state.provinces || []).filter(p => p.idPays === card.substitut.idPays).map(province => ( */}
                                {(state.provinces || []).map(province => (
                                    <option key={province.idProvince} value={province.idProvince}>
                                        {province.description.toUpperCase()}
                                    </option>))}
                            </select>
                            {/* {state.errors.province && <span className="color-error profile-span">{state.errors.province}</span>} */}

                        </div>
                        <div className="container">
                            <input type="text" name={"codePostal"} className="input" placeholder={TEXTS.input4}
                                   value={card.substitut.codePostal} onChange={(e) => onChangeProfile(e, index, card)}/>
                            <input type="text" name={"email"} className="input" placeholder={TEXTS.input5}
                                   value={card.substitut.email} onChange={(e) => onChangeProfile(e, index, card)}/>
                        </div>
                        {(card.orignal || card.cerf) && <div className="container">
                            <input type={"text"} name={"noPermis"} value={card.substitut.noPermis} className={"input"} placeholder={"No de certificat de chasse"} onChange={(e) => onChangeProfile(e, index, card)}/>
                        </div>}
                        <div className="btn-span-wrapper"
                             style={{marginTop: '0', textAlign: 'center'}} onClick={(e) => {
                            state.tirages.forEach((tirage) => {
                                if (tirage.id !== card.id) {
                                    tirage.substitut = card.substitut;
                                    tirage.hasSubstitut = card.hasSubstitut;
                                    const updatedItems = [...state.tirages];
                                    addToShoppingCart(updatedItems[index]);
                                }
                            })
                        }}>
                            <span>{TEXTS.input6}</span>
                        </div>
                    </div>}
                </div>
            </div>)
    }

    const onChange = (index, type) => {
        const updatedItems = [...state.tirages];
        updatedItems[index] = {
            ...updatedItems[index], [type]: !updatedItems[index][type]
        };
        if (!updatedItems[index].isSelected) {
            updatedItems[index].quantity = 0;
            props.removeFromShoppingCart({id: updatedItems[index].idTirage, name: updatedItems[index].description});

        }
        if (!updatedItems[index].hasSubstitut) {
            updatedItems[index].substitut = initialSubstitut;
        }
        setState({
            ...state, tirages: updatedItems
        });
    };

    const onChangeQuantity = (e, index, card) => {
        const updatedItems = [...state.tirages];
        updatedItems[index] = {
            ...updatedItems[index],
            quantity: e.target.value > card.maxQuantity ? card.maxQuantity : e.target.value < 1 ? 0 : e.target.value, ...(e.target.value === 0 && {hasSubstitut: false})
        };
        setState({
            ...state, tirages: updatedItems
        });

        addToShoppingCart(updatedItems[index]);
    }

    const addToShoppingCart = (item) => {
        const newItem = {};
        newItem["id"] = item.idTirage;
        newItem["name"] = item.description;
        newItem["orignal"] = item.orignal;
        newItem["cerf"] = item.cerf;
        let qte = parseInt(item.quantity);
        let total = 0;

        while (qte > 0) {
            const prixTier = item.prix.filter(f => f.quantite <= qte).sort((a, b) => b.quantite - a.quantite)

            total += prixTier[0].prix || 0;
            qte -= prixTier[0].quantite || 1;
        }
        newItem["montant"] = total;
        newItem["type"] = CartItemType.Tirage;
        newItem["SelectedProduct"] = {
            IdProduit: item.idProduitTirage,
            Quantite: item.quantity,
            PersonnesAssociees: item.hasSubstitut ? [item.substitut] : null
        }

        //if (!props.shop.cart.length) props.addToShoppingCart(newItem); else {
        const index = props.shop.cart.findIndex(e => e.id == item.idTirage);
        if (index > -1) {
            if (item.quantity < 1) {
                props.removeFromShoppingCart(newItem);
            } else if (item.quantity > 0) {
                props.updateShop(index, newItem, false);
            }
        } else if (item.quantity > 0) {
            props.addToShoppingCart(newItem);
        }
    }

    return (<div id="tirage-page-container">
        <div className="recherche-component billeterie-div-filter"/>
        <div className="card-sub-container"
             style={{width: "-webkit-fill-available", maxWidth: "900px", display: "flex", flexDirection: "column"}}>
            <div><h1>{TEXTS.title0}</h1></div>
            <div>{state?.tirages.length ? state.tirages.map(renderContent) : <h2>{TEXTS.title1}</h2>}</div>
        </div>
        <FacturePanierPopup
            showByType="billet"
            handleClose={() => setIsShoppingCartOpen(false)}
            isOpen={isShoppingCartOpen}
        />
        <div className="div-filtrer" onClick={() => setIsShoppingCartOpen(true)}>
      <span className="icon">
        <CartSvg width="30" height="30"/>
      </span>
        </div>
        <div className="sommaire-right-panel max-width sommaire-large-screen">
            <SommaireRightPanel
                // message={TEXTS.title1}
                confirm={() => props.goTo("/panier", "PANIER")}
                confirmTitle={TEXTS.btnConfirmerPanier}
                // confirmError={TEXTS.confirmError}

                // showByType={CartItemType.Billetterie}
                // canConfirm={props.shop.cart.length > 0}
            />
        </div>
    </div>)
        ;
}

const mapStateToProps = (state, ownProps) => ({
    shop: state.shop, entrepriseName: state.entreprise.nom
});
export default withNavigation(connect(mapStateToProps, {
    getPaysConfigWeb,
    getProvincesConfigWeb,
    addToShoppingCart,
    updateShop,
    removeFromShoppingCart,
    setAlert
})(TiragePage));