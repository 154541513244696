import {api} from "../api";

export function getDatesHorsService(idUnite) {
    return api.get(`Unite/${ idUnite }/DatesHorsService`);
}

export function getUniteById(idUnite, includeFields = "") {
    return api.get(`Unite/${ idUnite }/View?include=${ includeFields }`);
}

export function getPrixById(options) {
    return api.post(`Unite/GetTarif`, JSON.stringify(options));
}

export function getUniteImageById(idUnite) {
    return api.get(`Unite/${ idUnite }/Image`);
}

export function getUnitePrixBase(idUnite, dateDebut, dateFin) {
    return api.get(`Unite/${ idUnite }/PrixBase?dateDebut=${ dateDebut }&datefin=${ dateFin }`);
}

export function getOptionsReservation(idUnite, dateDebut, dateFin) {
    return api.get(`Unite/${ idUnite }/OptionsReservation?dateDebut=${ dateDebut }&datefin=${ dateFin }`);
}

export function getUniteProduits(idUnite, startDate, endDate) {
    return api.get(`unite/${ idUnite }/produits?start=${ startDate }&end=${ endDate }`);
}

export function getUnites(idTypeUnite, typeHebergement, includeFields) {
    return api.get(`unite/unites?idTypeUnite=${ idTypeUnite }&typeHebergement=${ typeHebergement }&include=${ includeFields }`);
}

export function getUniteSearchFields(idTypeUnite, typeHebergement) {
    return api.get(`unite/searchfields?idTypeUnite=${ idTypeUnite }&typeHebergement=${ typeHebergement }`);
}

export function getUniteTypeService(idTypeUnite) {
    return api.get(`unite/serviceType?idTypeUnite=${ idTypeUnite }`);
}

export function searchUnites(search) {
    return api.get(`unite/search${ search }`);
}

export function validateSearchOptions(selectedUniteJsonString) {
    return api.post("Unite/ValidateSearchOption", selectedUniteJsonString);
}

export function getTypeHebergementImages() {
    return api.get(`Unite/TypeHebergementImage`);
}

export function getTermesEtCondition(idUnite, langue) {
    return api.get(`Unite/${ idUnite }/termes?langue=${ langue }`);
}

export function getGroupSearch(url) {
    return api.get(url);
}

export async function getUnitesActivites(TypeUnite) { // Liste sur la page unite tout les items de l;unite activite
    return api.get(`TypeUnite/${ TypeUnite }/categoriesActivites`)
}

export async function getImageUnitesActivites(idUniteCategoire) { // Liste sur la page unite tout les items de l;unite activite
    return api.get(`UniteCategorie/${ idUniteCategoire }/image`)
}

export async function getCategoriesActivitesWihtData(TypeUnite, date) { // Liste tout les activites en fonction d'une date dans la page choix unite list
    return api.get(`TypeUnite/${ TypeUnite }/categoriesActivitesDisponibilites?date=${ date }`)

}

export async function getCategorieActivitesDisponibilites(IdUniteCategorie, date) {
    return api.get(`UniteCategorie/${ IdUniteCategorie }/categorieActivitesDisponibilites?date=${ date }`)
}

export function getForfaitList(selectedUniteJsonString) {
    return api.post("Unite/forfaitsUnites", selectedUniteJsonString);
}