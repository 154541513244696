import React from "react";
import "./achat-forfait-card.css";
import AchatForfaitItem from "./achat-forfait-item.js";

function AchatForfaitCard(props) {
  const renderContents = (contents, index) => {
    return (
      <ul className='achat-forfait-tab-ul' key={index}> {/*Ne pas oublier de mettre une key à chaque élément rendered dans un array.map();*/ }
        <AchatForfaitItem {...contents} forfaits={props.forfaits}/>
      </ul>
    )
  }

  const titleSpanClass = props.onTitleClick ? "clickable" : "";
  return (
    <div className="achat-forfait-card-container">
      <div className="card-sub-container">
        {props.cards.length
          ? props.cards.map(card =>
            <div key={card.key} >
              <div className="card-title achat-forfait-tab" >
                <span
                  className={titleSpanClass} onClick={card.onTitleClick || (() => { })}>
                  {card.title}
                </span>
              </div>
              {card.contents.map(renderContents)} {/*Ne pas oublier de mettre une key à chaque élément rendered dans un array.map();*/ }
            </div >
          )
          : <h2>AUCUN RÉSULTAT TROUVÉ</h2>
        }
      </div>
    </div>
  );
}

export default AchatForfaitCard;