export function toDateString(date) {
  if (date == null)
    return "";

  if (typeof date === 'string')
    date = new Date(date);

  var year = ("0000" + date.getFullYear()).substring(date.getFullYear().toString().length);
  var month = date.getMonth() <= 8 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  var day = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
  
  return year + "-" + month + "-" + day; // Format example: 2016-05-02
}

//cette function permet de formater la date suivant le format : "LUN 16-6-2023"
export function formatageDatePourDroitDacces(date) {
// Date d'origine
let dateOriginale = new Date(date);

// Tableau des noms des jours de la semaine en français
let joursSemaine = ["DIM", "LUN", "MAR", "MER", "JEU", "VEN", "SAM"];

// Obtention du jour de la semaine
let jourSemaine = joursSemaine[dateOriginale.getDay()];

// Obtention du jour du mois
let jourMois = dateOriginale.getDate() <= 9 ? "0" + dateOriginale.getDate() : dateOriginale.getDate();

// Obtention du mois (en ajoutant 1 car les mois sont indexés à partir de 0)
let mois = dateOriginale.getMonth() <= 8 ? "0" + (dateOriginale.getMonth()+ 1): dateOriginale.getMonth()+ 1;

// Obtention de l'année
let annee = dateOriginale.getFullYear();

// Formatage de la date finale
let dateFinale = jourSemaine + " " + jourMois + "-" + mois + "-" + annee;

return dateFinale

}

//cette fonction permet de comparer deux dates
export function compareDate(date1, date2) {
  var newDate1 = new Date(date1);
  var newDate2 = new Date(date2);

  var formattedDate1 = newDate1.toISOString().split('T')[0];
  var formattedDate2 = newDate2.toISOString().split('T')[0];

  if (formattedDate1 === formattedDate2) {
    return true
  } else {
    return false
  }
}

export function compareHeure(heure1, heure2) {

  function heureEnMinutes(heure) {
    let [heures, minutes] = heure.split(":");
    let totalMinutes = 0;
    if (heures === "00") {
      heures = "24";
      totalMinutes = parseInt(heures, 10) * 60 + parseInt(minutes, 10);
    } else {
      totalMinutes = parseInt(heures, 10) * 60 + parseInt(minutes, 10);
    }
    return totalMinutes;
  }

  // Conversion des heures en minutes
  let minutes1 =  heureEnMinutes(heure1);
  let minutes2 = heureEnMinutes(heure2);
  // Comparaison des heures
  return minutes1 < minutes2 ? true : false

}

export function toDateTimeString(date, includeMilliseconds = true) {
  if (date == null)
    return "";
  
  if (typeof date === 'string')
    date = new Date(date);

  var year = ("0000" + date.getFullYear()).substring(date.getFullYear().toString().length);
  var month = date.getMonth() <= 8 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  var day = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
  var hours = date.getHours() <= 9 ? "0" + date.getHours() : date.getHours();
  var minutes = date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
  var seconds = date.getSeconds() <= 9 ? "0" + date.getSeconds() : date.getSeconds();
  var milliseconds = ("000" + date.getMilliseconds()).substring(date.getMilliseconds().toString().length);

  return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds + (includeMilliseconds ? "." + milliseconds : ""); // Format example: 2016-05-02 15:45:05.125
}

export function toISODateTimeString(date, includeMilliseconds = true) {
  if (date == null)
    return "";

  if (typeof date === 'string')
    date = new Date(date);
  
  return includeMilliseconds
    ? date.toISOString()
    : new Date(date.setMilliseconds(0)).toISOString(); // Format example: 2016-05-02T15:45:05.000Z. Format nécessaire pour la désérialization du JSON.
}


/**
 * Retourne la ISO DateTime string de la date choisie, sans timezone offset.
 * Utile pour la désérialization principalement.
 * @param {Date} selectedDate
 */
export function toSelectedISODateTimeString(selectedDate) { // toISOStringNoOffset
  let date = new Date(selectedDate);
  date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  date.setMilliseconds(0);
  return date.toISOString();
}

export function isUnformattedPhoneNumber(something) {
  const somethingString = (something || "").toString();

  return /(^\d{10}$)|(^\d{11}$)/.test(somethingString); // Retourne true si a 10 ou 11 digits.
}

export function formatPhoneNumber(phoneNumber) {
  return phoneNumber.length === 10 ? "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6, 10)
       : phoneNumber.length === 11 ? "+" + phoneNumber.substring(0, 1) + " (" + phoneNumber.substring(1, 4) + ") " + phoneNumber.substring(4, 7) + "-" + phoneNumber.substring(7, 11)
       : "";
}


export function formatDateYMD (date)  { // put this function in utils after
  if (!(date instanceof Date)) return date;

  return date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0");
}

// export function formatDate () {
//  return new Date().getHours().toString().padStart(2, '0') + ":" + new Date().getMinutes().toString().padStart(2, '0')
// }