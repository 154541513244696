import React from "react";

function CloseSvg(props) {
  let { dispatch, fill, width, height, className, svgClassName, ...otherProps } = props

  fill = fill || "rgb(0, 0, 0)";
  width = width || "48px";
  height = height || "48px";

  className = className || "btn-svg";
  svgClassName = svgClassName || "jss620";

  return (
    <span className={className}>
      <svg className={svgClassName}  width={width} height={height} fill={fill} viewBox="0 0 24 24" aria-hidden="true" role="presentation" {...otherProps}>
        <path fill={fill} d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
        </path>
      </svg>
    </span>
  );
}

export default CloseSvg;
