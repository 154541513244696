import React, { Component } from 'react';

class OptionCard extends Component {
  render() {
    let transition;
    if (this.props.index === 1) transition = 'transform 225ms cubic-bezier(0.4,0,0.2,1) 0ms'
    if (this.props.index === 2) transition = 'transform 225ms cubic-bezier(0.4,0,0.2,1) 75ms'
    if (this.props.index === 3) transition = 'transform 225ms cubic-bezier(0.4,0,0.2,1) 150ms'
    if (this.props.index === 4) transition = 'transform 225ms cubic-bezier(0.4,0,0.2,1) 225ms'
    if (this.props.index === 5) transition = 'transform 225ms cubic-bezier(0.4,0,0.2,1) 300ms'

    return (
      <div value="option1" onClick={(e) => this.props.handleClick(this.props.item.name, this.props.optionIndex, this.props.item.montant)} className="jss2623" style={{ transform: 'scale(1)', transition: transition }}>
        <button className={`options-popup-btn jss2624 ${this.props.isChosen && "jss2625"}`} type="button">
          <div className="jss2626">
            <div className="jss2627">
              {
                this.props.isChosen &&
                <div className="jss2635">
                  <svg className="jss508 jss510 jss2637" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path fill="none" d="M0 0h24v24H0z">
                    </path><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                  </svg>
                </div>
              }
              <div className="jss2629">
                {this.props.isChosen && this.props.item.montant &&
                  <div className="jss805">
                    <p className="options-popup-font jss2631" id="price">+
                      {this.props.item.montant.toFixed(2).toString()} &nbsp;$&nbsp;CA
                   </p>
                  </div>
                }
                <p className="options-popup-font jss2631"><br />
                </p>
                <p className="options-popup-font jss2632">
                  {this.props.item.name + ' '}{this.props.item.montant && this.props.item.montant.toFixed(2).toString() + '$'}
                </p>
                <p className="options-popup-font jss898 jss2628">
                </p>
                {this.props.item.description &&
                  <p className="options-popup-font jss2634">{this.props.item.description}
                  </p>
                }
              </div>
            </div>
          </div>
          <span className="jss649">
          </span>
        </button>
      </div>
    );
  }
}

export default OptionCard;