import { api } from "../api";

export async function getTypesReservations() {
  return await api.get("TypeUnite/TypesReservations");
}

export async function getTypeUnite(idTypeUnite) { // J'ai renommé pour enlever les 's' (se nommait getTypesUnites). Si ça crash quelque part parce que ça appel le nouveau getTypesUnites, changer pour appeler getTypeUnite(idTypeUnite).
  return await api.get(`TypeUnite/${idTypeUnite}`);
}

export async function getTypesUnites({typeHebergement}) {
  return await api.get(`TypeUnite?typeHebergement=${typeHebergement}`);
}

export async function getTypeUniteImageByIdTypeUnite({idTypeUnite}) { // La fucntion prend un object plutôt qu'un Guid pour être facilement extensible.
  return await api.get(`TypeUnite/${idTypeUnite}/Image`);
}

export async function getTypeUniteQuantiteByIdTypeUnite({idTypeUnite}) { // La fucntion prend un object plutôt qu'un Guid pour être facilement extensible.
  return await api.get(`TypeUnite/${idTypeUnite}/Quantite`);
}

export async function getTypeUnitePrixBaseByIdTypeUnite({idTypeUnite}) { // La fucntion prend un object plutôt qu'un Guid pour être facilement extensible.
  return await api.get(`TypeUnite/${idTypeUnite}/PrixBase`);
}

export async function getTypeUniteDisponibilites({idTypeUnite, from, to}) { // La fucntion prend un object plutôt qu'un Guid pour être facilement extensible.
  return await api.get(`TypeUnite/${idTypeUnite}/Disponibilites?from=${from}&to=${to}`);
}