import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import withNavigation from "./higher-order/navigation";
import { connect } from "react-redux";
//import Container from "react-bootstrap/Container";
//import Row from "react-bootstrap/Row";
//import Col from "react-bootstrap/Col";
//import Button from "react-bootstrap/Button";
import CardContainer from './card-container.js';
import { getTypesReservations } from "../server/services/type-unite-service";
import { getTypeHebergementImages } from "../server/services/unite-service";
//import "../../css/choix-page.css";
import { useSeoTitle } from "../utils/hooks";
import Translator from "./translator";
import usePageTracking from "../utils/use-page-tracking";
import {setCategorieActivite, setIdtypeUniteActivite } from './actions/form-pop-active.js'
import {PATH_ACTIVITE} from "../constants/navigation-constants.js";

import {getUnitesActivites} from "../server/services/unite-service";
import { setFromStorage } from "../utils/localStorageUtils.js";


function mapStateToProps(state) {
  return {
    entrepriseName: state.entreprise.nom
  };
}

function ReservationPage(props) {
  useSeoTitle("Réservation");
  const { idZec } = useParams();
  const [typesReservations, setTypesReservations] = useState(null);
  const [typeHebergementImages, setTypeHebergementImages] = useState(null);
  const [cards, setCards] = useState([]);
  let TEXTS = Translator(props.match.params.language, "TXT_RESERVATION_PAGE");
  usePageTracking();
  useEffect(() => {
    TEXTS = Translator(props.match.params.language, "TXT_RESERVATION_PAGE");
  }, [props.match.params.language])

  useEffect(() => {
    getTypesReservations()
      .then(typesReservations => {
        setTypesReservations(typesReservations);
      })
  }, [idZec])

  useEffect(() => {
    getTypeHebergementImages()
      .then(images => {
        setTypeHebergementImages(images);
      })
  }, [idZec])


  const navigateToActivite = async (idTypeUnite) => {
    const idTypeUniteSetLocal = idTypeUnite
    setFromStorage('idTypeUnite', idTypeUniteSetLocal)
    props.setIdtypeUniteActivite(idTypeUniteSetLocal)
    props.goTo(PATH_ACTIVITE)
  }

  useEffect(() => {
    if (typesReservations === null || typeHebergementImages === null)
      return [];

    const cards = [];

    const cardHebergement = {
      key: "hebergement",
      thumbnail: ((typeHebergementImages || []).filter(i => i.typeHebergement !== 'CP' && i.typeHebergement !== 'AC')[0] || {}).image || "thumbnails/hebergement-511x384.jpg",
      thumbnailType: (((typeHebergementImages || []).filter(i => i.typeHebergement !== 'CP' && i.typeHebergement !== 'AC')[0] || {}).image || "") === "" ? "" : "base64",
      title: TEXTS.title0,
      onTitleClick: e => props.gotoTypeHebergement('PC'),
      contents: [{
        key: "card-content",
        type: 'bulletList',
        items: []
      }]
    };

    const cardCamping = {
      key: "camping",
      thumbnail: ((typeHebergementImages || []).filter(i => i.typeHebergement === 'CP')[0] || {}).image || "thumbnails/camping-576x384.jpg",
      thumbnailType: (((typeHebergementImages || []).filter(i => i.typeHebergement === 'CP')[0] || {}).image || "") === "" ? "" : "base64",
      title: TEXTS.title1,
      onTitleClick: e => props.gotoTypeHebergement('CP'),
      contents: [{
        key: "card-content",
        type: 'bulletList',
        items: []
      }]
    };

    const cardActivites = {
      key: "activites",
      thumbnail: ((typeHebergementImages || []).filter(i => i.typeHebergement === 'AC')[0] || {}).image || "thumbnails/activites-506x337.jpg",
      thumbnailType: (((typeHebergementImages || []).filter(i => i.typeHebergement === 'AC')[0] || {}).image || "") === "" ? "" : "base64",
      title: TEXTS.title2,
      onTitleClick: e => props.gotoTypeHebergement('AC'),
      contents: [{
        key: "card-content",
        type: 'bulletList',
        items: []
      }]
    };

    const cardAutres = {
      key: "autres",
      thumbnail: ((typeHebergementImages || []).filter(i => i.typeHebergement === 'A')[0] || {}).image || "thumbnails/activites-506x337.jpg",
      thumbnailType: (((typeHebergementImages || []).filter(i => i.typeHebergement === 'A')[0] || {}).image || "") === "" ? "" : "base64",
      title: TEXTS.title3,
      onTitleClick: e => props.gotoTypeHebergement('A'),
      contents: [{
        key: "card-content",
        type: 'bulletList',
        items: []
      }]
    };
    const isFrancais = (props.match.params.language.toLowerCase() || 'fr') === 'fr';
    for (var i = 0; i < typesReservations.length; i++) {
      const typeReservation = typesReservations[i];

      switch (typeReservation.typeHebergement) {
        case "CP":
          cardCamping.contents[0].items.push({
            key: typeReservation.idTypeUnite,
            name: isFrancais || typeReservation.descriptionAnglaise === "" ? typeReservation.description.toUpperCase() : typeReservation.descriptionAnglaise.toUpperCase(),
            onClick: () => props.gotoChoixUniteListe(typeReservation.typeHebergement, ``)
          });
          break;
       
        case "AC":
            cardActivites.contents[0].items.push({
                key: typeReservation.idTypeUnite,
                name: isFrancais || typeReservation.descriptionAnglaise === "" ? typeReservation.description.toUpperCase() : typeReservation.descriptionAnglaise.toUpperCase(),
                onClick: () =>   navigateToActivite(typeReservation.idTypeUnite)
                // props.gotoChoixUniteListe(typeReservation.typeHebergement, `?idTypeUnite=${typeReservation.idTypeUnite}`)
            });
          break;
        //temporairement désactivé
        /*case "A":
            cardAutres.contents[0].items.push({
                key: typeReservation.idTypeUnite,
                name: isFrancais || typeReservation.descriptionAnglaise === "" ? typeReservation.description.toUpperCase() : typeReservation.descriptionAnglaise.toUpperCase(),
                onClick: () => props.gotoChoixUniteListe(typeReservation.typeHebergement, `?idTypeUnite=${typeReservation.idTypeUnite}`)
            });
            break;*/

        default:
          cardHebergement.contents[0].items.push({
            key: typeReservation.idTypeUnite,
            name: isFrancais || typeReservation.descriptionAnglaise === "" ? typeReservation.description.toUpperCase() : typeReservation.descriptionAnglaise.toUpperCase(),
            onClick: () => props.gotoChoixUniteListe(typeReservation.typeHebergement, `?idTypeUnite=${typeReservation.idTypeUnite}`)
          });
          break;
      }
    }

    if (cardHebergement.contents[0].items.length > 0) cards.push(cardHebergement);
    if (cardCamping.contents[0].items.length > 0) cards.push(cardCamping);
    if (cardActivites.contents[0].items.length > 0) cards.push(cardActivites);
    if (cardAutres.contents[0].items.length > 0) cards.push(cardAutres)

    setCards(cards);
  }, [typesReservations, typeHebergementImages, props.match.params.language])

  return (
    <div id="reservation-page">
      <CardContainer cards={cards} />
    </div>
  );
}

export default withNavigation(connect(mapStateToProps, { setCategorieActivite,setIdtypeUniteActivite  })(ReservationPage));
