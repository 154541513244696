import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import ChampDeRechercheFieldError from "./reservation/champ-de-recherche-field-error";
import Calendar from "./calendar";
import ChampsDeRecherche from "./reservation/champ-de-recherche";
import Translator from "./translator";

const ChoixRecherchePanel = (props) =>{
  const [showCalendrierArrivee, setShowCalendrierArrivee] = useState(false);
  const [showCalendrierDepart, setShowCalendrierDepart] = useState(false);
  const TEXTS = Translator(props.language, "TXT_CHOIX_RECHERCHE_PANEL");
  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.showCalendrierArrivee) {
  //     ReactDOM.findDOMNode(this.refs.calendarArrivalWrapperDiv).focus();
  //   } else if (this.state.showCalendrierDepart) {
  //     ReactDOM.findDOMNode(this.refs.calendarDepartureWrapperDiv).focus();
  //   }
  // }

  // const [isPopupOpen, setPopupOpen] = useState(false);

  // useEffect(() => {
  //   if (props.showCalendrierArrivee) {
  //     ReactDOM.findDOMNode(this.refs.calendarArrivalWrapperDiv).focus();
  //   } else if (props.showCalendrierDepart) {
  //     // ReactDOM.findDOMNode(this.refs.calendarDepartureWrapperDiv).focus();
  //   }
  // }, []);
  

  return (
    <div id="choix-unite-recherche" className={props.affichageClass}>
      <div className="choix-unite-recherche-section date">
        <div className="choix-unite-recherche-title-wrapper date">
          {props.hasError && <ChampDeRechercheFieldError errorMessage={props.fieldError.selectedDate} />}
          <span>{TEXTS.title0}</span>
        </div>
        <div className={props.getInputClassName()}>
          <div className="choix-unite-recherche-demi-input-wrapper">
            <input type="text" placeholder={TEXTS.calendarTitle0} className="clickable" onMouseDown={(e) => props.toggleCalendrierArrivee()} value={props.formatDateYMD(props.previewArrivee || props.searchOptions.selectedArrivee) || ""} readOnly />
            <img name="image" className="calendar-icon clickable" src={require("../../images/icons/calendar-2.png")} alt="calendrier" onMouseDown={() => props.toggleCalendrierArrivee()} />
            {props.showCalendrierArrivee && (
              <div
                className="calendar-wrapper"
                // ref="calendarArrivalWrapperDiv"
                tabIndex={0}
                onBlur={(e) => props.blurCalendrierArrivee()}
              >
                <Calendar
                  maxJoursEnAvanceReservation={props.maxJoursEnAvanceReservation}
                  onDayClick={props.onDayClick}
                  onHoveredDayChange={props.onHoveredDayChange}
                  selectedRangeBegin={props.selectedRangeBegin}
                  selectedRangeEnd={props.selectedRangeEnd}
                  initialMonth={props.initialMonth}
                  isSelectingRangeBegin={props.isSelectingRangeBegin}
                  minDate={props.minDate}
                  language={props.language}
                />
              </div>
            )}
          </div>
          <div className="choix-unite-recherche-demi-input-wrapper">
            <input type="text" placeholder={TEXTS.calendarTitle1} className="clickable" onMouseDown={(e) => props.toggleCalendrierDepart()} value={props.formatDateYMD(props.previewDepart || props.searchOptions.selectedDepart) || ""} readOnly />
            <img className="calendar-icon clickable" src={require("../../images/icons/calendar-2.png")} alt="calendrier" onMouseDown={(e) => props.toggleCalendrierDepart()} />
            {props.showCalendrierDepart && (
              <div
                className="calendar-wrapper"
                // ref="calendarDepartureWrapperDiv"
                tabIndex={0}
                onBlur={(e) => props.blurCalendrierDepart()}
              >
                <Calendar
                  maxJoursEnAvanceReservation={props.maxJoursEnAvanceReservation}
                  onDayClick={props.onDayClickDepart}
                  onHoveredDayChange={props.onHoveredDayChangeDepart}
                  selectedRangeBegin={props.selectedRangeBegin}
                  selectedRangeEnd={props.selectedRangeEnd}
                  initialMonth={props.initialMonthDepart}
                  isSelectingRangeEnd={props.isSelectingRangeEnd}
                  minDate={props.minDate}
                  language={props.language}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ChampsDeRecherche
        idUnite={props.idUnite}
        isTypeAffichageUnite={props.isTypeAffichageUnite}
        idTypeUnite={props.idTypeUnite}
        fieldError={props.fieldError}
        searchOptions={props.searchOptions}
        typeHebergement={props.typeHebergement}
        applySearchFilters={() => props.applySearchFilters()}
        addUniteToShoppingCart={() => (props.addUniteToShoppingCart(props.idUnite))}
        showAddToShoppingCart={props.showAddToShoppingCart}
        resetSearchOptions={() => props.resetSearchOptions()}
        updateSearchField={props.updateSearchField}
        updateSearchFieldExtra={props.updateSearchFieldExtra}
        consultationSeulement={props.consultationSeulement}
        peutAjouterPanier={props.peutAjouterPanier}
        language={props.language}
      />
    </div>
  );
}
export default ChoixRecherchePanel;