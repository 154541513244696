import React from 'react';
import "./pagination.css";

function Pagination(props) {

  const previousPage = () => {
    if (props.currentPage <= 1) return
    props.changePage(props.currentPage - 1)
  }

  const nextPage = () => {
    if (props.currentPage >= props.pages) return
    props.changePage(props.currentPage + 1)
  }

  const renderPages = () => {
    var rows = []
    for (let i = 1; i <= props.pages; i++) {
      rows.push(
        <li data-test="li-page"
          className={`ais-Pagination-item ${i === props.currentPage && 'ais-Pagination-item--selected'} ais-Pagination-item--page`}
          key={i} onClick={() => props.changePage(i)}
        >
          <span className={`ais-Pagination-link ${i === props.currentPage ? 'active' : 'hover'}`}>{i}</span>
        </li >
      )
    }
    return rows;
  }

  return (
    // <div className="ais-controls">
    <div className="ais-footer">
      {/* <div className="ais-stats-container"> */}
      <div className="ais-stats">
        {props.currentPage === 1 ? 1 : props.currentPage * 8 - 8}
        {" "} - {" "}
        {props.currentPage * 8 > props.totalItems ? props.currentPage * 8 - (8 - props.currentTotalItems) : props.currentPage * 8}
        {" "} de {props.totalItems}
        {/* 1 - 32 out of 238 Results */}
      </div>
      {/* </div> */}
      <div className="ais-pagination-container">
        <div className="ais-Pagination">

          <ul data-test="test" className="text-centered pagination">
            <li className="ais-Pagination-item ais-Pagination-item--page"
              onClick={() => {
                if (props.currentPage <= 1) return;
                props.changePage(1)
              }}>
              <span
                className={`ais-Pagination-link ${props.currentPage === 1 && 'off'} hover`}
              >
                &#x276E;&#x276E;
              </span>
            </li>
            <li className="ais-Pagination-item ais-Pagination-item--page"
              onClick={previousPage}>
              <span
                className={`ais-Pagination-link ${props.currentPage === 1 && 'off'} hover`}
              >&#x276E;
              </span>
            </li>
            {renderPages}
            <li className="ais-Pagination-item ais-Pagination-item--page"
              onClick={nextPage}>
              <span
                className={`ais-Pagination-link ${props.currentPage === props.pages && 'off'} hover`}>
                &#x276F;
              </span>
            </li>
            <li className="ais-Pagination-item ais-Pagination-item--page"
              onClick={() => {
                if (props.currentPage >= props.pages) return;
                props.changePage(props.pages)
              }}>
              <span
                className={`ais-Pagination-link ${props.currentPage === props.pages && 'off'} hover`}
                aria-label="Last page"
              >
                &#x276F;&#x276F;
              </span>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="ais-pagination pagination-mobile">
          <div className="ais-custom-pagination">
            <div className="btn-prev">
              <button className="fa" disabled=""></button>
            </div><span className="ais-page-label"> Page 1 of 8
            </span>
            <div className="btn-next">
              <button className="fa">

              </button>
            </div>
          </div>
        </div> */}
    </div >
  );
}

export default Pagination;