import React, { Component } from "react";
import "./restaurant-card.css";

class InputTime extends Component {
  render() {
    return (
      <select className={this.props.className} value={this.props.time} onChange={(e) => this.props.handleChange('time', e.target.value)}>
        <option value="17:12">
          17:12
        </option>
        <option value="17:15">
          17:15
        </option>
        <option value="17:30">
          17:30
        </option>
        <option value="18:00">
          18:00
        </option>
      </select>
    );
  }
}

export default InputTime;