import React, { useState } from "react";
import withNavigation from "../higher-order/navigation";
import { connect } from "react-redux";
import AchatForfaitCard from "./achat-forfait-card.js";
import { getShop, addToShoppingCart, removeFromShoppingCart } from "../actions/shop";
import "./achat-forfait-page.css";
import jwtDecode from "jwt-decode";
import FilterLeftPanel from "./filter-left-panel";
import SommaireRightPanel from "./sommaire-right-panel.js";
import { useSeoTitle } from "../../utils/hooks";
import CartSvg from "../svg/cart";
import FacturePanierPopup from "../popups/facture-panier-popup";
import { getForfaits, searchProducts } from "../../server/services/catalogue-service";
import { CartItemType } from "../actions/shop";

function AchatForfaitPage(props) {
  const [forfaits, setForfaits] = useState([]);
  const [forfaitCards, setForfaitCards] = useState({});
  const [isShoppingCartOpen, setIsShoppingCartOpen] = useState(false);

  useSeoTitle("Achat de forfaits");

  const createCards = () => {
    return {
      liste: {
        key: "liste",
        thumbnail: "thumbnails/annuel-573x387.jpg",
        title: "LISTE DES FORFAITS",
        contents: [
          {
            key: "items",
            type: "buyableList",
            items: []
          }
        ]
      }
    };
  };

  const fetchForfaits = (recreateCards = true) => {
    const idProfile = props.authToken == null ? undefined : jwtDecode(props.authToken).idProfile;

    getForfaits(idProfile).then(({ forfaits, config }) => {
      setForfaits(forfaits);

      if (recreateCards)
        createForfaitCards(forfaits);
    });
  };

  const createForfaitCards = (forfaits) => {
    const forfaitCardItem = createCards();
    for (let i = 0; i < forfaits.length; i++) {
      const forfait = forfaits[i];
      forfaitCardItem.liste.contents[0].items.push(createCardItem(forfait));
    }
    setForfaitCards(Object.assign({}, forfaitCardItem));
  };

  const applyFilter = (searchFilter) => {
    const idProfile = props.authToken == null ? undefined : jwtDecode(props.authToken).idProfile;

    searchProducts(searchFilter, "", idProfile).then((forfaits) => {
      createForfaitCards(forfaits.forfaits);
    });
  };

  const createCardItem = (forfait) => {
    return {
      key: forfait.idProduit + (forfait.idProduitPrix || ""),
      idProduit: forfait.idProduit,
      idProduitPrix: forfait.idProduitPrix,
      name: forfait.description.trim(), // Dans au moins une des BD, il y a des descriptions commençant avec un espace (' '). Ça fait en sorte que le sort plus loins est incorrecte car, par exemple, ' b' vient avant 'a'. On fait un trim() pour corriger ça.
      montant: forfait.prixVente,
      idProduitRequis: forfait.idProduitRequis,
      onClick: (e) => {
        let updated = false;

        for (const cardName in forfaitCards) {
          for (const content in forfaitCards[cardName].contents) {
            for (const item in forfaitCards[cardName].contents[content].items) {
              if (forfaitCards[cardName].contents[content].items[item].idProduit === forfait.idProduit && forfaitCards[cardName].contents[content].items[item].idProduitPrix === forfait.idProduitPrix) {
                if (!updated) {
                  const isSelected = props.shop.cart.some((cartItem) => cartItem.type === CartItemType.Forfait && cartItem.idProduit === forfait.idProduit);

                  updateShoppingCart(forfait, isSelected);

                  updated = true;
                }
              }
            }
          }
        }
      }
    };
  };

  const updateShoppingCart = (forfait, add) => {
    const action = add ? props.addToShoppingCart : props.removeFromShoppingCart;

    console.log(forfait);

    action(
      {
        //id: 'forfait-' + forfait.idProduit, // id utilisé pour identifier l'item dans le cart
        id: forfait.idProduit, // id utilisé pour identifier l'item dans le cart
        idProduit: forfait.idProduit,
        name: forfait.description,
        montant: Number(forfait.prixVente),
        tps: forfait.tps ? Number(forfait.tps) : 0,
        tvq: forfait.tvq ? Number(forfait.tvq) : 0,
        total: Number(forfait.prixVente) + Number(forfait.tps) + Number(forfait.tvq),
        type: CartItemType.Forfait
      },
      false
    );
  };

  const handleCloseCart = () => {
    setIsShoppingCartOpen(false);
  };

  return (
    <div id="achat-forfait-page-container">
      {props.authToken == null ?
        <div id="droit-acces-page" className="droit-acces-page">
          <div className="droit-acces-div" style={{ textAlign: "center" }}>
            <span className="droit-acces-span message">Vous n'êtes pas connecté à votre compte.</span>
          </div>
          <div className="droit-acces-div" style={{ textAlign: "center" }}>
            <span className="droit-acces-span message">Cliquez ici pour vous </span>
            <span className="clickable message primary-color droit-acces-span droit-acces-span-primary" onClick={e => props.gotoProfile('achat-forfait')}>connecter à votre compte</span>
            <span className="droit-acces-span message"> ou pour </span>
            <span className="clickable message primary-color droit-acces-span droit-acces-span-primary" onClick={e => props.gotoCreerCompte("achat-forfait")}>créer un nouveau compte</span>
            <span className="droit-acces-span message">.</span>
          </div>
        </div>
        :
        <>
          <FilterLeftPanel applyFilter={applyFilter} getForfaits={fetchForfaits} />

          <AchatForfaitCard cards={Object.values(forfaitCards)} forfaits={forfaits} />

          <FacturePanierPopup showByType={CartItemType.Forfait} handleClose={handleCloseCart} isOpen={isShoppingCartOpen} />
          <div className="div-filtrer" onClick={() => setIsShoppingCartOpen(true)}>
            <span className="icon">
              <CartSvg width="30" height="30" />
            </span>
          </div>
          <div className="sommaire-right-panel max-width sommaire-large-screen">
            <SommaireRightPanel message="FACTURE" confirm={() => props.goTo("/panier", "PANIER")} confirmTitle="Aller au panier" confirmError="Sélectionner un forfait" showByType={CartItemType.Forfait} canConfirm={props.shop.cart.length > 0} />
          </div>
        </>

      }
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  authToken: state.authToken,
  shop: state.shop,
  entrepriseName: state.entreprise.nom
});

export default withNavigation(connect(mapStateToProps, { getShop, addToShoppingCart, removeFromShoppingCart })(AchatForfaitPage));
