import React, { useState } from "react";

function FilterPanelMobileSectionPopup(props) {

  const [state, setState] = useState({ isTabOpen: false });

  function handleTab() {
    setState((prevState) => ({
      isTabOpen: !prevState.isTabOpen
    }))
  }

  return (
    <div className="ais-facet" data-facet-title="in_fc">
      <div className={`ais-facet--header ${state.isTabOpen && 'open'}`}
        onClick={handleTab}
      >{props.item.category}</div>
      <div className="ais-refinement-list__wrapper collapsed">
        {state.isTabOpen &&
          <div className="ais-refinement-list--list">
            {props.item.values.map((value, valueIndex) => {
              return <div className="ais-facet--item" key={valueIndex}>
                <div>
                  <div className="checkbox-filter ais-facet--checkbox">
                    <input
                      className="checkbox__element"
                      checked={value.checked}
                      type="checkbox"
                      id={value.name}
                      value={props.categoryIndex}
                      lang={valueIndex}
                      onChange={props.handleOnChange}
                    />
                    <label className="checkbox__label" htmlFor={value.name}>
                    </label>
                  </div>
                  <label className="ais-facet--label unselectable" htmlFor={value.name}>
                    <span> {value.name} </span>
                    <span className="ais-facet--count"> ({value.quantity})
                    </span>
                  </label>
                </div>
              </div>
            })}
          </div>
        }
      </div>
    </div>
  )
}

export default FilterPanelMobileSectionPopup;