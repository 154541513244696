
import { SET_CATEGORIE_ACTIVITE, SET_IDTYPEUNITE_ACTIVITE } from '../components/actions/types'

const initialState = {
    categorie: [],
    idUniteCategorie:'',
    idTypeUniteActivite: ''
}

export default function (state = initialState, action) {
    let newState;
    switch (action.type) {
        case SET_CATEGORIE_ACTIVITE:
            newState = {
                ...state,
                categorie: action.value
            }
            return newState || state
        case SET_IDTYPEUNITE_ACTIVITE:
            newState = {
                ...state,
                idTypeUniteActivite: action.value
            }
            return newState || state
        default:
            return state;
    }
}
