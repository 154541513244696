import React, { Component } from "react";
import { connect } from "react-redux";
import "./restaurant-page.css";
import categories from "./categories.json";
import RestaurantTab from "./restaurant-tab.js";
import SommaireRightPanel from "../achat-forfait/sommaire-right-panel";
import DetailsCommande from "./details-commande.js";
import { setSeoTitle } from "../../utils/document";
import { CartItemType } from "../actions/shop";

function mapStateToProps(state) {
  return {
    entrepriseName: state.entreprise.nom
  };
}

class RestaurantPage extends Component {
  constructor(props) {
    super(props);

    var today = new Date(),
      time = today.getHours() + ':' + ((today.getMinutes() < 10) ? '0' + today.getMinutes() : today.getMinutes());
    this.state = {
      isDeliveryConfirmed: false,
      hasAnyFood: false,
      day: "Aujourd'hui",
      time: time,

      addressType: "Pourvoirie",
      addressNumber: "",
      addressStreet: "",
      addressPostal: "",
      city: "",
      province: "QC",
      instructionsDelivery: "",

      takeoutDay: "",
      takeoutTime: "",
      instructionsTakeout: "",

      areErrorsDisplayed: false,
      errors: {
        addressType: "",
        adressNumber: "",
        addressStreet: "",
        addressPostal: "",
        city: "",
        province: "",
      }
      //   type: "",
      // optionNow: true
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    setSeoTitle(this.props.entrepriseName, "Restaurant");
  }

  handleChange(stateName, event) {
    console.log(stateName)
    console.log(event)
    this.setState({
      [stateName]: event
    })
    // if(this.state.addressType && this.state.addressNumber && this.state.addressStreet && this.state.addressPostal && this.state.city && this.state.province){
    //   this.setState({
    //     canConfirm: true
    //   })
    // }
  }

  render() {
    let canConfirm = false;
    if (this.state.isDeliveryConfirmed) canConfirm = true;

    return (
      <div className="pageContainer" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <DetailsCommande
          day={this.state.day}
          time={this.state.time}
          handleChange={this.handleChange}

          addressType={this.state.addressType}
          addressNumber={this.state.addressNumber}
          addressStreet={this.state.addressStreet}
          addressPostal={this.state.addressPostal}
          city={this.state.city}
          province={this.state.province}
          instructionsDelivery={this.state.instructionsDelivery}

          takeoutDay={this.state.takeoutDay}
          takeoutTime={this.state.takeoutTime}
          instructionsTakeout={this.state.instructionsTakeout}
          errors={this.state.errors}
        />
        <div className="flex-details">
          <div className="div-restaurant-page">
            {categories.map((category, index) => {
              return <RestaurantTab key={index} category={category} />
            })
            }
          </div>
          <div className="sommaire-div">
            <div className="sticky">
              <SommaireRightPanel
                message="COMMANDE PRÊTE POUR"
                showByType={CartItemType.CommandeRestaurant}
                day={this.state.day}
                time={this.state.time}
                canConfirm={canConfirm}
                handleChange={this.handleChange}
              />
              {/* <SommaireRestaurant
                message="COMMANDE PRÊTE POUR"
                showByType="Commande restaurant"
                day={this.state.day}
                time={this.state.time}
                canConfirm={canConfirm}
                handleChange={this.handleChange}
              /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(RestaurantPage);