import React, { Component } from "react";
import "./restaurant-tab.css";
import BarUp from "../svg/barUp.js";
import BarDown from "../svg/barDown.js";
import items from "./items.json";
import RestaurantCard from "./restaurant-card.js";

class RestaurantTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTabActive: false
    }
  }

  setTab() {
    this.setState(prevState => ({
      isTabActive: !prevState.isTabActive
    }));
  }

  render() {
    return (
      <div className={`restaurant-tab-container ${this.state.isTabActive ? "open background-color" : "close"}`}>
        <div onClick={() => this.setTab()} className="mainButton jss450 jss414">
          <div className="jss454">
            <div className="jss456">
              <div className="display-flex">
                <p className={`tab-title ${this.state.isTabActive ? "openColor" : "closeColor"}`}>{(this.props.category.name).toUpperCase()}
                </p>
                <span className="tab-icon">
                  {this.state.isTabActive ?
                    <BarUp fill="rgb(255, 76, 1)" /> :
                    <BarDown />
                  }
                </span>
                {/* <div className="tab-right-text">
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="jss467" style={{ minHeight: "0px" }}> */}
          <div className="div-containers">
            <div style={{ width: "100%" }}>
              {/* <p className="normal-font jss416"> allo  </p> */}
              <div className="tab-separator" />
              <ul className="ul-container" style={{ margin: "-10px" }}>
                {
                  this.state.isTabActive &&
                  items.map((item, index) =>
                    <RestaurantCard key={index} item={item} />
                  )
                }
              </ul>
            </div>
          </div>
        {/* </div> */}
      </div>
    );
  }
}

export default RestaurantTab;