import { typePeriode } from "../../constants/color-disponiblite-activites";
/**
 * Obtient le label de la période en fonction du type et de la durée.
 * @param {number} type Le type de période.
 * @param {number} duree La durée associée à la période.
 * @returns {string} Le label de la période.
 */
export const getTypePeriode = (type, duree) => {
    // Obtient le label correspondant au type de période à partir de l'objet 'typePeriode'.
    const label = typePeriode[type];

    // Vérifie si la durée est supérieure à 1.
    // Si oui, retourne le label au pluriel en ajoutant un 's' à la fin, sinon retourne le label normal.
    return duree > 1 ? label + 's' : label;
};
