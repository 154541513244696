import React from 'react';

export default  function InfoClientFieldError(props) {
  if (!props.message)
    return <div/>;

  return (
    <span className='info-client-field-error' >
      <b>*{props.message}</b>
    </span>
  );
}

export const MandatoryFieldAsterisk = () => {
  return <span className="info-client-field-mandatory">*</span>
}

