import {
  SHOW_OVERLAY,
  HIDE_OVERLAY,
} from '../components/actions/types';

const initialState = {
  showByKey: {}, // Show fonctionne par key. S'il y a une key, le text de cette key est affiché. Ça facilite la gestion quand il peut y avoir plusieurs raison simulatané pour afficher un overlay, par exemple si plusieurs appels asynchrones de différentes components doivent avoir été résolu avant de cacher l'overlay.
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_OVERLAY:
      return {
        ...state,
        showByKey: {...state.showByKey, [action.key]: {text: action.text}},
      };

    case HIDE_OVERLAY:
      const showByKey = {...state.showByKey};

      action.keys.forEach(k => delete showByKey[k]);

      return {
        ...state,
        showByKey: showByKey,
      };

    default:
      return state;
  }
}