import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./sommaire-item.css";
import { removeFromShoppingCart } from "../actions/shop";
import SommaireItemOptions from "./sommaire-item-options.js";

function SommaireItem(props) {

  return (
    <React.Fragment>
      <div className="div-sommaire-item">
        <div className="float-left">
          <span className="sommaire-items-font sommaire-items-name">{props.item.name}</span>
        </div>
        <div className="float-right">
          <span className="sommaire-items-font sommaire-items-price">
            {props.item.montant && props.item.montant.toFixed(2).toString()}&nbsp;$&nbsp;CA
            {/* {props.showAllMontant ? props.item.montant.toFixed(2).toString() : props.item.montantOriginal.toFixed(2).toString()}&nbsp;$&nbsp;CA */}
          </span>
          {
            props.allowDelete &&
            <span className="remove"
              onClick={() => props.remove ? props.remove(props.item) : props.removeFromShoppingCart(props.item)}
            >☒</span>
          }
        </div>
      </div>
      {
        props.isDescriptionShowed && props.item.inclus &&
        <SommaireItemOptions
          index={props.index}
          item={props.item}
        // inclus={props.item.inclus}
        />
      }
    </React.Fragment>
  );
}

SommaireItem.propTypes = {
  removeFromShoppingCart: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  shop: state.shop
});

SommaireItem.defaultProps = {
  showAllMontant: true,
  showByType: "all",
  isDescriptionShowed: false
};

export default connect(mapStateToProps, { removeFromShoppingCart })(SommaireItem);
